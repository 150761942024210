import React, { useState, useEffect } from 'react';
import {useGlobalContext} from "../../GlobalContext";

export default function SelectedFilters(){
  const context  = useGlobalContext()[0];
  const filtersReducer = context.filtersReducer;
  const [ search, setSearch ] = useState();

  useEffect(()=>{
    let type;
    if(filtersReducer.search && filtersReducer.search.type){
      type = filtersReducer.search.type.map((type)=>type.toLowerCase()).join(" / ")
      type =  " ("+type+")"
      let search = filtersReducer.search.string+type
      setSearch(search)
    }
  },[filtersReducer.search])

  return (
      <>
        { filtersReducer.selectedKeywords ?
          <div className="selected-keywords">
          { search ?
            <p className="search">{search}</p> : null
          }
          {
            filtersReducer.selectedKeywords.map((d,i)=>{
              return <p key={d.id+"-"+i} className={d.exclude ? "exclude" : ""}>{d.label}</p>
            })
          }
          </div>
          : null
        }
      </>
  )
}
