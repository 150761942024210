import React, { useState, useRef, useEffect, useLayoutEffect } from "react"
import { Translate, withLocalize } from "react-localize-redux";
import ReactTooltip from 'react-tooltip'
import MaterialIcon from 'material-icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChevronUp, faMedal, faComment, faPaperclip, faVideo } from '@fortawesome/free-solid-svg-icons'

import _ from "lodash"
import loader from '../../../assets/images/loader.gif'
import LoaderView from '../../partials/LoaderView'
import SearchByKeywords from '../../partials/SearchByKeywords'
import ExplorationNav2 from '../../partials/ExplorationNav2'

import { filtersParams }  from '../../../helpers/filtersParams'
import { renderDate }  from '../../../helpers/helpers'
import { useGlobalContext } from '../../../GlobalContext'

import useFilterView from '../../../customHooks/useFilterView'
import useContentDimension from '../../../customHooks/useContentDimension'
import useListDocuments from '../../../customHooks/useListDocuments'

import ImgItem from '../../partials/ImgItem'
import Picto from '../../partials/Picto'
import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'

import IconInc from  '../../../assets/images/hausse.js'
import Icondesc from  '../../../assets/images/baisse.js'

import { getFilteredNewspaper, removeNewspaper, setSelectedRubrique, orderBy } from '../../../actions/newspaper'
import { getReferences } from '../../../actions/references'

import { getPicto } from '../../../helpers/getPicto'

// publication date = DATE_COLLECT
// ressource date | action date  = PUB_DATE default
// ressource date -> à renomer action date

// ressource date = PUB_DATE toujours sous les documents


function initSelectedFilter(order,translate){
  let res;
  switch (order) {
    case "date_ressource_desc":
      res = {label: translate("global.action-date"), id: "0"}
      break;
    case "date_ressource_inc":
      res = {label: translate("global.action-date"), id: "1"}
      break;
    case "date_pub_desc":
      res = {label: translate("global.date-pub"), id: "2"}
      break;
    case "date_pub_inc":
      res = {label: translate("global.date-pub"), id: "3"}
      break;
    default:
      break;
  }

  return res;

}

const StrategicWatch = (props) =>{
  const contentDimension = useContentDimension();
  const [ context, dispatch ]  = useGlobalContext();

  const filtersReducer = context.filtersReducer
  const newspaperReducer = context.newspaperReducer
  const viewReducer = context.viewReducer

  const [ isFilterOpen , setIsFilterOpen ] = useState(false)
  const [ isTypoOpen , setIsTypoOpen] = useState(false)
  const [ previousWidth , setPreviousWidth] = useState()

  const [ pictoCollection, setPictoCollection ] = useState()

  const [ filterSelected , setFilterSelected ] = useState()
  const [ mapCountries, setMapCountries ] = useState(null);

  const [ paddingTop, setPaddingTop ] = useState(0);

  const defaultRubrique = props.entries
  // SET FILTERVIEW
  useFilterView(defaultRubrique);

  //ACTIONS
  const _setSelectedRubrique = (keyword) => setSelectedRubrique(dispatch,keyword)
  const _getFilteredNewspaper = (codes,change) =>  getFilteredNewspaper(dispatch,codes,change);
  const _orderBy = (order) =>  orderBy(dispatch,order);
  const _getReferences = (filter,page,nameReducer) => getReferences(dispatch, filter, page, nameReducer)
  const _removeNewspaper = () => removeNewspaper(dispatch)

  let listNewspaper = useListDocuments(
    context,
    newspaperReducer,
    newspaperReducer.newspaper,
    _getFilteredNewspaper,
    _removeNewspaper,
    defaultRubrique,
    props.viewFilters
  )

  useLayoutEffect(()=>{
    let newspaperHeader = document.querySelector('.header-newspaper')
    if(newspaperHeader) setPaddingTop(newspaperHeader.offsetHeight)
  },[contentDimension.width,filtersReducer.filters])

  // INIT PICTO COLLECTIONS
  useEffect(()=>{
    if(!filtersReducer.filters || pictoCollection) return;

    let findCex = []
    if(filtersReducer.filters.find((d)=> d.id === "FILTER_CEX2")){
      findCex = filtersReducer.filters.find((d)=> d.id === "FILTER_CEX2")
    }

    let findNbm = []
    if(filtersReducer.filters.find((d)=> d.id === "FILTER_NBM2")){
      findNbm = filtersReducer.filters.find((d)=> d.id === "FILTER_NBM2")
    }

    let arr = []
    let pictosNbm = getPicto(findNbm);
    let pictosCex = getPicto(findCex);
    if(pictosNbm) arr = [ ...arr, ...pictosNbm]
    if(pictosCex) arr = [ ...arr, ...pictosCex]

    // THERE ARE A PROBLEM THERE
    // console.log("picto",arr);

    let merged = new Map(arr)
    setPictoCollection(merged)



  },[filtersReducer.filters])

  useEffect(()=>{
    return () => {
      setPictoCollection(null)
    }
  },[props.match])

  useEffect(()=>{
    setFilterSelected(initSelectedFilter(newspaperReducer.orderBy,props.translate))
  },[props.activeLanguage.code])

  // RUBRIQUE
  useEffect(()=>{
    if(!filtersReducer.matchCode) return

    let filtersCountries = new Map()
    filtersReducer.matchCode.forEach((d)=>{
      if(d.id === "FILTER_GAR2") filtersCountries.set(d.code, d.label)
    })

    setMapCountries(filtersCountries)
  },[
    props.activeLanguage.code,
    filtersReducer.matchCode,
    filtersReducer.selectedHeaderEntries
  ])

  function openDocument(id){
    props.toggleDocument(id,true,'newspaper')
  }

  function toggleTypo(){
    setIsFilterOpen(false)
    setIsTypoOpen(!isTypoOpen)
  }

  function toggleFilter(){
    setIsTypoOpen(false)
    setIsFilterOpen(!isFilterOpen)
  }

  function selectFilter(e){
    scrollTop()
    let filter;
    let idFilter = parseInt(e.target.id);
    if (idFilter === 0) {
      filter = "date_ressource_desc"
    } else if (idFilter === 1) {
      filter = "date_ressource_inc"
    } else if (idFilter === 2) {
      filter = "date_pub_desc"
    } else if (idFilter === 3) {
      filter = "date_pub_inc"
    }

    if(filter !== 'reset'){
      _orderBy(filter)
      let result = filtersParams(filtersReducer,viewReducer.lang)
      let filters = _.cloneDeep(result.filters);
      filters.orderBy = filter

      let foundRubrique = filters.filters.find((d)=>{ return d.id === "RUBRIQUE"})

      if(!foundRubrique){
        let obj = {}
        defaultRubrique.forEach((d)=>{
          if(!obj[d.id]){
            obj[d.id] = { ...d }
          }else{
            obj[d.id].code += "%20OR%20"+d.code
          }
        })
        filters.filters = [obj.RUBRIQUE,...filters.filters]
      }

      listNewspaper.getDocuments(filters,"orderBy")
      setFilterSelected({label : e.target.textContent, id : e.target.id })
    }

  }

  function renderActors(d,doc){
    return d.split(";").join(" / ")+renderCountry(doc.FILTER_GAR2)
  }

  function renderCountry(d){
    if(!d || !mapCountries) return "";
    let arr = []
    if(d.length>1){
      arr = d.map((d)=>{
        return mapCountries.get(d)
      })
    }else{
      arr = d[0].split(";").map((d)=>{
        return mapCountries.get(d)
      })
    }
    return " - "+arr.join(" - ")
  }

  function renderRubrique(rubCode){
    if(!filtersReducer.matchCode) return null
    let found = filtersReducer.matchCode.find((d) => d.code === rubCode)

    if(found){
      return <span className="rubrique">{found.label}</span>
    }else{
      return ''
    }
  }

  function scrollTop(){
    let elt = document.getElementById("newspaper")
    elt.scrollTop = 0
  }

  function getImage(image){
    if(Array.isArray(image)){
      return image[0]
    }else if (image){
      return image
    }else{
      return null
    }
  }


  function renderTexte (texte,type){
    if(typeof texte !== "string") return;
    let re = /style="(.*?)"/g
    // let reHTML = /<[a-z][\s\S]*>/i
    let textWithoutStyle = texte.replace(re,"")
    return textWithoutStyle
  }

  function renderPictos(cex2,nbm2, className){
    if(!pictoCollection) return;
    let pictos = []
    if(cex2){
      cex2[0].split(";").forEach((d)=>{
        let icon = pictoCollection.get(d)
        if(icon){
          let split = icon.picto.split(" ")
          pictos.push(<Picto key={`${split[1]}-${d}`} color={`\#${icon.color}`} icon={split} code={d} tooltipColor="white" />)
        }

      })
    }
    if(nbm2){
      nbm2[0].split(";").forEach((d)=>{
        let icon = pictoCollection.get(d)
        if(icon){
          let split = icon.picto.split(" ")
          pictos.push(<Picto key={`${split[1]}-${d}`} color={`\#${icon.color}`} icon={split} code={d} tooltipColor="white" />)
        }

      })
    }
    if(pictos.length > 0){
      return <div className={"pictos "+className}>{pictos}</div>
    }
  }

  function getDocuments(){
    // return newspaperReducer.references
    return listNewspaper.documents
  }

  // Norwegian EV Association - Fastest chargers 150kWh
  // 20190117/53b19f06-d607-42dd-803e-210cd553c14d.
  return(
    <>
      {props.filterIsOpen !== null &&
        <>
          <div onScroll={listNewspaper.scroll} id="newspaper" className={props.filterIsOpen ? "newspaper filters-opened" : "newspaper"} style={{height : contentDimension.height}}>
            {<div className={viewReducer.lightmode === "light" ? "header-newspaper light" : "header-newspaper"}>
              {(props.showSearchbar || !props.hideHeaderButtons) && <div className={viewReducer.lightmode === "light" ? "container-search light" : "container-search"}>
                <div className="container-left">
                  { (!props.hideHeaderButtons && !props.filterIsOpen) &&
                    <ButtonFilters
                      toggleFilter={props.toggleFilter}
                    />
                  }
                  <SearchByKeywords
                    testId={"global"}
                    className={"header"}
                    iconPosition={"right"}
                    isMulti={true}
                    isClearable={true}
                    placeholder={"Filter par mots clés"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }}
                  />
                </div>
                <div className="container-right">
                 <ExplorationNav2 history={props.history} match={props.match} />
                  { !props.hideHeaderButtons &&
                    <ButtonDocs
                      documentsIsOpen={props.documentsIsOpen}
                      toggleDocument={props.toggleDocument}
                    />
                  }
                </div>
              </div>}
              <div className="container-filters">
                <div className="filters-list filter-date">
                  <p className={isFilterOpen ? "open" : ""}
                  onClick={toggleFilter}>
                    { filterSelected ?
                      <>
                      <Translate id="global.filter-by"/> <b>{filterSelected.label}</b>
                      {filterSelected.id === "0" || filterSelected.id === "2" ?
                        <Icondesc color={viewReducer.lightmode === "light" ? "#6E708C" : "#FFFFFF"} /> : <IconInc color={viewReducer.lightmode === "light" ? "#6E708C" : "#FFFFFF"} />
                      }
                      </> : <><Translate id="global.filter-by"/> ...</>
                    }
                    { isFilterOpen ?
                      <MaterialIcon key="arrow-up" icon="keyboard_arrow_up" size={18} /> :
                      <MaterialIcon key="arrow" icon="keyboard_arrow_down" size={18} />
                    }
                  </p>
                  { isFilterOpen ?
                    <div className="select sortBy" onMouseLeave={() => setIsFilterOpen(false)}>
                      <ul>
                        { !filterSelected || filterSelected.id !== "0" ? <li id="0" onClick={selectFilter}><Translate id="global.action-date" /> <Icondesc color={"#6E708C"} /></li> : null}
                        { !filterSelected || filterSelected.id !== "1" ? <li id="1" onClick={selectFilter}><Translate id="global.action-date" /> <IconInc color={"#6E708C"} /></li> : null}
                        { !filterSelected || filterSelected.id !== "2" ? <li id="2" onClick={selectFilter}><Translate id="global.date-pub" /> <Icondesc color={"#6E708C"} /></li> : null}
                        { !filterSelected || filterSelected.id !== "3" ? <li id="3" onClick={selectFilter}><Translate id="global.date-pub" /> <IconInc color={"#6E708C"} /></li> : null}
                      </ul>
                    </div> : null
                  }

                </div>
              </div>
            </div>}
            {(!getDocuments() && listNewspaper.isLoading) && <LoaderView/>}
            {(!listNewspaper.isLoading && listNewspaper.countDocs === 0) && <p className="zero-doc">0 Document</p>}
            {getDocuments() ?
              <div
                className={listNewspaper.isLoading || listNewspaper.disabledHitBottom ? "container-newspaper loading" : "container-newspaper"}
                style={{paddingTop : `${paddingTop}px`}}
              >
              <div className="scrollTop" onClick={scrollTop} ><FontAwesomeIcon icon={faChevronUp} /></div>
              {getDocuments().map((doc, i)=>{
                return (
                  <a onClick={(id) => openDocument(doc.ID_QES)} target="_blank" className="document" key={"doc-"+i}>
                    <div className="container-icons">
                      {doc.TOP_RESSOURCE ?
                        <>
                        <ReactTooltip className="react-tooltip white" delayShow={500} id='help-top-ressource' place="top" effect='solid'>
                          <span><Translate id="strategic-watch.top-ressources"/></span>
                        </ReactTooltip>
                          <div className="icon" data-tip data-for='help-top-ressource'><FontAwesomeIcon color={"#FF7D67"} icon={faMedal} /></div>
                        </> : null
                      }
                      {doc.COMMENTS_PLATFORM_count > 0 ?
                        <>
                          <ReactTooltip className="react-tooltip white" delayShow={500} id='help-commentaire' place="top" effect='solid'>
                            <span><Translate id="strategic-watch.comments"/></span>
                          </ReactTooltip>
                          <div className="icon" data-tip data-for='help-commentaire'><FontAwesomeIcon color={"#FF7D67"} icon={faComment} /></div>
                        </> : null
                      }
                      {doc.FILES ?
                        <>
                        <ReactTooltip className="react-tooltip white" delayShow={500} id='help-files' place="top" effect='solid'>
                          <span><Translate id="strategic-watch.linked-documents"/></span>
                        </ReactTooltip>
                        <div className="icon" data-tip data-for='help-files'><FontAwesomeIcon color={"#FF7D67"} icon={faPaperclip} /></div>
                        </> : null
                      }
                      {doc.VIDEO ?
                        <>
                          <ReactTooltip className="react-tooltip white" delayShow={500} id='help-video' place="top" effect='solid'>
                            <span><Translate id="strategic-watch.videos"/></span>
                          </ReactTooltip>
                          <div className="icon" data-tip data-for='help-video'><FontAwesomeIcon color={"#FF7D67"} icon={faVideo} /></div>
                        </> : null
                      }
                    </div>
                    <ImgItem image={getImage(doc.IMAGE)}/>
                    <div className="container-info">
                      <div className="sub">
                        {renderPictos(doc.FILTER_CEX2,doc.FILTER_NBM2,"hide-on-mobile")}
                        <p className="date">
                          { filterSelected.id === "0" || filterSelected.id === "1" ?
                             <>
                              {/* console.log(filterSelected) */}
                              {/* console.log(renderDate(doc.PUB_DATE,props.activeLanguage.code), 'PUB_DATE') */}
                              {renderRubrique(doc.RUBRIQUE[0])} | {renderDate(doc.PUB_DATE,props.activeLanguage.code)}
                             </> :
                             <>
                              {/* console.log(filterSelected) */}
                              {/* console.log(renderDate(doc.DATE_COLLECT,props.activeLanguage.code), 'DATE_COLLECT') */}
                              {renderRubrique(doc.RUBRIQUE[0])} | {renderDate(doc.DATE_COLLECT,props.activeLanguage.code)}
                             </>
                          }
                        </p>
                        {doc.ACTEURS_IMPLIQUES && doc.ACTEURS_IMPLIQUES.length > 0 ? <p>{renderActors(doc.ACTEURS_IMPLIQUES[0],doc)}</p> : null}
                        {renderPictos(doc.FILTER_CEX2,doc.FILTER_NBM2,"only-on-mobile")}
                      </div>
                      <TitleMobile title={doc.TITLE[0]}/>
                      <p className="title title-hide-on-mobile">{doc.TITLE[0]}</p>
                    </div>
                    <div className="container-more-info">
                      { doc.ABSTRACT &&
                        <span className="abstract" dangerouslySetInnerHTML={{ __html: renderTexte(doc.ABSTRACT[0],"abstract")}}></span>
                      }
                    </div>
                    <button className="read-more"><Translate id="documents.document-view.read-more"/></button>
                  </a>
                )
              })}
              {listNewspaper.isLoading || listNewspaper.disabledHitBottom ?
                <div className={"container-loader fullwidth"}>
                  <img className="loader" src={loader} alt="loader" />
                </div> : null
              }
              {!listNewspaper.isLoading && getDocuments().length === listNewspaper.countDocs ?
                <p className="loaded"><Translate id="global.all-docs"/></p> : null
              }
              </div>
              : null
            }

        </div>
        </>
      }
    </>
  )
}

const TitleMobile = ({title}) => {
  const titleMobileRef = useRef()
  const [ truncated, setTruncated ] = useState(false)

  useLayoutEffect(()=>{
    if(!titleMobileRef.current) return;
    if(titleMobileRef.current.offsetHeight > 64 && !truncated){
      setTruncated(true)
    }else if(truncated){
      setTruncated(false)
    }

  },[titleMobileRef])

  return (
    <div  ref={titleMobileRef} className="title title-only-on-mobile" style={truncated ? { height : "64px", overflow : "hidden"} : {}} >
      {title}
      {truncated && <div className="title-gradient"></div>}
    </div>
  )
}

export default withLocalize(StrategicWatch)
