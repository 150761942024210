const initialState = { explorationView : "expand", intelligenceView : "reduce", lightmode : "light", footerState : "minimize", lang : "en"};
// const initialState = { explorationView : "expand", intelligenceView : "reduce", lightmode : ""};

export default function countReducer(state = initialState, action) {
  switch (action.type) {
    case 'setLang':
      return {...state, lang : action.payload};
    case 'setFooterState':
      return {...state, footerState : action.payload};
    case 'setView':
      return {...state, ...action.payload};
    case 'lightmode':
      return {...state, lightmode : action.payload};
    default:
      return {...state};
  }
}
