import React, { useState, useEffect } from "react"
import _ from 'lodash'
// import WordsCloudViz from './WordsCloudViz3'
import WordsCloudVizUpdate from './WordsCloudVizUpdate'
import Export from '../../partials/Export'
import InfoExport from "../../partials/InfoExport";

import { fnExportImage } from '../../../helpers/helpers'

import LoaderView from '../../partials/LoaderView'
import SearchByKeywords from '../../partials/SearchByKeywords'
import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'
import ExplorationNav2 from '../../partials/ExplorationNav2'

import { useGlobalContext } from '../../../GlobalContext'
import useContentDimension from '../../../customHooks/useContentDimension'
import useFilterView from '../../../customHooks/useFilterView'

import { datavizParams } from '../../../helpers/filtersParams'
import { getNodes } from '../../../actions/dataviz'

const WordsCloud = (props) => {
  const [ context , dispatch ] = useGlobalContext();
  const contentDimension = useContentDimension();
  const filtersReducer = context.filtersReducer
  const datavizReducer = context.datavizReducer
  const viewReducer = context.viewReducer

  const [ init, setInit ] = useState(true)
  const [ data, setData ] = useState(null)
  const [ isWaitingForUpdate , setIsWaitingForUpdate] = useState(false)

  const _getNodes = (filter) => getNodes(dispatch, filter)

  // const defaultRubrique = [
  //    { code : "RUB_24", id : "RUBRIQUE" },
  //    { code : "RUB_58", id : "RUBRIQUE" },
  //    { code : "RUB_87", id : "RUBRIQUE" },
  //    { code : "RUB_71", id : "RUBRIQUE" }]

  const defaultRubrique = props.entries

  useFilterView(defaultRubrique)

  // get nodes
  useEffect(()=>{

    if(filtersReducer.date.min.date &&
      filtersReducer.date.max.date){
      // setData(null)
      let result = _.cloneDeep(datavizParams(filtersReducer,viewReducer.lang))
      setIsWaitingForUpdate(true)

      let isRUBSelected = result.filters.find((d)=> d.id === "RUBRIQUE") ? true : false

      if(isRUBSelected){
        result.filters = [ ...result.filters]
      }else{

        let obj = {}
        defaultRubrique.forEach((d)=>{
          if(!obj[d.id]){
            obj[d.id] = { ...d }
          }else{
            obj[d.id].code += "%20OR%20"+d.code
          }
        })
        if(Object.values(obj)[0]) result.filters.push(Object.values(obj)[0])

      }
      _getNodes(result)
    }

  },[
    defaultRubrique,
    viewReducer.lang,
    filtersReducer.selectedKeywords,
    filtersReducer.search,
    filtersReducer.filteredByDate,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date])

  useEffect(()=>{
    return () => {
      setData(null)
    }
  },[])

  useEffect(()=>{
    if(!datavizReducer.nodes || !filtersReducer.matchCode) return
    // let entriesWords = _.cloneDeep(datavizReducer.nodes)

    // REMOVE NODES WHICH CORRESPOND TO SELECTED FILTERS
    let selectedFilterCodes = filtersReducer.selectedKeywords.map(d=> d.code)
    let entriesWords = datavizReducer.nodes.filter(d => !selectedFilterCodes.includes(d.value) )

    let matchCodeConcept = filtersReducer.matchCode.filter((k) => k.id === "CONCEPT")

    let intersection = _.intersectionWith(entriesWords, matchCodeConcept, function(d, d2){
      return d.value.toLowerCase() === d2.value.toLowerCase()
    })

    intersection.forEach((d)=>{
      let found = matchCodeConcept.find((k)=> k.value.toLowerCase() === d.value.toLowerCase())
      d.id = found.id
    })

    if(isWaitingForUpdate){
      setTimeout(()=>{
        setIsWaitingForUpdate(false)
      },800)
    }

    setInit(false)
    setData(intersection)

  },[datavizReducer.nodes])

  function exportImage(){
    let exportGroup = document.querySelector(".wordscloud")
    let words = exportGroup.querySelectorAll(".wordscloud text");

    exportGroup.style.backgroundColor = getComputedStyle(exportGroup).backgroundColor

    let infoExport = exportGroup.querySelector(".info-export");
    if(infoExport) infoExport.style.display = "block"

    words.forEach((word)=>{
      let style = getComputedStyle(word);
      word.setAttribute("fill",style.fill)
    })

    let options = {
      width : document.querySelector(".wordscloud").clientWidth
    }

    fnExportImage(exportGroup,"wordscloud.png",options)
    exportGroup.style.backgroundColor = null

    if(infoExport) infoExport.style.display = "none"

  }

  return(
    <>
      <Export className="wordscloud-export" exportImage={exportImage} isLoading={datavizReducer.isLoading || isWaitingForUpdate || init}/>
      <div className={props.filterIsOpen ? "wordscloud filters-opened" : "wordscloud"} style={{height : contentDimension.height}}>
        <div className={viewReducer.lightmode === "light" ? "container-search light" : "container-search"}>
          <div className="container-left">
            { (!props.hideHeaderButtons && !props.filterIsOpen) &&
              <ButtonFilters
                toggleFilter={props.toggleFilter}
              />
            }
            <SearchByKeywords
              testId={"global"}
              className={"header"}
              iconPosition={"right"}
              isMulti={true}
              isClearable={true}
              placeholder={"Filter par mots clés"}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
            />
          </div>
          <div className="container-right">
            <ExplorationNav2 history={props.history} match={props.match} />
            { !props.hideHeaderButtons &&
              <ButtonDocs
                documentsIsOpen={props.documentsIsOpen}
                toggleDocument={props.toggleDocument}
              />
            }
          </div>
        </div>
        <InfoExport/>
        <div className={ datavizReducer.isLoading || isWaitingForUpdate ? "container-wordscloud loading " : "container-wordscloud"}>
          <WordsCloudVizUpdate data={data} isLoading={datavizReducer.isLoading || isWaitingForUpdate} activeZoom={true} maxFontSize={60} width={contentDimension.width < 1165 ?  contentDimension.width : contentDimension.width } height={contentDimension.height}/>
        </div>
        { (datavizReducer.isLoading || isWaitingForUpdate || init) &&
          <LoaderView/>
        }
      </div>
    </>
  )

}

export default WordsCloud
