import React, { useState, useEffect } from 'react'
import useContentDimension from '../../customHooks/useContentDimension'
import { useGlobalContext } from '../../GlobalContext'
import { filtersParams } from '../../helpers/filtersParams'

import GraphLine from './GraphLine';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { setFilterDate, setInputRange } from '../../actions/filters'

export default function GraphRange(props){
  const [ context , dispatch ] = useGlobalContext();
  const filtersReducer = context.filtersReducer
  const datavizReducer = context.datavizReducer
  const viewReducer = context.viewReducer
  const newspaperReducer = context.newspaperReducer

  const dateRange = filtersReducer.dateRange

  // ACTIONS
  const _setFilterDate = (obj) => setFilterDate(dispatch,obj)
  const _setInputRange = (obj) => setInputRange(dispatch,obj)

  const contentDimension = useContentDimension();
  const [ isDataLoading, setIsDataLoading ] = useState(0);
  const [ widthLeft, setWidthLeft ] = useState(0);
  const [ widthRight, setWidthRight ] = useState(0);

  const [ value, setValue ] = useState({min : 0, max : 1});
  const [ inputRangeInterval, setInputRangeInterval ] = useState({min : 0, max : 1});
  const [ dataGraphLine , setDataGraphLine ] = useState(null)

  const [ timer , setTimer ] = useState(null)

  //HANDLE DATE IS LOADING
  useEffect(()=>{
    // newspaperReducer.isLoading is for
    // NEWSPAPER

    // datavizReducer.isLoading is for
    // DASHBOARD, WORDCLOUD, LINK EXPLO,
    // PARTNERSHIP, GEOVIZ, TIMELINE
    let isLoading = false
    if(datavizReducer.isLoading || newspaperReducer.isLoading){
      isLoading = true
    }
    setIsDataLoading(isLoading)

  },[datavizReducer.isLoading,newspaperReducer.isLoading])

  useEffect(()=>{
    if(dateRange){
      setDataGraphLine(dateRange)
      setInputRangeInterval({min : 0, max : dateRange.length-1 <= 0 ? 1 : dateRange.length-1})
      setValue({min : 0, max : dateRange.length-1 < 0 ? 1 : dateRange.length-1})
      // updateOverlay({min : 0, max : dateRange.length-1},dateRange)
    }
  },[dateRange])

  useEffect(()=>{
    if(filtersReducer.inputRange){
      let valueMin = filtersReducer.inputRange.min;
      let valueMax = filtersReducer.inputRange.max;
      setValue({min : valueMin, max : valueMax})
      // updateOverlay({min : valueMin, max : valueMax},dataGraphLine)
    }
  },[])

  function onChangeRange(value){
    if(isDataLoading) return;
    setValue({min : value.min, max : value.max })
  }

  function onChangeRangeComplete(){
    let dateMin = dataGraphLine[value.min].date;
    let dateMax = dataGraphLine[value.max].date;
    _setInputRange({min : value.min, max : value.max })
    _setFilterDate({min : { date : dateMin, value : value.min }, max : { date : dateMax, value : value.max }})
  }

  // function widthOverlayLeft(val,arrValue){
  //   let step = (contentDimension.width-140)/(arrValue.length-1)
  //   setWidthLeft(val.min*(step))
  // }

  // function widthOverlayRight(val,arrValue){
  //   let step = (contentDimension.width-140)/(arrValue.length-1)
  //   if(contentDimension.width-(val.max*(step)) - 140 > 0){
  //     setWidthRight(contentDimension.width-(val.max*(step)) - 140);
  //   }else{
  //     setWidthRight(0);
  //   }
  // }

  // function updateOverlay(val,arrValue){
  //   widthOverlayLeft(val,arrValue)
  //   widthOverlayRight(val,arrValue)
  // }

  function formatLabel(value,label){
    let date = dataGraphLine[value];
    if(!date) return null;
    date = date.date
    let month = parseInt(date.getMonth())+1
    let formatDate = month < 10 ? "0"+month : month
    formatDate += "."
    formatDate += date.getFullYear().toString()
    return `${formatDate}`
  }

  function renderClassNames(){
    let disabled = isDataLoading ? " disabled" : ""
    return !props.graphLine ? "container-input-range label-below"+disabled : "container-input-range"+disabled
  }

  return(
    <>
      { dataGraphLine && props.graphLine ?
        <>
          <div className="overlay-left" style={{ width : widthLeft}}></div>
          <div className="overlay-right" style={{ width : widthRight}}></div>
          <GraphLine
            footerState={viewReducer.footerState}
            data={dataGraphLine}
            nodesDates={datavizReducer.nodesDates}
            current={value}/>
        </> : null
      }
      { dataGraphLine ?
        <div className={renderClassNames()}>
          <InputRange
          maxValue={inputRangeInterval.max}
          minValue={inputRangeInterval.min}
          formatLabel={formatLabel}
          value={value}
          onChange={value => onChangeRange(value)}
          onChangeComplete={value => onChangeRangeComplete(value)}
          />

        </div> : null
      }

    </>
  )
}

//
