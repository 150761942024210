import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3';
import _ from 'lodash';
import { useGlobalContext } from '../../../GlobalContext'
import useContentDimension from '../../../customHooks/useContentDimension'

import {
  addSelectedKeywords,
  removeSelectedKeyword
} from '../../../actions/filters'

const NetworkViz = (props) => {
  const contentDimension = useContentDimension();
  const [ configRadius ]  =  useState({min : 5, max : 35})
  // const [ configThickness ] =  useState({min : .2, max : 5})
  const [ configThickness ] =  useState({min : 1, max : 5})
  const [ circles, setCircles] = useState()
  const [ links, setLinks] = useState()
  const [ labels, setLabels] = useState()

  const [ context, dispatch ]  = useGlobalContext();
  const filtersReducer = context.filtersReducer

  const [ data ] = useState(() => {
    return _.cloneDeep(props.data)
  })

  let simulationRef = useRef()

  //ACTIONS
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)
  const _removeSelectedKeyword = (keyword) => removeSelectedKeyword(dispatch,keyword)

  /// INIT NETWORK VIZ
  useEffect(()=>{

    /// SCALE OPACITY FOR LINKS

    let maxCount = 0
    let minCount = 0
    if(data.links.length > 0 ) maxCount = _.maxBy(data.links,(d)=> d.count).count
    if(data.links.length > 0 ) minCount = _.minBy(data.links,(d)=> d.count).count

    let opacityRange = []
    for(let i = 0; i <= 50; i++){
      opacityRange.push(i/100)
    }

    let domain = []
    _.each(opacityRange, function(c,i){
      if(i === 0){
        domain.push(minCount)
      }else if(i === opacityRange.length - 1){
        domain.push(maxCount)
      }else{
        domain.push((maxCount-minCount)/(opacityRange.length-i))
      }
    })

   let scaleOpacity = d3.scaleLinear()
     .domain(domain)
     .range(opacityRange);

    ////////////////////////

    /// SCALE RADIUS FOR LINKS

    let maxCountNode = 0
    let minCountNode = 0
    if(data.nodes.length > 0 ) maxCountNode = _.maxBy(data.nodes,(d)=> d.count).count
    if(data.nodes.length > 0 ) minCountNode = _.minBy(data.nodes,(d)=> d.count).count

    let scaleRadius = d3.scaleLinear()
      .domain([minCountNode,maxCountNode])
      .range([configRadius.min,configRadius.max]);

    let scaleThickness = d3.scaleLinear()
      .domain([minCount,maxCount])
      .range([configThickness.min,configThickness.max]);

    data.nodes.forEach((node)=>{
      node.radius = scaleRadius(node.count)
    })

    data.links.forEach((link)=>{
      link.thickness = scaleThickness(link.count)
    })

    let svgTranslationX = contentDimension.width < 1165 ? props.width/2 : (props.width/2)

    let svg = d3.select('.networkViz')
                .append('svg')
                .attr('width', props.width)
                .attr('height', props.height)
                .append("g")
                .attr("id","container-group")
                .attr("transform",
                      "translate("+ svgTranslationX +", "+ props.height/2  +")");

    let selectorLinks = svg.append("g")
                   .attr("id","group-lines")
                   .selectAll('line')
                   .data(data.links)

    let eltLinks = selectorLinks.enter()
                   .append("line")
                   .attr('class','line')
                   .style('opacity',(d)=>{
                     if(props.colorLinks) return 1
                     return scaleOpacity(d.count)
                   })
                   .style('stroke',(d)=>{
                     let color;
                     if(props.colorLinks) color = props.colorLinks[d.type].color
                     return color
                   })
                   .style('stroke-width', (d)=>{
                     if(props.strokeWidth) return props.strokeWidth+"px"
                     return d.thickness+'px'
                   }).on("click", (d)=>{
                     selectEntries([d.source,d.target])
                     // console.log(d);
                   }).on("mouseover", (d,i)=>{
                      let elt = document.getElementById("group-lines").childNodes[i]
                      let thickness = props.strokeWidth ? props.strokeWidth : d.thickness
                      elt.setAttribute("stroke-width",2*thickness+"px")
                      elt.style["stroke-width"] = 2*thickness+"px"
                   }).on("mouseout", (d,i)=>{
                     let elt = document.getElementById("group-lines").childNodes[i]
                     let thickness = props.strokeWidth ? props.strokeWidth : d.thickness
                     elt.setAttribute("stroke-width", thickness+"px")
                     elt.style["stroke-width"] = thickness+"px"
                   })

   let selectorCircles = svg.append("g")
               .attr("id","group-circles")
               .selectAll('circle')
               .data(data.nodes, function(d, i) { return d.value; })


    let eltCircles = selectorCircles.enter()
                .append('g')
                .attr("id", (d,i) => {
                  return sanitizeId(d.value)+"-"+i
                })
                .append('circle')
                .attr("class",(d) =>{
                  if(d.actived) return "circle active"
                  return "circle"
                })
                // .attr('r', (d) => {return 10 } )
                .attr('r', (d) => {return d.radius } )
                .attr('fill',(d)=>{
                  if(d.color){
                    return d.color
                  }else{
                    return 'white'
                  }
                })
                .attr('stroke',(d)=>{
                  if(d.color){
                    return d.color
                  }else{
                    return 'white'
                  }
                })
                .attr('cx',(d,i)=>{ return props.width/2})
                .attr('cy',(d,i)=>{ return props.height/2})
                .on("click", d => selectEntries([d]))
                .call(
                  d3.drag()
                  .on("start", dragstarted)
                  .on("drag", dragged)
                  .on("end", dragended)
                );


    eltCircles.each((d,i)=>{

      // let circleGroupe = d3.select("#"+d.value.replace(';',""))
      let circleGroupe = d3.select("[id='"+sanitizeId(d.value)+"-"+i+"']")
      circleGroupe.append('text')
      .attr("id",(d,i)=>{ return "text-"+sanitizeId(d.value)+"-"+i})
      .attr("class",(d)=>{

        if(props.labelCentered){
          return "label dark"
        }else{
          return "label"
        }
      })
      .attr('opacity',(d)=>{
        return d.radius > 3 ? 1 : 0
        // return d.radius > 12 ? 1 : 0
      })
      .attr('font-size',12)
      .attr('font-family','Verdana')
      .attr('text-anchor','middle')
      .style('transform-origin', 'center')
      .text((d)=>{
        if(d.name){
          return d.name
        }else if(!d.name && d.value){
          return d.value
        }

      })

      // eltLabels.push(circleGroupe)

    })


    let eltLabels = d3.selectAll("#group-circles g text")

    setCircles(eltCircles)
    setLinks(eltLinks)
    setLabels(eltLabels)

    return () => {
      eltLinks.remove();
      eltCircles.remove();
      eltLabels.remove();
      d3.select('.networkViz').selectAll('svg').remove();
    }

  },[])

  function dragstarted() {
    d3.event.sourceEvent.stopPropagation();
    d3.select(this).classed("fixed", true);
    if (!d3.event.active) simulationRef.current.alphaTarget(0.3).restart();
    d3.event.subject.fx = d3.event.subject.x;
    d3.event.subject.fy = d3.event.subject.y;
  }
  
  function dragged() {
    d3.event.subject.fx = d3.event.x;
    d3.event.subject.fy = d3.event.y;
  }
  
  function dragended() {
    if (!d3.event.active) simulationRef.current.alphaTarget(0);
    d3.event.subject.fx = d3.event.x;
    d3.event.subject.fy = d3.event.y;
    simulationRef.current.alpha(1).restart();
  }

  // /// APPLY FORCE
  useEffect(()=>{
    if(circles && labels && links){

      var zoom = d3.zoom()
      .scaleExtent([.5, 20])
      .on("zoom", zoomed);

      d3.select('.networkViz').selectAll('svg').call(zoom)

      // console.log('APPLY FORCE');
      let simulation = d3.forceSimulation()
      simulation.nodes(data.nodes)
      simulation.alphaMin(0.02)
      simulation.alphaDecay(0.05)

      simulationRef.current = simulation

      simulationForces(simulation,data.links)
      simulation.on("tick", forceTicked)
                .on("end", () => { simulationEnd() })

    }
  },[circles, links])

  useEffect(()=>{
    if(!circles) return;

    circles.each((d,i)=>{
      let circleGroupe = d3.select("[id='"+sanitizeId(d.value)+"-"+i+"']")
      circleGroupe.style('opacity',(d)=>{
        if(props.enableAge){
          return d.age
        }else{
          return 1
        }
      })
    });

  },[props.enableAge])

  useEffect(()=>{
    d3.select('.networkViz').select("svg")
      .attr("height", props.height)
  },[props.height])

  function simulationForces(sim,links){
    const forceX = d3.forceX(0).strength(props.gravityStrength)
    const forceY = d3.forceY(0).strength(props.gravityStrength)

    sim.force("links", d3.forceLink(links)
          .id(function(d) { return d.value; })
          .distance(function(d) {
            return 100 - 10*d.thickness
          })
        )
        .force("charge", d3.forceManyBody()
          .strength(function(d) {
            // return -2000
            return -100-(100*d.radius)
          })
        )
        .force('x', forceX)
        .force('y',  forceY)

        // .force("center", d3.forceCenter(props.width / 2, props.height / 2))

  }

  function forceTicked(i) {
    // simulation.alphaTarget(.2).restart();
    circles.attr("cx", function (d,i) {
      return d.x;
      }).attr("cy", function(d) { return d.y; });


    links.attr("x1", function(d) { return d.source.x })
        .attr("y1", function(d) { return d.source.y; })
        .attr("x2", function(d) { return d.target.x; })
        .attr("y2", function(d) { return d.target.y; });

    labels.attr("x", function (d) {return d.x; })
        .attr("y", function(d) {
          if(props.labelCentered){
            return d.y+3;
          }else{
            return d.y - (d.radius+10);
          }
        });
  }

  function zoomed() {
    let groupContainer = d3.select("#container-group")
    let groupCircles = d3.select("#group-circles")
    let groupLines = d3.select("#group-lines")

    d3.selectAll("#group-circles g").each(function(d, i) {

      d3.select("g [id='"+sanitizeId(d.value)+"-"+i+"'] text")
      .attr('font-size',12*1/d3.event.transform.k)
         .attr('transform',"translate( 0 "+d3.event.transform.k+ ")")
         .attr('opacity',(d)=>{
           return d.radius*d3.event.transform.k > 3 ? 1 : 0
         })
    });


    let svgTranslationX = contentDimension.width < 1165 ? props.width/2 : (props.width/2)

    groupContainer.attr("transform", "translate(" + svgTranslationX*d3.event.transform.k+" "+(props.height/2)*d3.event.transform.k+ ")");

    groupCircles.attr("transform", "translate(" + d3.event.transform.x+" "+d3.event.transform.y+ ")scale(" + d3.event.transform.k + ")");
    groupLines.attr("transform", "translate(" + d3.event.transform.x+" "+d3.event.transform.y+ ")scale(" + d3.event.transform.k + ")");

  }

  function selectEntries(entries){

    let add = []
    let remove = []

    entries.forEach((d)=>{
      let isSelected;
      if(filtersReducer.selectedKeywords && filtersReducer.selectedKeywords.length > 0){
        isSelected = filtersReducer.selectedKeywords.find((k)=> k.code.toLowerCase() === d.value.toLowerCase())
      }

      if(!isSelected){
        let keyword = {
          code : d.value,
          id : d.category,
          label : d.name ? d.name : d.value,
          value : d.name ? d.name : d.value
        }

        add.push(keyword)
      }else if(isSelected){
        remove.push({value : isSelected.value})
      }
    })

    if(add.length>0){
      _addSelectedKeywords(add)
    }

    if(remove.length>0){
      _removeSelectedKeyword(remove)
    }


  }

  function simulationEnd(){
    if(props.simulationEnd){
      props.simulationEnd()
    }
  }

  function sanitizeId(val){
    return val.replace("'","").replace(";","").replace(" ","")
  }

  return (
    <>
      <div className="networkViz" >
      </div>
    </>
  )
}

const NetworkVizMemo = React.memo(NetworkViz)

export default NetworkVizMemo;
