import React from 'react'
import { NavLink } from "react-router-dom"
import styles from './grantthornton.module.scss'
import { API_URL } from '../../../../actions/index';
import logo from '../../../../assets/images/logo-grantthornton.png'

const Grantthornton = () => {
  return (
    <div className={`${styles["sso"]}`}>
      <div>

        <div className={`${styles["container"]}`}>
          <h2>
            Connectez-vous<br/>à votre espace
            Grant Thornton !
          </h2>
          <NavLink to="/">
            <button>Login standard</button>
          </NavLink>
          <a href={`${API_URL}auth/grantthornton/login-saml`}>
            <button>Login SSO</button>
          </a>
        </div>
      </div>
      <div className={`${styles["image-container"]}`}>
        <img src={logo} alt="Grant Thornton" />
      </div>
    </div>
  )
}

export default Grantthornton;
