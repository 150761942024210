import React from 'react'
import _ from "lodash"
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver';
import moment from "moment"
const listMonthFr =
["janvier",
"février",
"mars",
"avril",
"mai",
"juin",
"juillet",
"août",
"septembre",
"octobre",
"novembre",
"décembre"];

const listMonthEn =
["january",
"february",
"March",
"April",
"May",
"June",
"July",
"August",
"September",
"October",
"November",
"December"];

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function getIndexLang(lang){
  if(!lang) return 0
  let index
  if(lang.code === "en"){
      index = 0
  }else if (lang.code === "fr") {
      index = 1
  }
  return index;
}

export function getLinkDocument(doc,filtersReducer){
  let splitID = doc.ID_QES.split('/')
  let search = { id : splitID[0], doc : splitID[1]}
  return window.location.origin+`/document?platform=${filtersReducer.plateformeID}&entry=${filtersReducer.indexSelectedEntries}&id=${search.id}&doc=${search.doc}`
}


export function renderNote(document){
  let note = parseInt(document.TRAITEMENT_INFO)

  let dots = []
  for(let i = 0; i < 5; i++){
    let active = ""
    if(i < note) active = "active"
    dots.push(
      <div key={"dot-"+i} className={"dot "+active}></div>
    )
  }

  return <div className="notation">{dots}</div>

}

export function renderDate(inputDate,lang = "en"){
  let date = new Date(inputDate)
  let day = date.getDate()
  let year = date.getFullYear()
  let month = date.getMonth()

  let stringMonth;
  if(lang === "fr"){
   stringMonth = listMonthFr[month]
  }else{
    stringMonth = listMonthEn[month]
  }

  return day+" "+stringMonth+" "+year
}

export function renderState(etat){
  let state;
  switch (etat) {
    case "1":
      state = 'Brouillon'
      break;
    case "4":
      state = 'Collecte'
      break;
    case "60":
      state = 'Archive'
      break;
    case "70":
      state = 'Supprimé'
      break;
    case "100":
      state = 'Publié'
      break;
    default:
      state = 'Non renseigné'
  }

  return <p className="state">{state}</p>

}

export function fnSanitizeFilters(filters){
  let listFilters = _.cloneDeep(filters)

  listFilters.forEach((filter)=>{
    let valeurs = []
    let realCount = {}

    //GET CORRECT FREQUENCE FOR FILTER FIRST LEVEL
    if(/FILTER/.test(filter.id) && filter.id[filter.id.length-1] !== "2"){
      let findSecondLevel = listFilters.find(d=> d.id === filter.id+"2")
      if(findSecondLevel){
        findSecondLevel.valeurs.forEach(d=>{
          if(!realCount[d.code.substring(0,6)]){
            realCount[d.code.substring(0,6)] = parseInt(d.frequence)
          }else{
            realCount[d.code.substring(0,6)] += parseInt(d.frequence)
          }
        });

        filter.valeurs.forEach(d=>{
          if(realCount[d.code]){
            d.wrongFrequence = d.frequence
            d.frequence = realCount[d.code].toString()
          }
        })
      }
    }


    filter.valeurs.forEach((value)=>{
      if((value.code !== value.libelle || !/FILTER/.test(filter.id)) ||
        /QES|ACTEURS_IMPLIQUES/.test(filter.id)
      ){
        valeurs.push(value)
      }
    })

    filter.valeurs = valeurs

  })

  return listFilters

}

export function clusteriser (docs,scaleOffsetX, clusterPeriod){
  let data = _.cloneDeep(docs)
  let cluster = { length : 0}
  let firstPosition = 2;
  let arrPosition = [ 0, 1, 2, 3, 4, 5, 6]
  let docsPosition = []

  data.forEach((d)=>{
    let date = new Date(d.date_pub)
    let timestamp = Math.floor(date.getTime()/1000);
    let period;
    if(clusterPeriod === "days"){
       period = moment(date).days()+"-"+moment(date).week()+"-"+date.getFullYear()
    }else if(clusterPeriod === "per4Weeks"){
      let t;
      if(moment(date).day()<=1){
        t = 1
      }else if(moment(date).day()>1 && moment(date).day()<=3){
        t = 2
      }else if(moment(date).day()>3 && moment(date).day()<=5){
        t = 3
      }else{
        t = 4
      }

       period = moment(date).week()+"-"+t+"-"+date.getFullYear()
    }else if(clusterPeriod === "halfWeeks"){
      let t;
      if(moment(date).day()<4){
        t = 1
      }else{
        t = 2
      }

       period = moment(date).week()+"-"+t+"-"+date.getFullYear()
    }else if(clusterPeriod === "weeks"){

       period = moment(date).week()+"-"+date.getFullYear()
    }else if(clusterPeriod === "2weeks"){
      let t = moment(date).week() % 2 === 0 ? moment(date).week() : moment(date).week()+1;
      period = t+"-"+date.getFullYear()
    }else if(clusterPeriod === "month"){
      period = moment(date).month()+"-"+date.getFullYear()
    }


    if(cluster[period+"-"+d.rubrique] && d.rubrique){
      cluster[period+"-"+d.rubrique].nodes.push(d)
      cluster[period+"-"+d.rubrique].timestamp = timestamp
      cluster[period+"-"+d.rubrique].offsetX = scaleOffsetX(timestamp)

    }else if(d.rubrique){

      cluster[period+"-"+d.rubrique] = {}

      let i = cluster.length
      if(i === 0){
        docsPosition.push(firstPosition)
        cluster[period+"-"+d.rubrique].lineIndex = 2
      }else{

        let prevPosition = docsPosition[i-1]
        let arrPositionEmpty = []
        if(prevPosition-2 >= 0){
          arrPositionEmpty.push(prevPosition-2)
        }

        if(prevPosition-1 >= 0){
          arrPositionEmpty.push(prevPosition-1)
        }
        arrPositionEmpty.push(prevPosition)
        if(prevPosition+1 <= 6){
          arrPositionEmpty.push(prevPosition+1)
        }

        if(prevPosition+2 <= 5){
          arrPositionEmpty.push(prevPosition+2)
        }

        let possibilityPosition = _.difference(arrPosition,arrPositionEmpty)
        let x = ~~(Math.random() * possibilityPosition.length);
        docsPosition.push(possibilityPosition[x])
        cluster[period+"-"+d.rubrique].lineIndex = possibilityPosition[x]


      }

      cluster[period+"-"+d.rubrique].rubrique = d.rubrique
      cluster[period+"-"+d.rubrique].timestamp = timestamp
      cluster[period+"-"+d.rubrique].date = d.date_pub
      cluster[period+"-"+d.rubrique].nodes = [d]
      cluster[period+"-"+d.rubrique].offsetX = scaleOffsetX(timestamp)
      cluster.length++
    }

  })

  return cluster

}

export function fnGroupByAdvancedFilters(groupFilter,filters){

  let groupe = _.cloneDeep(groupFilter)

  _.forEach(groupe,(d)=>{

    let arrCat = d.categories

    if(arrCat){
      arrCat.forEach((d)=>{
        if(d[d.length-1] === "2"){
          let parentCat = d.slice(0,-1)
          arrCat.push(parentCat)
        }
      })
      d.regex = new RegExp(escapeRegExp(arrCat.join('|')))
    }
    d.filters = []
  })


  filters.forEach((filter)=>{

    let found = groupe.find((d)=>{
      return d.regex && d.regex.test(filter.id)
    })


    if(found){
      found.filters = [...found.filters, filter]
    }else{
      groupe[groupe.length-1].filters = [...groupe[groupe.length-1].filters, filter]
    }

  })

  return groupe

}

export function isNumericValues(values,key){
  let reIntegerPositionOrNegative = new RegExp(/^-?\d+$/)
  let reFloatPositionOrNegative = new RegExp(/^-?\d+\.\d+$/)
  let isNumeric = values.every(value => {
    return(
      reIntegerPositionOrNegative.test(value[key]) ||
      reFloatPositionOrNegative.test(value[key])
    )
  })
  return isNumeric
}

export function fnGroupBy(groupFilter,filters){
  let groupe = _.cloneDeep(groupFilter)

  _.forEach(groupe,(d)=>{
    if(d.categories){
      let categories = d.categories;
      let sortFilters = [];
      categories.forEach((d)=>{
        let cat = d
        
        if(d.code){
          cat = d.code
        }
        let found = filters.find((j)=>j.id === cat)
        // console.log(filters.filter((j)=>j.id === cat),cat);
        // SU_NUM_FUNDING_ROUND
        if(found){



          // found.valeurs.forEach(value => {
          //   console.log(re.test(value),value);
          // })

          if(d.code && d.code[d.code.length-1] !== "2" && d.order){
            // THIS IS A FIX
            // IN CONFIG ORDER.PROPS to "label" instead of "libelle"
            // DONT WANT TO ASK THE CLIENT TO CHANGE EVERY  "label" in "libelle"

            if(d.order.props === "label"){
              let isNumeric = isNumericValues(found.valeurs,"libelle")
              if(isNumeric){
                found.valeurs = _.orderBy(found.valeurs,(value) => {
                  return parseFloat(value.libelle)
                },[d.order.sort])
              }else{
                found.valeurs = _.orderBy(found.valeurs,["libelle"],[d.order.sort])
              }

            }else if(d.order.props === "frequence"){
              found.valeurs = _.orderBy(found.valeurs,[(k) => parseInt(k.frequence)],[d.order.sort])
            }else{
              found.valeurs = _.orderBy(found.valeurs,[d.order.props],[d.order.sort])
            }

          }

          if(d.openFilters){
            sortFilters.push({
              ...found,
              openFilters : d.openFilters,
              help : d.help ? d.help : null,
              order : d.order ? d.order : null,
              openFilters : d.openFilters ? d.openFilters : null
            })
          }else sortFilters.push({
            ...found,
            help : d.help ? d.help : null,
            order : d.order ? d.order : null,
            openFilters : d.openFilters ? d.openFilters : null
          })
        }

      })

      d.filters = sortFilters

    }else{
      d.filters = []

    }

  })

  return groupe

}

export function fnExportImage(elt,fileName,options){

  let containerSearch = elt.querySelector(".container-search");
  if(containerSearch) containerSearch.style.display = "none"

  html2canvas(elt,options).then(canvas => {
    canvas.toBlob(function(blob) {
      if(!blob) return;
      // var url = URL.createObjectURL(blob);
      // console.log(blob);
      saveAs(blob, fileName);
      if(containerSearch) containerSearch.style.display = "flex"

    });
  });

}

export function getDownloadBaseUrl(ext){
  if(ext === 'pdf' || ext === 'pptx' || ext === 'docx' || ext === 'mp4'){
    return "https://imakerqwam.intelligencemaker.com/imakerapi/servlet/QESServlet_fwdBlob?Base=22801201&Fichier="
  }else{
    return "https://imakerqwam.intelligencemaker.com/imakerapi/servletpub/QESServlet_fwdImg?Base=22801201&Fichier="
  }
}

export function getExt(doc){
  let rePDF = /.pdf$/g
  let reDOCX = /.docx$/g
  let rePPTX = /.pptx$/g
  let reMp4 = /.mp4$/g

  let ext;
  switch (true) {
    case rePDF.test(doc):
      ext = "pdf"
      break;
    case reMp4.test(doc):
      ext = "mp4"
      break;
    case reDOCX.test(doc):
      ext = "docx"
      break;
    case rePPTX.test(doc):
      ext = "pptx"
      break;
    default:
      ext = ""
  }
  return ext
}
