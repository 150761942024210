import React, { useState, useEffect, useRef } from 'react';
import ListeDocuments from './listeDocuments/ListeDocuments'
import DocView from './DocView'

const Documents = (props) => {
  const [selectedDocID , setSelectedDoc] = useState(null)
  const isInitialMount = useRef(true);

  useEffect(()=>{
    if(props.idSelectedDoc || props.idSelectedDoc === null){
      setSelectedDoc(props.idSelectedDoc)
    }
  },[props.idSelectedDoc])


  useEffect(()=>{

    if (isInitialMount.current) {
      isInitialMount.current = false;
    }else{
      // TOGGLE DOC IF THE DOC WAS OPEN NOT FROM THE <ListeDocuments/> COMPONENT
      if(!selectedDocID && props.idSelectedDoc){
        props.toggleDocument(null,false);
      }

    }
  },[selectedDocID])

  function selectDoc(doc){
    if(doc){
      setSelectedDoc(doc)
    }else{
      setSelectedDoc(null)
    }
  }

  return(
    <>
    {selectedDocID ?
      <>
        <DocView match={props.match} history={props.history} originOpenDocs={props.originOpenDocs} references={props.references} documentID={selectedDocID} selectDoc={(doc) => selectDoc(doc)}/>
      </>
       : null
    }
    { !props.idSelectedDoc &&
      <ListeDocuments
        closeDocuments={props.closeDocuments}
        selectDoc={(doc) => {
          setSelectedDoc(doc.ID_QES)
        }}
       />
    }
    </>
  )
}

export default Documents
