import React from 'react';

export default function IconPartnerships(){
  return(
		<svg version="1.1" x="0px" y="0px" width="25px" height="25px"
				 viewBox="0 0 493.4 493.4" >
			<g>
				<path fill="#8A8BA2" d="M358.4,217.7l-95.3,89.9c-2.4,2.3-2.8,4.7-1.4,7.4c1.5,2.7,4.1,4,7.9,4h61v162.6c0,2,0.8,3.6,2.4,4.9
					c1.6,1.3,3.7,1.9,6.3,1.9h52.3c2.5,0,4.6-0.6,6.3-1.9c1.6-1.3,2.4-2.9,2.4-4.9V319h61c3.6,0,6.3-1.3,7.9-4c1.5-2.7,0.9-5.2-1.6-7.4
					l-96.4-89.9c-1.8-1.4-4-2.1-6.5-2.1C362.3,215.5,360.2,216.3,358.4,217.7z"/>
			</g>
			<path fill="#8A8BA2" d="M137.8,303.5c37.1,0,67.2-31.6,67.2-70.5c0-39-9.9-70.5-67.2-70.5S70.6,194,70.6,232.9C70.6,271.9,100.7,303.5,137.8,303.5z
				"/>
			<path fill="#8A8BA2" d="M264.8,444.7C264.8,443.9,264.8,439.4,264.8,444.7L264.8,444.7z"/>
			<path fill="#8A8BA2" d="M264.6,439.1c-1.2-79.6-11.5-102.2-90-116.6c0,0-11,14.3-36.8,14.3S101,322.6,101,322.6c-77.6,14.2-88.5,36.5-89.9,114
				c-0.1,6.3-0.2,6.7-0.2,5.9c0,1.4,0,3.9,0,8.4c0,0,18.7,38.2,126.9,38.2c108.2,0,126.9-38.2,126.9-38.2c0-2.9,0-4.8,0-6.2
				C264.7,445.1,264.7,444.2,264.6,439.1z"/>
			<path fill="#8A8BA2" d="M365.5,89c23.5,0,42.6-19.5,42.6-43.5c0-24-6.3-43.5-42.6-43.5s-42.6,19.5-42.6,43.5C322.8,69.5,341.9,89,365.5,89z"/>
			<path fill="#8A8BA2" d="M304.4,196.1l53.2-51.4c2-1.6,4.4-2.4,7-2.4c2.8,0,5.3,0.8,7.3,2.4l54.1,51.6c16.1-6.8,20-14.7,20-14.7c0-1.8,0-3.1,0-3.9
				c0,0.3,0-0.3-0.1-3.5c-0.8-50.5-7.3-64.8-57.1-73.9c0,0-7,9-23.3,9c-16.3,0-23.3-9-23.3-9c-49.2,9-56.1,23.2-57,72.3
				c-0.1,4-0.1,4.2-0.1,3.8c0,0.9,0,2.5,0,5.3C285,181.7,288.7,189.4,304.4,196.1z"/>
		</svg>
  )
}
