import React, { useState } from 'react';
import MaterialIcon from 'material-icons-react'
const compose = (...functions) => args => functions.reduceRight((arg, fn) => fn(arg), args);

export default function InputField(props){

  const inputAttributes = props.initialState.DOMAttribute
  const setType = props.initialState.setType
  const clearInput = compose(props.fnClear, props.initialState.clearInput)

  const [ initialType ] = useState(inputAttributes.type)


  function showPassword(){
    if(inputAttributes.type === "password"){
      setType('text')
    }else if(inputAttributes.type === "text"){
      setType('password')
    }
  }

  return (
    <span className={props.classNameInput ? props.classNameInput : "inputField"}>
      <input className={props.isClearable ? "clearable" : ""} {...inputAttributes} />
      {props.isClearable && inputAttributes.value !== ""  ?
        <span className={props.classNameClearButton ? props.classNameClearButton : "clearField"} onClick={clearInput}>
          <MaterialIcon key="clear" icon="clear" size={18} color={"#D0D1DA"}/>
        </span> : null
      }
      {initialType === "password" ?
        <span onClick={showPassword}>
          { inputAttributes.type === "text" ?
            <MaterialIcon key="eye-grey" icon="visibility" size={18} color={"#151845"}/> : <MaterialIcon key="eye-blue" icon="visibility" size={18} color={"#D0D1DA"}/>
          }
        </span> :
        <span></span>
      }
    </span>
  )
}
