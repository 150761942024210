import React from 'react';

export default function IconGeolocalized(){
  return (
    <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="words_cloud" transform="translate(-26.000000, -378.000000)" fill="#8A8BA2">
                <g id="Group" transform="translate(23.000000, 233.000000)">
                    <path d="M15,161.584 L15,149.728 L9,147.616 L9,159.472 L15,161.584 Z M20.496,145.6 C20.6240006,145.6 20.7399995,145.651999 20.844,145.756 C20.9480005,145.860001 21,145.975999 21,146.104 L21,161.2 C21,161.312001 20.9600004,161.412 20.88,161.5 C20.7999996,161.588 20.7120005,161.648 20.616,161.68 L15,163.6 L9,161.488 L3.528,163.6 C3.38399928,163.6 3.26000052,163.548001 3.156,163.444 C3.05199948,163.339999 3,163.224001 3,163.096 L3,148 C3,147.887999 3.0399996,147.788 3.12,147.7 C3.2000004,147.612 3.28799952,147.552 3.384,147.52 L9,145.6 L15,147.712 L20.352,145.648 L20.496,145.6 Z" id="icon-geolocalized"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}
