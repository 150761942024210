import React, { useEffect } from 'react'
import Select , { components } from 'react-select'
import propTypes from 'prop-types'
import customStyle from './style'
import styles from './styledSelect.module.scss'

// menuIsOpen

const StyledSelect = ({options,
  value,
  placeholder,
  onChange,
  isMulti = false,
  noBorderTop = false,
  style = null
}) => {

  return (
    <Select
      value={value}
      isMulti={isMulti}
      noBorderTop={noBorderTop}
      placeholder={placeholder}
      options={options}
      styles={style ? customStyle[style] : customStyle.style}
      isClearable={true}
      onChange={(option,change) => onChange(option,change)}
      components={ (style === "style2" || style === "style3Dark") ? {
        DropdownIndicator : CustomDropdownIndicatorLight,
        ClearIndicator : CustomClearIndicatorLight
      } : {
        DropdownIndicator : CustomDropdownIndicator,
        ClearIndicator : CustomClearIndicator
      }}
    />
  )


}

const CustomDropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={`${styles["dropdown-indicator"]}`}>
      </div>
    </components.DropdownIndicator>
  );
};

const CustomDropdownIndicatorLight = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={`${styles["dropdown-indicator"]} ${styles["light"]}`}>
      </div>
    </components.DropdownIndicator>
  );
};

const CustomClearIndicator = props => {
  console.log("not light")
  return (
    <components.ClearIndicator {...props}>
      <div className={styles["clear-indicator"]}>
      </div>
    </components.ClearIndicator>
  );
};


const CustomClearIndicatorLight = props => {
  console.log("light")
  return (
    <components.ClearIndicator {...props}>
    <div className={`${styles["clear-indicator"]} ${styles["light"]}`}>
      </div>
    </components.ClearIndicator>
  );
};

StyledSelect.propTypes = {
  options: propTypes.oneOfType([propTypes.func, propTypes.array]),
  value: propTypes.oneOfType([propTypes.object, propTypes.array]),
  placeholder: propTypes.string,
  id: propTypes.string,
  onChange : propTypes.func,
  isMulti : propTypes.bool
};

export default StyledSelect;