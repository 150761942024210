import { useState, useEffect, useRef } from 'react';
import { useGlobalContext } from '../GlobalContext'
export default function useContentDimension(){
  const context = useGlobalContext()[0];
  const isInitialMount = useRef(true);

  const headerHeight = 50
  const footerHeight = 0
  const [dimension, setDimension] = useState(getDimension());

  useEffect(()=>{
    window.addEventListener("resize",handleResize)
    return function () {
      window.removeEventListener("resize",handleResize)
    }
  },[])

  useEffect(()=>{

    if(context.viewReducer.footerState === "minimize"){
      setDimension(getDimension())
    }else if(!isInitialMount.current){
      setDimension(getDimension())
    }
    isInitialMount.current = false
  },[context.viewReducer.footerState])


  function getDimension(){
    if(context.viewReducer.footerState === "minimize"){
      return (
        // With height footer
        // {width : window.innerWidth, height : window.innerHeight - (headerHeight + 24)}
        {width : window.innerWidth, height : window.innerHeight - headerHeight}
      )
    }else{
      return (
        {width : window.innerWidth, height : window.innerHeight - (headerHeight + footerHeight)}
      )
    }
  }

  function handleResize(e) {
    setDimension({width : window.innerWidth, height : window.innerHeight - (headerHeight + footerHeight)})
  }

  return dimension
}
