import React, { useState, useEffect, useRef } from 'react';
import _ from "lodash";
import queryString from 'query-string'
import { NavLink } from "react-router-dom";
import { withLocalize, Translate } from 'react-localize-redux'
import ProximityFeedback from 'react-proximity-feedback';
import useContentDimension from '../../customHooks/useContentDimension'
import { useGlobalContext } from '../../GlobalContext';
import usePlateforme from '../../customHooks/usePlateforme'
import history from '../../history'

import ToggleDarkMode from '../partials/ToggleDarkMode'

// import IconConsolidatedVision from '../../assets/images/exploration/icon-consolidated-vision.js'
import IconHome from '../../assets/images/exploration/icon-home.js'
import IconRelationships from '../../assets/images/exploration/icon-relationships.js'
import IconDashboard from '../../assets/images/exploration/icon-dashboard.js'
import IconGeolocalisation from '../../assets/images/exploration/icon-geolocalisation.js'
import IconWordsCloud from '../../assets/images/exploration/icon-words-cloud.js'
import IconTimeline from '../../assets/images/exploration/icon-timeline.js'
import IconKF from '../../assets/images/exploration/icon-partner.js'
import IconNewspaper from '../../assets/images/exploration/icon-newspaper.js'
import IconAnalysisTools from '../../assets/images/exploration/icon-analysis-tools.js'
import IconHelp from '../../assets/images/exploration/icon-help.js'

const ExplorationNav = (props) => {
  const contentDimension = useContentDimension()
  const [ context , dispatch] = useGlobalContext();
  const [ indexLang, setIndexLang ] = useState();
  const hideNav = useRef();

  const [ explorationTools, setExplorationTools ] = useState();
  const viewReducer = context.viewReducer;
  const filtersReducer = context.filtersReducer;

  const filtersJSON = usePlateforme()

  const iconCases = {
   "/home" : <IconHome/>,
   "/strategic-watch" : <IconNewspaper/>,
   "/dashboard" : <IconDashboard/>,
   "/wordscloud" : <IconWordsCloud/>,
   "/links-exploration" : <IconRelationships/>,
   "/partnerships" : <IconKF/>,
   "/geolocalisation" : <IconGeolocalisation/>,
   "/timeline" : <IconTimeline/>,
   "/analysis-tools" : <IconAnalysisTools/>,
   "/help" : <IconHelp/>
  }

  useEffect(()=>{
    if(!filtersJSON || filtersReducer.indexSelectedEntries === null) return;
    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
    hideNav.current = shouldIhideNavbar(history.location,currentHeaderEntry)
    history.listen((location)=>{
      hideNav.current = shouldIhideNavbar(location)
    })
  },[history.location.pathname,filtersJSON,filtersReducer.indexSelectedEntries])

  useEffect(()=>{
    let index;
    props.languages.forEach((d,i) => {
      if(d.active) index = i
    })
    setIndexLang(index)
  },[props.activeLanguage])

  // useEffect(()=>{
  //   if(!filtersJSON) return;
  //   let codeEntries = filtersReducer.selectedHeaderEntries.map(entries => entries.code)
  //   let findEntries = filtersJSON["header"].filter((d) => d.rubrique).find((d)=>{
  //     return _.differenceBy(d.rubrique,codeEntries).length === 0
  //   })
  //   if(findEntries && findEntries.navbar) setExplorationTools(findEntries.navbar)
  // },[
  //   filtersJSON,
  //   filtersReducer.selectedHeaderEntries
  // ])

  useEffect(()=>{
    if(!filtersJSON) return;
    let findEntries = filtersJSON["header"][filtersReducer.indexSelectedEntries];
    if(findEntries && findEntries.navbar) setExplorationTools(findEntries.navbar)
  },[
    filtersJSON,
    filtersReducer.indexSelectedEntries
  ])

  function shouldIhideNavbar(location,currentHeaderEntry){
    if(currentHeaderEntry && currentHeaderEntry.hideNavbar) return true
    let re = new RegExp(`\/search-innov\/${props.match.params.id}\/(about|analysis-tools|home)$`)

    if(re.test(location.pathname)){
      return true
    }else{
      return false
    }
  }

  function toggleLight(){
    if(viewReducer.lightmode !== 'light'){
      dispatch({type : "lightmode" , payload : 'light'})
    }else{
      dispatch({type : "lightmode" , payload : ''})
    }
  }

  function renderHelp(mobile){
    let help = explorationTools.find((tool)=>tool.url === "/help");
    if(!mobile && help) {
      return (
        <div className="item-help">
          <NavLink to={`/search-innov/${props.match.params.id}${help.url}`}>{iconCases[help.url]}<span>{help.title[indexLang]}</span></NavLink>
        </div>
      )
    }else if(mobile && help){
      return (
        <li>
          <NavLink to={`/search-innov/${props.match.params.id}${help.url}`}>{iconCases[help.url]}</NavLink>
        </li>
      )
    }else{
      return null
    }
  }

  function getHeight(){


    if(contentDimension.width < 867){
      return 48
    }

    let help = explorationTools.find((tool)=>tool.url === "/help");
    if(help){
      return ((explorationTools.length-1)*48)
    }else{
      return ((explorationTools.length)*48)
    }
  }

  function getObjectTo(url){
    console.log();
    let search = history.location.search
    let entry = queryString.parse(search)?.entry
    return ({
      pathname : url,
      search : entry ? `?entry=${entry}` : ''
    })
  }

  if(contentDimension.width < 867){
    return (
      <>
        { explorationTools &&
          <span className={hideNav.current ? "hide-navbar"  : ""}>
            <>
              <div className={'container-navbar'} style={{height : `${getHeight()}px`}}>
                <div className={'navbar tablet'} style={{height : `${getHeight()}px`}} >
                  <div className="container-nav">
                    <ul>
                      {explorationTools.map((tool,i)=>
                        <React.Fragment key={`tool-${i}`}>
                          { tool.url !== "/help" ?
                            <li>
                              { /\/analysis-tools/.test(tool.url) ?
                                <NavLink to={getObjectTo(`/search-innov/${props.match.params.id}${tool.url}`)}>{iconCases["/analysis-tools"]}</NavLink> :
                                <NavLink exact to={getObjectTo(`/search-innov/${props.match.params.id}${tool.url}`)}>{iconCases[tool.url]}</NavLink>
                              }

                            </li> : null
                          }
                        </React.Fragment>
                      )}
                      {renderHelp(true)}
                    </ul>
                  </div>
                </div>

              </div>
            </>
          </span>
        }
      </>
    )
  }else{
    return null;
    return (
      <>
      { explorationTools &&
        <span className={hideNav.current ? "hide-navbar"  : ""}>
          <ProximityFeedback threshold={20}>
            {({ ref, isNearby }) => (
              <>
              <div ref={ref} className={isNearby ? 'container-navbar nearby' : 'container-navbar'} style={{height : `${getHeight()}px`}}>
                {/*<span className="hide-on-tablet"><ToggleDarkMode/></span>*/}
                <div className={isNearby ? 'navbar nearby' : 'navbar'} style={{height : `${getHeight()}px`}} >
                  <div className="container-nav">
                    <ul>
                      {explorationTools.map((tool,i)=>
                        <React.Fragment key={`tool-${i}`}>
                          { tool.url !== "/help" ?
                            <li>
                              { /\/analysis-tools/.test(tool.url) ?
                                <NavLink to={getObjectTo(`/search-innov/${props.match.params.id}${tool.url}`)}>{iconCases["/analysis-tools"]}<span>{tool.title[indexLang]}</span></NavLink> :
                                <NavLink exact to={getObjectTo(`/search-innov/${props.match.params.id}${tool.url}`)}>{iconCases[tool.url]}<span>{tool.title[indexLang]}</span></NavLink>
                              }
                            </li> : null
                          }
                        </React.Fragment>
                      )}
                    </ul>
                  </div>
                </div>
                {renderHelp()}
              </div>
              <div className={isNearby ? 'overlay nearby' : 'overlay'} style={{width : contentDimension.width, height : contentDimension.height}}></div>
              </>
            )}
          </ProximityFeedback>
        </span>
      }
      </>
    )
  }

}

export default withLocalize(ExplorationNav)
