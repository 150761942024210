import React, { useState, useEffect } from 'react'
import { withLocalize, Translate } from 'react-localize-redux'
import loader from '../../assets/images/loader-disabled.gif'

const ImgItem = (props) => {
  const [ img, setImg ] = useState(null)
  const [ error, setError ] = useState(false)

  useEffect(()=>{
    const plateforme = JSON.parse(localStorage.getItem('plateforme'));
    const baseUrl = plateforme?.url.split('/').splice(0,4).join('/');
    if (!plateforme?.url) return;   

    let image = null;
    if (props.image) {
      image = `${baseUrl}/servletpub/QESServlet_fwdImg?Base=22801201&Fichier=${props.image}`
    } else {
      let img = JSON.parse(localStorage.getItem("plateforme")).image
      image = `${baseUrl}/servletpub/QESServlet_fwdImg?Base=22801201&Fichier=${img}`;
    }
    let constructorImg = new Image()

    let promise = new Promise(function(resolve, reject){

        if(/.bin$/.test(image)){
          // console.log("image in .bin ?", image);
          reject()
        }

        constructorImg.onload = function(){
          resolve(image)
        }
        constructorImg.onerror = function(){
          // console.log(image);
          reject()
        }
        constructorImg.src = image

    })

    let cancelablePromise = makeCancelable(promise)

    cancelablePromise.promise.then((urlImg)=>{
      setImg(urlImg)
      setError(false)
    }).catch(() => {
      setError(true)
      // console.log("error");
    })

    return () => {
      constructorImg.src = '';
      constructorImg.onload = null;
      constructorImg.onerror = null;
      setImg(null)
      setError(false)
      cancelablePromise.cancel()
    }


  },[props.image])

  function makeCancelable(promise) {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
      promise
        .then(val => (isCanceled ? reject(new Error({ isCanceled })) : resolve(val)))
        .catch(error => (isCanceled ? reject(new Error({ isCanceled })) : reject(error)));
    });

    return {
      promise: wrappedPromise,
      cancel() {
        isCanceled = true;
      },
    };
  }

  function renderBackground(){
    if(img){
      return {backgroundImage : "url("+img+")"}
    }else{
      return { backgroundImage : ''}
    }
  }

  return(
    <div className={props.className ? props.className : "image-doc"} style={renderBackground()}>

      {error ? <p><Translate id="global.cant-load-img" /></p> : null}
      { !img && !error ? <img src={loader} alt="loader"/> : null}
    </div>
  )
}

export default withLocalize(ImgItem)
