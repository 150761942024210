import {
  AUTH_LOGIN,
  ERROR_AUTH
} from '../actions/types'

import _ from 'lodash'


const initialState = { isAuthenticated : false, user : null, plateformes : null, messageError : null};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      localStorage.setItem('token', action.payload.user.token);
      let user = _.cloneDeep(action.payload.user)
      let defaultImage = {}

      user.plateformes.forEach((plateforme)=>{
        defaultImage[plateforme.id] = plateforme.image
      })
      
      delete user.token
      delete user.plateformes
      localStorage.setItem('user',JSON.stringify(user));
      return {...state, messageError : null, isAuthenticated : true, plateformes : action.payload.user.plateformes};
    case ERROR_AUTH :
      return {...state, messageError : action.payload}
    default:
      return {...state};
  }
}
