import React, { useState, useEffect } from "react"
import _ from "lodash"
import { withLocalize } from 'react-localize-redux'
import { useGlobalContext } from '../../../GlobalContext'
import useContentDimension from '../../../customHooks/useContentDimension'
import usePlateforme from '../../../customHooks/usePlateforme'

import ImgLinks from "../../../assets/images/exploration/screenshot-links.png"
import ImgGeo from "../../../assets/images/exploration/screenshot-geo.png"
import ImgWordCloud from "../../../assets/images/exploration/screenshot-wordcloud.png"
import ImgPartnership from "../../../assets/images/exploration/screenshot-partnership.png"
import ImgDashboard from "../../../assets/images/exploration/screenshot-dashboard.png"
import ImgTimeline from "../../../assets/images/exploration/screenshot-timeline.png"
import ImgStragetic from "../../../assets/images/exploration/screenshot-strategic.png"


import HelpItem from './HelpItem'

const Help = (props) => {
  const context = useGlobalContext()[0];
  const contentDimension = useContentDimension();
  const [ explorationTools, setExplorationTools ] = useState();
  const filtersReducer = context.filtersReducer;
  const filtersJSON = usePlateforme()

  const helpCases = {
    "/links-exploration" : { img : ImgLinks, text : props.translate("help.links-exploration.text"), title : props.translate("help.links-exploration.title")},
    "/geolocalisation" : { img : ImgGeo, text : props.translate("help.geolocalisation.text"), title : props.translate("help.geolocalisation.title")},
    "/wordscloud" : { img : ImgWordCloud, text : props.translate("help.wordscloud.text"), title : props.translate("help.wordscloud.title")},
    "/partnerships" : { img : ImgPartnership, text : props.translate("help.partnerships.text"), title : props.translate("help.partnerships.title")},
    "/dashboard" : { img : ImgDashboard, text : props.translate("help.dashboard.text"), title : props.translate("help.dashboard.title")},
    "/timeline" : { img : ImgTimeline, text : props.translate("help.timeline.text"), title : props.translate("help.timeline.title")},
    "/strategic-watch" : { img : ImgStragetic, text : props.translate("help.strategic-watch.text"), title : props.translate("help.strategic-watch.title")},
  }

  useEffect(()=>{
    if(!filtersJSON) return;
    let codeEntries = filtersReducer.selectedHeaderEntries.map(entries => entries.code)
    let findEntries = filtersJSON["header"].find((d)=>{
      return d.rubrique && _.differenceBy(d.rubrique,codeEntries).length === 0
    })
    setExplorationTools(findEntries.navbar)
  },[
    filtersJSON,
    filtersReducer.selectedHeaderEntries
  ])

  return(
    <div className="container-help" style={{width : contentDimension.width, height : contentDimension.height}}>
      <div className="container">
        <ul className="list-help">
          {explorationTools && explorationTools.map((d)=>(

            <React.Fragment key={`view-${d.url}`}>
              { helpCases[d.url] &&
                <HelpItem
                  history={props.history}
                  img={helpCases[d.url].img}
                  text={helpCases[d.url].text}
                  title={helpCases[d.url].title}
                  link={`${props.match.params.id}${d.url}`}
                />
              }
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default withLocalize(Help)
