import _ from 'lodash'
import { fnSanitizeFilters, escapeRegExp } from '../helpers/helpers'
import { getPlateforme } from '../customHooks/usePlateforme'
import {
  GET_FILTERS,
  GET_DATES,
  TOGGLE_FILTER_BY_DATE,
  SET_FILTER_VIEW,
  SET_FILTER_SEARCH,
  SET_FILTER_SEARCH_TYPES,
  ADD_SELECTED_KEYWORDS,
  REMOVE_SELECTED_KEYWORDS,
  REMOVE_SELECTED_KEYWORDS_BY_CAT,
  REMOVE_ALL_SELECTED_KEYWORDS,
  SET_PLATEFORME_ID,
  SET_FILTER_DATE,
  SET_INPUT_RANGE,
  SET_HEADER_ENTRIES,
  SET_FILTER_RELATIONSHIPS,
  LOADING_DATES,
  LOADING_FILTERS,
  ERROR_FIlTERS,
  ////
  GET_TIMELINES,
  GET_NODES
  /////
} from '../actions/types'

const initialState = {
  selectedHeaderEntries: null,
  indexSelectedEntries: null,
  filterView : [],
  matchCode: null,
  defaultFilters: null,
  defaultExcludedFilters : null,
  filters: null,
  search : null,
  searchTypes : [],
  selectedKeywords : null,
  keywordDate : null,
  keywordAction : null,
  date : {
    min : {
      date : null,
      value : null
    },
    max : {
      date : null,
      value : null
    }
  },
  inputRange : null,
  dateRange : null,
  isDateLoading : false,
  isFiltersLoading : false,
  filtersRelationships : null,
  plateformeID : null,
  filteredByDate : true,
  type:null
};



let newSelectedKeywords = null
let newMatchCode = []
let dateRange = null
let sanitizeFilters = null
let updateDefaultFilters = null
let updateDefaultExcludedFilters = null

export default function filterReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NODES:
    case GET_TIMELINES:
    // case GET_PARTNERSHIPS:
      newMatchCode = []
      sanitizeFilters = []
      if(action.payload.result){
        sanitizeFilters = fnSanitizeFilters(action.payload.result.filters)
        sanitizeFilters.forEach( d => {
          let values = d.valeurs
          values = values.filter( v => parseInt(v.frequence) > 0)
          d.valeurs = values
          if(values){
            let id = d.id
            let mode = d.mode
            values.forEach(k=>{

              let obj = {
                value : k.libelle,
                label : k.libelle,
                code : k.code,
                id : id,
                frequence : k.frequence,
                mode
              }
              if(k.countryCode) obj.countryCode = k.countryCode
              newMatchCode.push(obj)
            })
          }
        })

        let instersectionSelectedKeywords = _.intersectionBy(newMatchCode,state.selectedKeywords,"code")
        // console.log("new   ",newSelectedKeywords);
        newSelectedKeywords = []
        instersectionSelectedKeywords.forEach((d)=>{
          newSelectedKeywords.push({code : d.code, id : d.id, value : d.value+" ("+d.frequence+")", label : d.value+" ("+d.frequence+")"})
        })

      }

      return {
        ...state,
        isFiltersLoading : false,
        filters : sanitizeFilters,
        matchCode : newMatchCode
      };
    case GET_FILTERS:
      sanitizeFilters = fnSanitizeFilters(action.payload.filters)
      newMatchCode = []
      sanitizeFilters.forEach( d => {
        let values = d.valeurs
        values = values.filter( v => parseInt(v.frequence) > 0)
        d.valeurs = values
        if(values){
          let id = d.id
          values.forEach(k=>{
            let obj = {
              value : k.libelle,
              label : k.libelle,
              code : k.code,
              id : id,
              frequence : k.frequence,
            }
            newMatchCode.push(obj)
          })
        }
      })

      return {...state, filters : sanitizeFilters, matchCode : newMatchCode, isFiltersLoading : false};
    case GET_DATES :
      dateRange = _.cloneDeep(action.payload.dates)

      let dateRangeClompleted = []
      let config = getPlateforme(state.plateformeID)

      dateRange.forEach((d)=>{
        let date = new Date(d.label)
        date.setHours(12)
        date.setMinutes(0)
        date.setSeconds(0)
        
        if(config.minimumYear){
          if(date.getFullYear() >= config.minimumYear){
            dateRangeClompleted.push({
              date,
              nbDocs : d.count
            })
          }
        }else{
          dateRangeClompleted.push({
            date,
            nbDocs : d.count
          })
        }



      })

      let date;
      if(state.keywordDate){
        date = state.keywordDate
      }else{
        if(dateRangeClompleted.length === 1){
          let end = new Date(dateRangeClompleted[0].date)
          let start = new Date(dateRangeClompleted[0].date)

          end.setDate(end.getDate()+1)
          start.setDate(start.getDate()-1)
          date = {
            min : {
              date : start,
              value: 0
            },
            max : {
              date : end,
              value : 1
            }
          }
        }else{
          date = {
            min : {
              ...dateRangeClompleted[0],
              value: 0
            },
            max : {
              ...dateRangeClompleted[dateRangeClompleted.length-1],
              value : dateRangeClompleted.length-1
            }
          }
        }

      }

      return { ...state, dateRange : dateRangeClompleted, date, isDateLoading : false}
    case SET_FILTER_SEARCH_TYPES :
      return {...state, searchTypes : action.payload }
    case SET_FILTER_SEARCH :
      return {...state, search : action.payload }
    case SET_PLATEFORME_ID :
      return {...state, plateformeID : action.payload}
    case SET_FILTER_VIEW :
      return {...state, filterView : action.payload}
    case ADD_SELECTED_KEYWORDS:
      let arrKeywords = Array.isArray(action.payload) ? action.payload : [action.payload]
      if(state.selectedKeywords !== null){
        newSelectedKeywords = [...state.selectedKeywords, ...arrKeywords]
      }else{
        newSelectedKeywords = [...arrKeywords]
      }
      return {...state, selectedKeywords : newSelectedKeywords, type : null }
    case REMOVE_SELECTED_KEYWORDS:
      let arrDeletedKeywords = Array.isArray(action.payload) ? action.payload : [action.payload]
      let reg = new RegExp(escapeRegExp(arrDeletedKeywords.map((d) => d.value).join("|")))
      newSelectedKeywords = state.selectedKeywords.filter((keyword)=>{
        return !reg.test(keyword.value)
      })
      return {...state, selectedKeywords : newSelectedKeywords, type : null }
    case REMOVE_SELECTED_KEYWORDS_BY_CAT:
      newSelectedKeywords = state.selectedKeywords.filter((keyword)=>{
        return keyword.id !== action.payload
      })
      return {...state, selectedKeywords : newSelectedKeywords, type : null }
    case REMOVE_ALL_SELECTED_KEYWORDS:
      return {...state, selectedKeywords : [], type : REMOVE_ALL_SELECTED_KEYWORDS }
    case SET_INPUT_RANGE:
      let valueMin = action.payload.min;
      let valueMax = action.payload.max;
      return {...state, inputRange : {min : valueMin, max : valueMax}}
    case SET_FILTER_DATE:
      if(action.payload.isKeywordDate){
        let date = _.cloneDeep(action.payload)
        delete date.isKeywordDate
        return {...state, date : date, keywordDate : date}
      }else{
        return {...state, date : action.payload, keywordDate : null}
      }
    case SET_FILTER_RELATIONSHIPS :
      return {...state, filtersRelationships : action.payload}
    case SET_HEADER_ENTRIES :
      if(action.payload.defaultFilters){
         updateDefaultFilters = action.payload.defaultFilters
      }else{
         updateDefaultFilters = []
      }

      if(action.payload.defaultExcludedFilters){
         updateDefaultExcludedFilters = action.payload.defaultExcludedFilters
      }else{
         updateDefaultExcludedFilters = []
      }

      if(action.origin === `/search-innov/${state.plateformeID}/home`){
        newSelectedKeywords = state.selectedKeywords ? state.selectedKeywords : []
      }else{
        newSelectedKeywords = []
      }

      return { ...state,
        defaultFilters : updateDefaultFilters,
        defaultExcludedFilters : updateDefaultExcludedFilters,
        selectedHeaderEntries : action.payload.entries,
        indexSelectedEntries : action.payload.index,
        selectedKeywords : newSelectedKeywords,
        filterView : [],
        isFiltersLoading : false
      }
    case TOGGLE_FILTER_BY_DATE :
      return {
        ...state,
        filteredByDate : action.payload
      }
    case LOADING_FILTERS :
      return { ...state, isFiltersLoading : true}
    case LOADING_DATES :
      // console.log(LOADING_DATES);
      return { ...state, isDateLoading : true}
    case ERROR_FIlTERS:
      console.log(ERROR_FIlTERS);
      return {...state}
    default:
      return {...state};
  }
}
