import React from "react";
import history from '../../history'
import popupImg from '../../assets/images/popup_img.svg'

export default function NoMatch() {

  function backToApp(){
    if(JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("plateformeID"))){
      history.push(`/search-innov/${localStorage.getItem("plateformeID")}/strategic-watch`)
    }else{
      history.push(`/`)
    }
  }

  return (
    <div className="noMatch">
      <div className="container">
        <img src={popupImg} alt="Ops" />
        <h1>Ops !</h1>
      </div>
      <p>La page que vous cherchez n'existe malheureusement pas !</p>
      <button onClick={backToApp}>Retour sur le site</button>

    </div>
  )
}
