import { useState } from 'react'

export function useFormInput(initialValue,placeholder, type = "text"){
  const [value, setValue] = useState(initialValue)
  const [inputType, setInputType] = useState(type)

  function handleChange(e){
    setValue(e.target.value)
  }


  function setType(newType){
    setInputType(newType)
  }

  return {
    DOMAttribute : {
      value,
      type : inputType,
      placeholder,
      onChange : handleChange,
    },
    setType : setType,
    setValue : setValue,
    clearInput : () => setValue("")
  }

}
