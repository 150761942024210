import { postData } from './index'
import {
   GET_NODES,
   GET_RELATIONSHIPS,
   GET_PARTNERSHIPS,
   REMOVE_PARTNERSHIPS,
   REMOVE_RELATIONSHIPS,
   GET_TIMELINES,
   LOADING_DATAVIZ,
   ERROR_DATAVIZ
} from './types'

export async function getRelationships(dispatch, filters, origine){
  let url = 'dataviz/relationships'
  // console.log(GET_RELATIONSHIPS);
  dispatch({type : LOADING_DATAVIZ})
  await postData(GET_RELATIONSHIPS, ERROR_DATAVIZ, true, url , dispatch, filters, origine)
}

export async function getPartnerships(dispatch, filters, change = "default"){
  let url = 'dataviz/partnerships'
  // console.log(GET_PARTNERSHIPS);
  dispatch({type : LOADING_DATAVIZ})
  await postData(GET_PARTNERSHIPS, ERROR_DATAVIZ, true, url , dispatch, filters)
}

export async function removePartnerships(dispatch){
  // console.log(REMOVE_PARTNERSHIPS);
  dispatch({type : REMOVE_PARTNERSHIPS})
}

export async function removeRelationships(dispatch){
  // console.log(REMOVE_RELATIONSHIPS);
  dispatch({type : REMOVE_RELATIONSHIPS})
}

export async function getNodes(dispatch, filters , origine){
  let url = 'dataviz/nodes'
  dispatch({type : LOADING_DATAVIZ})
  await postData(GET_NODES, ERROR_DATAVIZ, true, url , dispatch, filters, origine)
}

export async function getTimelines(dispatch, filters, change = "default"){
  let url = 'doc/filtered'
  // console.log(GET_TIMELINES);
  dispatch({type : LOADING_DATAVIZ, payload : change})
  await postData(GET_TIMELINES, ERROR_DATAVIZ, true, url , dispatch, filters)
}
