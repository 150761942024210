import React from 'react';
import PropTypes from 'prop-types';
import Pie from '../../partials/Pie'
import { useGlobalContext } from '../../../GlobalContext'
import history from '../../../history'
import MaterialIcon from 'material-icons-react'
import IconBackward from '../../../assets/images/arrow_back_icon.svg'

export default function DashboardSection({
  section,
  index,
  indexLang,
  marginTop = 0,
  backward,
  isMobile,
  isLoading
}){
  const [ context , dispatch ] = useGlobalContext();
  const viewReducer = context.viewReducer

  if(section.pies.length === 0) return null
  let styleCol = section.pies.length >= 4 ? "col-4" : "col-"+section.pies.length
  return (
    <div key={"section-"+index} id={section.title[indexLang].toLowerCase()}
      style={index === 0 ? { marginTop : marginTop+"px"} : {}}
    >
      <h3 className="title-section" onClick={backward}>
        <MaterialIcon key="icon-backward" icon="arrow_back" size={24} />
        {section.title[indexLang]}
      </h3>
      <div className={isMobile ? "container-dashboard mobile "+styleCol : "container-dashboard "+styleCol}>
        { section.pies ?
          <>
          {section.pies.map((k,j)=>{
            return (
              <div key={"pie-"+index+"-"+j}>
                <Pie
                  refPie={"pie-"+index+"-"+j}
                  width={220}
                  export={true}
                  height={200}
                  title={section.titlesCategory[j]}
                  data={k}
                  showList={true}
                  sortList={"desc"}
                  isLoading={isLoading}
                  light={viewReducer.lightmode === "light"}
                />
              </div>
            )
          })}
          </> : null
        }
      </div>
    </div>
  )

}

const shapeValue = () => {}
shapeValue.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    count: PropTypes.number,
    frequence: PropTypes.string,
    id: PropTypes.string,
    label : PropTypes.string,
    mode : PropTypes.string,
    name : PropTypes.string,
    tendance : PropTypes.string,
    value : PropTypes.string
  })
}

DashboardSection.propTypes = {
  title : PropTypes.arrayOf(PropTypes.string),
  titlesCategory : PropTypes.arrayOf(PropTypes.string),
  categories : PropTypes.arrayOf(PropTypes.string), // ex : FILTER_HTG, FILTER_HTG2, CONCEPT
  pies : PropTypes.arrayOf(PropTypes.arrayOf(shapeValue)),
  backward : PropTypes.func,
  isMobile : PropTypes.bool
}
