import homepage from './homepage.json'
import groupestravail from './groupes-travail.json'
import innovations from './innovations.json'
import startups from './startups.json'
import analyses from './analyses.json'
import publicreports from './public-reports.json'
import agenda from './agenda.json'
import gt1technology from './GT1Technology.json'
import gt2regulation from './GT2Regulation.json'
import gt3economie from './GT3Economie.json'
import gt4thermalstorage from './GT4Thermalstorage.json'
import gt5mobility from './GT5Mobility.json'
import gt6acv from './GT6ACV.json'
import playerchain from './playerchain.json'


const config = {
 "header" : [
   homepage,
   groupestravail,
   innovations,
   startups,
   analyses,
   publicreports,
   playerchain,
   agenda,
   gt1technology,
   gt2regulation,
   gt3economie,
   gt4thermalstorage,
   gt5mobility,
   gt6acv
 ]
}

export default config
