//= =====================
// Auth Actions
//= =====================

export const AUTH_LOGIN = 'AUTH_LOGIN',
             ERROR_AUTH = 'ERROR_AUTH';

//= =====================
// Filter Actions
//= =====================
export const GET_FILTERS = 'GET_FILTERS',
             GET_DATES = 'GET_DATES',
             GET_NODES_DATES_FILTERED = 'GET_NODES_DATES_FILTERED',
             SET_PLATEFORME_ID = 'SET_PLATEFORME_ID',
             SET_FILTER_VIEW = 'SET_FILTER_VIEW',
             ADD_SELECTED_KEYWORDS = 'ADD_SELECTED_KEYWORDS',
             REMOVE_SELECTED_KEYWORDS_BY_CAT = 'REMOVE_SELECTED_KEYWORDS_BY_CAT',
             REMOVE_SELECTED_KEYWORDS = 'REMOVE_SELECTED_KEYWORDS',
             REMOVE_ALL_SELECTED_KEYWORDS = 'REMOVE_ALL_SELECTED_KEYWORDS',
             SET_FILTER_SEARCH = 'SET_FILTER_SEARCH',
             SET_FILTER_SEARCH_TYPES = 'SET_FILTER_SEARCH_TYPES',
             SET_FILTER_DATE = 'SET_FILTER_DATE',
             SET_INPUT_RANGE = 'SET_INPUT_RANGE',
             SET_HEADER_ENTRIES = 'SET_HEADER_ENTRIES',
             SET_FILTER_RELATIONSHIPS = 'SET_FILTER_RELATIONSHIPS',
             TOGGLE_FILTER_BY_DATE = 'TOGGLE_FILTER_BY_DATE',
             LOADING_DATES = 'LOADING_DATES',
             LOADING_FILTERS = 'LOADING_FILTERS',
             ERROR_FIlTERS = 'ERROR_FIlTERS';

//= =====================
// Documents Actions
//= =====================
export const GET_DOCUMENTS_BY_ID = 'GET_DOCUMENTS_BY_ID',
             GET_REFERENCES_DOCUMENT = 'GET_REFERENCES_DOCUMENT',
             GET_SIMILIRAIRE_DOCUMENT_BY_ID = 'GET_SIMILIRAIRE_DOCUMENT_BY_ID',
             SET_DOCUMENT_NUMBER = 'SET_DOCUMENT_NUMBER',
             SET_ORDER_BY_DOCUMENT = 'SET_ORDER_BY_DOCUMENT',
             SET_DOCUMENTS_COUNT = 'SET_DOCUMENTS_COUNT',
             REMOVE_DOCS = 'REMOVE_DOCS',
             REMOVE_SIMILIRAIRE_DOCUMENT = 'REMOVE_SIMILIRAIRE_DOCUMENT',
             GET_DOCUMENTS_COUNT = 'GET_DOCUMENTS_COUNT',
             GET_FILTERED_DOCUMENTS = 'GET_FILTERED_DOCUMENTS',
             LOADING_DOCUMENTS = 'LOADING_DOCUMENTS',
             ERROR_DOCUMENTS = 'ERROR_DATA';

//= =====================
// Newspaper Actions
//= =====================
export const GET_NEWSPAPER_BY_ID = 'GET_NEWSPAPER_BY_ID',
            REMOVE_NEWSPAPER = 'REMOVE_NEWSPAPER',
            SET_RUBRIQUE = 'SET_RUBRIQUE',
            SET_TYPOLOGY = 'SET_TYPOLOGY',
            SET_ORDER_BY_NEWSPAPER = 'SET_ORDER_BY_NEWSPAPER',
            GET_FILTERED_NEWSPAPER = 'GET_FILTERED_NEWSPAPER',
            GET_REFERENCES_NEWSPAPER = 'GET_REFERENCES_NEWSPAPER',
            LOADING_NEWSPAPER = 'LOADING_NEWSPAPER',
            ERROR_NEWSPAPER = 'ERROR_DATA';


//= =====================
// DATAVIZ Actions
//= =====================
export const GET_RELATIONSHIPS = 'GET_RELATIONSHIPS',
             GET_PARTNERSHIPS = 'GET_PARTNERSHIPS',
             GET_NODES = 'GET_NODES',
             GET_TIMELINES = 'GET_TIMELINES',
             REMOVE_PARTNERSHIPS = 'REMOVE_PARTNERSHIPS',
             REMOVE_RELATIONSHIPS = 'REMOVE_RELATIONSHIPS',
             LOADING_DATAVIZ = 'LOADING_DATAVIZ',
             ERROR_DATAVIZ = 'ERROR_DATAVIZ';

//= =====================
// REFERENCES Actions
//= =====================
export const GET_FILTERED_REFERENCES = 'GET_FILTERED_REFERENCES',
            LOADING_REFERENCES = 'LOADING_REFERENCES',
            ERROR_REFERENCES = 'ERROR_REFERENCES';
