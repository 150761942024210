import React from 'react';

export default function IconDown(props){
	return(
		<svg width="15" height="15" x="0px" y="0px" viewBox="0 0 493.4 493.4" >
			<g>
				<path  fill={props.color} d="M424.3,413.9l-6.9-148.2c-0.4-3.9-2.4-6.6-6.1-8.1c-3.6-1.5-6.9-0.8-9.7,2l-45.2,45.2L104.5,52.9c-1.9-1.9-4-2.8-6.5-2.8
					c-2.4,0-4.6,0.9-6.5,2.8L52.9,91.6c-1.9,1.9-2.8,4-2.8,6.5c0,2.4,0.9,4.6,2.8,6.5l252,252l-45.2,45.2c-2.7,2.7-3.4,5.9-2,9.7
					c1.5,3.6,4.2,5.6,8.3,5.9l149,6c2.7,0,5-0.9,6.9-2.8C423.4,418.8,424.3,416.5,424.3,413.9z"/>
			</g>
		</svg>
	)
}
