import React, { useState, useEffect } from 'react';
import history from '../../../history'
import { Translate, withLocalize } from 'react-localize-redux'
import usePlateforme from '../../../customHooks/usePlateforme'

import imgAbout from "../../../assets/images/about-anna.svg"
import iconBack from "../../../assets/images/arrow_back_icon.svg"

const About = (props) => {
  const filtersJSON = usePlateforme()
  const [ indexLang, setIndexLang ] = useState(null);

  useEffect(()=>{
    let index;
    props.languages.forEach((d,i) => {
      if(d.active) index = i
    })
    setIndexLang(index)
  },[props.activeLanguage])

  function goBack(){
    if(history.length > 2){
      history.goBack()
    }else {
      history.push(`/search-innov/${props.match.params.id}/strategic-watch`)
    }
  }

  return (
    <>
      <span className="backward" onClick={() => goBack()}>
        <img src={iconBack} alt="backward"/>
        <Translate id="global.back"/>
      </span>
      <div className="container-about" >
        <div className="container">

          <div className="content-about">
            <img src={imgAbout} alt="about"/>
            { (indexLang !== null && filtersJSON?.about) &&
              <div>
                <h1><Translate id={"about.title"}/></h1>
                <span dangerouslySetInnerHTML={{ __html : filtersJSON.about[indexLang]}} />
              </div>

            }
          </div>

        </div>
      </div>
    </>

  )
}

export default withLocalize(About)
