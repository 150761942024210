import React from 'react'
import { withLocalize } from "react-localize-redux";
import { renderDate }  from '../../../helpers/helpers'
import ImgItem from "../../partials/ImgItem"
import checkboxChecked from '../../../assets/images/checkboxes/checkbox-checked.svg'
import checkbox from '../../../assets/images/checkboxes/checkbox.svg'

const DocItem = (props) => {
  function renderCompany(companies){
    return companies.split(";").join(" / ")
  }

  function renderLocation(location){
    return location.split(";").join(" / ")
  }

  function getImage(image){
    if(Array.isArray(image)){
      return image[0]
    }else if (image){
      return image
    }else{
      return null
    }
  }

  return (
    <li onClick={(e)=> props.selectDoc(e,props.content)}>
      <div>
        <>
          {props.selected ?
            <img src={checkboxChecked} alt="checkbox checked" onClick={(e) => props.toggleCheckbox(e,props.content, "remove")} /> :
            <img src={checkbox} alt="checkbox" onClick={(e) => props.toggleCheckbox(e,props.content, "add")} />
          }
        </>
        <ImgItem image={getImage(props.content.IMAGE)}/>
      </div>
      <div>
        <p className="extract">{props.content.TITLE}</p>
        <div className="sub">
          {props.content.QES_CompanyTopic && props.content.QES_CompanyTopic.length > 0 ?
            <p className="society">{renderCompany(props.content.QES_CompanyTopic[0])}</p> : null
          }
          {props.content.QES_Location && props.content.QES_Location.length > 0 ?
            <p>{renderLocation(props.content.QES_Location[0])}</p> : null
          }
          <p className="date">
            { props.indexFilter === "0" || props.indexFilter === "1" ?
               <>{renderDate(props.content.DATE_COLLECT,props.activeLanguage.code)}</> :
               <>{renderDate(props.content.PUB_DATE,props.activeLanguage.code)}</>
            }
          </p>
        </div>
      </div>
    </li>
  )
}

export default withLocalize(DocItem)
