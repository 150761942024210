import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../../GlobalContext'
import { Translate, withLocalize } from "react-localize-redux";

import Indicators from "../indicators/Indicators"
import SliderImage from "../sliderImage/SliderImage"
import Picto from '../../../partials/Picto'

import { getPicto } from '../../../../helpers/getPicto'
import { renderDate }  from '../../../../helpers/helpers'

const NormalLayout = ({
  doc,
  translate,
  toggleKeywords,
  activeLanguage,
  toggleMobileTags,
  countTags
}) => {
  const [ context, dispatch ]  = useGlobalContext();
  const filtersReducer = context.filtersReducer
  const [ readMore, setReadMore ] = useState(false)
  const [ suIndicators , setSuIndicators] = useState(null)
  const [ pictoCollection, setPictoCollection ] = useState()

  //INIT SU INDICATORS
  useEffect(()=>{
    if(!doc || !filtersReducer.matchCode) return;
    let su = {}

    Object.keys(doc).map(key=>{
      if(/^SU_/.test(key)){
        let found = filtersReducer.matchCode.find(match => match.code === doc[key])
        if(!su[key]){
          let find = filtersReducer.filters.find(filter => filter.id === key)
          su[key] = { id : key, title : find ? find.libelle : null, values : []}
          let values = doc[key].split(";")
          values.forEach((value) => {
            let found = filtersReducer.matchCode.find(match => (match.code === value && match.id === key))
            if(found) {
              let isSelected = !!filtersReducer.selectedKeywords.find((keyword) => keyword.code === value)
              su[key].values.push({...found, tag : true, isSelected})
            }else{
              su[key].values.push({id : key, value : value, label : value, tag : false})
            }
          })

        }
      }
    })

    setSuIndicators(Object.values(su))
  },[doc,filtersReducer.matchCode,filtersReducer.selectedKeywords])

  // INIT PICTO COLLECTIONS
  // useEffect(()=>{
  //   if(!filtersReducer.filters || pictoCollection) return;
  //   let findCex = filtersReducer.filters.find((d)=> d.id === "FILTER_CEX2")
  //   let findNbm = filtersReducer.filters.find((d)=> d.id === "FILTER_NBM2")
  //   var merged = new Map([...getPicto(findNbm),...getPicto(findCex)])
  //   setPictoCollection(merged)
  // },[filtersReducer.filters])

  // INIT PICTO COLLECTIONS
  useEffect(()=>{
    if(!filtersReducer.filters || pictoCollection) return;

    let findCex = []
    if(filtersReducer.filters.find((d)=> d.id === "FILTER_CEX2")){
      findCex = filtersReducer.filters.find((d)=> d.id === "FILTER_CEX2")
    }

    let findNbm = []
    if(filtersReducer.filters.find((d)=> d.id === "FILTER_NBM2")){
      findNbm = filtersReducer.filters.find((d)=> d.id === "FILTER_NBM2")
    }

    let arr = []
    let pictosNbm = getPicto(findNbm);
    let pictosCex = getPicto(findCex);
    if(pictosNbm) arr = [ ...arr, ...pictosNbm]
    if(pictosCex) arr = [ ...arr, ...pictosCex]

    let merged = new Map(arr)
    setPictoCollection(merged)


  },[filtersReducer.filters])

  function renderPictos(cex2,nbm2){
    if(!pictoCollection) return;
    let pictos = []
    if(cex2){
      cex2.split(";").forEach((d)=>{
        let icon = pictoCollection.get(d)
        if(icon){
          let split = icon.picto.split(" ")
          pictos.push(<Picto key={`${split[1]}-${d}`} color={`\#${icon.color}`} icon={split} code={d} tooltipColor="bleu" />)
        }
      })
    }
    if(nbm2){
      nbm2.split(";").forEach((d)=>{
        let icon = pictoCollection.get(d)
        if(icon){
          let split = icon.picto.split(" ")
          pictos.push(<Picto key={`${split[1]}-${d}`} color={`\#${icon.color}`} icon={split} code={d} tooltipColor="bleu" />)
        }
      })
    }
    if(pictos.length > 0){
      return <div className="pictos">{pictos}</div>
    }
  }

  function renderTexte (texte,type){
    let re = /style="(.*?)"/g
    let reHTML = /<[a-z][\s\S]*>/i

    // if there are suIndicators, we are on a start up document
    let isStartUpDoc = suIndicators ? suIndicators.length>0 : false
    // is HTML content, reHTML.test(texte) ?
    if(isStartUpDoc && !reHTML.test(texte)){
      let texteContent = texte.replace('.','.<br/>')
      texteContent = `<p>${texteContent}</p>`
      return texteContent
    }else{
      let textWithoutStyle = texte.replace(re,"")

      if(type === "abstract" && filtersReducer.plateformeID === 1015){
        textWithoutStyle = textWithoutStyle.split('. ').join(".<br/>")
      }

      return textWithoutStyle
    }


  }

  return (
    <>
      {renderPictos(doc.FILTER_CEX2,doc.FILTER_NBM2)}
      <div className="article-info">
        <span>
          <p className="date">{renderDate(doc.PUB_DATE, activeLanguage.code)}</p>
        </span>
        {countTags &&
          <button className="btn-tags" onClick={() => toggleMobileTags()}>
            {countTags} tags
          </button>
        }
      </div>
      <div className="doc-content-left">
        { doc.KEY_LEARNING &&
          <fieldset className="take-away">
            <legend>Take away</legend>
            <span dangerouslySetInnerHTML={{ __html: renderTexte(doc.KEY_LEARNING)}} />
          </fieldset>
        }
        {(suIndicators && suIndicators.length > 0) && (
          <Indicators
            title={translate("documents.document-view.start-up-indicators")}
            filters={filtersReducer.filters}
            indicators={suIndicators}
            toggleKeywords={toggleKeywords}
            activeLanguage={activeLanguage.code}
            websiteLink={doc.SOURCE_LINK}
          />
        )}
        <SliderImage doc={doc}/>
        { doc.ABSTRACT &&
          <span className="abstract" dangerouslySetInnerHTML={{ __html: renderTexte(doc.ABSTRACT,"abstract")}}></span>
        }

        {(readMore && doc.YOUTUBE) &&
          <div className="iframe">
            <iframe src={`https://www.youtube.com/embed/${doc.YOUTUBE}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        }

        { doc.TEXTE ?
          <div className={readMore ? "detail-fact" : "detail-fact hide"}>
            {!readMore &&
              <>
                <div className="gradient"></div>
                <div className="centered">
                  <button onClick={() => setReadMore(true)}><Translate id="documents.document-view.read-more"/></button>
                </div>
              </>
            }

            <p dangerouslySetInnerHTML={{ __html: renderTexte(doc.TEXTE) }} ></p>
            { readMore &&  <div className="centered"><button onClick={() => setReadMore(false)}><Translate id="documents.document-view.back-to-resume"/></button></div>}
          </div> : null
        }

      </div>
    </>
  )
}

export default withLocalize(NormalLayout)
