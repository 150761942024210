import React, { useState } from "react"
import { NavLink } from "react-router-dom";
import history from '../../../history'

export default function HelpItem(props){
    const [ isHover, setIsHover] = useState(false)

    function is_touch_device() {
      let supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
      return supportsTouch
    }

    function goTo(e,url){
      if((is_touch_device() && isHover) || !is_touch_device()){
        history.push(url)
      }
    }

    function toggleIn(e){
      if(!isHover){
        e.preventDefault()
        e.stopPropagation()
      }
      setIsHover(true)
    }

    function toggleOut(){
      setIsHover(false)
    }

    return (
      <li className={isHover ? "item-help hover" : "item-help"}>
        <a onClick={(e) => goTo(e,"/search-innov/"+props.link)}>
          <h3>{props.title}</h3>
          <h3 className="title-hover">{props.title}</h3>
          <div className="image-preview" onTouchEnd={(e) => toggleIn(e)} onMouseEnter={toggleIn} onMouseLeave={toggleOut} >
            <img src={props.img} alt={'help '+props.title} />
            <div className="text-content">
              {props.text}
            </div>
          </div>
        </a>
      </li>
    )
}
