import React from 'react';
import cs from "classnames"
import style from './toggle-dark-mode.module.scss';
import { useGlobalContext } from '../../../GlobalContext';
import Toggle from 'react-toggle';
import IconSunBlue from '../../../assets/images/sun-blue.svg'
import IconSun from '../../../assets/images/sun.svg'
import IconMoon from '../../../assets/images/moon.svg'

export default function ToggleDarkMode({blueSun}){
  const [ context, dispatch ] = useGlobalContext();
  const viewReducer = context.viewReducer;

  function toggleLight(){
    if(viewReducer.lightmode !== 'light'){
      dispatch({type : "lightmode" , payload : 'light'})
    }else{
      dispatch({type : "lightmode" , payload : ''})
    }
  }

  return (
    <div className={cs(style["toggle-darkmode"],"toggle-darkmode")}>
      {viewReducer.lightmode === 'light' ?
        <img src={IconMoon} alt="dark mode"/> : <img src={blueSun ? IconSunBlue : IconSun} alt="light mode"/>
      }
      <Toggle
      defaultChecked={false}
      aria-label='No label tag'
      onChange={toggleLight} />
    </div>
  )
}
