import React, { useEffect , useState, useRef } from 'react';
import axios from 'axios';
import queryString from 'query-string'
import { API_URL } from '../../../actions/index'
import { Translate, withLocalize } from "react-localize-redux";
import MaterialIcon from 'material-icons-react'

import { useGlobalContext } from '../../../GlobalContext'
import { filtersParams } from '../../../helpers/filtersParams'
import history from '../../../history'

import { addSelectedKeywords } from '../../../actions/filters'
import ImgItem from '../../partials/ImgItem'
import loader from '../../../assets/images/loader.gif'
import usePlateforme from '../../../customHooks/usePlateforme'

const InspiringContents = ({
  match,
  doc,
  selectDoc,
  toggleInspiringContent,
  isOpenedOnMobile
}) => {
  const [ context, dispatch ] = useGlobalContext()
  const filtersReducer = context.filtersReducer
  const viewReducer = context.viewReducer
  const filtersJSON = usePlateforme()

  const [ noMatchCode, setNoMatchCode ] = useState(false)
  const [ inspiringContents, setInspiringContents ] = useState()
  const [ isLoading, setIsLoading ] = useState(false);
  const [ disabledHitBottom, setDisabledHitBottom ] = useState(false)
  const [ totalCount , setTotalCount ] = useState(false)
  const [ listRubriques, setListRubriques ] = useState(null);
  const [ activeRubrique, setActiveRubrique ] = useState()

  const docsPerLoad = 15
  const nextPage = useRef()
  
  //ACTIONS
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)

  useEffect(()=>{
    if(!filtersJSON || filtersReducer.indexSelectedEntries === null) return;
    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
    if(currentHeaderEntry.inspiringContentsDefaultRub){
      setActiveRubrique({code : currentHeaderEntry.inspiringContentsDefaultRub, id : "RUBRIQUE"})
    }else{
      setActiveRubrique(null)
    }
  },[filtersJSON,filtersReducer.indexSelectedEntries])

  useEffect(()=>{
    if(!doc) return;
    get();
    async function get(){
      /// CALL HERE TO GET FILTERS
      if(!listRubriques){
        let result = await getContent(true)
        let arrRubriques = []
        if(result){
          let filterRub = result.filters.find(d => d.id === "RUBRIQUE")
          if(filterRub && filterRub.valeurs.length > 0) setListRubriques(filterRub.valeurs)
        }
      }
    }
    return () => {
      setListRubriques(null)
    }
  },[doc])

  // GET INSPIRING CONTENTS
  useEffect(()=>{
    if(!doc || activeRubrique === undefined) return

    // IF THERE ARE NO SECOND LEVEL FILTER WE SEARCH FOR A KTC OR CMG CODE
    let filter = Object.keys(doc).find((key)=>{
      if(key === "FILTER_KTC" || key === "FILTER_CMG"){
        return doc[key]
      }
    })

    if(!filter){
      let reg = new RegExp(/^FILTER_/)

      filter = Object.keys(doc).find((key)=>{
        if(reg.test(key) && key.slice(-1) === "2"){
          return doc[key]
        }
      })
    }

    let find;
    if(filter){
      find = filtersReducer.matchCode.find((d)=>{
        return d.id === filter
      })
    }

    // IF DOC CHANGE RESET INSPIRING CONTENT
    // BEFORE REQUEST THEN
    setInspiringContents(null)
    setTotalCount(null)
    setNoMatchCode(false)
    nextPage.current = 1

    if(find){
      get();
      async function get(){
        /// CALL HERE TO GET docs and totalCount
        let result = await getContent()
        if(result && result.docs.length>0){
          setTotalCount(result.totalCount)
          setInspiringContents(result.docs)
          setNoMatchCode(false)
        }else{
          setNoMatchCode(true)
        }
      }

    }else{
      setNoMatchCode(true)
    }

  },[doc,activeRubrique])

  function getFilterId(){
    let reg = new RegExp(/^FILTER_/)
    let splitLocation = history.location.pathname.split("/");
    let filterId = splitLocation[splitLocation.length-1]
    return reg.test(filterId) ? filterId : null
  }


  async function getContent(all) {
    if(!doc) return

    // IF THERE ARE NO SECOND LEVEL FILTER WE SEARCH FOR A KTC OR CMG CODE
    let filtersDoc = []
    Object.keys(doc).forEach((key)=>{
      if(key === "FILTER_KTC" || key === "FILTER_CMG"){
        filtersDoc.push({id : key, code : doc[key]})
      }
    })

    if(filtersDoc.length === 0){
      let reg = new RegExp(/^FILTER_/)

      Object.keys(doc).forEach((key)=>{
        if(reg.test(key) && key.slice(-1) === "2"){
          filtersDoc.push({id : key, code : doc[key]})
        }
      })
    }

    let selectedKeywords = filtersReducer.selectedKeywords.map(d => ({ code : d.code, id : d.id}))
    let filters = [...selectedKeywords, ...filtersDoc]
    if(activeRubrique && !all){
      filters = [ ...filters, {code: activeRubrique.code, id: "RUBRIQUE"}]
    }

    return getInspiringContent(filters,all)

  }

  async function getInspiringContent(filter){
    let result = filtersParams(filtersReducer,viewReducer.lang)

    //HANDLE IF THERE ARE ADDITIONALS FILTERS
    // TO APPLY ON THE REQUEST TO GET INSPING CONTENTS
    // !!! ADDITIONNALS FILTERS ARE AVAILABLE ONLY ON ANALYSIS-TOOLS PAGE
    let additionalFilters;
    if(/\/analysis-tools/.test(history.location.pathname) && history.location.search){
      additionalFilters = getAdditionFilters()
    }

    if(additionalFilters){
      result.filters.filters = [...filter, ...additionalFilters]
    }else{
      result.filters.filters = filter
    }

    result.filters.number = docsPerLoad;
    if(nextPage.current) result.filters.page = nextPage.current;

    result.filters.withReference = false

    let selectedKeywords = filtersReducer.selectedKeywords.map(d => ({ code : d.code, id : d.id}))
    let excludesKeywords = filtersReducer.defaultExcludedFilters.map((d)=>({ code : d.code, id : d.id}))
    result.filters.filters = [
      ...result.filters.filters,
      ...filtersReducer.selectedHeaderEntries,
      ...filtersReducer.defaultFilters,
    ]


    let docs = await getDocumentsFiltered(result.filters);

    function getDocumentsFiltered(filters){

      return new Promise((resolve,reject)=>{
        const requestUrl = API_URL + 'doc/filtered';
        let headers = { headers: { Authorization: localStorage.getItem('token') } };
        setIsLoading(true)
        axios.post(requestUrl, filters, headers)
        .then((response) => {
          setIsLoading(false)
          setDisabledHitBottom(false)
          let page = response.data.result.currentPage
          page = page + 1
          nextPage.current = page
          resolve({ docs : response.data.result.docs, totalCount : parseInt(response.data.result.numFound),filters : response.data.result.filters})
        })
        .catch((error) => {
          setIsLoading(false)
          reject(error)
        });
      })
    }

    return docs

  }


  function getAdditionFilters(){

    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
    let parseSearch, initAdditionalFilters = null;
    parseSearch = queryString.parse(history.location.search)
    let indexSpecificTool = parseInt(parseSearch['specific-tool'])
    let indexSpecificToolItem = parseInt(parseSearch['item'])


    if(!isNaN(indexSpecificTool) && !isNaN(indexSpecificToolItem)){
      let currentDropdownEntry = currentHeaderEntry["dropdown-tools"].find((d,i) => i === indexSpecificTool)
      if(currentDropdownEntry){
        let activeAnalisysTool = currentDropdownEntry.tools[0].items[indexSpecificToolItem]
        if(activeAnalisysTool.filters && !activeAnalisysTool.inspiringContentsNotSpecific) initAdditionalFilters = activeAnalisysTool.filters
      }
    }
    return initAdditionalFilters
  }

  function getImage(image){
    if(Array.isArray(image)){
      return image[0]
    }else{
      return image
    }
  }

  function openDoc(inspiringDoc){
    localStorage.setItem('previousDoc',doc.ID_QES)
    selectDoc(inspiringDoc.ID_QES)
  }

  async function scroll(e){
    let scrollPosition = Math.ceil(e.target.scrollTop)+1
    let scrollBottomTarget = e.target.scrollHeight-e.target.offsetHeight

    if((!disabledHitBottom && scrollBottomTarget<scrollPosition) &&
       inspiringContents && inspiringContents.length !== totalCount
    ){
      setDisabledHitBottom(true)
      let result = await getContent()
      setInspiringContents([...inspiringContents, ...result.docs])
    }

  }

  function selectRubrique(d){
    setActiveRubrique(d)
  }

  function selectCorrespondingFilter(){
    let id = getFilterId()

    let obj = { 
      code : doc[id],
      frequence : "",
      id : id,
      label: doc.TITLE,
      libelle: doc.TITLE,
      value: doc.TITLE,
    }

    let url = `/search-innov/${match.params.id}/strategic-watch`
    _addSelectedKeywords(obj)
    history.push(url)
  }

  function showButton(){
    let id = getFilterId()
    let bool;
    let navbarEntries = filtersJSON?.header[filtersReducer.indexSelectedEntries].navbar

    if(
      (filtersReducer.selectedKeywords.length>0 &&
      filtersReducer.selectedKeywords.some(keyword => keyword.code === doc[id]))
    ) bool = false
    else{
      bool = true
    }

    // IF THERE NO ENTRY WITH URL /strategic-watch
    // DOESN'T SHOW BUTTON
    if(
      !navbarEntries || 
      !navbarEntries.some(d => d.url === "/strategic-watch")
    ){
      bool = false
    }

    return bool 
  }


  return (
    <div className={isOpenedOnMobile ? "inspiring-contents open" : "inspiring-contents"}>
      <div className="content" onScroll={(e) => scroll(e)} >
        <div className="backyard only-on-mobile">
          <span onClick={() => toggleInspiringContent()}>
            <MaterialIcon key="icon-backward" icon="arrow_back" size={18} color="#151845"/>
            <Translate id="global.back"/>
          </span>
        </div>
        <div className="title">
          <h2>
            <Translate id={"documents.ref.title-inspiring-content"} />
          </h2>
          { (doc && showButton()) &&
              <button onClick={selectCorrespondingFilter}><Translate id={"documents.ref.inspiring-content-see-all"} /></button>
            }
        </div>
        { (listRubriques && listRubriques.length > 0) &&
          <ul className="filters-inspiring-contents">
            <li onClick={() => selectRubrique(null)} className={!activeRubrique ? "filter-rub active" : "filter-rub"}>
              <Translate id="global.all"/>
            </li>
            { listRubriques.map((d, i)=>(
              <li key={"rub-"+i+"-"+d.code}
                className={(activeRubrique && activeRubrique.code === d.code) ? "filter-rub active" : "filter-rub"}
                onClick={() => selectRubrique(d)}>
                {d.libelle} ({d.frequence})
              </li>
            ))}
          </ul>
        }
        { inspiringContents &&
          <div className="list-docs" style={disabledHitBottom ? { opacity : 0.3 } : {}}>
            { inspiringContents.map(doc => {
              return (
                <div key={`doc-${doc.ID_QES}`} onClick={() => openDoc(doc)}>
                  <ImgItem image={getImage(doc.IMAGE)}/>
                  <p>{doc.TITLE}</p>
                </div>
              )
            })}
          </div>
        }
        { noMatchCode &&
          <p><Translate id={"documents.ref.no-inspiring-content"} /></p>
        }
        { isLoading &&
          <img src={loader} className="loader" alt="loader"/>
        }

        {(!isLoading && (inspiringContents && inspiringContents.length === totalCount)) &&
          <p className="loaded"><Translate id="documents.ref.all-inspiring-content"/></p>
        }
      </div>
    </div>
  )
}

export default withLocalize(InspiringContents)
