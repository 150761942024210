import React, { useEffect, useState, useRef } from "react"
import _ from "lodash"
import queryString from 'query-string'
import pptx from '../../../assets/images/pptx.png'
import docx from '../../../assets/images/docX.png'
import iconCopy from '../../../assets/images/copy-solid.svg'

import usePlateforme from '../../../customHooks/usePlateforme'

import loader from '../../../assets/images/loader-disabled.gif'
import { Translate, withLocalize } from "react-localize-redux";

import MaterialIcon from 'material-icons-react'

import DocSimilaire from "./DocSimilaire"
import TwoColsLayout from "./layoutArticle/TwoColsLayout"
import NormalLayout from "./layoutArticle/NormalLayout"

import Tags from "./Tags"

// Babylon designed an end-to-end platform for AI-driven health services and virtual medical consultations
// The future 100 trends (pdf, pptx)

import {
  addSelectedKeywords,
  removeSelectedKeyword
} from '../../../actions/filters'

import { renderDate, escapeRegExp }  from '../../../helpers/helpers'
import { useGlobalContext } from '../../../GlobalContext'
import useContentDimension from  '../../../customHooks/useContentDimension'
import { getDownloadBaseUrl, getExt, getLinkDocument } from '../../../helpers/helpers'


const Article = (props) => {
  const [ context, dispatch ]  = useGlobalContext();
  const contentDimension = useContentDimension();
  const filtersReducer = context.filtersReducer
  const filtersJSON = usePlateforme()
  const timerCopy = useRef()

  const [ isMobile, setIsMobile] = useState(null)
  const [ openMobileTags, setOpenMobileTags] = useState(null)
  const [ isFixed, setIsFixed] = useState(false)
  const [ positionTitle, setPositionTitle] = useState(null)
  const [ positionTitleBottom, setPositionTitleBottom] = useState(null)
  const [ doc , setDoc] = useState(null)
  const [ articleLayout , setArticleLayout] = useState(null)
  const [ moreComments, setMoreComments ] = useState(false)

  const [ tagsFilters , setTagsFilters ] = useState([])
  const [ tagsQES , setTagsQES ] = useState([])
  const [ countTags , setCountTags ] = useState(null)
  const [ showSimilarContent, setShowSimilarContent] = useState(false)
  const [ showLinkedRessources, setShowLinkedRessources] = useState(false)

  const [ comments, setComments ] = useState(null)
  const [ filesLinks , setFilesLinks] = useState(null)
  const [ permanentLinks , setPermanentLinks] = useState(null)
  const [ isCopyToClipboard , setIsCopyToClipboard] = useState(false)

  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)
  const _removeSelectedKeyword = (keyword) => removeSelectedKeyword(dispatch,keyword)

  useEffect(()=>{
    setDoc(props.document)
  },[props.document])

  // FIND WHICH ARTICLE LAYOUT SHOULD BE USE
  useEffect(()=>{
    if(!filtersJSON || filtersReducer.indexSelectedEntries === null) return;

    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
    setArticleLayout(currentHeaderEntry.layoutArticle)

  },[filtersJSON,filtersReducer.indexSelectedEntries])

  //HANDLE IS MOBILE
  useEffect(()=>{
    if(contentDimension.width < 867 && (!isMobile || isMobile === null)){
      setIsMobile(true)
    }else if(contentDimension.width >= 867 && (isMobile || isMobile === null)){
      setIsMobile(false)
    }
  },[contentDimension.width])

  // SET COMMENTS
  useEffect(()=>{
    if(doc){

      let title = document.querySelector('.document-view h1')
      setPositionTitle(title.offsetTop)
      setPositionTitleBottom(title.offsetTop+title.offsetHeight)

      if(typeof doc.COMMENTS === "string"){

        let comment = doc.COMMENTS.split("|#|"+doc.COMMENTS_USER_ID+"|#|"+doc.COMMENTS_USER+"|#|")
        let date = comment[0]
        date = renderDate(date, props.activeLanguage.code)
        comment = comment[comment.length-1]
        let match2 = "|#|"+doc.COMMENTS_PLATFORM
        comment = comment.split(match2)[0]

        setComments([{auth : doc.COMMENTS_USER, text : comment, date}]);

      }else if(Array.isArray(doc.COMMENTS) && doc.COMMENTS.length > 0 ){

        let arrComments = []

        for (let i = 0; i < doc.COMMENTS.length; i++){

          let comment = doc.COMMENTS[i].split("|#|"+doc.COMMENTS_USER_ID[i]+"|#|"+doc.COMMENTS_USER[i]+"|#|")
          let date = comment[0]
          let timestamp = new Date().getTime()
          date = renderDate(date, props.activeLanguage.code)

          comment = comment[comment.length-1]
          let match2 = "|#|"+doc.COMMENTS_PLATFORM[i]
          comment = comment.split(match2)[0]
          arrComments.push({auth : doc.COMMENTS_USER[i], text : comment, date, timestamp})

        }
        arrComments = _.sortBy(arrComments,"timestamp").reverse()

        setComments(arrComments);
      }

    }


  },[doc,props.activeLanguage.code])

  useEffect(()=>{
    return () => { clearTimeout(timerCopy.current) }
  },[])

  // SET URL FILES
  useEffect(()=>{
    if(!doc) return;

    if(doc.FILES && doc.FILES.length > 0){
      let token = localStorage.getItem("token")

      let arrFiles = []
      if(Array.isArray(doc.FILES)){
        doc.FILES.forEach((d,i)=>{
          let ext = getExt(doc.FILES[i])
          let urlFile = getDownloadBaseUrl(ext)+doc.FILES[i]+"&token="+token
          arrFiles.push({ url : urlFile, name : doc.FILES[i] , ext});
        })
      }else if(typeof doc.FILES === 'string'){
        let ext = getExt(doc.FILES)
        let urlFile = getDownloadBaseUrl(ext)+doc.FILES+"&token="+token
        arrFiles.push({ url : urlFile, name : doc.FILES , ext});
      }

      setFilesLinks(arrFiles)
    }

  },[doc])

  // SET PERMANENT LINK
  useEffect(()=>{
    if(!doc) return;
    let plateforme = localStorage.getItem("plateforme")
    let token = localStorage.getItem("token")
    plateforme = JSON.parse(plateforme);
    setPermanentLinks({link : plateforme.urlpermlink+doc.ID_QES , linkWithToken : plateforme.urlpermlink+doc.ID_QES+"&token="+token})
  },[doc])

  // LIST TAGS
  useEffect(()=>{

    if(!doc || !filtersJSON) return;

    let tagsFilters = []
    let tagsQES = []

    for(var property in doc ){
      let regex = /FILTER/g;
      let regex2 = /^QES/g;


      if(regex.test(property)){
        let arrCode = doc[property].split(";")
        tagsFilters.push(arrCode)
      }

      let arrCode;

      if(regex2.test(property) || property === "CONCEPT" || /ACTEURS_IMPLIQUES/g.test(property)){
        if(property === "CONCEPT" ||
          property === "QES_Location" ||
          property === "QES_PersonTopic" ||
          property === "ACTEURS_IMPLIQUES"
        ){
          let title;
          switch (property) {
            case 'CONCEPT':
              title = props.translate("documents.document-view.concept")
              break;
            case 'QES_Location':
              title = props.translate("documents.document-view.location")
              break;
            case 'QES_PersonTopic':
              title = props.translate("documents.document-view.personnes")
              break;
            case 'ACTEURS_IMPLIQUES':
              title = props.translate("documents.document-view.entreprises")
              break;
            default:

          }

          if(Array.isArray()){
            let tags = [];
            doc[property].forEach((d)=>{
              tags.push(d.split(";"))
            })
            tagsQES.push({ title, code : property, tags : _.flatten(tags)})
          }else if(typeof doc[property] === "string"){
            arrCode = doc[property].split(";")
            tagsQES.push({ title, code : property, tags : arrCode})
          }

        }


      }

    }

    tagsQES = tagsQES.map((d)=>{
      let obj = Object.assign({},d)
      let tags = []
      obj.tags.forEach((tag)=>{
        let isActive = _.find(filtersReducer.selectedKeywords, (keyword)=>{
          return keyword.code.toLowerCase() === tag.toLowerCase()
        })
        if(tag !== "") tags.push({ label : tag, active : isActive ? true : false, id : obj.code})

      })
      delete obj.tags
      obj.tags = tags
      return obj
    })

    tagsFilters = _.uniq(_.flatten(tagsFilters));
    tagsFilters = tagsFilters.map((tag)=>{
      let found = filtersReducer.matchCode.find(d=>{
        return tag === d.code
      })

      let isActive = false

      if(found){
        isActive = _.find(filtersReducer.selectedKeywords, (keyword)=>{
          return keyword.code === tag
        })
        if(isActive){
          isActive = true
        }else{
          isActive = false
        }
      }

      if(found){
        return { label : found.label, active : isActive, id : found.id, code : found.code}
      }
      return null
    })

    tagsFilters = _.compact(tagsFilters)
    tagsFilters = tagsFilters.sort( (a,b) => b.active - a.active)

    let groupFilters = _.cloneDeep(filtersJSON["doc-tags"])

    let mapTags = {}
    tagsFilters.forEach((d)=>{
      mapTags[d.code] = d
    })
    groupFilters.forEach((d)=>{
      let categories = d.categories;
      let filters = [];
      Object.values(mapTags).forEach((tag)=>{
        if(categories.includes(tag.id) ){
          filters.push(tag)
          delete mapTags[tag.code]
        }
      })

      d.filters = filters
    })

    groupFilters = groupFilters.filter((d) => d.filters.length>0)

    groupFilters.forEach((d)=>{
      d.filters = _.orderBy(d.filters,"id")
    })


    let organizedFilters = []
    groupFilters.forEach((group,i)=> {

      let firstLevelFilters = []
      let secondLevelFilters = []
      group.filters.forEach((d)=>{
        if(d.id[d.id.length-1] !== "2"){
          d.filters = []
          firstLevelFilters.push(d)
        }else{
          secondLevelFilters.push(d)
        }
      })

      firstLevelFilters.forEach((d)=>{
        secondLevelFilters.forEach((k,i)=>{
          let reg = new RegExp(escapeRegExp(d.code))
          if(reg.test(k.code)){
            d.filters.push(k)
            k.hasParent = true
          }
        })
      })


      secondLevelFilters = secondLevelFilters.filter((d)=>!d.hasParent)
      let obj = {}
      obj.title = group.title ? getTranslatedTitle(group.title) : null;
      obj.visible = !!group.visible
      obj.filters = [...firstLevelFilters, ...secondLevelFilters]
      obj.filters = _.groupBy(obj.filters, "id")
      organizedFilters.push(obj)
    })

    ////
    setTagsFilters(organizedFilters);
    setTagsQES(tagsQES);


  },[
    filtersJSON,
    doc,
    filtersReducer.selectedKeywords,
    props.activeLanguage.code
  ])

  // COUNT TAGS
  useEffect(()=>{
    if(tagsFilters.length === 0 && tagsQES.length === 0) return;

    let filtersCounts = 0
    tagsFilters.forEach((d)=>{
      Object.keys(d.filters).forEach(key=>{
        filtersCounts += d.filters[key].length;
        d.filters[key].forEach((k)=>{
          if(k.filters) filtersCounts += k.filters.length
        })
      })

    })

    let qesCount = 0
    tagsQES.forEach((qes)=>{
      qesCount += qes.tags.length
    })

    setCountTags(filtersCounts+qesCount)

  },[tagsFilters,tagsQES])

  function scroll(e){
    if(e.target.scrollTop > positionTitle){
      // console.log("fixed");
      setIsFixed(true)
    }else{
      setIsFixed(false)
    }
  }

  function removeTag(content){
    let html = content;
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.innerText
  }

  // WARNING THIS FUNCTION NAME LEAD TO CONFUSION
  // IT'S USED TO GO TO PREV DOC BUT ALSO TO CLOSE DOCS
  function prevDocument(){

    // IF TRIGGER WHEN WE ARE ON "strategic-watch"
    // REMOVE SEARCH IS EXIST
    if(props.match.params.page === "strategic-watch" &&
      props.history.location.search
    ){
      let params = queryString.parse(props.history.location.search)
      props.history.replace({
        search: `?entry=${params.entry}`,
      })
    }

    let previousRef = localStorage.getItem('previousDoc')
    if(previousRef){
      localStorage.removeItem('previousDoc')
      props.selectDoc(previousRef)
    }else{
      props.selectDoc(null)
    }
  }

  function renderMoreLikeThis(docsSimilaires){
    let docs = [...docsSimilaires]
    if(!showSimilarContent) docs = docs.splice(0,3)

    function openDocument(d){
      props.selectDoc(d.ID_QES)
      localStorage.setItem('previousDoc',doc.ID_QES)
    }

    let elts = docs.map((d, i)=>{
      return (
        <DocSimilaire selectDoc={() => openDocument(d)} key={"docs-similaires-"+i} plateformeID={filtersReducer.plateformeID} ID_QES={d.ID_QES}/>
      )
    })

    return <>{elts}</>

  }

  function renderLinkedRessources(linkedRessource){
    let arrLinkedRessources = linkedRessource.split(";")
    let linkedRessources = [...arrLinkedRessources]
    if(!showLinkedRessources) linkedRessources = linkedRessources.splice(0,3)
    let elts = linkedRessources.map((d, i)=>{
      return (
        <DocSimilaire selectDoc={() => props.selectDoc(d)} key={"linked-ressource"+i} plateformeID={filtersReducer.plateformeID} ID_QES={d}/>
      )
    })

    return <>{elts}</>

  }

  function toggleKeywords(e,keyword,type){
    e.stopPropagation();
    e.preventDefault();

    let obj;
    if(type === "QES"){
      obj = {
        id : keyword.id,
        code : keyword.label.toLowerCase(),
        label : keyword.label,
        value : keyword.label
      }
    }else{
      obj = {
        id : keyword.id,
        code : keyword.code,
        label : keyword.label,
        value : keyword.label
      }
    }

    let isSelected = _.find(filtersReducer.selectedKeywords, (keyword)=>{
      return keyword.code === obj.code
    })

    if(isSelected){
      isSelected = true
    }else{
      isSelected = false
    }

    if(!isSelected){
       _addSelectedKeywords(obj)
    }else{
      _removeSelectedKeyword(obj)
    }

  }

  function copyToClipboard(){
    var copyUrl = getLinkDocument(doc,filtersReducer);
    var elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = copyUrl;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    setIsCopyToClipboard(true)
    timerCopy.current = setTimeout(()=>{
      setIsCopyToClipboard(false)
      clearTimeout(timerCopy.current)
    },1000)
  }

  function getTranslatedTitle(title){
    let indexLang;
    if(props.activeLanguage.code === "fr"){
      indexLang = 1
    }else if(props.activeLanguage.code === "en"){
      indexLang = 0
    }
    return title[indexLang]
  }

  return(
    <div className="document-view article" onScroll={scroll}>
      {isMobile &&
        <div className={openMobileTags ? "mobile-container-tags open" : "mobile-container-tags"}>
          <div>
            <div onClick={()=>setOpenMobileTags(false)}>
              <MaterialIcon key="icon-backward" icon="arrow_back" size={18} color="#151845"/>
              <Translate id="global.back"/>
            </div>
            <p>{countTags} tags</p>
          </div>
          <Tags
            tagsFilters={tagsFilters}
            tagsQES={tagsQES}
            toggleKeywords={toggleKeywords}
            isMobile={true}
          />
        </div>
      }

      <div>
        { doc ?
          <>
          <h1 className={isFixed ? "fixed" : ""}>
            <div className="back" style={localStorage.getItem('previousDoc') ? {width : "75px"} : {}} onClick={prevDocument}>
              { localStorage.getItem('previousDoc') ?
                <>
                  <MaterialIcon key="icon-backward" icon="arrow_back" size={14} color="#FF7D67"/>
                  <Translate id="global.back"/>
                </> :
                <>
                  <span className="close"></span>
                  <Translate id="global.close"/>
                </>
              }
            </div>

            <span dangerouslySetInnerHTML={{__html: doc.TITLE}}></span>

            {isFixed ? <div className="separator"></div> : null}
          </h1>
          <div className="content" style={(contentDimension.width > 876 && isFixed ) ? {marginTop : `${positionTitleBottom}px`} : {}}>

            { articleLayout === "two-cols" ?
              <TwoColsLayout
                doc={doc}
                toggleKeywords={toggleKeywords}
                toggleMobileTags={() => setOpenMobileTags(!openMobileTags)}
                countTags={countTags}
              /> :
              <NormalLayout
                doc={doc}
                toggleKeywords={toggleKeywords}
                toggleMobileTags={() => setOpenMobileTags(!openMobileTags)}
                countTags={countTags}
              />
            }

            <div className="doc-content-right">
              { !isMobile &&
                <Tags
                  tagsFilters={tagsFilters}
                  tagsQES={tagsQES}
                  toggleKeywords={toggleKeywords}
                />
              }
              { filesLinks ?
                <>
                <h5><Translate id="documents.document-view.attached-document"/>{filesLinks.length>1 ? "s" : ""}</h5>
                {filesLinks.map((d,i)=>{
                  return <div key={"links-files-"+i} className="file-link">
                    {d.ext === "pdf" && <MaterialIcon icon="picture_as_pdf" size={25} color="#6E708C"/>}
                    {d.ext === "docx" && <img src={docx} alt="file docx" />}
                    {d.ext === "pptx" && <img src={pptx} alt="file pptx" />}
                    <a target="_blank" rel="noopener noreferrer" href={d.url}>{d.name}</a>
                  </div>
                })}</> : null
              }
            </div>
            {(doc.ARTICLES_SIMILAIRES && doc.ARTICLES_SIMILAIRES.length > 0) &&
              <div className="docs-similaires">
                <h5><Translate id="documents.document-view.similar-content"/>{doc.ARTICLES_SIMILAIRES>1 ? "s" : ""}</h5>
               {renderMoreLikeThis(doc.ARTICLES_SIMILAIRES)}
               {doc.ARTICLES_SIMILAIRES.length>3 &&
                <div className="center">
                  <button onClick={() => setShowSimilarContent(!showSimilarContent)}>
                  { showSimilarContent ?
                    <Translate id="documents.document-view.less-similar-content"/> :
                    <Translate id="documents.document-view.more-similar-content"/>
                  }
                  </button>
                </div>
               }
              </div>
            }

            {(doc.IDLIE && doc.IDLIE.length > 0) &&
              <div className="linked-ressource">
                <h5><Translate id="documents.document-view.linked-ressources"/></h5>
               {renderLinkedRessources(doc.IDLIE)}
               {doc.IDLIE.split(";").length>3 &&
                <div className="center">
                  <button onClick={() => setShowLinkedRessources(!showLinkedRessources)}>
                  { showLinkedRessources ?
                    <Translate id="documents.document-view.less-linked-ressources"/> :
                    <Translate id="documents.document-view.more-linked-ressources"/>
                  }
                  </button>
                </div>
               }
              </div>
            }

            {/*
              permanentLinks ?
               <a className="source hide-on-mobile" rel="noopener noreferrer" target="_blank" href={permanentLinks.linkWithToken}>{permanentLinks.link}</a> : null
            */}

            <div className="source hide-on-mobile">
              <span>
                <span style={{fontWeight : "bold"}} className="test"><Translate id="documents.document-view.permanent-link"/>{` : `}</span>
                <a href={getLinkDocument(doc,filtersReducer)} id="permalink">
                 {getLinkDocument(doc,filtersReducer)}
                </a>
              </span>
              
              <button className="btn-copy" onClick={()=> copyToClipboard()}><img src={iconCopy} alt="copy to clipboard"/></button>
              <div className={isCopyToClipboard ? "copied in" : "copied"}><Translate id="documents.document-view.copy"/></div>
            </div>



            {comments ?
              <div className={moreComments ? "container-comments" : "container-comments hide"}>
                <div className="header-comments">
                  <h3>{!filtersJSON["doc-export-view"] ? <Translate id="documents.document-view.comment"/> : <Translate id="documents.document-view.expert"/>}</h3>
                </div>
                { moreComments || comments.length === 1 ?
                  <>
                    {
                      comments.map((d,i)=>{
                        return (
                          <div className="container-comment" key={"comment-"+i}>
                            <p className="author"><b>{d.auth}</b> - {d.date} </p>
                            <div className="comments" ><span dangerouslySetInnerHTML={{__html: d.text}}></span></div>
                          </div>
                        )
                      })
                    }
                  </> :
                  <div>
                    <div className="container-comment" key={"comment-1"}>
                      <p className="author"><b>{comments[0].auth}</b> - {comments[0].date} </p>
                      <div className="comments" ><span dangerouslySetInnerHTML={{__html: comments[0].text}}></span></div>
                    </div>
                    <div className="container-comment" key={"comment-2"}>
                      <div className="gradient"></div>
                      <p className="author"><b>{comments[1].auth}</b> - {comments[1].date} </p>
                      <div className="comments" ><span dangerouslySetInnerHTML={{__html: comments[1].text}}></span></div>
                    </div>
                  </div>
                }
                { comments.length > 1 &&
                  <div className="content-centered">
                    <button className="btn-comments" onClick={() => setMoreComments(!moreComments)}>
                      {moreComments ? <><Translate id="documents.document-view.less-comments"/></> : <Translate id="documents.document-view.more-comments"/>}
                    </button>
                  </div>
                }
              </div> : null
            }
          </div>
          </> : <img className="loader-doc" alt="loader" src={loader} />
        }

      </div>
    </div>
  )
}

export default withLocalize(Article)
