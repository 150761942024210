import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import _ from 'lodash'
import { Translate, withLocalize } from "react-localize-redux";
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import imgCheckboxChecked from '../../assets/images/checkboxes/checkbox-checked.svg'
import imgCheckbox from '../../assets/images/checkboxes/checkbox.svg'
import iconLoupe from '../../assets/images/icon-loupe-white.svg'

import usePlateforme from '../../customHooks/usePlateforme'
import useContentDimension from '../../customHooks/useContentDimension'
import { useGlobalContext } from '../../GlobalContext'
import { useFormInput } from '../../customHooks/useFormInput'

import InputField from '../partials/InputField'
import TagsPanel from './TagsPanel'
import TagsCount from './TagsCount'
import Tag from './Tag'
import PopupAbout from './PopupAbout'

import GraphRange from '../partials/GraphRange'
import SearchByKeywords from '../partials/SearchByKeywords'

import { fnGroupBy } from '../../helpers/helpers'

import { setSearch, setFilterSearchTypes, toggleFilterByDate, addSelectedKeywords } from '../../actions/filters'

const Filters = (props) => {
  const contentDimension = useContentDimension()
  const [context, dispatch] = useGlobalContext()
  const filtersReducer = context.filtersReducer;
  let refsPanel = useRef();

  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false)
  const [openGroupFilters, setOpenGroupFilters] = useState([]);
  const [openFilters, setOpenFilters] = useState([]);

  const inputSearch = useFormInput(filtersReducer.search && filtersReducer.search.string ? filtersReducer.search.string : '', props.translate("filters.placeholder-search"))
  const [checkbox, setCheckbox] = useState(filtersReducer.search && filtersReducer.search.type ? filtersReducer.search.type : [])
  const [withDate, setWithDate] = useState(filtersReducer.filteredByDate)
  const [pinUpFilters, setPinUpFilters] = useState([])
  const [panelFiltersOpened, setPanelFiltersOpened] = useState()
  const [isTimeline, setIsTimeline] = useState()
  const [isRubrique, setIsRubrique] = useState()
  const [isStartUpPlatform, setIsStartUpPlatform] = useState()

  const [keywordDate, setKeywordDate] = useState()

  const filtersJSON = usePlateforme()

  // ACTIONS
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch, keyword)
  const _setSearch = (obj) => setSearch(dispatch, obj)
  const _setFilterSearchTypes = (obj) => setFilterSearchTypes(dispatch, obj)
  const _toggleFilterByDate = (bool) => toggleFilterByDate(dispatch, bool)

  // INIT REF FOR ListCheckboxes
  useEffect(() => {
    if (!filtersJSON) return;
    let groupFilter = _.cloneDeep(filtersJSON["advanced"])
    let refs = {}
    let categories = []

    let defaultOpenFilters = []

    groupFilter.forEach(d => {
      if (d.openFilters) defaultOpenFilters.push(...d.openFilters)
      categories.push(...d.categories)
    })

    if (defaultOpenFilters.length > 0) setOpenFilters(defaultOpenFilters)

    categories.forEach((d) => {
      refs[d.code] = React.createRef()
    })

    refsPanel.current = { ...refsPanel.current, ...refs }

    setIsStartUpPlatform(!!filtersJSON["startup-platform"]);

  }, [filtersJSON])

  //SET IF WE SHOW TIMELINE
  //SET PIN UP FILTERS
  // BY SPACE
  useEffect(() => {
    if (!filtersJSON || filtersReducer.indexSelectedEntries === null || !filtersReducer.filters) return;

    let isRubriqueFilter = false
    if (filtersReducer.filters) {
      isRubriqueFilter = !!filtersReducer.filters.find(d => d.id === "RUBRIQUE")
    }
    setIsRubrique(isRubriqueFilter)

    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
    if (currentHeaderEntry && currentHeaderEntry["pin-up-filters"]) {
      let pinup = []
      let refs = {}
      currentHeaderEntry["pin-up-filters"].forEach((d) => {
        let found = filtersReducer.filters.find(filter => filter.id === d.code)
        if (found) {
          pinup.push({
            ...found,
            order: d.order ? d.order : null,
            help: d.help ? d.help : null,
            openFilters: d.openFilters ? d.openFilters : null
          })
          refs[`${d.code}-pinup`] = React.createRef()
        }
      })

      let re = new RegExp(/\/analysis-tools/)
      let isTimeline = !currentHeaderEntry.navbar.find(d => re.test(d.url))
      setIsTimeline(isTimeline)

      refsPanel.current = { ...refsPanel.current, ...refs }
      setPinUpFilters(pinup)

    }

  }, [
    filtersJSON,
    filtersReducer.indexSelectedEntries,
    filtersReducer.filters,
    props.pathname
  ])

  //HANDLE DEFAULT OPEN GROUP
  useEffect(() => {
    if (!filtersJSON) return;
    let openFilters = filtersJSON["openFilters"]
    if (openFilters) setOpenGroupFilters(openFilters)
  }, [filtersJSON])

  // HANDLE TAG DATE keywordDate
  useEffect(() => {
    if (filtersReducer.keywordDate && !isTimeline) {

      let minYear = filtersReducer.keywordDate?.min?.date
      let maxYear = filtersReducer.keywordDate?.max?.date
      let keywordYear;
      if (minYear && maxYear && minYear.getFullYear() === maxYear.getFullYear()) {

        keywordYear = {
          value: minYear,
          label: `${minYear.getFullYear()}`,
          date: true
        }

      }

      setKeywordDate(keywordYear)
    } else {
      setKeywordDate(null)
    }
  }, [filtersReducer.keywordDate, isTimeline])

  useEffect(() => {
    if (!filtersReducer.search) {
      setCheckbox([])
      inputSearch.clearInput()
    } else {
      inputSearch.setValue(filtersReducer.search.string)
    }
  }, [filtersReducer.search])

  function toggleGroupFilters(index) {
    if (!_.includes(openGroupFilters, index)) {
      setOpenGroupFilters([...openGroupFilters, index])
    } else {
      let openGroupFiltersArr = _.filter(openGroupFilters, (d) => d !== index)
      setOpenGroupFilters(openGroupFiltersArr)
    }
  }

  function clearSearch() {
    localStorage.removeItem("search")
    _setSearch(null)
  }

  function search(val) {
    let arrTypes;

    if (typeof val === "string" && !checkbox.includes(val)) {
      arrTypes = [...checkbox, val]
    } else if (typeof val === "string" && checkbox.includes(val)) {
      arrTypes = checkbox.filter((d) => d !== val)
    } else {
      arrTypes = [...checkbox]
    }

    _setSearch({
      string: inputSearch.DOMAttribute.value,
      type: arrTypes
    })
  }

  function handlePressEnter(e) {
    if (e.key !== "Enter") return;
    search()
  }

  function toggleCheckbox(val, fulltextOpt) {

    if (inputSearch.DOMAttribute.value ||
      val === "VIDEO" || val === "TOP_RESSOURCE"
    ) {
      search(val)
    }



    let checkboxArr = []

    if (!_.includes(checkbox, val)) {
      checkboxArr = [...checkbox, val]
    } else {
      checkboxArr = _.filter(checkbox, (d) => d !== val)
    }

    setCheckbox(checkboxArr)
    if (fulltextOpt) {
      _setFilterSearchTypes(checkboxArr)
    }

  }

  function toggleDate() {
    _toggleFilterByDate(!withDate)
    setWithDate(!withDate)
  }

  function togglePanel(keyRef) {
    Object.keys(refsPanel.current).forEach(key => {
      if (key === keyRef) {
        refsPanel.current[keyRef].current.toggle();
        setPanelFiltersOpened(keyRef)
      }
      else if (refsPanel.current[key].current) refsPanel.current[key].current.close()
    })
  }

  function renderConfigurableFilters() {
    if (!filtersJSON || !filtersReducer.filters) return null;
    let filters = _.cloneDeep(filtersReducer.filters)
    let listFilters = filtersJSON["advanced"]
    if (!listFilters) return;

    listFilters = _.flatten(listFilters.map((d) => {
      var categories = d.categories
      categories = _.map(categories, (cat) => {
        if (cat.code) {
          return cat.code
        } else {
          return cat
        }
      })
      return categories
    }
    ))

    filters = filters.filter((d) => {
      return _.includes(listFilters, d.id)
    })
    filters = _.sortBy(filters, "id")

    let listIdFilter = filters.map((filter) => filter.id)
    let filterLevel = []

    filters.forEach((d) => {
      if (d.id[d.id.length - 1] === '2') {
        if (_.includes(listIdFilter, d.id.slice(0, -1))) filterLevel.push(d.id.slice(0, -1))
      }
    })

    let groupFilter = _.cloneDeep(filtersJSON["advanced"])


    let mapSelected = new Map();
    if (filtersReducer.selectedKeywords) {
      filtersReducer.selectedKeywords.forEach((d) => {
        mapSelected.set(d.code, true)
      })
    }
    groupFilter = fnGroupBy(groupFilter, filters)

    let allFilter = []
    groupFilter.forEach((group, j) => {

      if (group.filters.length > 0) {

        let eltFilters = []

        group.filters.forEach((filter, i) => {
          let id = filter.id
          if (filter.valeurs && filter.valeurs.length > 0) {

            eltFilters.push(
              <React.Fragment key={id}>
                <p
                  onClick={() => togglePanel(id)}
                  className={panelFiltersOpened === filter.id ? "title-filter open" : "title-filter"}
                >
                  <span>{filter.libelle}</span>
                </p>
                {refsPanel.current[id] &&
                  <PortalPanel>
                    <TagsPanel
                      ref={refsPanel.current[id]}
                      id={id}
                      help={filter.help}
                      order={filter.order}
                      openFilters={filter.openFilters}
                      panelLevel={id[id.length - 1] === '2' ? 2 : 1}
                      advancedConfig={filtersJSON["advanced"]}
                    />
                  </PortalPanel>
                }
              </React.Fragment>
            )
          }
        });

        let indexLang;
        if (props.activeLanguage.code === "fr") {
          indexLang = 1
        } else if (props.activeLanguage.code === "en") {
          indexLang = 0
        }

        allFilter.push(
          <span key={"group-filters-" + j}>

            <p className={_.includes(openGroupFilters, j) ? "title interactive actived" : "title interactive"} onClick={() => toggleGroupFilters(j)}>
              <span className="icon">
                {_.includes(openGroupFilters, j) ?
                  <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />
                }
              </span>
              <span>{group.title[indexLang]}</span>
            </p>
            <div className='container-filters' style={_.includes(openGroupFilters, j) ? {} : { height: 0, overflow: 'hidden' }}>
              {eltFilters}
            </div>

          </span>
        )
      }

    })

    return allFilter

  }

  function getWidthFilters() {
    if (contentDimension.width < 867) {
      return { left: "0px" }
    } else if (contentDimension.width < 1165) {
      return { left: "30px", width: "440px" }
    } else {
      return { width: "440px" }
    }
  }

  function getCount(id) {
    let count = {
      cumul: 0,
      select: 0,
      exclude: 0,
      rub: 0
    }

    filtersReducer.selectedKeywords.forEach(keyword => {
      if (keyword.exclude) {
        count.exclude++
      } else if (/%20OR%20/.test(keyword.code)) {
        count.cumul++
      } else if (keyword.id === "RUBRIQUE") {
        count.rub++
      } else {
        count.select++
      }
    })

    return <TagsCount count={count} />
  }

  function getAllTags() {
    if (keywordDate) return [...filtersReducer.selectedKeywords, filtersReducer.search, keywordDate]
    else return [...filtersReducer.selectedKeywords, filtersReducer.search]
  }

  function renderTags() {
    let tags = getAllTags().filter(d => {
      if (!d) return false;
      if (d.hasOwnProperty("string") && d.hasOwnProperty("type")) {
        return d.string && d.string !== ''
      } else {
        return d
      }
    })
    if (tags.length === 0) return;
    return (
      <ul className="list-tags">
        {tags.map((keyword, i) =>
          <li key={`tag-${i}`}>
            <Tag tag={keyword} iconClose={true} />
          </li>
        )}
      </ul>
    )
  }


  return (
    <>
      {pinUpFilters && pinUpFilters.length > 0 &&
        <>
          {
            pinUpFilters.map(d => {
              return (
                <TagsPanel
                  key={`tags-panel-$${d.id}`}
                  ref={refsPanel.current[`${d.id}-pinup`]}
                  id={d.id}
                  panelLevel={d.id[d.id.length - 1] === '2' ? 2 : 1}
                  advancedConfig={filtersJSON["advanced"]}
                  help={d.help}
                  order={d.order}
                  openFilters={d.openFilters}
                />
              )
            }
            )
          }
        </>
      }
      <span id="portal-panel"></span>
      <div className="filters" style={getWidthFilters()}>
        <div>
          <span className="close" onClick={props.closeFilter}></span>
          {pinUpFilters && pinUpFilters.length > 0 &&
            <div className="container-pinup">
              <ul>
                {pinUpFilters.map(filter =>
                  <li key={`${filter.id}`} onClick={() => togglePanel(`${filter.id}-pinup`)}
                    className={panelFiltersOpened === `${filter.id}-pinup` ? "open" : ""}
                  >
                    <span>{filter.libelle}</span>
                  </li>
                )}
              </ul>
            </div>
          }
          {isRubrique &&
            <div className="container-rubrique">
              <p className="title">
                <Translate id="filters.category" />
              </p>
              <SearchByKeywords
                testId={"rubrique"}
                id={"RUBRIQUE"}
                className="search-rubrique"
                classNamePrefix={"filters-rubrique"}
                isMulti={true}
                isClearable={true}
                components={{
                  IndicatorSeparator: () => null
                }}
              />
            </div>
          }
          <div className="container-fulltext-search">
            <p className="title">
              <Translate id="filters.title-search" />
            </p>
            <div className="content-centered" onKeyPress={handlePressEnter}>
              <InputField initialState={inputSearch} isClearable={true} fnClear={clearSearch} />
              <button className="btn-search" onClick={search}><img src={iconLoupe} alt="fulltext search" /></button>
            </div>
            {(typeof isStartUpPlatform === "boolean" && !isStartUpPlatform) &&
              <div className="container-checkbox">
                <div onClick={() => toggleCheckbox('TITLE', true)} className="field-checkbox">
                  <div className="checkbox">
                    {_.includes(checkbox, 'TITLE') ?
                      <img src={imgCheckboxChecked} alt="checkbox checked" /> :
                      <img src={imgCheckbox} alt="checkbox" />
                    }
                  </div>
                  <label><Translate id="filters.restrict.title" /></label>
                </div>
                <div onClick={() => toggleCheckbox('ABSTRACT', true)} className="field-checkbox">
                  <div className="checkbox">
                    {_.includes(checkbox, 'ABSTRACT') ?
                      <img src={imgCheckboxChecked} alt="checkbox checked" /> :
                      <img src={imgCheckbox} alt="checkbox" />
                    }
                  </div>
                  <label><Translate id="filters.restrict.abstract" /></label>
                </div>
                <div onClick={() => toggleCheckbox('TEXTE', true)} className="field-checkbox">
                  <div className="checkbox">
                    {_.includes(checkbox, 'TEXTE') ?
                      <img src={imgCheckboxChecked} alt="checkbox checked" /> :
                      <img src={imgCheckbox} alt="checkbox" />
                    }
                  </div>
                  <label><Translate id="filters.restrict.texte" /></label>
                </div>
                <div onClick={() => toggleCheckbox('FILES_TEXT', true)} className="field-checkbox">
                  <div className="checkbox">
                    {_.includes(checkbox, 'FILES_TEXT') ?
                      <img src={imgCheckboxChecked} alt="checkbox checked" /> :
                      <img src={imgCheckbox} alt="checkbox" />
                    }
                  </div>
                  <label><Translate id="filters.restrict.files-texte" /></label>
                </div>
              </div>
            }
          </div>
          {(typeof isStartUpPlatform === "boolean" && !isStartUpPlatform) &&
            <div className="container-restrict-search">
              <p className="title"><Translate id="filters.title-restrict" /></p>
              <div className="container-checkbox">
                <div onClick={() => toggleCheckbox('VIDEO')} className="field-checkbox">
                  <div className="checkbox">
                    {_.includes(checkbox, 'VIDEO') ?
                      <img src={imgCheckboxChecked} alt="checkbox checked" /> :
                      <img src={imgCheckbox} alt="checkbox" />
                    }
                  </div>
                  <label><Translate id="filters.restrict.video" /></label>
                </div>
                <div onClick={() => toggleCheckbox('TOP_RESSOURCE')} className="field-checkbox">
                  <div className="checkbox">
                    {_.includes(checkbox, 'TOP_RESSOURCE') ?
                      <img src={imgCheckboxChecked} alt="checkbox checked" /> :
                      <img src={imgCheckbox} alt="checkbox" />
                    }
                  </div>
                  <label><Translate id="filters.restrict.top-ressource" /></label>
                </div>
                {isTimeline &&
                  <div onClick={() => toggleDate()} className="field-checkbox">
                    <div className="checkbox">
                      {withDate ?
                        <img src={imgCheckboxChecked} alt="checkbox checked" /> :
                        <img src={imgCheckbox} alt="checkbox" />
                      }
                    </div>
                    <label>Dates</label>
                  </div>
                }
              </div>
            </div>
          }

          {(typeof isStartUpPlatform === "boolean" && !isStartUpPlatform) &&
            <>
              {/*IF WE ARE NOT ON A START UP PLATFORM INPUT DATE IS IN ADVANCED SEARCH*/}
              {isTimeline && withDate &&
                <div className="container-date">
                  <p className="title"><Translate id="filters.dates" /></p>
                  <GraphRange graphLine={false} />
                </div>
              }
            </>
          }

          <div className="container-advanced-search">
            <p className="title interactive title-advanced" onClick={() => setIsAdvancedSearch(!isAdvancedSearch)}>
              {isAdvancedSearch ?
                <FontAwesomeIcon icon={faChevronDown} /> :
                <FontAwesomeIcon icon={faChevronRight} />
              }
              <Translate id="filters.advanced" />
              <span className="container-count">
                {getCount()}
              </span>
            </p>
            {isAdvancedSearch &&
              <>
                {renderTags()}
                <div className="container-advanced-filters">
                  <div className="container-title">
                    {/*<p className="title">Tags</p>*/}
                    <ReactTooltip className={`react-tooltip tooltip-filter-selection-help`} delayShow={500} id={"filter-selection-help"} place="right" effect='solid'>
                      <span><Translate id="filters.filter-selection-help" /></span>
                    </ReactTooltip>
                  </div>
                  {renderConfigurableFilters()}
                </div>
              </>
            }
          </div>
        </div>
        <PopupAbout
          title={filtersJSON?.header[filtersReducer.indexSelectedEntries]?.["title-about"]}
          text={filtersJSON?.header[filtersReducer.indexSelectedEntries]?.about}
        />
      </div>
    </>

  )

}

const PortalPanel = ({
  children
}) => {
  const modalRoot = document.getElementById("portal-panel");

  return createPortal(children, modalRoot)
}

export default withLocalize(Filters)
