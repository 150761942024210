import React, { useEffect } from 'react'
import axios from 'axios';
import _ from 'lodash'
import styles from './redirect.module.scss'
import LoaderView from '../partials/LoaderView'
import { useGlobalContext } from '../../GlobalContext'
import { getPlateforme } from '../../customHooks/usePlateforme'
import { API_URL } from '../../actions'
import { loginSSO } from '../../actions/auth'
import { setPlateformeId } from '../../actions/filters'

const Redirect = ({history}) => {
  const [ context, dispatch] = useGlobalContext();
  const authReducer = context.authReducer;

  /// ACTIONS
  const _loginSSO = (obj) => loginSSO(dispatch,obj)
  const _setPlateformeId = (id) => setPlateformeId(dispatch,id)

  useEffect(()=>{
    let token = history.location.pathname.replace("/redirect/","")
    if (!token) return;
    
    const getProfile = async () => {

        let config = {
          headers: {
            Authorization : token
          }
        }
        try {
          const res = await axios.get(`${API_URL}auth/profile`,config);
          if(res.data.user) _loginSSO({
            user : {
              ...res.data.user,
              token
            }
          })

        } catch (err) {
          console.error(err);
        }

    };

    getProfile();

  }, []);

  //WHEN AUTHENTIFICATED
  useEffect(() => {
    if(authReducer.isAuthenticated){

      let plateformes =  _.unionBy(authReducer.plateformes, 'id');

      let idPlateformeSelectedOnLog = 1015
      let plateformeSelected = plateformes.find((plateforme)=> plateforme.id === idPlateformeSelectedOnLog)

      if(plateformeSelected){
        plateformes = plateformes.filter((plateforme)=> plateforme.id !== idPlateformeSelectedOnLog)
        plateformes = [plateformeSelected, ...plateformes]
      }

      //////////////////

      localStorage.setItem("plateformeID", plateformes[0].id)
      _setPlateformeId(plateformes[0].id)

      let plateforme = authReducer.plateformes.find((d)=>{
        return d.id === plateformes[0].id
      })

      localStorage.setItem("plateformes", JSON.stringify(plateformes))
      localStorage.setItem("plateforme", JSON.stringify(plateforme))
      localStorage.setItem("popup", JSON.stringify({popup : true}))

      let json = getPlateforme(plateformes[0].id)
      let findEntry = json.header.find(d => d.url || d.navbar)
      let findEntryIndex = json.header.findIndex(d => d.url || d.navbar)

      if(findEntry.url){
        history.push({
          pathname : "/search-innov/"+plateformes[0].id+findEntry.url,
          search: "?" + new URLSearchParams({entry: findEntryIndex}).toString()
        })
      }else if(findEntry.navbar){
        let firstView = findEntry.navbar.find(d => d.url)
        history.push({
          pathname : "/search-innov/"+plateformes[0].id+firstView.url,
          search: "?" + new URLSearchParams({entry: findEntryIndex}).toString()
        })
      }

    }
  }, [authReducer.isAuthenticated]);

  return (
    <div className={`${styles["redirect"]}`}>
      <LoaderView noText/>
    </div>
  )
}

export default Redirect;
