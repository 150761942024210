import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import ReactTooltip from 'react-tooltip'
import { withLocalize, Translate } from 'react-localize-redux'
import { API_URL } from '../../../../actions/index.js'
import history from "../../../../history"
import { useGlobalContext } from '../../../../GlobalContext'
import { addSelectedKeywords } from '../../../../actions/filters'
import { filtersParams } from '../../../../helpers/filtersParams'
import { renderDate }  from '../../../../helpers/helpers'
import ImgItem from '../../../partials/ImgItem'
import IconInfo from '../../../../assets/images/icon-info.svg'
import LoadingDocument from '../LoadingDocument'
import format from 'format-number';
import styles from './block-filter.module.scss'

const fnFormat = (number) => format({integerSeparator: " "})(number)

const BlockFilter = ({
  id,
  toggleDocument,
  defaultFilters = null,
  requestReference = false,
  tooltip = [],
  noResult = [],
  documentsCount = 3,
  activeLanguage
}) => {
  const [context, dispatch]  = useGlobalContext();
  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;

  const [ title, setTitle ] = useState()
  const [ isLoading, setIsLoading ] = useState()
  const [ textToolTip, setTextToolTip ] = useState()
  const [ textNoResult, setTextNoResult ] = useState()
  const [ documents, setDocuments ] = useState()
  const [ countDocs, setCountDocs ] = useState()
  const [ defaultKeywords, setDefaultKeywords ] = useState()

  //ACTIONS

  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)

  useEffect(()=>{
    if(activeLanguage.code === "en"){
      if(tooltip) setTextToolTip(tooltip[0])
      if(noResult) setTextNoResult(noResult[0])
    }else if (activeLanguage.code === "fr") {
      if(tooltip) setTextToolTip(tooltip[1])
      if(noResult) setTextNoResult(noResult[1])
    }
  },[activeLanguage.code])

  useEffect(()=>{
    if(!filtersReducer.filters || isLoading) return;
    setTitle()
    getDocuments()
  },[filtersReducer.filters])

  function getDocuments(){
    let requestUrl;
    if(requestReference){
      requestUrl = API_URL + 'doc/reference'
    }else{
      requestUrl = API_URL + 'doc/filtered'
    }
    let { filters : params } = filtersParams(filtersReducer,viewReducer.lang)
    setIsLoading(true)
    let config = {
      headers: {
        Authorization : localStorage.getItem('token')
      }
    }

    params.number = documentsCount
    params.orderBy = "date_ressource_desc"

    let defaultBlockFilters = []
    let keywords = []
    let and = activeLanguage.code === "en" ? " and " : " et "
    let or = activeLanguage.code === "en" ? " or " : " ou "

    defaultFilters && defaultFilters.forEach((filter) => {
      if(filter.id && !filter.code) {
        let find = filtersReducer.filters.find((d=> d.id === filter.id))
        if(find) {
          
          let codes = find.valeurs.map(value=> value.code).join("%20OR%20")
          let label = find.valeurs.map(value=> value.libelle).join(" or ");
          keywords.push({ id : filter.id, code : codes, label, value : label})

          if(title){
            setTitle((state) => !state ? find.libelle : `${state}${and}${find.libelle}`)
          }else{
            setTitle(find.libelle)
          }
          defaultBlockFilters.push({id : filter.id, code : codes})
        }
      }else if (filter.code && new RegExp('%20OR%20').test(filter.code)) {
        let codes = filter.code.split('%20OR%20');

        let label;
        codes.forEach((code) => {
          let find = filtersReducer.matchCode.find((d=> d.code === code))
          label = !label ? find.label : `${label}${or}${find.label}`
        })

        keywords.push({
          id : filter.id,
          code : filter.code,
          label : label,
          value : label,
        })
        setTitle((state) => !state ? label : `${state}${and}${label}`)
        defaultBlockFilters.push({id : filter.id, code : filter.code})
      }else {
        let find = filtersReducer.matchCode.find((d=> d.code === filter.code))
        if(find){
          setTitle((state) => !state ? find.label : `${state}${and}${find.label}`)
          keywords.push(find)
        }
        defaultBlockFilters.push({id : filter.id, code : filter.code})
      }


    });

    params.filters = [...params.filters, ...defaultBlockFilters]
    setDefaultKeywords(keywords)

    axios.post(requestUrl, params, config)
      .then((response) => {
        setCountDocs(response.data.result.numFound)
        setDocuments(response.data.result.docs)
        setIsLoading(false)
      })
      .catch((error) => {
        setDocuments([])
        setIsLoading(false)
      });

  }

  function openDocument(id){
    toggleDocument(id,true,'home')
  }

  function getImage(image){
    if(Array.isArray(image)){
      return image[0]
    }else if (image){
      return image
    }else{
      return null
    }
  }

  function goTo(){

    _addSelectedKeywords(defaultKeywords)
    history.push({
      pathname : `/search-innov/${filtersReducer.plateformeID}/strategic-watch`,
      search : history.location.search
    });

  }

  function getClassNameLightMode(){
    return viewReducer.lightmode === "light" ? ` ${styles.light}` : ''
  }


  return (
    <div className={isLoading ? `${styles["block-filter"]} ${styles["loading"]}${getClassNameLightMode()}` : `${styles["block-filter"]}${getClassNameLightMode()}`}>
      <ReactTooltip className="react-tooltip white" delayShow={500} id={`filter-block-${id}`} place="top" effect='solid'>
        {textToolTip}
      </ReactTooltip>
      <div className={styles["block-title"]}>
        <h3>{title}</h3>
        <span>
          {countDocs && <p className={styles.count}>{fnFormat(countDocs)}</p>}
          <span data-tip  data-for={`filter-block-${id}`}><img src={IconInfo} alt="info" /></span>
        </span>
      </div>
      {documents ?
        <>
          { documents.length > 0 &&
            <ul className={styles["list-documents"]}>
              {documents.map(doc =>
                <li key={`document-${doc.ID_QES}`} onClick={() => openDocument(doc.ID_QES)}>
                  <ImgItem
                    className={styles["image-doc"]}
                    image={getImage(doc.IMAGE)}
                  />
                  <div>
                    <p className={styles["date"]}>{renderDate(doc.PUB_DATE,activeLanguage.code)}</p>
                    <label>{doc.TITLE}</label>
                  </div>
                </li>
              )}
            </ul>
          }
        </> :
        <ul className={styles["list-documents"]}>
          {Array(documentsCount).fill(null).map((d,i) =>
            <li key={`document-${i}`}>
               <LoadingDocument/>
            </li>
          )}
        </ul>
      }
      { (documents && documents.length === 0 ) &&
        <p className={styles["no-documents"]}>
          {textNoResult}
        </p>

      }

      {(documents) &&
        <div className={styles["link-entry"]}>
          <p onClick={()=> goTo()}><Translate id="home.see-all"/></p>
        </div>
      }

    </div>
  )

}

export default withLocalize (BlockFilter)
