import { API_URL, postDataWithoutDispatching } from './index'
import axios from 'axios';

import {
  GET_REFERENCES_DOCUMENT,
  GET_REFERENCES_NEWSPAPER,
  GET_FILTERED_REFERENCES,
  ERROR_REFERENCES
} from './types'

export async function getReferences(dispatch,filters, page, nameReducer){
  let url = 'doc/reference'
  let result;

  let action;
  if(nameReducer === "newspaper"){
    action = GET_REFERENCES_NEWSPAPER
  }else if(nameReducer === "documents"){
    action = GET_REFERENCES_DOCUMENT
  }

  if(action){
    await postDataWithoutDispatching(GET_FILTERED_REFERENCES, ERROR_REFERENCES, true, url , dispatch, filters).then((res)=>{
      result = res.result
      result.currentPage = page
    })

    if(action){
      dispatch({
        type : action,
        payload : result
      })
    }


    return result.docs
  }else{
    throw new Error("Can't determine which reducer should be use");
  }



}

export async function getOneReferences(filters){
  const requestUrl = API_URL + 'doc/reference';
  let headers = { headers: { Authorization: localStorage.getItem('token') } };

  filters.number = 1;
  filters.page = filters.page+1;

  return new Promise(function(resolve, reject) {
    axios.post(requestUrl, filters, headers)
    .then((response) => {
      resolve(response.data.result.docs[0])
    })
  });


}
