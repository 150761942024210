import React, { useState, useEffect, useRef,useMemo } from "react"
import { Translate, withLocalize } from "react-localize-redux";

import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import Export from '../../partials/Export'

import { customStylesRelationships } from '../../partials/SearchByKeywords/styleSelect'
import LoaderView from '../../partials/LoaderView'
import SearchByKeywords from '../../partials/SearchByKeywords'
import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'
import ExplorationNav2 from '../../partials/ExplorationNav2'

import IconSettings from '../../../assets/images/exploration/icon-settings.svg'
import IconExport from '../../../assets/images/exploration/icon-export.svg'

import { useGlobalContext } from '../../../GlobalContext'
import usePrevious from '../../../customHooks/usePrevious'
import useContentDimension from '../../../customHooks/useContentDimension'
import useFilterView from '../../../customHooks/useFilterView'
import { setFilterRelationships } from '../../../actions/filters'
import { getRelationships, removeRelationships } from '../../../actions/dataviz'
import { datavizParams } from '../../../helpers/filtersParams'
import { getFilters } from '../../../actions/filters'
import { fnExportImage, escapeRegExp } from '../../../helpers/helpers'

import usePlateforme from '../../../customHooks/usePlateforme'

import NetworkVizSvg from './NetworkVizSvg'
import InfoExport from "../../partials/InfoExport";

const LinksExploration = (props) =>{
  const isInitialMount = useRef(true);
  const contentDimension = useContentDimension();
  const [ context , dispatch ] = useGlobalContext();

  const filtersReducer = context.filtersReducer
  const datavizReducer = context.datavizReducer
  const viewReducer = context.viewReducer
  const prevLang = usePrevious(viewReducer.lang)
  const filtersJSON = usePlateforme()


  const [ valueCountNodes, setValueCountNodes ] = useState(100)
  const [ valueCountDocuments, setValueCountDocuments ] = useState(1000)

  const [ filteredDocuments , setFilteredDocuments ] = useState(null)
  const [ optionsFilters, setOptionsFilters ] = useState(null)
  const [ selectedOptions , setSelectedOptions] = useState(null)
  const [ isSettingsOpened , setIsSettingsOpened] = useState(false)
  const [ hideLinks , setHideLinks] = useState(false)
  const [ enableAge , setEnableAge] = useState(false)


  const [ hideButtonShowLinks, setHideButtonShowLinks] = useState(true)

 const defaultRubrique = props.entries
  useFilterView(defaultRubrique)

  // ACTIONS
  const _setFilterRelationships = (filter) => setFilterRelationships(dispatch, filter)
  const _getRelationships = (filter, origine) => getRelationships(dispatch, filter, origine)
  const _removeRelationships = () => removeRelationships(dispatch)
  const _getFilters = (obj,origine) => getFilters(dispatch,obj,origine)

  useEffect(()=>{
    if(!filtersReducer.filters || (prevLang !== viewReducer.lang)){
      setOptionsFilters(null)

      let filters = props.entries;
      if(filtersReducer.defaultFilters){
        filters = [ ...filters, ...filtersReducer.defaultFilters]
      }

      _getFilters({
        plateformeID : filtersReducer.plateformeID,
        filters,
        lang : viewReducer.lang
        },
        props.location.pathname
      )
    }

    return function (){
      _removeRelationships()
    }

  },[viewReducer.lang])

  // INIT FILTER RELATIONSHIPS NODES
  useEffect(()=>{
    if(!filtersJSON) return;
    if(!filtersReducer.filters || optionsFilters) return;

    let linksExplorationConfig = _.cloneDeep(filtersJSON["links-exploration"])

    linksExplorationConfig.forEach((d)=>{
      d.label = getLibelle(d.code);
      d.value = d.code
    })

    _setFilterRelationships([linksExplorationConfig[0]])
    setOptionsFilters(linksExplorationConfig)

  },[
    filtersJSON,
    filtersReducer.filters
  ])

  // get dataviz relationships data
  useEffect(()=>{
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {

      // Your useEffect code here to be run on update
      if(filtersReducer.filtersRelationships && filtersReducer.date.min.date && filtersReducer.date.max.date){
        let result = datavizParams(filtersReducer,viewReducer.lang)
        setFilteredDocuments(null)
        let entries = filtersReducer.filtersRelationships.map((d)=> d.value)
        result.entries = entries;

        let isRUBSelected = result.filters.find((d)=> d.id === "RUBRIQUE") ? true : false

        if(isRUBSelected){
          result.filters = [ ...result.filters]
        }else{
          let obj = {}
          defaultRubrique.forEach((d)=>{
            if(!obj[d.id]){
              obj[d.id] = { ...d }
            }else{
              obj[d.id].code += "%20OR%20"+d.code
            }
          })
          if(Object.values(obj)[0]) result.filters.push(Object.values(obj)[0])
        }

        result.countNodes = valueCountNodes
        result.countDocuments = valueCountDocuments
        _getRelationships(result,props.location.pathname)
      }
    }
  },[
    defaultRubrique,
    viewReducer.lang,
    filtersReducer.filtersRelationships,
    filtersReducer.search,
    filtersReducer.filteredByDate,
    filtersReducer.selectedKeywords,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date,
    valueCountNodes,
    valueCountDocuments
    ])

  useEffect(()=>{
    if(selectedOptions && datavizReducer.relationships && !filteredDocuments && !datavizReducer.isLoading){
      let data = datavizReducer.relationships

      let arrRe= selectedOptions.map((d)=> {
       return d.value
      })

      var regex = new RegExp(escapeRegExp(arrRe.join("|")));

      let nodes = data.nodes.filter((node)=>{
        let found = filtersReducer.matchCode.find((d)=> d.code.toLowerCase() === node.value.toLowerCase())
        if(found) node.name = found.label;

        // REMOVE ALL NODE WITH CATEGORY FILTER
        // WHO DIDN'T HAVE A LABEL CORRESPONDANCE IN MATCHCODE
        if(!found){
          if(!/FILTER/.test(node.category)){
            return regex.test(node.category)
          }
        }else{
          return regex.test(node.category)
        }
      })


      let nodesCode = nodes.map((d)=>d.value)

      let links = data.links.filter((d)=>{
        return _.includes(nodesCode,d.source) && _.includes(nodesCode,d.target)
      })

      nodes.forEach((node)=>{
        let color = selectedOptions.find((d)=> d.value === node.category).color
        node.color = color

        let found = links.find((d)=>{
          return d.source === node.value || d.target === node.value
        })
        if(!found){
          node.linked = false
        }else{
          node.linked = true
        }
      })


      setFilteredDocuments({nodes,links})
    }
  },[datavizReducer.relationships,filteredDocuments])

  useEffect(()=>{
    setSelectedOptions(filtersReducer.filtersRelationships)
  },[filtersReducer.filtersRelationships])

  function getLibelle(code){
    let found = filtersReducer.filters.find((d)=>{
      return d.id.toLowerCase() === code.toLowerCase()
    })

    if(found){
      return found.libelle
    }else{
      return "Aucune correspondance"
    }
  }

  function handleChange (elt) {
    if(elt.length>0){
      let codeSelection = [...elt].map((d)=>{
        return {value : d.value, label : d.label, color : d.color}
      })
      _setFilterRelationships(codeSelection)
    }
  }

  function toggleButtonShowLinks(bool){
    setHideButtonShowLinks(bool)
  }

  function exportImage(){
    let exportGroup = document.getElementById("export-group")
    let target = exportGroup.querySelector(".networkViz");

    let labels = target.querySelectorAll("#group-circles text");
    let circles = target.querySelectorAll("#group-circles circle");
    let lines = target.querySelectorAll("#group-lines line");

    let infoExport = exportGroup.querySelector(".info-export");
    if(infoExport) infoExport.style.display = "block"

    let filters = exportGroup.querySelector(".relationships-filters");
    let attrLeft = getComputedStyle(filters).left
    let attrTop = getComputedStyle(filters).top

    filters.style.left = "20px";
    filters.style.top = "23px";


    let iconsDelete = exportGroup.querySelectorAll(".css-51a8fw");

    iconsDelete.forEach((iconDelete)=>{
      iconDelete.style.display = "none"
    })

   

    labels.forEach((label)=>{
      let style = getComputedStyle(label);
      label.setAttribute("fill",style.fill)
    })

    circles.forEach((circle)=>{
      let style = getComputedStyle(circle);
      circle.setAttribute("stroke-width",style["stroke-width"])
      circle.setAttribute("stroke",style["stroke"])
      circle.setAttribute("stroke-opacity",style["stroke-opacity"])
    })

    if(!hideLinks){
      lines.forEach((line)=>{
        let style = getComputedStyle(line);
        // if(parseInt(line.style["stroke-width"].replace("px","")) < 1){
        //   line.style["stroke-width"] = "1px"
        // }
        line.style["stroke"] = style["stroke"]
      })
    }


    let options = {
      width : target.clientWidth,
    }

    fnExportImage(exportGroup,"links-exploration.png",options)

    if(!hideLinks){
      lines.forEach((line)=>{
        line.style["stroke"] = ""
      })
    }

    if(infoExport) infoExport.style.display = "none"
    filters.style.left = attrLeft;
    filters.style.top = attrTop;

    iconsDelete.forEach((iconDelete)=>{
      iconDelete.style.display = "inherit"
    })

  }

  function getClassNameInputCount(){
    let classNameWithSearchbar = props.showSearchbar ? " with-searchbar" : ""
    return isSettingsOpened ? `input-count open${classNameWithSearchbar}` : `input-count${classNameWithSearchbar}`
  }

  function getClassNameInputCount(){
    let classNameWithSearchbar = props.showSearchbar ? " with-searchbar" : ""
    return isSettingsOpened ? `input-count open${classNameWithSearchbar}` : `input-count${classNameWithSearchbar}`
  }

  function getClassNameRelationships(){
    let className = hideLinks ? "relationships hideLinks" : "relationships"
    if(props.filterIsOpen) className = className+" filters-opened"
    return className
  }

  return useMemo(()=>{
    return(
      <>
        <div className={getClassNameRelationships()}>
          {/*<Export exportImage={exportImage} isLoading={!filteredDocuments || datavizReducer.isLoading}/>*/}
          <span id="export-group">
            <InfoExport/>
            {optionsFilters ?
              <div className={ props.showSearchbar ? `relationships-filters with-searchbar` : `relationships-filters`}>
                <Select
                  className="search-field relationships"
                  styles={customStylesRelationships}
                  isMulti={true}
                  isClearable={false}
                  options={optionsFilters}
                  value={selectedOptions}
                  onChange={handleChange}
                  isSearchable={false}
                />
              </div>
               : null
            }

            { filteredDocuments ?
              <>
              {/*console.log('rendered network container')*/}
              <NetworkVizSvg
                gravityStrength={0.1}
                width={contentDimension.width}
                height={contentDimension.height}
                data={filteredDocuments}
                labelCentered={false}
                hideLinks={false}
                enableAge={enableAge}
                repulsion={-(contentDimension.height/2)}
                simulationEnd={() => toggleButtonShowLinks(false)}
                simulationStart={() =>  toggleButtonShowLinks(true)}
              />


              </> : null
            }
          </span>
          <div className={viewReducer.lightmode === "light" ? "container-search light" : "container-search"}>
            <div className="container-left">
            { (!props.hideHeaderButtons && !props.filterIsOpen) &&
                <ButtonFilters
                  toggleFilter={props.toggleFilter}
                />
              }
              
              <SearchByKeywords
                testId={"global"}
                className={"header"}
                iconPosition={"right"}
                isMulti={true}
                isClearable={true}
                placeholder={"Filter par mots clés"}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
              />
            </div>
            <div className="container-right">
              <ExplorationNav2 history={props.history} match={props.match} />
              { !props.hideHeaderButtons &&
                <ButtonDocs
                  documentsIsOpen={props.documentsIsOpen}
                  toggleDocument={props.toggleDocument}
                />
              }
            </div>
          </div>
          { (!filteredDocuments || datavizReducer.isLoading) &&
            <LoaderView/>
          }
          <div className={getClassNameInputCount()}>
            <div className="settings" onClick={() => setIsSettingsOpened(!isSettingsOpened)}>
              { isSettingsOpened ?
                <div className="close"></div> : <img src={IconSettings} alt="open settings" />
              }
            </div>
            { isSettingsOpened &&
              <>
                <div className="countNodes">
                  <label><Translate id="links-exploration.nb-nodes" /> </label>
                  <ul>
                    <li className={valueCountNodes === 10 ? "selected" : ""} onClick={() => setValueCountNodes(10)} >10</li>
                    <li className={valueCountNodes === 50 ? "selected" : ""} onClick={() => setValueCountNodes(50)} >50</li>
                    <li className={valueCountNodes === 100 ? "selected" : ""} onClick={() => setValueCountNodes(100)} >100</li>
                    <li className={valueCountNodes === 1000 ? "selected" : ""} onClick={() => setValueCountNodes(1000)} >1000</li>
                  </ul>
                  {/*<div className="help"><p data-tip data-for='helpNodes'>?</p></div>
                  <ReactTooltip className="react-tooltip white" delayShow={350} id='helpNodes' place="top" effect='solid'>
                    <span><Translate id="links-exploration.nb-nodes-info" /> </span>
                  </ReactTooltip>*/}
                </div>
                <div className="countDocuments">
                  <label><Translate id="links-exploration.nb-documents" /> : </label>
                  <ul>
                    <li className={valueCountDocuments === 100 ? "selected" : ""} onClick={() => setValueCountDocuments(100)} >100</li>
                    <li className={valueCountDocuments === 1000 ? "selected" : ""} onClick={() => setValueCountDocuments(1000)} >1000</li>
                    <li className={valueCountDocuments === 5000 ? "selected" : ""} onClick={() => setValueCountDocuments(5000)} >5000</li>
                  </ul>
                  {/*<div className="help"><p data-tip data-for='helpDocs'>?</p></div>
                  <ReactTooltip className="react-tooltip white" delayShow={350} id='helpDocs' place="top" effect='solid'>
                    <span><Translate id="links-exploration.nb-documents-info" /> </span>
                  </ReactTooltip>*/}
                </div>
                { !hideButtonShowLinks &&
                  <div className="btn-group">
                    <button onClick={() => setHideLinks(!hideLinks)} className="orange">{hideLinks ? <Translate id={"links-exploration.show-links"} /> : <Translate id={"links-exploration.hide-links"} /> }</button>
                    <button onClick={() => setEnableAge(!enableAge)} className="orange">{!enableAge ? <Translate id={"links-exploration.show-age"} /> : <Translate id={"links-exploration.hide-age"} /> }</button>
                  </div>
                }
                { (filteredDocuments && !datavizReducer.isLoading) &&
                  <div className="export-vizualisation hide-on-mobile">
                    <img src={IconExport}  onClick={exportImage}/>
                    <p onClick={exportImage}><Translate id="global.export"/></p>
                  </div>
                }
              </>
            }
          </div>

        </div>
      </>
    )
  },[
    props.activeLanguage.code,
    props.filterIsOpen,
    filteredDocuments,
    selectedOptions,
    datavizReducer.isLoading,
    enableAge,
    hideLinks,
    hideButtonShowLinks,
    valueCountDocuments,
    valueCountNodes,
    isSettingsOpened,
    contentDimension.height
  ])

}

export default withLocalize(LinksExploration)
