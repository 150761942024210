import React from 'react'
import PropTypes from 'prop-types';
import styles from './button-filters.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useGlobalContext } from '../../../GlobalContext'

const ButtonFilters = ({toggleFilter,background = false, glow = false}) => {
  const context = useGlobalContext()[0];
  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;

  function getCountFilters(){
    let countActiveFilters = 0
    if(filtersReducer.selectedKeywords) countActiveFilters = filtersReducer.selectedKeywords.length
    if(filtersReducer.filteredByDate) countActiveFilters++
    if(filtersReducer.search?.type) countActiveFilters += filtersReducer.search.type.length
    return (
      <div className={styles["count-filters"]}>
        <p>{countActiveFilters}</p>
      </div>
    )
  }

  function getClassName(){
    if(background){
      return `${styles["container-btn-filters"]} ${styles["with-background"]}`
    }else if(glow){
      return `${styles["container-btn-filters"]} ${styles["glow"]}`
    }else{
      return `${styles["container-btn-filters"]} ${styles["light-background"]} ${styles[viewReducer.lightmode]}`
    }
  }

  return (
    <div
      className={getClassName()}
      onClick={()=>toggleFilter()}
    >
       { !glow &&
        <div className={styles["btn-filters"]}>
          <FontAwesomeIcon icon={faFilter} />
          {getCountFilters()}
        </div>
      }
      <div className={styles["advanced-filter"]}>
        Advanced Search
      </div>

    </div>
  )
}


ButtonFilters.propTypes = {
  background : PropTypes.bool,
  toggleFilter : PropTypes.func
}

export default ButtonFilters
