import React, { useState, useEffect } from 'react'

import MaterialIcon from 'material-icons-react'
import IconBackward from '../../../../assets/images/arrow_back_icon.svg'
import IconForward from '../../../../assets/images/arrow_forward_icon.svg'

import styles from './slider-image.scss'

export default function SliderImage({ doc, offline = false }) {
  const [img, setImg] = useState(null);
  const [baseUrl, setBaseUrl] = useState(null);

  useEffect(() => {
    if (Array.isArray(doc.IMAGE)) {
      setImg(doc.IMAGE[0])
    } else if (typeof doc.IMAGE === 'string') {
      setImg(doc.IMAGE)
    } else {
      setImg(null)
    }

    if (offline) {
      setBaseUrl('https://performancematerials.intelligencemaker.com/performancematerials/servletpub/QESServlet_fwdImg?Base=22801201&Fichier=')
    } else {
      const plateforme = JSON.parse(localStorage.getItem('plateforme'));
      if (plateforme?.url) {
        const url = plateforme?.url.split('/').splice(0, 4).join('/');
        setBaseUrl(`${url}/servletpub/QESServlet_fwdImg?Base=22801201&Fichier=`);
      }
    }


  }, [])

  function nextImage() {
    let indexCurrentImage = doc.IMAGE.indexOf(img)
    if (doc.IMAGE[indexCurrentImage + 1]) {
      setImg(doc.IMAGE[indexCurrentImage + 1])
    } else {
      setImg(doc.IMAGE[0])
    }
  }

  function prevImage() {
    let indexCurrentImage = doc.IMAGE.indexOf(img)
    if (doc.IMAGE[indexCurrentImage - 1]) {
      setImg(doc.IMAGE[indexCurrentImage - 1])
    } else {
      setImg(doc.IMAGE[doc.IMAGE.length - 1])
    }
  }

  function selectImage(img) {
    let image = document.getElementById("image")
    let docView = document.querySelector(".document-view")
    let title = document.querySelector(".document-view h1")

    if (docView) {
      docView.scrollTo(0, image.offsetTop - title.offsetHeight)
    }

    setImg(img)
  }

  return (
    <>
      {(baseUrl && img) &&
        (
          <div className="slider-image">
            {(doc.IMAGE && Array.isArray(doc.IMAGE) && doc.IMAGE.length > 1) &&
              <>
                <div className="arrow" onClick={prevImage}>
                  <img src={IconBackward} alt="icon-backward" />
                </div>
                <div className="arrow" onClick={nextImage}>
                  <img src={IconForward} alt="icon-forward" />
                </div>
              </>
            }
            <img id="image" src={`${baseUrl}${img}`} alt="document" />
          </div>
        )
      }
      {(baseUrl && doc.IMAGE && Array.isArray(doc.IMAGE) && doc.IMAGE.length > 1)
        && <div className="morepics">
          {doc.IMAGE.map((d, i) => {
            return (
              <div key={"key " + i} style={{ backgroundImage: `url(${baseUrl}${d})` }} onClick={() => selectImage(d)}>
                {d === img ? <div className="overlay" ><MaterialIcon icon="remove_red_eye" size={20} color="#FFFFFF" /></div> : null}
              </div>
            )
          })}
        </div>
      }
    </>
  )
}
