import innovations from './innovations.json'
import businessmodels from './business-models.json'
import keytech from './key-tech.json'
import reports from './reports.json'
import events from './events.json'

const config = {
 "header" : [
   innovations,
   businessmodels,
   keytech,
   reports,
   events
 ]
}

export default config
