import React, { useState, useEffect, useRef } from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import queryString from 'query-string'
/// TRADUCTION
import globalTranslations from "../assets/translations/global.json";
import headerTranslations from "../assets/translations/header.json";
import documentsTranslations from "../assets/translations/documents.json";
import strategicWatchTranslations from "../assets/translations/explorations/strategic-watch.json";
import linksExplorationTranslations from "../assets/translations/explorations/links-exploration.json";
import timelineTranslations from "../assets/translations/explorations/timeline.json";
import filtersTranslations from "../assets/translations/filters.json";
import helpTranslations from "../assets/translations/help.json";
import assitModeTranslations from "../assets/translations/assist-mode.json";
import startups from "../assets/translations/start-ups.json";
import homeTranslations from "../assets/translations/home.json";
import aboutTranslations from "../assets/translations/about.json";

///
import { useGlobalContext } from '../GlobalContext'
import usePlateforme from '../customHooks/usePlateforme'
import usePrevious from '../customHooks/usePrevious'

import Header from './header/Header.js'
import ListEntries from './header/ListEntries'

import Filters from './filters/FiltersGroup.js'
import Documents from './documents/Documents.js'
import Exploration from './exploration/Exploration.js'

import { setDocumentCount } from "../actions/documents"

import { addSelectedKeywords, setPlateformeId, setSearch } from '../actions/filters'

import { useFormInput } from '../customHooks/useFormInput'

const ContainerApp = (props) => {
  const isInitialMount = useRef(true);

  const [ filterIsOpen, setFilterIsOpen ]  = useState(null);
  const [ documentsIsOpen, setDocumentsIsOpen ]  = useState(false);
  const [ idSelectedDoc, setIdSelectedDoc] = useState();
  const prevLocationPathname = usePrevious(props.history.location.pathname)

  //USE TO DETERMINE WHICH REDUCER SHOULD BE USE FOR REFERENCES NAVIGATION
  //(WHEN A REFERENCE VIEW IS OPEN IN 50/50)
  const [ originOpenDocs, setOriginOpenDocs] = useState();


  const [ context, dispatch ] = useGlobalContext();
  const [ styleButtonClose , setStyleButtonClose] = useState("");
  const [ entriesOpened , setEntriesOpened ] = useState(false)

  const documentsReducer = context.documentsReducer;
  const filtersReducer = context.filtersReducer;
  const newspaperReducer = context.newspaperReducer
  const analysisToolsReducer = context.analysisToolsReducer

  const [ selectedFilter, setSelectedFilter] = useState();

  const filtersJSON = usePlateforme()
  const [ inputActive, setInputActive ] = useState(false)
  const inputSearch = useFormInput('',props.translate("assist-mode.placeholder-search"))
  const prevIndexSelectedEntries = usePrevious(filtersReducer.indexSelectedEntries)


  // ACTIONS
  const _setDocumentCount = (count) => setDocumentCount(dispatch, count)
  const _setPlateformeId = (id) => setPlateformeId(dispatch,id)
  const _setSearch = (obj) => setSearch(dispatch,obj)
  const _addSelectedKeywords = (arr) => addSelectedKeywords(dispatch, arr)

  useEffect(()=>{
    // const plateformeID = parseInt(localStorage.getItem("plateformeID"));
    const plateformeID = props.match.params.id;
    if(plateformeID) _setPlateformeId(plateformeID)

    props.initialize({
      languages: [
        { name: "EN", code: "en" },
        { name: "FR", code: "fr" }
      ],
      translation: {
        ...globalTranslations,
        ...headerTranslations,
        ...documentsTranslations,
        ...strategicWatchTranslations,
        ...linksExplorationTranslations,
        ...timelineTranslations,
        ...filtersTranslations,
        ...helpTranslations,
        ...aboutTranslations,
        ...assitModeTranslations,
        ...homeTranslations,
        ...startups
      },
      options: { renderToStaticMarkup }
    });

    let lang = localStorage.getItem("lang")
    if(lang){
      props.setActiveLanguage(lang)
      dispatch({
        type : "setLang",
        payload : lang
      })
    }else{
      localStorage.setItem("lang",'en')
      props.setActiveLanguage('en')
    }


  },[])

  useEffect(()=>{
    if(props.match.params.page === "strategic-watch" &&
      props.history.location.search
    ){
      let search = props.history.location.search
      let parseSearch = queryString.parse(props.history.location.search)
      if(parseSearch && parseSearch.entry && parseSearch.id && parseSearch.doc){

        setFilterIsOpen(false)
        if(newspaperReducer.newspaper){
          let id_qes = `${parseSearch.id}/${parseSearch.doc}`
          toggleDocument(id_qes,true,"newspaper")
        }

      }
    }
  },[
    newspaperReducer.newspaper,
    props.match,
    props.history.location.pathname
  ])


  // REDIRECT IF CURRENT props.history.location.pathname
  // IS'NT ALLOW FOR THIS ENTRIES
  useEffect(()=>{
    if(
      !filtersJSON ||
      typeof filtersReducer.indexSelectedEntries !== "number" ||
      (typeof filtersReducer.indexSelectedEntries === "number" &&
      !filtersJSON["header"][filtersReducer.indexSelectedEntries].navbar)
    ) return;

    let allowViews = filtersJSON["header"][filtersReducer.indexSelectedEntries].navbar.map((d) => '\\'+d.url)
    allowViews.push("\\/about")
    let reg = new RegExp(allowViews.join('|'))
    // if(!reg.test(props.history.location.pathname)){
    //   props.history.push(`/search-innov/${props.match.params.id}${filtersJSON["header"][0].navbar[0].url}`)
    // }

  },[filtersJSON,filtersReducer.indexSelectedEntries])

  useEffect(()=>{
    if(props.history.location.pathname === '/search-innov' ||
      props.history.location.pathname === '/search-innov/'
    ){
      props.history.push('/')
    }

    let plateformeId = localStorage.getItem('plateformeID');
    if(props.match.params.id !== plateformeId){
      _setPlateformeId(props.match.params.id)
      localStorage.setItem('plateformeID',props.match.params.id);
    }


  },[])

  //ACTS LIKE A HISTORY CHANGE
  useEffect(()=>{
    if(isInitialMount.current) return;
    let pathname = props.history.location.pathname
    if(pathname === '/search-innov' ||
      pathname === '/search-innov/'
    ){
      props.history.push('/')
    }

    // let reg = new RegExp(`/search-innov/${props.match.params.id}/analysis-tools`)

    _setDocumentCount(null)

  },[props.history.location.pathname])

  useEffect(()=>{
    if(isInitialMount.current){
      isInitialMount.current = false;

      let filters = localStorage.getItem('filters');
      if(filters){
        _addSelectedKeywords(JSON.parse(filters))
      }

      let search = localStorage.getItem('search');
      if(search){
        _setSearch(JSON.parse(search))
      }

    }
  },[])

  //TRIGGER WHEN indexSelectedEntries changed
  // (use to handle state open document)
  useEffect(()=>{
    if((prevIndexSelectedEntries !== undefined && prevIndexSelectedEntries !== null) &&
       prevIndexSelectedEntries !== filtersReducer.indexSelectedEntries){
      setDocumentsIsOpen(false)
    }
  },[filtersReducer.indexSelectedEntries])

  //HANDLE CLOSE FILTER IF
  // HEADER ENTRIES, hideHeaderButtons : true
  useEffect(()=>{
    if(!filtersJSON?.header || filtersReducer.indexSelectedEntries === null) return;
    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]

    if(currentHeaderEntry){
      let hideHeaderButtons = !!currentHeaderEntry.hideHeaderButtons
      hideHeaderButtons && setFilterIsOpen(false)
      if(!hideHeaderButtons){
        if(currentHeaderEntry.filtersOpen === undefined){
          setFilterIsOpen(true)
        }else{
          setFilterIsOpen(currentHeaderEntry.filtersOpen)
        }
        // let bool = !!currentHeaderEntry.filtersOpen
        // console.log(currentHeaderEntry.filtersOpen)
        // setFilterIsOpen(bool)
      }
    }

  },[filtersJSON?.header,filtersReducer.indexSelectedEntries])


  // CLOSE FILTER ON ABOUT PAGE
  useEffect(()=>{
    if(props.history.location.pathname === `/search-innov/${props.match.params.id}/about`){
      setFilterIsOpen(false)
    }
  },[props.history.location.pathname])

  useEffect(()=>{

    if(filtersReducer.selectedKeywords && filtersReducer.selectedKeywords.length>0 && selectedFilter){
      props.history.replace(`/search-innov/${localStorage.getItem('plateformeID')}${selectedFilter}`)
    }

    //SAVE KEYWORD IN LOCALSTORAGE
    if(filtersReducer.selectedKeywords){
      localStorage.setItem('filters',JSON.stringify(filtersReducer.selectedKeywords));
    }

  },[filtersReducer.selectedKeywords])

  useEffect(()=>{
    //SAVE SEARCH IN LOCALSTORAGE
    if( filtersReducer.search){
      localStorage.setItem('search',JSON.stringify(filtersReducer.search));
    }

  },[filtersReducer.search])

  useEffect(()=>{
    if(inputSearch.DOMAttribute.value.length === 0) setInputActive(false)
    if(inputSearch.DOMAttribute.value.length > 0) setInputActive(true)
  },[inputSearch.DOMAttribute.value])

  useEffect(()=>{
    // IF WE ARE ON strategic-watch looks if there are a correct id in search
    // BEFORE exec setDocumentsIsOpen(false)
    // ELSE setDocumentsIsOpen(false)
    if(props.history.location.search && props.match.params.page === "strategic-watch"){
      let search = props.history.location.search
      let parseSearch = queryString.parse(props.history.location.search)
      if(!parseSearch || !parseSearch.id || !parseSearch.doc) setDocumentsIsOpen(false)
    }else{
      setDocumentsIsOpen(false)
    }

    /// IF AN EVENT WAS BIND FOR CLOSING OPEN PART ON CLICK ABOVE EXPLORATION
    /// EXIST UNBIND IT
    let target = document.querySelector(".exploration #container-exploration")
    if(target){
      target.removeEventListener("click",closeDocuments)
      target.removeEventListener("click",closeFilter)
    }

  },[props.location.pathname])

  function toggleFilter(bool){
    setDocumentsIsOpen(false)
    setEntriesOpened(false)
    setFilterIsOpen(bool === undefined ? !filterIsOpen : bool)


    let target = document.querySelector(".exploration #container-exploration")
    if(bool === undefined ? !filterIsOpen : bool){
      target.addEventListener("click",closeFilter)
    }else{
      target.removeEventListener("click",closeFilter)
    }

  }

  function toggleDocument(idDoc = null, bool, origin){

    if(origin === "documents" && idSelectedDoc !== null){
      setIdSelectedDoc(null)
    }else{
      setIdSelectedDoc(idDoc)
    }

    setFilterIsOpen(false)
    let isOpen = bool === undefined ? !documentsIsOpen : bool

    if(bool === undefined && (originOpenDocs !== null && originOpenDocs !== origin) ){
      isOpen = true;
    }

    setDocumentsIsOpen(isOpen)

    if(isOpen){
      setOriginOpenDocs(origin)
    }else{
      localStorage.removeItem('previousDoc')
      setOriginOpenDocs(null)
    }


    let target = document.querySelector(".exploration #container-exploration")
    if(target && (bool === undefined ? !documentsIsOpen : bool)){
      target.addEventListener("click",(e) => closeDocuments(e))
    }else if(target){
      target.removeEventListener("click",(e) => closeDocuments(e))
    }

  }

  function toggleEntries(){
    setFilterIsOpen(false)
    setEntriesOpened(!entriesOpened)
  }

  function closeDocuments(){
    toggleDocument(null, false)
  }

  function closeFilter(e){
    let target = document.elementFromPoint(e.clientX, e.clientY);
    let targetsContainer = document.querySelectorAll('#search-by-keywords')

    let shouldClose = true ;
    Array.from(targetsContainer).forEach((elt)=>{
      if(elt.contains(target)) shouldClose = false
    })

    if(shouldClose) toggleFilter(false)
  }

  function search(){
    // closePopup()
    _setSearch({
      string : inputSearch.DOMAttribute.value,
      type : []
    })
  }

  function selectFilter(link){
    setSelectedFilter(link)
  }

  function closeDocument () {
    if(props.match.params.page === "strategic-watch" &&
      props.history.location.search
    ){
      props.history.replace({
        search: null,
      })
    }

    toggleDocument(null, false)
  }

  function getReferences(){
    if(originOpenDocs === "newspaper"){
      return newspaperReducer.references
    }else if(originOpenDocs === "analysis-tools"){
      return analysisToolsReducer.references
    }else if(originOpenDocs === "documents"){
      return documentsReducer.references
    }else{
      return null
    }
  }

  return (
      <>
      { filtersReducer.plateformeID ?
        <>
          <Header
            location={props.location}
            match={props.match}
            entriesOpened={entriesOpened}
            toggleEntries={toggleEntries}
            toggleDocument={toggleDocument}
            toggleFilter={toggleFilter}
            documentsIsOpen={documentsIsOpen}
          />
          <ListEntries
            history={props.history}
            location={props.location}
            match={props.match}
            toggleEntries={toggleEntries}
            entriesOpened={entriesOpened}
          />
          { !isInitialMount.current && filtersReducer.selectedHeaderEntries ?
            <Exploration
              entries={filtersReducer.selectedHeaderEntries}
              documentsIsOpen={documentsIsOpen}
              filterIsOpen={filterIsOpen}
              match={props.match}
              pathname={props.location.pathname}
              toggleFilter={toggleFilter}
              toggleDocument={toggleDocument}
            /> : null
          }

          {filterIsOpen && <Filters closeFilter={() => toggleFilter(false)} pathname={props.location.pathname}/>}

          { documentsIsOpen &&
            <>
              <div className={"overlay-documents"} onClick={() => closeDocument()} ></div>
              <Documents
                match={props.match}
                history={props.history}
                originOpenDocs={originOpenDocs}
                references={getReferences()}
                toggleDocument={toggleDocument}
                idSelectedDoc={idSelectedDoc}
                closeDocuments={closeDocuments}
              />
            </>
          }
        </> : null
      }
      </>
  )

}

export default withLocalize(ContainerApp);
