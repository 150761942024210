import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { ResponsiveLine } from '@nivo/line'

export default function GraphLine(props){
  const [data , setData ] = useState()

  useEffect(()=>{
    // console.log(props.data);
    if(!props.nodesDates || !props.data) return
    let nodesDates = _.cloneDeep(props.nodesDates)
    let countDates = {}

    nodesDates.forEach((d) =>{
      let date = new Date(d)
      date.setDate(2)
      date.setHours(12)
      date.setMinutes(0)
      date.setSeconds(0)

      let timestampSec = Math.floor(date.getTime()/1000)

      if(countDates[timestampSec]){
        countDates[timestampSec].nbDocs++;
      }else{
        countDates[timestampSec] = {}
        countDates[timestampSec].nbDocs = 1
        countDates[timestampSec].date = date
      }
    })

    let data = _.cloneDeep(props.data)

    data.forEach((d)=>{
      let timestampSec = Math.floor(d.date.getTime()/1000)
      if(!countDates[timestampSec]){
        d.nbDocs = 0;
      }
    })

    data.forEach((d,i)=>{
      d.x = "doc-"+i
      d.y = d.nbDocs
    })

    if(data.length>0) setData([{id : "timeline-docs", color : "#FF7D67", data}])



  },[props.nodesDates])

  return(
    <div className="dataRange" style={ props.footerState === "minimize" ?
      {width: "100%", height:"0"} : {width: "100%", height:"100%"}}>
      { data ?
        <>
        <ResponsiveLine
            data={data}
            curve="monotoneX"
            margin={{
                "top": 10,
                "right": 20,
                "bottom": 0,
                "left": 20
            }}
            xScale={{
                "type": "point"
            }}
            yScale={{
                "type": "linear",
                "stacked": true,
                "min": "auto",
                "max": _.maxBy(props.data,'nbDocs').nbDocs
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}

            colors={ d => d.color }
            useMesh={true}
            enableDots={false}
            dotSize={3}
            dotColor="#FF7D67"
            dotBorderWidth={2}
            dotBorderColor={{
                "from": "color"
            }}
            dotLabel="y"
            dotLabelYOffset={-12}
            enableArea={true}
            animate={true}
            motionStiffness={10}
            motionDamping={5}
            isInteractive={false}
            enableStackTooltip={false}
            legends={[]}
        /></> : null
      }
    </div>
  )
}
