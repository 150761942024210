import React, { useState, useEffect } from 'react' 
import { withLocalize } from "react-localize-redux";
import styles from './popup-about.module.scss'
import image from '../../../assets/images/popup_img.svg'

const PopupAbout = ({title, text,activeLanguage}) => {
    const [ open, setOpen ] = useState(true);
    const [ removeFromDOM, setRemoveFromDOM ] = useState(false);

    useEffect(()=>{
        if(open) return;
        const timer = setTimeout(()=>{
            setRemoveFromDOM(true)
            clearTimeout(timer)
        },1000)

        return () => {
            if(timer) clearTimeout(timer)
        }
    },[open])

    function getIndexLang(){
        if(activeLanguage.code === "en") return 0
        else if(activeLanguage.code === "fr") return 1
    }

    return (
        <>
        { (!removeFromDOM && (title && title.length) && (text && text.length) ) &&
            <div className={open ? styles["popup-about"] : `${styles["popup-about"]} ${styles["close"]}`}>
                <span className={`close ${styles["icon-close"]}`} onClick={() => setOpen(false)}></span>
                <div className={styles["mask"]}>
                    <img src={image} alt="about"/>
                    <div className={styles["container"]}>
                        {title && <label>{title[getIndexLang()]}</label>}
                        {text && <span className={styles["container-text"]} dangerouslySetInnerHTML={{ __html: text[getIndexLang()]}}></span>}
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default withLocalize(PopupAbout);