import _ from 'lodash'
const initialState = { references : null };

let updatedReferences = null

export default function analysisToolsReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_REFERENCES":
      updatedReferences = _.cloneDeep(action.payload)
      if(updatedReferences && updatedReferences.length > 0){
        updatedReferences[updatedReferences.length-1].lastOne = true
      }
      return {...state, references : updatedReferences};
    default:
      return {...state};
  }
}
