import global from './global.json'
import espaces from './espaces'
import docTags from './doc-tags.json'
import advanced from './advanced.json'
import dashboard from './dashboard.json'
import linksExploration from './links-exploration.json'

const config = {
  ...global,
  ...espaces,
  ...docTags,
  ...advanced,
  ...dashboard,
  ...linksExploration
}

export default config