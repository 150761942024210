import React from 'react';

export default function IconRelationships(){
  return(
    <svg width="16px" height="17px" viewBox="0 0 16 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="words_cloud" transform="translate(-27.000000, -278.000000)" fill="#8A8BA2">
                <g id="Group" transform="translate(23.000000, 233.000000)">
                    <path d="M10.416,50.408 C10.416,49.5279956 10.6279979,48.7200037 11.052,47.984 C11.4760021,47.2479963 12.0519964,46.6640022 12.78,46.232 C13.5080036,45.7999978 14.3119956,45.584 15.192,45.584 C16.0720044,45.584 16.8799963,45.7999978 17.616,46.232 C18.3520037,46.6640022 18.9359978,47.2479963 19.368,47.984 C19.8000022,48.7200037 20.016,49.5279956 20.016,50.408 C20.016,51.2880044 19.8000022,52.0919964 19.368,52.82 C18.9359978,53.5480036 18.3520037,54.1239979 17.616,54.548 C16.8799963,54.9720021 16.0720044,55.184 15.192,55.184 C14.3119956,55.184 13.5080036,54.9720021 12.78,54.548 C12.0519964,54.1239979 11.4760021,53.5480036 11.052,52.82 C10.6279979,52.0919964 10.416,51.2880044 10.416,50.408 Z M12.792,59.6 C12.792,59.0399972 12.987998,58.564002 13.38,58.172 C13.772002,57.779998 14.2479972,57.584 14.808,57.584 C15.3680028,57.584 15.8359981,57.779998 16.212,58.172 C16.5880019,58.564002 16.776,59.0399972 16.776,59.6 C16.776,60.1600028 16.5880019,60.635998 16.212,61.028 C15.8359981,61.420002 15.3680028,61.616 14.808,61.616 C14.2479972,61.616 13.772002,61.420002 13.38,61.028 C12.987998,60.635998 12.792,60.1600028 12.792,59.6 Z M3.984,56 C3.984,55.407997 4.12799856,54.8680024 4.416,54.38 C4.70400144,53.8919976 5.09599752,53.5080014 5.592,53.228 C6.08800248,52.9479986 6.62799708,52.808 7.212,52.808 C7.79600292,52.808 8.33199756,52.9479986 8.82,53.228 C9.30800244,53.5080014 9.69599856,53.8919976 9.984,54.38 C10.2720014,54.8680024 10.416,55.407997 10.416,56 C10.416,56.592003 10.2760014,57.1279976 9.996,57.608 C9.7159986,58.0880024 9.33200244,58.4679986 8.844,58.748 C8.35599756,59.0280014 7.81600296,59.168 7.224,59.168 C6.63199704,59.168 6.08800248,59.0280014 5.592,58.748 C5.09599752,58.4679986 4.70400144,58.0840024 4.416,57.596 C4.12799856,57.1079976 3.984,56.5760029 3.984,56 Z" id="icon-relationships"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}
