import React, { useState , useEffect, useRef } from 'react'
import { withLocalize, Translate } from 'react-localize-redux'
import ReactTooltip from 'react-tooltip'
import { BiSearch } from 'react-icons/bi';
import { IoCloseSharp } from 'react-icons/io5';

import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Select, { components } from 'react-select';

import { useGlobalContext } from '../../../GlobalContext'
import usePlateforme from '../../../customHooks/usePlateforme'

import {
  customStylesGlobal,
  customStylesGlobal2,
  customStylesGlobalExpanded,
  customStylesGlobalExpanded2,
  customStylesGlobalHeader,
  customStylesGlobalExpandedHeader,
  groupStyles,
  groupBadgeStyles
  } from './styleSelect'


// COMPONENT ACTIONS
import {
  setFilterDate,
  setSearch,
  addSelectedKeywords,
  removeSelectedKeyword,
  removeSelectedKeywordsByCat,
  removeAllSelectedKeywords
 } from '../../../actions/filters'


const formatGroupLabel = (
  data,
  selectedKeywords,
  addSelectedKeywords,
  removeSelectedKeyword,
  filters) => {

  function fnIsSelected(){
    let isSelected = false
    if(selectedKeywords){
      isSelected = selectedKeywords.find((keyword)=> keyword.code === data.code) ? true : false
    }
    return isSelected
  }

  function handleClick(data){
    let isSelected = fnIsSelected()

    // NOMBRE DE CARACTERES A SUPPRIMER A LA FIN DU
    // LABEL
    let removeLastCharacters = -(data.frequence.length+3)

    let obj = {
      code : data.code,
      frequence : data.frequence,
      id : data.id,
      label : data.label.slice(0,removeLastCharacters),
      value : data.value
    }

    let cat = filters.find((filter)=> filter.id === obj.id)
    if(cat && cat.libelle) obj.label = obj.label+" ("+cat.libelle.toLowerCase()+")";

    if(!isSelected){
      addSelectedKeywords(obj)
    }else{
      removeSelectedKeyword(obj)
    }

  }

  return (
    <div onClick={() => handleClick(data)} className={fnIsSelected() ? "select-label active" : "select-label"} style={groupStyles}>
      <span>{data.label}</span>
      <span>Test</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )
};

// PROPS
// testId, exclude, isMulti, isClearable, placeholder, components
const SearchByKeywords = (props) => {
  const filtersJSON = usePlateforme()
  const [ style , setStyle ] = useState(()=>{
    if(props.id){
      return customStylesGlobalExpanded2
    }else{
      if(props.className === "header"){
        return customStylesGlobalExpandedHeader
      }else{
        return customStylesGlobalExpanded
      }
    }
  })

  const [keywordsSelected , setKeywordsSelected ] = useState('')
  const [placeholder , setPlaceholder ] = useState('')
  const isExpandable = useState(true)
  const [isExpand , setIsExpand ] = useState(true)
  const [ inputValue, setInputValue ] = useState('')

  const [ context, dispatch ]  = useGlobalContext();
  const [ keywords , setKeywords] = useState([])
  const [ withDateOptions, setWithDateOptions ] = useState()
  const [ optionsYear , setOptionsYear ] = useState()
  const [ matchYear , setMatchYear ] = useState()

  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;


  //ACTIONS
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)
  const _removeSelectedKeyword = (keyword) => removeSelectedKeyword(dispatch,keyword)
  const _removeSelectedKeywordsByCat = (id) => removeSelectedKeywordsByCat(dispatch,id)
  const _removeAllSelectedKeywords = () => removeAllSelectedKeywords(dispatch)
  const _setSearch = (obj) => setSearch(dispatch,obj)
  const _setFilterDate = (obj) => setFilterDate(dispatch,obj)

  //SHOULD WE USE DATE OPTIONS ?
  useEffect(()=>{
    if(!filtersJSON) return;
    if(props.id){
      setWithDateOptions(false)
    }else{

      let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]

      if(currentHeaderEntry){
        let re = new RegExp(/\/analysis-tools/)
        let withDate = !!currentHeaderEntry.navbar.find(d=> re.test(d.url) )
        setWithDateOptions(withDate)

        if(withDate){
          let matchDates = {}
          let allYears = []
          let now = new Date()
          let from = 1990
          let yearNow = now.getFullYear()

          for(let currentYear = from; currentYear <= yearNow; currentYear++ ){
            let first = new Date(Date.UTC(currentYear, 0, 1, 0, 0, 0))
            let last = new Date(Date.UTC(currentYear+1, 0, 0, 0, 0, 0))
            allYears.push({ label : `${currentYear}`, value : currentYear, year : true})
            matchDates[currentYear] = {
              start : first,
              end : last
            }
          }

          setOptionsYear(allYears)
          setMatchYear(matchDates)
        }

      }

    }
  },[
    filtersJSON,
    filtersReducer.indexSelectedEntries
  ])

  useEffect(()=>{
    /// INIT KEYWORDS
    if(withDateOptions === null) return;
    if(filtersReducer.filters !== null && typeof withDateOptions === "boolean"){
      let keywords = []
      let placeholder = ""

      if(props.id){


        let found = filtersReducer.filters.find( d => {
          return d.id === props.id
        })

        let subFilters;
        if(props.multiLevel){
          subFilters = filtersReducer.filters.find( d => {
            return d.id === props.id+'2'
          }).valeurs
        }

        if(found){
          placeholder = found.libelle
          setPlaceholder(placeholder)
          keywords = found.valeurs.map((d)=> {
            let subOptions;

            if(props.multiLevel){

              subOptions = []
              subFilters.forEach( v => {
                let codeParent = v.code.split("_")
                codeParent.pop()
                codeParent = codeParent.join('_')
                if(codeParent === d.code){
                  let obj = {
                     value : v.code,
                     label : v.libelle+" ("+v.frequence+")",
                     code : v.code,
                     id : props.id+"2",
                     frequence : v.frequence
                  }
                  subOptions.push(obj)
                }
              })

            }

            return ({
              value : d.libelle ,
              label : d.libelle+" ("+d.frequence+")",
              code : d.code,
              id : props.id,
              frequence : d.frequence,
              options : subOptions ? subOptions : null
            })

          });
        }

      }else{
        let filters = _.cloneDeep(filtersReducer.filters)
        filters = filters.filter(d => {
          return /FILTER_/.test(d.id)
        })

        let match = {}

        filters.forEach( d => {
          let idFirstLevel;
          if(d.id[d.id.length-1] === "2"){
            idFirstLevel = d.id.slice(0,d.id.length-1)
          }else{
            idFirstLevel = d.id
          }

          let firstLevelLabel;
          if(match[idFirstLevel]){
            firstLevelLabel = match[idFirstLevel].libelle
          }else{
            let find = filters.find(d => d.id === idFirstLevel)
            if(find){
              firstLevelLabel = find.libelle
              match[idFirstLevel] = find
            }
          }

          let values = d.valeurs
          values = values.filter( v => v.frequence > 0)
          d.valeurs = values

          if(values){
            let id = d.id
            values.forEach(k=>{
              let libelle;
              if(firstLevelLabel){
                libelle = k.libelle+"("+ firstLevelLabel+")"+" ("+k.frequence+")"
              }else{
                libelle = k.libelle+" ("+k.frequence+")"
              }

              keywords.push({
                value : k.libelle ,
                firstLevelLabel : firstLevelLabel,
                label : libelle,
                code : k.code,
                id : id,
                frequence : k.frequence
              })

            })
          }
        })

        keywords = _.flatten(keywords)

        // <Translate id="global.placeholder-searchbar"/>
        setPlaceholder(props.translate("header.placeholder-searchbar"))
      }

      keywords = _.sortBy(keywords,(d) => parseInt(d.frequence)).reverse();

      if(withDateOptions) keywords = [...keywords, ...optionsYear]

      // keywords = _.differenceBy(keywords, filtersReducer.selectedKeywords,'code')
      setKeywords(keywords)
    }
  },[filtersReducer.filters,props.id,withDateOptions])

  /// SET SELECTED KEYWORDS
  useEffect(()=>{
    if(typeof withDateOptions !== "boolean") return;

    let keywordSearch;
    let keywordsSelected = [];
    let keywordYear;


    if(filtersReducer.selectedKeywords !== null && props.id === undefined){

      if(props.exclude){
        keywordsSelected = keywordsSelected.filter((keyword)=>keyword.exclude)
      }

      keywordsSelected = filtersReducer.selectedKeywords

    }else if(filtersReducer.selectedKeywords !== null){

      keywordsSelected = filtersReducer.selectedKeywords.filter( d => {
        return d.id === props.id || d.id.slice(0,-1) === props.id
      });

    }

    if(
      filtersReducer.search && props.id === undefined &&
      filtersReducer.search?.string
    ){
      keywordSearch = {value : filtersReducer.search.string, label : filtersReducer.search.string, fulltext: true}
    }


    let allSelectedKeywords = []
    if(keywordsSelected) allSelectedKeywords = keywordsSelected
    if(keywordSearch) allSelectedKeywords = [ keywordSearch, ...allSelectedKeywords ]

    if(withDateOptions && filtersReducer.keywordDate ){
      let minYear = filtersReducer.keywordDate?.min?.date
      keywordYear = {
        value : minYear,
        label : `${minYear.getFullYear()}`,
        year : true
      }
    }
    if(keywordYear) allSelectedKeywords = [ keywordYear, ...allSelectedKeywords ]


    setKeywordsSelected(allSelectedKeywords)


  },[
    filtersReducer.keywordDate,
    withDateOptions,
    filtersReducer.selectedKeywords,
    props.id, filtersReducer.search
  ])

  function handleChange (selectedOption, elt) {

    /// REMOVE FULLTEXT SEARCH VALUE
    if(elt.action === "remove-value" && elt.removedValue.fulltext){
      let updatedKeywords = keywordsSelected.filter((keyword)=> !keyword.fulltext)
      setKeywordsSelected(updatedKeywords)
      _setSearch(null)
      _setFilterDate({
        min : { ...filtersReducer.dateRange[0], value : 0 },
        max : { ...filtersReducer.dateRange[filtersReducer.dateRange.length-1], value : filtersReducer.dateRange.length-1 }
      })
      localStorage.removeItem("search")

      return;
    }

    let obj = null


    if(elt.action === "clear" && props.id ){
      _removeSelectedKeywordsByCat(props.id)
    }else if(elt.action === "clear" && !props.id ){
      clearAll()
    }

    if(elt.action === "create-option"){
      _setSearch({
        string : selectedOption[selectedOption.length-1].value,
        type : filtersReducer.searchType
      })
    }else if(elt.action === "select-option" && elt.option.year){
      _setFilterDate({
        isKeywordDate : true,
        min : { date : matchYear[elt.option.value].start },
        max : { date : matchYear[elt.option.value].end }
      })

    }else if(elt.action === "select-option"){
      if(elt.option){
        obj = elt.option
      }else{
        obj = selectedOption
      }

      obj.label = obj.label.split(" ")
      obj.label.pop()
      obj.label = obj.label.join(" ")

      if(obj.firstLevelLabel){
        obj.label = obj.label.replace(`(${obj.firstLevelLabel})`,"")
      }

      // let cat = filtersReducer.filters.find((filter)=> filter.id === obj.id)
      // if(cat && cat.libelle) obj.label = obj.label+" ("+cat.libelle.toLowerCase()+")";

      if(props.exclude){
        obj.exclude = true;
      }

      _addSelectedKeywords(obj);

    } else if (elt.action === "remove-value" && elt.removedValue.year) {
      _setFilterDate({
        min : { ...filtersReducer.dateRange[0], value : 0 },
        max : { ...filtersReducer.dateRange[filtersReducer.dateRange.length-1], value : filtersReducer.dateRange.length-1 }
      })
    } else if (elt.action === "remove-value") {
      obj = elt.removedValue;
      _removeSelectedKeyword(obj);
    }

  }

  function handlePressEnter(e) {
    if(e.key !== "Enter") return;
    handleFullTextSearch();
  }

  function handleFullTextSearch() {
    if (!inputValue || inputValue === "") return;
    setInputValue("");
    document.activeElement.blur();
    _setSearch({
      string : inputValue,
      type : filtersReducer.searchTypes
    });
  }

  function clearAll(){
    if (props.exclude) {
      _removeSelectedKeyword(keywordsSelected);
    } else {
      let isConfirm = window.confirm("Supprimer tous les mots clés ?");

      if (isConfirm) {
        _setSearch(null);
        localStorage.removeItem("search");
        _removeAllSelectedKeywords();
      }
    }
  }

  function expand(){
    if(!isExpandable){return false}

    if(!props.id){
      if(!isExpand && props.className === "header"){
        setIsExpand(true)
        setStyle(customStylesGlobalExpandedHeader)
      }else if(!isExpand && props.className !== "header"){
        setIsExpand(true)
        setStyle(customStylesGlobalExpanded)
      }else if(isExpand && props.className === "header"){
        setIsExpand(false)
        setStyle(customStylesGlobalHeader)
      }else if(isExpand && props.className !== "header"){
        setIsExpand(false)
        setStyle(customStylesGlobal)
      }
    }

    if(props.id){
      if(!isExpand){
        setIsExpand(true)
        setStyle(customStylesGlobalExpanded2)
      }else{
        setIsExpand(false)
        setStyle(customStylesGlobal2)
      }
    }

  }

  function classExpandComponent(){
    let style = ''
    if (isExpand) style = 'expanded'
    return isExpandable ? "toggle-expand "+style : "toggle-expand disabled"
  }

  function renderClass(){
    let multiLevel = props.multiLevel ? "multi" : ""
    return !props.id ? "global-search "+multiLevel+" "+viewReducer.lightmode : multiLevel+" "+viewReducer.lightmode
  }

  function getSearchTypes(){
    if(filtersReducer.searchTypes.length === 0) return ""

    let arr = filtersReducer.searchTypes.map((d)=>{
      return d.toLowerCase()
    })

    if(props.activeLanguage.code === "en"){
      return `, in ${arr.join(" or ")}`
    }else if(props.activeLanguage.code === "fr"){
      return `, dans ${arr.join(" ou ")}`
    }

  }

  const filterOption = (option, inputValue) => {
    if (option.data.__isNew__ || inputValue.length < 3) return true;
    const { label } = option;
    const reg = new RegExp(`(^(${inputValue})|(\\s|-)+(${inputValue}))`,"i")
    return reg.test(label);
 };

  return (
    <>
    <div id="search-by-keywords" data-testid={props.testId ? props.testId : ""} className={renderClass()}
      onKeyPress={(e) => handlePressEnter(e)}
    >
      {!props.id && !props.isClearable ?
        <>
        <div className={props.iconPosition === "right" ? "icon right" : "icon"}>
            <FontAwesomeIcon data-tip data-for='clearFilter' onClick={clearAll} icon={faTrashAlt} color="#FF7D67" />
          </div>
          <ReactTooltip className="react-tooltip" delayShow={500} id='clearFilter' place="top" effect='solid'>
            <span><Translate id="filters.tooltip-searchbar"/></span>
          </ReactTooltip>
        </> : null
      }
      {!props.id ?
        <>
          <Select
            formatCreateLabel={(value) => `${props.translate("filters.label-fulltext-search")} `}
            filterOption={filterOption}
            isClearable={props.isClearable}
            styles={style}
            inputValue={inputValue}
            formatGroupLabel={
              (data) => formatGroupLabel(
                data,
                filtersReducer.selectedKeywords,
                _addSelectedKeywords,
                _removeSelectedKeyword,
                filtersReducer.filters)
            }
            onInputChange={(str) => {
              setInputValue(str)
            }}
            components={{
              ...props.components,
              NoOptionsMessage : () => null,
              ClearIndicator : ({clearValue}) => (
                <span style={{cursor: "pointer"}}><IoCloseSharp onClick={clearValue} color="#6E708C" size="22px"/></span>
              ),
              DropdownIndicator : ()=>(
                <span style={{cursor:"pointer"}}>
                  <BiSearch color="#6E708C" size="22px"/>
                </span>
              ),
              MenuList : ({children, ...props}) => {
                return (
                  <>
                    <components.MenuList {...props}>
                      {(props.selectProps.inputValue && props.selectProps.inputValue.length > 2) &&
                        <button className="search-field-button-fulltext"
                          onClick={() => handleFullTextSearch()}
                        >
                          <Translate id="filters.label-fulltext-search"/> {props.selectProps.inputValue} {getSearchTypes()}
                        </button>
                      }
                      {children}
                    </components.MenuList>
                  </>
                )
              }
            }}
            classNamePrefix={props.classNamePrefix}
            className={props.className ? "search-field "+props.className : "search-field"}
            isMulti={props.isMulti}
            value={keywordsSelected}
            onChange={handleChange}
            options={keywords}
            placeholder={placeholder}
            onMenuOpen={props.onMenuOpen ? props.onMenuOpen : null}
          />
        </> :
        <Select
          isClearable={props.isClearable}
          styles={style}
          filterOption={filterOption}
          formatGroupLabel={
            (data) => formatGroupLabel(
              data,
              filtersReducer.selectedKeywords,
              _addSelectedKeywords,
              _removeSelectedKeyword,
              filtersReducer.filters)
          }
          components={props.components}
          classNamePrefix={props.classNamePrefix}
          className={props.className ? "search-field "+props.className : "search-field"}
          isMulti={props.isMulti}
          value={keywordsSelected}
          onChange={handleChange}
          options={keywords}
          placeholder={placeholder}
          onMenuOpen={props.onMenuOpen ? props.onMenuOpen : null}
        />
      }
      { !props.id ?
        <div data-tip data-for='expandFilter' onClick={expand} className={classExpandComponent()}></div> : null
      }

      {isExpandable ?
        <ReactTooltip className="react-tooltip white" delayShow={500} id='expandFilter' place="bottom" effect='solid'>
          <span><Translate id="global.show-all-filter" /></span>
        </ReactTooltip>
        : null
      }

    </div>
    </>
  )

}

export default withLocalize(SearchByKeywords)
