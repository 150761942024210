import React, { useState, useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'
import * as d3 from 'd3'

// import { ResponsiveChoropleth } from '@nivo/geo'
import world from './world'

import { useGlobalContext } from '../../../GlobalContext'
import useContentDimension from '../../../customHooks/useContentDimension'


import {
  addSelectedKeywords,
  removeSelectedKeyword
} from '../../../actions/filters'


const GeoViz = (props) => {
  const [context, dispatch]  = useGlobalContext();
  const contentDimension = useContentDimension();
  const isInitialMount = useRef(true);
  const [ data, setData ] = useState([])
  const [ topo, setTopo ] = useState()
  const filtersReducer = context.filtersReducer

  //ACTIONS
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)
  const _removeSelectedKeyword = (keyword) => removeSelectedKeyword(dispatch,keyword)

  useEffect(()=>{
    let topo = world()
    setTopo(topo)
  },[])

  useEffect(()=>{
    d3.select('.geomap').select("svg")
      .attr("height", props.height)
  },[props.height])

  /// INIT GEO VIZ
  useEffect(()=>{
    if(props.data.length === 0){
      setData([])
      return
    }
    var data = props.data.map((d)=>{
      return { abbr : d.abbreviation, count : d.count, country : d.country, code : d.value }
    })

    let maxCount = _.maxBy(data,(d)=> d.count).count
    let minCount = _.minBy(data,(d)=> d.count).count

    var linearScale = d3.scaleLinear()
                           .domain([minCount,maxCount])
                           .range([0,2500000]);

    data.forEach((d)=>{
      d.value = linearScale(d.count)
    })

    setData(data)
  },[props.data])

  useEffect(()=>{
    if (data && isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      d3.select('#map').select('svg').remove();
      d3.select('#map')
        .append('svg')
        .attr('width', props.width)
        .attr('height', props.height)
        .append("g")

    }
  },[data,props.width])

  useEffect(()=>{

    if(!topo) return ;

    let scaleColor = fnScaleColor(data);

    const path = d3.geoPath();

    let translateX = contentDimension.width < 1165 ? props.width/2 : (props.width/2)


    const projection = d3.geoMercator()
      .center([2.454071, 46.279229])
      .scale(props.projectionScale)
      .translate([translateX, props.height / 2]);

    path.projection(projection);


    d3.select('#map').select('svg').call(d3.zoom()
       .scaleExtent([0.7, 3])
       .on("zoom", zoomed));

    let country = d3.select('#map').select("svg").select("g")
    country.selectAll("path").remove()
    country = country.selectAll("path")
        .data(topo.features)


    country.enter()
        .append("path")
        .attr("d", path)
        .attr("id", function (d) {
          return d.id
        })
        .attr("fill", function (d) {
         let found = data.find((country)=> country.abbr === d.id)
         if(found) {
           return scaleColor(found.count);
         }
        })
        .attr("class", function (d) {
          let found = data.find((country)=> country.abbr === d.id)
          if(!found) {
            return "empty"
          }
        }).on("mouseover", function(d) {
          let tooltip = d3.select("#tooltip-"+d.id)
          tooltip.style("display", "block");
        })
        .on("mousemove", function(d) {
          let tooltip = d3.select("#tooltip-"+d.id)
          let boundGeomap = document.querySelector(".geomap").getBoundingClientRect()
          tooltip.style("left", (d3.event.pageX)-(boundGeomap.left) + "px")
          .style("top", (d3.event.pageY)-(boundGeomap.top) + "px");
        })
        .on("mouseout", function(d) {
          let tooltip = d3.select("#tooltip-"+d.id)
          tooltip.style("display", "none");
        }).on("click", d => {
          let found = data.find((country)=> country.abbr === d.id)
          if(!found) return;
          let country = filtersReducer.matchCode.find((k)=> k.code === found.code)
          if(!country) return;

          let isSelected;
          if(filtersReducer.selectedKeywords && filtersReducer.selectedKeywords.length > 0){
            isSelected = filtersReducer.selectedKeywords.find((k)=> k.code === country.code)
          }

          let keyword = {
            id : country.id,
            abbr : country.countryCode,
            code : country.code,
            label : country.label,
            value : country.label
          }

          if(isSelected){
            _removeSelectedKeyword({value : keyword.label})
          }else{
            _addSelectedKeywords(keyword)
          }

        })


    country.exit()
      .remove();

    let tooltips = d3.select("body").select('#map')

    tooltips.selectAll(".container-tooltip").remove()

    tooltips.append("div")
      .attr('class', 'container-tooltip')
      .selectAll('.tooltip')
      .data(data)
      .enter()
      .append('div')
      .attr('id', (d)=>{
       return 'tooltip-'+d.abbr
      })
      .html((d)=>{
       return "<p id="+d.code+"//"+d.country+">"+d.country+" "+d.count+"</p>"
      })
      .attr('class', 'tooltip');
  },[topo,data,props.width])

  function zoomed(projection,path) {

    let groupContainer = d3.select('#map').select("svg").select("g")
    groupContainer.attr("transform", "translate(" + d3.event.transform.x+" "+d3.event.transform.y+ ")scale(" + d3.event.transform.k + ")");

    // projection.translate(d3.event.translate).scale(d3.event.scale);
    // d3.select('#map').select('svg').select("g").selectAll("path").attr("d", path);
  }

  function fnScaleColor(data){

    let minCount = 0
    let maxCount = 0

    if(data && data.length > 0){
      minCount = _.minBy(data,(d)=> d.count).count
      maxCount = _.maxBy(data,(d)=> d.count).count
    }

    // let colors = [
    //    "#998e77",
    //    "#ffec6a",
    //    "#fc842b",
    //    "#f8505c",
    //    "#fa85a7",
    //    "#7f4798",
    //    "#9affea",
    //    "#26c784",
    //    "#266f84",
    //  ];

     let colors = [
        "#bdf4f0",
        "#91ede5",
        "#65e6db",
        "#39dfd1",
        "#20b2b7",
        "#1c9db0",
        "#0f5871"
      ];

     let domain = []
     _.each(colors, function(c,i){
       if(i === 0){
         domain.push(minCount)
       }else if(i === colors.length - 1){
         domain.push(maxCount)
       }else{
         domain.push((maxCount-minCount)/(colors.length-i))
       }
     })

    let scaleColor = d3.scaleLinear()
      .domain(domain)
      .range(colors);

    return scaleColor
  }

  return useMemo(()=>{
    return(
      <div className={"geomap"} style={{height: props.height, width : props.width}}>
      {topo ?
        <>
          <div id="map"></div>
        </> : null
      }
      </div>
    )
  },[data,props.height,props.width])


}

export default GeoViz
