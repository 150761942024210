import { useEffect } from 'react'
import { useGlobalContext } from '../GlobalContext'
import { datavizParams } from '../helpers/filtersParams'
import { getNodes } from '../actions/dataviz'

export default function useGetNodes(defaultRubrique){
  const [ context , dispatch ] = useGlobalContext()
  const filtersReducer = context.filtersReducer
  const viewReducer = context.viewReducer

  // ACTIONS
  const _getNodes = (filter) => getNodes(dispatch, filter)

  //WARNING: ACTION GET_NODES IS ALSO
  // USE IN FILTERS REDUCERS & DOCUMENTS REDUCER

  useEffect(()=>{

    if(filtersReducer.date.min.date &&
       filtersReducer.date.max.date){

       let result = datavizParams(filtersReducer,viewReducer.lang)
       if(defaultRubrique) {
         let isRUBSelected = result.filters.find((d)=> d.id === "RUBRIQUE") ? true : false
         if(isRUBSelected){
           result.filters = [ ...result.filters]
         }else{
           result.filters = [ ...result.filters,...defaultRubrique]
         }
       }

       _getNodes(result)

    }

  },[
    viewReducer.lang,
    filtersReducer.selectedKeywords,
    filtersReducer.date.min.date, filtersReducer.date.max.date])

}
