import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { NavLink } from "react-router-dom"
import MaterialIcon from 'material-icons-react'
import { withLocalize, Translate  } from 'react-localize-redux'
import axios from 'axios';
import { API_URL } from '../../../actions/index.js'
import usePlateforme from '../../../customHooks/usePlateforme'
import { useGlobalContext } from '../../../GlobalContext'

const KeyTechToolsTools = (props) => {
  const [context, dispatch] = useGlobalContext()
  const filtersJSON = usePlateforme()
  const filtersReducer = context.filtersReducer
  const viewReducer = context.viewReducer

  const [ nav, setNav ] = useState();
  const [ indexLang, setIndexLang ] = useState()
  const [ filters, setFilters ] = useState()
  const [ referencesMap, setReferencesMap ] = useState()

  useEffect(()=>{
    const requestUrl = API_URL + props.endpoint;
    let selectedKeywords = [];
    let selectedExcludeKeywords = [];
    filtersReducer.selectedKeywords.forEach((d)=>{
      if(d.exclude){
        selectedExcludeKeywords.push({ code : d.code, id : d.id})
      }else{
        selectedKeywords.push({ code : d.code, id : d.id})
      }
    })

    let config = {
      headers: {
        Authorization : localStorage.getItem('token')
      }
    }

    let obj = {
      plateformeID : filtersReducer.plateformeID,
      filters: selectedKeywords,
      excludes: selectedExcludeKeywords,
      lang : viewReducer.lang
    }

    axios.post(requestUrl, obj, config)
      .then(async (response) => {
        let map = new Map()
        let references = await getReferences(response.data.result)
        references.forEach((d)=>{
          map.set(d.code2,d)
        })

        setReferencesMap(map)
        setFilters(response.data.result)
      })
      .catch((error) => {
        console.log(error);
      });


  },[
    props.endpoint,
    filtersReducer.selectedKeywords,
  ])

  // RESET ANALYSIS TOOLS REF ON UNMOUNT
  useEffect(()=>{
    return () => {
      dispatch({
        type : "SET_REFERENCES",
        payload : null
      })
    }
  },[])


  // SET INDEX LANG
  useEffect(()=>{
    let indexLang = props.languages.map((d)=>d.active).indexOf(true);
    setIndexLang(indexLang)
  },[props.activeLanguage])

  // INIT NAV
  useEffect(()=>{
    if(!filtersJSON || !props.allFilters) return;
    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
    if(!currentHeaderEntry["tools"]) return;

    let tools = currentHeaderEntry["tools"]
    let nav = []
    let allTools = []

    tools.forEach(tool => allTools.push(...tool.items))
    allTools.forEach((tool)=>{
      let found = props.allFilters.find((d)=> d.id === tool.code)
      if(found) tool.label = found.libelle
    })

    setNav(allTools)
  },[
    props.endpoint,
    filtersJSON,
    props.allFilters
  ])

  // INIT MANSORY
  useEffect(()=>{
    resizeAllMasonryItems()

    setTimeout(()=>{
      resizeAllMasonryItems()
    },2000)

  },[filters])

  useEffect(()=>{
    setTimeout(()=>{
      resizeAllMasonryItems()
    },500)
  },[
    props.endpoint,
    props.filterIsOpen
  ])

  // ADD EVENT FOR HANDLE RESIZE SCREEN
  useEffect(()=>{
    /* Resize all the grid items on the load and resize events */
    var masonryEvents = ['load', 'resize'];

    const resizeMansory = () => resizeAllMasonryItems()

    masonryEvents.forEach( function(event) {
      window.addEventListener(event, resizeMansory );
    } )

    return () => {
      masonryEvents.forEach( function(event) {
        window.removeEventListener(event, resizeMansory);
      } )
    }
  },[
    props.endpoint,
    props.history.location
  ])

  function resizeAllMasonryItems(){
    var allItems = document.querySelectorAll('.table-tools .tools-list');
    for(var i=0;i<allItems.length;i++){
      resizeMasonryItem(allItems[i]);
    }
  }

  function resizeMasonryItem(item){
    /* Get the grid object, its row-gap, and the size of its implicit rows */
    var grid = document.querySelector('.table-tools'),
        rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
        rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));

    var rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = 'span '+rowSpan;
  }

  function getReferences(paramsFilters){

    const requestUrl = API_URL + 'doc/reference';

    let config = {
      headers: {
        Authorization : localStorage.getItem('token')
      }
    }

    let arrValues = []
    Object.values(paramsFilters).map((d) => {
      let values = d.values
      values.forEach((k) => k.codeParent = d.label)
      arrValues.push(...values)
    });

    let arrCode = arrValues.map(d => d.code)

    let obj = {
      plateformeID : filtersReducer.plateformeID,
      filters : [
        {code: arrCode.join("%20OR%20"), id: props.match.params.tools}
      ],
      lang : viewReducer.lang,
      page : 1,
      number : 1000
    }

    if(arrCode.length === 0) return;

    return new Promise((resolve,reject)=>{
      axios.post(requestUrl, obj, config)
      .then((response) => {

        let referenceWithFrequence = []

        response.data.result.docs.forEach((d)=>{
          if(d[props.match.params.tools]){
            let targetCode = d[props.match.params.tools][0]
            let find = arrValues.find((u)=> u.code === targetCode);
            referenceWithFrequence.push({ ...d, frequence : find ? parseInt(find.frequence) : 0, title : d.TITLE[0], code : find.codeParent, code2 : targetCode})
          }
        })

        referenceWithFrequence = referenceWithFrequence.filter(d => d.frequence > 0)
        referenceWithFrequence = _.orderBy(referenceWithFrequence,["code", (d) => parseInt(d.frequence) , "title"], ["asc","desc","desc"])

        if(referenceWithFrequence.length > 0){
          referenceWithFrequence[referenceWithFrequence.length-1].lastOne = true
        }

        dispatch({
          type : "SET_REFERENCES",
          payload : referenceWithFrequence
        })

        resolve(referenceWithFrequence)

      })
      .catch((error) => {
        reject(error)
      });
    })

  }

  function openDocument(value,filter){

    const requestUrl = API_URL + 'doc/reference';

    let config = {
      headers: {
        Authorization : localStorage.getItem('token')
      }
    }

    let arrCode = Object.keys(filters).map((key)=> key)

    let obj = {
      plateformeID : filtersReducer.plateformeID,
      filters : [
        { code : value.code, id : props.match.params.tools}
      ],
      lang : viewReducer.lang,
      page : 1,
      number : 1
    }

    axios.post(requestUrl, obj, config)
    .then((response) => {
      props.toggleDocument(response.data.result.docs[0].ID_QES,true,'analysis-tools')
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function getImage(filter){
    if(filter.image){
      let url = `${process.env.PUBLIC_URL}/images/tools/${filter.code}.png`
      return <img src={url} alt={filter.code}/>
    }else{
      return null
    }
  }

  function sortValues(values){
    return _.orderBy(values,[ (d) => parseInt(d.frequence) , "title"], ["desc","desc"])
  }

  return (
    <div className="page-tools">
      <h3 className="only-on-tablet">
        <NavLink to={`/search-innov/${props.match.params.id}/analysis-tools`}>
          <MaterialIcon key="icon-backward" icon="arrow_back" size={24} />
          <Translate id="global.view.analysis-tools" />
        </NavLink>
      </h3>
      { nav &&
        <ul className="navigation">
          {nav.map((d,i)=>(
            <li key={`item-nav-${i}`}>
              <NavLink to={`/search-innov/${props.match.params.id}/analysis-tools/${d.code}`}>
                {d.label}
              </NavLink>
            </li>
          ))}
        </ul>
      }
      { filters &&
        <div className="table-tools mansory">
          {Object.values(filters).map((filter,k)=>(
            <div key={`tools-list-${k}`} className="tools-list">
              <div className="content">
                <label>{filter.label}</label>
                {getImage(filter)}
                <ul>
                  {sortValues(filter.values).map((d,i)=>(
                    <li key={`value-${i}`} className={(referencesMap && referencesMap.get(d.code)) ? "" : "disabled"}>
                      <div onClick={(referencesMap && referencesMap.get(d.code)) ? () => openDocument(d,filter) : () => {}}>
                        <p>{d.libelle}</p>
                        {!isNaN(d.frequence) &&
                         <span>{parseInt(d.frequence) > 0 ? parseInt(d.frequence) - 1 : parseInt(d.frequence)}</span>
                         }
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      }

      { nav && (
        <div className="navigation-dots only-on-tablet">
          {nav.map((d,i)=>(
            <div key={"dot-"+i}>
              <NavLink to={`/search-innov/${props.match.params.id}/analysis-tools/${d.code}`}>
                <div
                  className={props.match.params.tools === d.code ? "dot active" : "dot"}
                >
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      )}

    </div>
  )
}

export default withLocalize(KeyTechToolsTools);
