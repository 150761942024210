import React, { useState , useEffect, useLayoutEffect, useMemo } from "react"
import Pie from '../../partials/Pie'
import DashboardSection from './DashboardSection'
import DashboardNavigation from './DashboardNavigation'
import { withLocalize } from "react-localize-redux";

import history from '../../../history'
import useContentDimension from '../../../customHooks/useContentDimension'
import { useGlobalContext } from '../../../GlobalContext'
import { getNodes } from '../../../actions/dataviz'
import { datavizParams } from '../../../helpers/filtersParams'

import SearchByKeywords from '../../partials/SearchByKeywords'
import LoaderView from '../../partials/LoaderView'
import ExplorationNav2 from '../../partials/ExplorationNav2'

import useFilterView from '../../../customHooks/useFilterView'
import usePlateforme from '../../../customHooks/usePlateforme'

import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'

const Dashboard = (props) => {
  const contentDimension = useContentDimension()
  const [ context , dispatch ] = useGlobalContext();
  const datavizReducer = context.datavizReducer
  const filtersReducer = context.filtersReducer
  const viewReducer = context.viewReducer

  const [ heightNav, setHeightNav ] = useState()
  const [ caterogiesPie, setCaterogiesPie ] = useState()
  const [ selectedSection, setSelectedSection ] = useState()
  const [ isMobile, setIsMobile ] = useState()
  const [ sectionsCount, setSectionsCount ] = useState()
  const [ targetedSection, setTargetedSection ] = useState()
  const filtersJson = usePlateforme()

  const [ timestamp , setTimestamp] = useState(()=>{
    let d = new Date()
    return d.getTime()
  })

  const defaultRubrique = props.entries
  useFilterView(defaultRubrique)

  const _getNodes = (filter) => getNodes(dispatch, filter)

  // DETERMINE HEIGHT NAV
  // FOR MARGIN TOP OF FIRST PIES PART
  useLayoutEffect(()=>{
    if(!caterogiesPie) return;
    let nav = document.querySelector('.nav-dashboard')
    if(nav){
      setHeightNav(nav.offsetHeight)
    }else{
      setHeightNav(0)
    }

    // targetedSection is in array of params
    // because when there are an targetedSection
    // the nav isn't shown so we have to but sure
    // heightNav is equal to 0

  },[
    contentDimension.width,
    caterogiesPie,
    targetedSection
  ])

  // DETERMINE IS THERE ARE A ANCHOR OR TYPE NUMBER
  useEffect(()=>{
    if(history.location.hash){
      let hash = history.location.hash.replace("#", "")
      if(!isNaN(hash)){
        setTargetedSection(hash)
      }else{
        setTargetedSection(null)
      }
    }else{
      setTargetedSection(null)
    }
  },[history.location])

  useEffect(()=>{

    if(contentDimension.width <= 720 && !isMobile){
      setIsMobile(true)
    }else if(contentDimension.width > 720 && (isMobile || isMobile === undefined)){
      setIsMobile(false)
    }

  },[contentDimension.width])

  //GET NODES
  useEffect(()=>{

    if(filtersReducer.date.min.date &&
       filtersReducer.date.max.date){

      let result = datavizParams(filtersReducer,viewReducer.lang)

      let isRUBSelected = result.filters.find((d)=> d.id === "RUBRIQUE") ? true : false

      if(isRUBSelected){
        result.filters = [ ...result.filters]
      }else{
        let obj = {}
        defaultRubrique.forEach((d)=>{
          if(!obj[d.id]){
            obj[d.id] = { ...d }
          }else{
            obj[d.id].code += "%20OR%20"+d.code
          }
        })
        if(Object.values(obj)[0]) result.filters.push(Object.values(obj)[0])
      }

      _getNodes(result)

    }

  },[
    defaultRubrique,
    viewReducer.lang,
    filtersReducer.selectedKeywords,
    filtersReducer.search,
    filtersReducer.filteredByDate,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date])

  // SET CATEGORIES (section)
  useEffect(()=>{
    if(!filtersJson) return;
    if (datavizReducer.nodes && filtersReducer.matchCode){

      // let dataCategoriesPies = filtersJson.plateformes["dashboard-"+filtersReducer.plateformeID]
      let dataCategoriesPies = filtersJson["dashboard"]

      let allPiesCategory = []
      let mapPies = new Map()

      // filtersJson.plateformes["dashboard-"+filtersReducer.plateformeID]
      filtersJson["dashboard"].forEach((d)=>{
        allPiesCategory = [ ...allPiesCategory, ...d.categories]
      })

      allPiesCategory.forEach((d)=>{
        mapPies.set(d,[])
      })

      let nodes = datavizReducer.nodes.map(d => d )

      nodes.forEach((node)=>{
        if(mapPies.has(node.category)){
          let found = filtersReducer.matchCode.find((d)=> d.code.toLowerCase() === node.value.toLowerCase())
          if(found){
            found.name = found.label
            found.count = node.count
            found.code = node.value
            found.tendance = node.tendance
            mapPies.get(node.category).push(found)
          }
        }
      });

      dataCategoriesPies.forEach((d)=>{
        d.pies = []
        d.titlesCategory = []
        d.categories.forEach((category)=>{
          let found = filtersReducer.filters.find((d)=>{
            return d.valeurs && d.id === category
          })
          if(mapPies.get(category).length > 0){
            d.titlesCategory.push(found.libelle)
            d.pies.push(mapPies.get(category))
          }
        })
      })

      setTimestamp(()=>{
        let d = new Date()
        return d.getTime()
      })

      setSectionsCount(dataCategoriesPies.length)
      if(targetedSection && dataCategoriesPies[targetedSection]){
        setCaterogiesPie([dataCategoriesPies[targetedSection]])
      }else{
        setCaterogiesPie(dataCategoriesPies)
      }

    }

  },[
    filtersJson,
    datavizReducer.nodes,
    filtersReducer.matchCode,
    targetedSection
  ])

  function selectSection(anchor,i){
    if(!isMobile){
      setSelectedSection(anchor)
      scrollTo(anchor,'.dashboard')
    }else{
      let container = document.querySelector('.dashboard')
      container.scrollTo(0, 0);
      history.push(`/search-innov/${filtersReducer.plateformeID}/dashboard#${i}`)
    }

  }

  function scrollTo(anchor,parent, duration = 400) {
    let element = document.getElementById(anchor)
    let container = document.querySelector(parent)
    var start = container.scrollTop,
        marginTop = 78,
        change = element.offsetTop - start - marginTop ,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        container.scrollTo(0, val);
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    //
    Math.easeInOutQuad = function (t, b, c, d) {
      t /= d/2;
    	if (t < 1) return c/2*t*t + b;
    	t--;
    	return -c/2 * (t*(t-2) - 1) + b;
    };

    animateScroll();
}

  function renderSectionPies(){
    let indexLang;
    if(props.activeLanguage.code === "fr"){
      indexLang = 1
    }else if(props.activeLanguage.code === "en"){
      indexLang = 0
    }

    return (
      caterogiesPie.map((d,i)=>{
        return (
          <DashboardSection
            key={`section-${i}`}
            marginTop={isMobile ? 0 : heightNav}
            isLoading={datavizReducer.isLoading}
            section={d}
            index={i}
            indexLang={indexLang}
            isMobile={isMobile}
            backward={() => history.push(`/search-innov/${filtersReducer.plateformeID}/dashboard`)}
          />
        )
      })
    )

  }

  function shouldRenderDashboardSection(){
    // DETERMINE IS THERE ARE A ANCHOR OR TYPE NUMBER
    let hashIndex;
    if(history.location.hash){
      let hash = history.location.hash.replace("#", "")
      if(!isNaN(hash)) hashIndex = hash
    }

    return !isMobile || hashIndex

  }

  function getClassNameSearch(){
    let className = (isMobile && !targetedSection) ? "container-search hide" : "container-search"
    if(viewReducer.lightmode === "light") className = className+" light"
    return className
  }

  return useMemo(()=>{
    let indexLang;
    if(props.activeLanguage.code === "fr"){
      indexLang = 1
    }else if(props.activeLanguage.code === "en"){
      indexLang = 0
    }

    return(
      <>
        <div className={props.filterIsOpen ? "dashboard filters-opened" : "dashboard"} style={{height : contentDimension.height}} >
          { datavizReducer.isLoading &&
            <LoaderView/>
          }

  
          <div className={isMobile ? "nav-dashboard mobile" : "nav-dashboard"}>
            <div className={getClassNameSearch()}>
              <div className="container-left">
                { (!props.hideHeaderButtons && !props.filterIsOpen) &&
                  <ButtonFilters
                    toggleFilter={props.toggleFilter}
                  />
                }
                <SearchByKeywords
                  testId={"global"}
                  className={"header"}
                  iconPosition={"right"}
                  isMulti={true}
                  isClearable={true}
                  placeholder={"Filter par mots clés"}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
              <div className="container-right">
                <ExplorationNav2 history={props.history} match={props.match} />
                { !props.hideHeaderButtons &&
                  <ButtonDocs
                    documentsIsOpen={props.documentsIsOpen}
                    toggleDocument={props.toggleDocument}
                  />
                }
              </div>
            </div>
            { (caterogiesPie && caterogiesPie.length>1) && (
              <DashboardNavigation
                isMobile={isMobile}
                sections={caterogiesPie}
                indexLang={indexLang}
                selectedSection={selectedSection}
                selectSection={selectSection}
              />
            )}
          </div>

          { shouldRenderDashboardSection() && caterogiesPie && (
             renderSectionPies()
          )}

          { (sectionsCount && isMobile && targetedSection) && (
            <div className="navigation-dots">
              {Array(sectionsCount).fill().map((d,i)=>(
                <div key={"dot-"+i}>
                  <div
                    onClick={() => selectSection(null,i)}
                    className={parseInt(targetedSection) === i ? "dot active" : "dot"}
                  >
                  </div>
                </div>
              ))}
            </div>
          )}

        </div>
      </>
    )

  },[
    heightNav,
    props.activeLanguage.code,
    props.filterIsOpen,
    timestamp,selectedSection ,
    datavizReducer.isLoading,
    contentDimension.height,
    viewReducer.lightmode,
    isMobile
  ])

}

export default withLocalize(Dashboard)
