import React from 'react'
import styles from './tag.module.scss'
import { useGlobalContext } from '../../../GlobalContext'

import {
  setSearch,
  setFilterDate,
  addSelectedKeywords,
  removeSelectedKeyword
} from '../../../actions/filters'

import checkboxCheckedCumul from '../../../assets/images/checkboxes/checkbox-checked-cumul.svg'
import checkboxCumul from '../../../assets/images/checkboxes/checkbox-cumul.svg'

// className => oneOF [ grey ]

export default function Tag({
  tag,
  iconClose = false,
  exclude = false,
  className,
  selected = false,
  checked = false,
  toggleCheckbox,
  widthCheckbox = false,
  withFrequence = false
}){
  const [ context , dispatch ]  = useGlobalContext();
  const filtersReducer = context.filtersReducer;

  //ACTIONS
  const _setSearch = (obj) => setSearch(dispatch,obj)
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)
  const _removeSelectedKeyword = (keyword) => removeSelectedKeyword(dispatch,keyword)
  const _setFilterDate = (obj) => setFilterDate(dispatch,obj)

  function getClassName(){
    if(tag.string || tag.date) return `${styles["tag"]} ${styles["string"]}`

    let code = tag.code
    let strClass = ""
    if(className){
      strClass = `${styles["tag"]} ${styles[className]}`
    }else if(/%20OR%20/.test(code)){
      strClass = `${styles["tag"]} ${styles["cumul"]}`
    }else if(tag.id === "RUBRIQUE"){
      strClass = `${styles["tag"]} ${styles["rub"]}`
    }else{
      strClass = !tag.exclude ? `${styles["tag"]}` : `${styles["tag"]} ${styles["exclude"]}`
    }

    return selected ? `${strClass} ${styles["selected"]}` : `${strClass}`

  }


  function toggleKeywords(){
    if(tag.date){
      _setFilterDate({
        min : { ...filtersReducer.dateRange[0], value : 0 },
        max : { ...filtersReducer.dateRange[filtersReducer.dateRange.length-1], value : filtersReducer.dateRange.length-1 }
      })
    }if(tag.string){
      localStorage.removeItem("search")
      _setSearch(null)
    }else{

      let found = filtersReducer.selectedKeywords.find(keyword => {
        return keyword.label === tag.label
      })

      if(found) _removeSelectedKeyword({value : tag.label})
      else if(tag.code && tag.id) _addSelectedKeywords({...tag, value : tag.label, exclude})
      else {
        console.log("can't add this tag, missing property");
      }

    }

  }

  function getFrequence(){
    if(withFrequence && `(${tag.frequence})` && !filtersReducer.isFiltersLoading){
      let frequence = tag.frequence
      return `(${frequence})`
    }else{
      return null
    }
  }

  function fnToggleCheckbox(e,tag){
    e.preventDefault();
    e.stopPropagation();
    toggleCheckbox(tag)
  }

  return (
    <div className={getClassName()} onClick={toggleKeywords}>
      { widthCheckbox && 
        <>
          {checked ?
            <img className={styles["img-checkbox"]} src={checkboxCheckedCumul} alt="checkbox checked" onClick={(e) => fnToggleCheckbox(e,tag)} /> :
            <img className={styles["img-checkbox"]} src={checkboxCumul} alt="checkbox" onClick={(e) => fnToggleCheckbox(e,tag)} />
          }
        </>
      }

      <label>{tag.string ? tag.string : tag.label} {getFrequence()}</label>
      {iconClose && <div className={styles[`close`]}></div>}
    </div>
  )
}
