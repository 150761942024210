import React, { useState, useEffect } from "react"
import { getSimilaireDocumentById } from "../../../actions/documents"
import { useGlobalContext } from '../../../GlobalContext'
import { Translate, withLocalize } from "react-localize-redux";

import ImgItem from '../../partials/ImgItem'

const DocSimilaire = function (props){
  const [ context, dispatch ]  = useGlobalContext();
  const _getSimilaireDocumentById = (obj) => getSimilaireDocumentById(dispatch,obj)
  const documentsReducer = context.documentsReducer

  const [ doc, setDoc ] = useState(null)
  // const [ permanentLink, setPermanentLink] = useState(null)

  useEffect(()=>{
    if(props.ID_QES) _getSimilaireDocumentById({id : props.ID_QES, plateformeID : props.plateformeID})
  },[])

  useEffect(()=>{
    if(!doc){
      let found = documentsReducer.docsSimilaires.find((d)=>{
        if(d){ return d.ID_QES === props.ID_QES }
      })
      if(found) {
        // let plateforme = localStorage.getItem("plateforme")
        // let token = localStorage.getItem("token")
        // plateforme = JSON.parse(plateforme);
        // setPermanentLink({link : plateforme.urlpermlink+found.ID_QES , linkWithToken : plateforme.urlpermlink+found.ID_QES+"&token="+token})
        setDoc(found)
      }
    }
  },[documentsReducer.docsSimilaires])

  function getImage(image){
    if(Array.isArray(image)){
      return image[0]
    }else{
      return image
    }
  }

  return (
    <div>
      { doc ?
        <div onClick={props.selectDoc}>
          <ImgItem image={getImage(doc.IMAGE)}/>
          <p>{doc.TITLE}</p>
        </div> : null
      }
    </div>
  )

}

export default withLocalize(DocSimilaire)
// export default DocSimilaire;
