import innovations from './innovations.json'
import intelligencetools from './intelligence-tools.json'
import clientexpectations from './client-expectations.json'
import megatrends from './megatrends.json'
import hottopics from './hot-topics.json'
import businessmodels from './business-models.json'
import keytechnologies from './key-technologies.json'
import marketingconcepts from './marketing-concepts.json'

const config = {
  "header": [
    innovations,
    intelligencetools,
    clientexpectations,
    megatrends,
    hottopics,
    businessmodels,
    keytechnologies,
    marketingconcepts
  ]
}

export default config
