import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux'
import { useGlobalContext } from '../../GlobalContext'
import history from '../../history'
import plateformesID from "../../config/list-plateforms-id.json"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { getPlateforme } from '../../customHooks/usePlateforme'

export function SelectPlatforms({ match, platformsOpened, togglePlatforms }) {
  const context = useGlobalContext()[0];
  const filtersReducer = context.filtersReducer;
  const [isOpen, setIsOpen] = useState(false);
  const [availablePlateformes, setAvailablePlateformes] = useState(null)

  useEffect(() => {
    let plateformeID = JSON.parse(localStorage.getItem("plateformeID"))

    let found = JSON.parse(localStorage.getItem("plateformes")).filter((plateforme) => {
      return plateformesID.includes(plateforme.id)
    })
    let plateforme = found.find((plateforme) => {
      return plateforme.id === plateformeID
    })

    let sortPlateformes = _.sortBy(found, (plateforme) => plateforme.libelle.toLowerCase());
    setAvailablePlateformes(sortPlateformes)
  }, [])

  function selectPlateforme(plateformeID) {
    let plateforme = JSON.parse(localStorage.getItem("plateformes")).find(plateforme => plateforme.id === plateformeID);
    let config = getPlateforme(plateformeID);
    let firstEntry = config.header[0];

    localStorage.setItem("plateforme", JSON.stringify(plateforme));
    localStorage.setItem("plateformeID", plateformeID);

    localStorage.removeItem("filters");
    localStorage.removeItem("search");
    if (firstEntry) {
      let url = firstEntry.url || firstEntry.navbar[0].url;
      history.push(`/search-innov/${plateformeID}${url}?entry=0`);
    } else {
      history.push(`/search-innov/${plateformeID}/${match.params.page}?entry=0`);
    }
    window.location.reload()
  }

  return (
    <>
      <div className={"select-platforms"}
      >
        <div>

          {(availablePlateformes && availablePlateformes.length > 0) &&
            <ul>
              <li className={isOpen ? "toggle-platforms opened" : "toggle-platforms"} key={"toggle-plateforme"} onClick={() => setIsOpen(!isOpen)}>
                <label><Translate id="header.otherPlatforms" /></label>
                {isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
              </li>

              {isOpen && availablePlateformes.map((d) => (
                <li key={"plateforme-" + d.id} className={parseInt(filtersReducer.plateformeID) === parseInt(d.id) ? "plateforme selected" : "plateforme"} onClick={() => selectPlateforme(d.id)} >{d.libelle}</li>
              ))}
            </ul>
          }

        </div>
      </div>
    </>
  )
}

// const MobileSelectPlatforms = (props) => {
//   const dimension = useContentDimension();
//   return dimension.width < 1165 ? <SelectPlatforms {...props} height={dimension.height} /> : null
// }

export default withLocalize(SelectPlatforms)
