import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { withLocalize, Translate } from 'react-localize-redux'
import { useGlobalContext } from '../../../../GlobalContext'
import styles from './block-tags.module.scss'
import Tag from '../../../filters/Tag'
import { fnSelectEntry } from '../../../header/helpers'
import history from '../../../../history'

const BlockTags = ({
    filter,
    linkTo,
    filtersJSON
}) => {
    const [ context, dispatch ]  = useGlobalContext();
    const filtersReducer = context.filtersReducer;
    const viewReducer = context.viewReducer;
    const [ tags, setTags ] = useState();
    const [ title, setTitle ] = useState()

    const _fnSelectEntry = (entry,indexSelectedEntries,index,url) => {
        return (
          fnSelectEntry(
            history,
            filtersJSON,
            dispatch,
            entry,
            indexSelectedEntries,
            index,
            url
          )
        )
    }


    useEffect(()=>{
        if(!filtersReducer.filters) return;
        let found = filtersReducer.filters.find((d => d.id === filter.id))
        if(found){
            setTitle(found.libelle)
            let values = found.valeurs
            if(filter.order){
                let props = filter.order.props === "label" ? "libelle" : filter.order.props
                values = _.orderBy(values,[props],[filter.order.sort])
            }
            setTags(values)
        }
    },[filtersReducer.filters])

    function goTo(linkId){
        if(!filtersJSON) return;
        let findIndex = filtersJSON.header.findIndex(d=>d.id === linkId)
        let findEntry = filtersJSON.header.find(d=>d.id === linkId)
        if(findIndex > 0){
            _fnSelectEntry(
                findEntry,
                filtersReducer.indexSelectedEntries,
                findIndex
            )
        }

    }

    function getClassNameLightMode(){
        return viewReducer.lightmode === "light" ? ` ${styles.light}` : ''
    }

    return (
        <div className={`${styles["block-tags"]}${getClassNameLightMode()}`}>
            {title && 
                <div className={styles["block-title"]}>
                    <h3>{title}</h3>
                    <div className={styles["link-entry"]}>
                        <p onClick={() => goTo(linkTo)}><Translate id="home.see-all"/></p>
                    </div>
                </div>
            }
            { tags &&
                <ul>
                    {tags.map((tag,i)=>
                        <li key={`tags-${i}`}>
                            <Tag
                                className={'grey'}
                                selected={filtersReducer.selectedKeywords.some(keyword => keyword.code === tag.code)}
                                tag={{...tag, label : tag.libelle, id : filter.id}}
                            />
                        </li>
                    )}
                </ul>
            }
        </div>
    )
}

export default withLocalize(BlockTags);