import {
  GET_DOCUMENTS_BY_ID,
  GET_REFERENCES_DOCUMENT,
  GET_SIMILIRAIRE_DOCUMENT_BY_ID,
  GET_DOCUMENTS_COUNT,
  GET_FILTERED_DOCUMENTS,
  SET_ORDER_BY_DOCUMENT,
  SET_DOCUMENTS_COUNT,
  SET_DOCUMENT_NUMBER,
  REMOVE_DOCS,
  REMOVE_SIMILIRAIRE_DOCUMENT,
  LOADING_DOCUMENTS,
  ERROR_DOCUMENTS,
  ///
  GET_NODES,
  GET_FILTERS,
  GET_TIMELINES,
  GET_RELATIONSHIPS,
  GET_PARTNERSHIPS,
  ADD_SELECTED_KEYWORDS,
  REMOVE_SELECTED_KEYWORDS,
  REMOVE_ALL_SELECTED_KEYWORDS,
  REMOVE_SELECTED_KEYWORDS_BY_CAT,
  SET_FILTER_DATE
} from '../actions/types'

const initialState = {
  nameReducer : 'documents',
  document : null ,
  documents : null,
  references : null,
  docsSimilaires : [],
  countDocs : null,
  page : 1,
  pageRef : null,
  number : 30, // NUMBER DE DOCUMENTS A CHARGER PAR APPELLE
  orderBy : "date_ressource_desc",
  isLoading : false,
  change : null,
  lang : "En"};


let updatedDocs = null
let updatedReferences = null
let numFound  = null

export default function documentsReducer(state = initialState, action) {
   switch (action.type) {
    case REMOVE_DOCS :
    case SET_FILTER_DATE :
    case ADD_SELECTED_KEYWORDS :
    case REMOVE_SELECTED_KEYWORDS :
    case REMOVE_ALL_SELECTED_KEYWORDS :
    case REMOVE_SELECTED_KEYWORDS_BY_CAT :
      return { ...state, documents : null}
    // INIT NUMBER DOCUMENTS
    case GET_RELATIONSHIPS :
    case GET_PARTNERSHIPS :
    case GET_TIMELINES :
    case GET_NODES :
      numFound = action.payload.result ? action.payload.result.numFound : action.payload.numFound
      return { ...state, countDocs : numFound}
    case GET_FILTERS :
      numFound = action.payload.result ? action.payload.result.numFound : action.payload.numFound
      let reg = new RegExp(`/search-innov/${action.plateformeID}/analysis-tools`)

      if(
         action.origine !== "/search-innov/"+action.plateformeID+"/home" &&
         action.origine !== "/search-innov/"+action.plateformeID+"/help" &&
         action.origine !== "/search-innov/"+action.plateformeID+"/strategic-watch" &&
         !reg.test(action.origine)
       ){
        return { ...state }
      }
      return { ...state, countDocs : numFound}
    case SET_DOCUMENTS_COUNT :
      return { ...state, countDocs : action.payload}
    case GET_DOCUMENTS_COUNT :
      return { ...state , countDocs : action.payload.result.numFound, isLoading : false}
    case GET_DOCUMENTS_BY_ID :
      return { ...state, document : action.payload.result}
    case GET_SIMILIRAIRE_DOCUMENT_BY_ID :
      return { ...state, docsSimilaires : [...state.docsSimilaires, action.payload.result]}
    case REMOVE_SIMILIRAIRE_DOCUMENT :
      return { ...state, docsSimilaires : [] }
    case GET_FILTERED_DOCUMENTS :

      if(action.payload.result){
        if(action.payload.result.currentPage > 1 && Array.isArray(state.documents)){
          updatedDocs = [ ...state.documents, ...action.payload.result.docs ]
        }else{
          updatedDocs = action.payload.result.docs
        }
        return { ...state, documents : updatedDocs,
          countDocs : parseInt(action.payload.result.numFound),
          page : action.payload.result.currentPage,
          isLoading : false,
          change : null
        }
      }else{
        return { ...state, countDocs:'Ops !' , isLoading : false, change : null}
      }
    case GET_REFERENCES_DOCUMENT :
      if(action.payload.currentPage > 1){
        updatedReferences = [ ...state.references, ...action.payload.docs ]
      }else{
        updatedReferences = action.payload.docs
      }

      if(updatedReferences[parseInt(action.payload.numFound)-1]){
        updatedReferences[parseInt(action.payload.numFound)-1].lastOne = true
      }

      return {...state, references : updatedReferences, pageRef : action.payload.currentPage}
    case SET_ORDER_BY_DOCUMENT :
      return { ...state, orderBy : action.payload}
    case SET_DOCUMENT_NUMBER :
      return { ...state, number : action.payload}
    case LOADING_DOCUMENTS :
      let change = action.payload && action.payload.change ? action.payload.change : "undefined"
      return { ...state, isLoading : true, change}
    case ERROR_DOCUMENTS :
    return {...state}
    default:
      return {...state};
  }
}
