import React, { useEffect, useState, useRef } from "react"
import _ from 'lodash';
import { useGlobalContext } from '../../GlobalContext'
import usePrevious from '../../customHooks/usePrevious'

import { filtersParams } from '../../helpers/filtersParams'
import { getReferences } from '../../actions/references'

import Article from './article/Article'
import Referentiel from './referentiel/Referentiel'
import loader from '../../assets/images/loader.gif'

import {
  getDocumentById,
  removeSimilaireDocument
} from "../../actions/documents"

export default function DocView(props){
  const isMount  = useRef(false);
  const [ context, dispatch ]  = useGlobalContext();
  const documentsReducer = context.documentsReducer
  const newspaperReducer = context.newspaperReducer

  const filtersReducer = context.filtersReducer
  const viewReducer = context.viewReducer

  const prevIndexSelectedEntries = usePrevious(filtersReducer.indexSelectedEntries)

  // ACTIONS
  const _getReferences = (filter,page, nameReducer) => getReferences(dispatch, filter, page, nameReducer)
  const _getDocumentById = (obj) => getDocumentById(dispatch,obj)
  const _removeSimilaireDocument = () => removeSimilaireDocument(dispatch)

  const [ doc , setDoc] = useState(null)
  const [ isRef, setIsRef ] = useState(null)

  useEffect(()=>{
    if(!documentsReducer.document || documentsReducer.document.ID_QES !== props.documentID || !isMount.current){
      setDoc(null)
      _getDocumentById({id : props.documentID, plateformeID : filtersReducer.plateformeID})
    }
    isMount.current = true
  },[props.documentID])

  useEffect(()=>{
    if(documentsReducer.document &&
      documentsReducer.document.ID_QES === props.documentID
    ){
      let found;

      if(props.references){
        found = props.references.find((ref)=>{
          return ref.ID_QES === props.documentID
        })
      }


      if(found && found.lastOne){
        setDoc({...documentsReducer.document, lastOne : true})
      }else{
        setDoc(documentsReducer.document)
      }

    }

    return () => {
      _removeSimilaireDocument()
    }

  },[documentsReducer.document])

  // IS THIS DOC A REFERENTIEL ?
  useEffect(()=>{
    if(!doc) return;
    if(doc.REFERENCE === "True"){
     setIsRef(true)
    }else{
      setIsRef(false)
    }

  },[doc])

  useEffect(()=>{
    let allInstances = []
    allInstances = documentsReducer.documents ? [ ...allInstances, ...documentsReducer.documents] : allInstances
    allInstances = newspaperReducer.newspaper ? [ ...allInstances, ...newspaperReducer.newspaper] : allInstances

    let found = allInstances.find((d)=> d.ID_QES === props.documentID)

    // if(!found &&
    //   props.match.params.page !== "analysis-tools"
    // ){
    //    props.selectDoc(null)
    // }

  },[
    documentsReducer.documents,
    newspaperReducer.newspaper
  ])

  async function prevNext(dir){
    let indexOfCurrentRef;
    props.references.find((ref,i)=>{
      if(ref.ID_QES === props.documentID){
        indexOfCurrentRef = i
      }
    })

    if(typeof indexOfCurrentRef !== "number"){
      console.log("Can't find actual ref");
    }else{
      if(dir === "next"){
        indexOfCurrentRef++
      }else if(dir === "prev"){
        indexOfCurrentRef--
      }

      let targetRef = props.references[indexOfCurrentRef];

      if(targetRef){
        props.selectDoc(targetRef.ID_QES)
      }else if(!targetRef && props.originOpenDocs !== "analysis-tools" ){

        let result = filtersParams(filtersReducer,viewReducer.lang)
        result = _.cloneDeep(result)
        result.filters.filters = [ ...result.filters.filters, ...filtersReducer.filterView ]

        //SET PAGE DEPENDING ON originOpenDocs
        let page;
        if(props.originOpenDocs === "newspaper"){
          page = newspaperReducer.pageRef
          result.filters.orderBy = newspaperReducer.orderBy
        }else if(props.originOpenDocs === "documents"){
          page = documentsReducer.pageRef
          result.filters.orderBy = documentsReducer.orderBy
          result.filters.number = documentsReducer.number
        }

        page++


        let nextRefs;
        await _getReferences({...result.filters, page : page}, page, props.originOpenDocs).then((res) => {
          nextRefs = res
        })

        if(nextRefs) props.selectDoc(nextRefs[0].ID_QES)
      }

    }

  }

  return (
    <>
      { doc === null && <img src={loader} className="document-loading" alt="document loading" />}
      { (typeof isRef === "boolean" && !isRef) &&
        <Article history={props.history} match={props.match} document={doc} selectDoc={(doc) => props.selectDoc(doc)}/>
      }
      { ((typeof isRef === "boolean" && isRef)) &&
        <Referentiel
          match={props.match}
          firstRef={props.references ? props.references[0] : null}
          prevNext={prevNext}
          document={doc}
          selectDoc={(doc) => props.selectDoc(doc)}
        />
      }
    </>
  )
}
