import React, { useState, useEffect, useImperativeHandle ,forwardRef } from 'react'
import _ from "lodash"
import ReactTooltip from 'react-tooltip'
import { Translate, withLocalize } from "react-localize-redux";
import styles from './tags-panel.module.scss'
import { useGlobalContext } from '../../../GlobalContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import iconAbout from '../../../assets/images/icon-about.svg'
import checkboxCheckedCumul from '../../../assets/images/checkboxes/checkbox-checked-cumul.svg'
import checkboxCumul from '../../../assets/images/checkboxes/checkbox-cumul.svg'

import {
  addSelectedKeywords,
  removeSelectedKeyword
} from '../../../actions/filters'

import TagsCount from '../TagsCount'
import Tag from '../Tag'

const TagsPanel = withLocalize(({
  id,
  panelLevel,
  help = null,
  order,
  openFilters = null,
  advancedConfig,
  panelRef,
  activeLanguage
}) => {
  const [ context , dispatch ]  = useGlobalContext();
  const filtersReducer = context.filtersReducer;

  const [ groupedTags, setGroupedTags ] = useState();
  const [ open, setOpen ] = useState(false);
  const [ selectedKeywords, setSelectedKeywords ] = useState({});
  const [ listGroupOpened, setListGroupOpened ] = useState([]);
  const [ mode, setMode ] = useState('select')
  const [ selectedCheckboxes, setSelectedCheckboxes ] = useState({})


  //ACTIONS
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)
  const _removeSelectedKeyword = (keyword) => removeSelectedKeyword(dispatch,keyword)

  useImperativeHandle(panelRef,()=>({
    isOpen : () => open,
    toggle : () => {
      if(!open){
        setOpen(true)
      }else{
        close()
      }
    },
    close : () => close()
  }))
  
  useEffect(()=>{
    let selectedKeywords = {}
    filtersReducer.selectedKeywords.map((d)=>{
      selectedKeywords[d.code] = d
    })

    setSelectedKeywords(selectedKeywords)
  },[filtersReducer.selectedKeywords])

 
  useEffect(()=>{
    if(!filtersReducer.filters) return;

    let targetId = /2$/.test(id) ? id.replace(/2$/,"") : id
    let filter = filtersReducer.filters.find(filter => filter.id === targetId)

    let grouped = {}
    let groupOpened = []

    //IF ISNT SECOND LEVEL FILTER
    if(/2$/.test(id)){
      let childrenFilter = filtersReducer.filters.find(filter => filter.id === id)
      // SEARCH CORRESPING ORDER FOR THE LEVEL 1 OF THIS FILTER IN ADVANCED SEARCH (advanced.json)
      let findCorrespingOrder = advancedConfig.map(d=>d.categories).flat(1).find(d => d.code === targetId)?.order
      if(findCorrespingOrder){
        filter.valeurs = sortValues(filter.valeurs,findCorrespingOrder)
      }

      if(childrenFilter){
        filter.valeurs.forEach(value =>{

          // let codeParent = value.code.substring(0,7)
          let re = new RegExp(`^${value.code}_`)
          let foundValues = childrenFilter.valeurs.filter(d=>{
            return re.test(d.code)
          })

          if(order) foundValues = sortValues(foundValues,order)

          groupOpened.push(value.code)

          if(foundValues && foundValues.length>0){
            grouped[value.code] = {
              ...value,
              valeurs : foundValues
            }
          }

        })
      }
    }else if(filter && filter.valeurs.length>0){

      if(order) filter.valeurs = sortValues(filter.valeurs,order)

      groupOpened=[id]
      grouped[id] = filter
    }

    if(openFilters){
      setListGroupOpened(openFilters)
    }else{
      setListGroupOpened(groupOpened)
    }
    
    setSelectedCheckboxes({})
    setGroupedTags(grouped)

  },[filtersReducer.filters])


  function close(){
    setSelectedCheckboxes({})
    setMode("select")
    setOpen(false)
  }

  function sortValues(values,argOrder){
    let arrValues = _.cloneDeep(values)

    if(argOrder){
      if(argOrder.props === "label"){
        arrValues = _.orderBy(arrValues,["libelle"],[argOrder.sort])
      }else if(argOrder.props === "frequence"){
        arrValues = _.orderBy(arrValues,[(k) => parseInt(k.frequence)],[argOrder.sort])
      }else{
        arrValues = _.orderBy(arrValues,[argOrder.props],[argOrder.sort])
      }
    }

    return arrValues

  }

  function toggleGroup(code){
    if(listGroupOpened.includes(code)){
      setListGroupOpened(listGroupOpened.filter(d => d !== code))
    }else{
      setListGroupOpened([...listGroupOpened, code])
    }
  }

  function getFilterId(code,value){
    if(code === null){
      return null
    }else if(/FILTER_/.test(id)){
      let resId = code.substring(0,3)
      resId = code.length>6 ? `FILTER_${resId}2` : `FILTER_${resId}`
      return resId
    }else {
      return id
    }

  }

  function getCount(code){
    let count = {
      cumul : 0,
      select : 0,
      exclude : 0,
      rub : 0
    }

    let re = new RegExp(`${code}`)
  
    let found = filtersReducer.selectedKeywords.filter(keyword => {
      return keyword.id === id && re.test(keyword.code)
    })

    found.forEach(keyword=> {
      if(keyword.exclude){
        count.exclude++
      }else if(/%20OR%20/.test(keyword.code)){
        count.cumul++
      }else if(keyword.id === "RUBRIQUE"){
        count.rub++
      }else{
        count.select++
      }
    })

    return <TagsCount count={count}/>
  }

  function handleClickLabelTag(tag){

    let found = filtersReducer.selectedKeywords.find(keyword => {
      return keyword.label === tag.libelle
    })

    if(found) _removeSelectedKeyword({value : tag.value})
    else if(tag.code && getFilterId(tag.code)) _addSelectedKeywords({
      label : tag.libelle,
      value : tag.libelle,
      code : tag.code,
      id : getFilterId(tag.code),
      exclude : mode === "exclude"
    })

  }

  function toggleCheckbox(data){
    let obj = Object.assign({}, selectedCheckboxes) ;

    if(obj[data.code]){
      delete obj[data.code]
    }else{
      obj[data.code] = data
    }

    setSelectedCheckboxes(obj)
  }

  function applyRefine(){

    let obj = {
      level1 : {
        id : null,
        value : null,
        label : null,
        code : null,
        type : "full-join"
      },
      level2 : {
        id : null,
        value : null,
        label : null,
        code : null,
        type : "full-join"
      }
    }

    Object.keys(selectedCheckboxes).forEach((key)=>{
      let split = key.split("_").length;
      let checkBoxlevel;
      let tag = selectedCheckboxes[key]

      switch (split) {
        case 2:
          checkBoxlevel = 1;
          break;
        case 3:
          checkBoxlevel = 2;
          break;
        default:
          checkBoxlevel = 1;
          break;
      }

      if(checkBoxlevel === 2){
        if(!obj.level2.code) {
          obj.level2.id = id
          obj.level2.label = tag.libelle
          obj.level2.code = tag.code
        }else{
          obj.level2.label = obj.level2.label+" or "+tag.libelle
          obj.level2.code = obj.level2.code+"%20OR%20"+tag.code
        }
        obj.level2.value = obj.level2.label
      }else if(checkBoxlevel === 1){

          if(!obj.level1.code) {
            // IF selectedCheckboxes is level 1 (selected title group)
            // But inside a panel who show level 2 value
            if(panelLevel === 2){
              obj.level1.id = id.slice(0, -1)
            }else{
              obj.level1.id = id
            }

            obj.level1.label = tag.libelle
            obj.level1.code = tag.code
          }else{
            obj.level1.label = obj.level1.label+" or "+tag.libelle
            obj.level1.code = obj.level1.code+"%20OR%20"+tag.code
          }
          obj.level1.value = obj.level1.label
      }
      
    })

    if(!obj.level1.id) delete obj.level1
    if(!obj.level2.id) delete obj.level2
    _addSelectedKeywords(Object.values(obj))
    setSelectedCheckboxes({})

  }

  function getTranslatedHelp(){
    let translation;
    if(activeLanguage.code === "en") translation = help[0]
    else translation = help[1]
    return translation
  }

  return (
    <>
      { (open && Object.keys(groupedTags).length > 0) &&
        <>
          { help &&
            <ReactTooltip 
              className={`react-tooltip ${styles["tooltip-pinup"]}`}
              delayShow={500}
              id={`pinpup-${id}`}
              place="bottom"
              effect='solid'
            >
              <span>{getTranslatedHelp()}</span>
            </ReactTooltip>
          }
          <div
            className={listGroupOpened.length>0 ? `${styles["tags-panel"]} ${styles["list-opened"]}` : `${styles["tags-panel"]}`}
          >
            {
              help &&
              <img alt="help filter" className={styles.help} src={iconAbout} data-tip data-for={`pinpup-${id}`}/>
            }
            <span className={styles["close"]} onClick={() => close()}></span>
            <div>
              <ul className={styles["select-mode"]}>
                <li className={mode === "select" ? `${styles.select} ${styles.active}` : styles.select} onClick={() => setMode("select")}><Translate id="filters.select" /></li>
                <li className={mode === "cumul" ? `${styles.cumul} ${styles.active}` : styles.cumul} onClick={() =>{
                  setMode("cumul")
                  if(mode === "cumul") applyRefine()
                }}>
                  {mode === "cumul" ? <Translate id="filters.apply-cumul" /> : <Translate id="filters.cumul" />}
                </li>
                <li className={mode === "exclude" ? `${styles.exclude} ${styles.active}` : styles.exclude} onClick={() => setMode("exclude")}><Translate id="filters.exclude" /></li>
                <li><img alt="help filter" src={iconAbout} data-tip data-for={"filter-selection-help"}/></li>
                
              </ul>
              <ReactTooltip className={`react-tooltip ${styles["tooltip-filter-selection-help"]}`} delayShow={500} id={"filter-selection-help"} place="right" effect='solid'>
                <span><Translate id="filters.filter-selection-help"/></span>
              </ReactTooltip>
              { groupedTags &&
                <ul>
                  {Object.values(groupedTags).sort((a, b) => a.libelle.localeCompare(b.libelle)).map(group=>
                    <div key={`group-filter-${group.code}`} className={styles["group-filter"]}>
                      <label className={selectedKeywords && selectedKeywords[group.code] ? `${styles["label-tag-filter"]} ${styles["selected"]}` : `${styles["label-tag-filter"]}`}>
                        {listGroupOpened.includes(group.code ? group.code : group.id) ?
                          <FontAwesomeIcon icon={faChevronDown} onClick={()=>toggleGroup(group.code ? group.code : group.id)}/> :
                          <FontAwesomeIcon icon={faChevronRight} onClick={()=>toggleGroup(group.code ? group.code : group.id)}/>
                        }
                        { (panelLevel === 2 && mode === "cumul") && 
                          <>
                            {!!selectedCheckboxes[group.code] ?
                              <img className={styles["img-checkbox"]} src={checkboxCheckedCumul} alt="checkbox checked" onClick={() => toggleCheckbox(group)} /> :
                              <img className={styles["img-checkbox"]} src={checkboxCumul} alt="checkbox" onClick={() => toggleCheckbox(group)} />
                            }
                          </>
                        }
                        <span className={styles["title"]} onClick={()=>handleClickLabelTag(group)}>{group.libelle}</span>
                        {getCount(group.code)}
                      </label>
                      {(listGroupOpened.includes(group.code ? group.code : group.id) && group.valeurs) &&
                        <ul>
                          {group.valeurs.map(value=>
                            <li key={`tag-${value.code}`}>
                              <Tag
                                className={'grey'}
                                withFrequence
                                selected={!!selectedKeywords[value.code]}
                                toggleCheckbox={toggleCheckbox}
                                checked={!!selectedCheckboxes[value.code]}
                                tag={{...value, label : value.libelle, id : getFilterId(value.code,value)}}
                                exclude={mode === "exclude"}
                                widthCheckbox={mode === "cumul"}
                              />
                            </li>
                          )}
                        </ul>
                      }
                    </div>
                  )}
                </ul>
              }
            </div>
          </div>
        </>
      }
    </>
  )
})

export default React.forwardRef((props, ref) => {
  return <TagsPanel {...props} panelRef={ref} />;
});


