import React, { useState, useEffect } from "react"
import _ from 'lodash'
import * as d3 from 'd3';
import * as cloud from 'd3-cloud'
import { useGlobalContext } from '../../../GlobalContext'

// import layout from './d3.layout.cloud.js'
import {
  addSelectedKeywords,
  removeSelectedKeyword
} from '../../../actions/filters'


export default function WordsCloudViz(props){
  const [ context , dispatch ]  = useGlobalContext();

  const [ words, setWords ] = useState(null);

  const [wordCloudState, setWordCloudState] = useState()
  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;

  //ACTIONS
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)
  const _removeSelectedKeyword = (keyword) => removeSelectedKeyword(dispatch,keyword)

  useEffect(()=>{
    setWordCloudState(wordCloud())
  },[])

  useEffect(()=>{
    let svg = d3.select('#wordscloud').select("svg")
      .attr("height", props.height)
  },[props.height])

  useEffect(()=>{
    if(!props.data || props.data.length === 0){
      if(words && words.length>0) setWords([])
      return
    }else{

      let arrWords = []

      let maxCount = _.maxBy(props.data, function(d) { return d.count; }).count;
      let minCount = _.minBy(props.data, function(d) { return d.count; }).count;
      var linearScale = d3.scaleLinear()
                             .domain([minCount,maxCount])
                             .range([10,props.maxFontSize]);

      props.data.forEach((d)=>{
        let size = linearScale(d.count)

        if(d.value && d.value.length < 25){
          arrWords.push({ label : d.value, size, id : d.id, code : d.value})
        }else if(d.value && d.value.length >= 25){
          arrWords.push({ label : d.value.slice(0,22)+"...", size, id : d.id, code : d.value})
        }

      })

      if(props.countGreaterThan){
        arrWords = arrWords.filter((d)=> d.size > props.countGreaterThan)
      }

      // arrWords.forEach((d)=>{
      //   delete d.sprite
      // })

      // console.log(arrWords);
      setWords(arrWords)

    }

  },[props.data])

  useEffect(()=>{
    if(!words) return;
    wordCloudState.update(words,filtersReducer.selectedKeywords)

  },[words])

  function wordCloud(){

    let svg = d3.select('#wordscloud').append("svg")
      .attr("width", props.width)
      .attr("height", props.height)


    if(props.activeZoom){
      d3.select('#wordscloud').select('svg')
         .call(d3.zoom()
         .scaleExtent([0.7, 3])
         .on("zoom", zoomed));
    }

    svg.append("g")
      .attr('class','wordscloud-centered')
      .attr("transform", "translate(" + props.width/2 + "," + props.height/2 + ")")
      .append('g')
      .attr('class','wordscloud');

    function draw(words,selectedKeywords) {

      let wordcloud = d3.select('#wordscloud').select(".wordscloud")
      wordcloud.html('')

      let dataWordCloud = wordcloud.selectAll("text")
        .data(words)

      let groupWord = dataWordCloud.enter()
        .append("g")

      groupWord.on("mouseover", (d,i)=>{
          removeArea()

          let words = document.querySelectorAll("#wordscloud .wordscloud text.word")
          words.forEach(function(word) {
            word.classList.remove("active")
          });

          let eltDom = document.querySelector("[id='word-"+d.code+"']");
          eltDom.classList.add("active")

          let nodesData = d
          let rect = document.querySelector("#wordscloud .wordscloud #area")
          if(rect) return;

          let elt = d3.select("[id='word-"+d.code+"']");

          var bbox = elt.node().getBBox()

          let width = bbox.width+60
          let height = bbox.height > 20 ? bbox.height : 20

          let ignore = false

          let area = wordcloud.append("g")
            .attr("id","area")

          area.append("rect")
            .attr("width",width)
            .attr("height",height)
            .attr("x", function (d){
              return nodesData.x-(width/2)
            })
            .attr("y", function (d){
              return nodesData.y-(height)+(height*.2)
            })
            // .style("fill", "red")
            .style("fill-opacity", "0")

          area.append("circle")
            .attr("id","btn-exclude")
            .style("visibility","inherit")
            .attr("cx", function (){
              return nodesData.x+(width/2)-15
            })
            .attr("cy", function (){
              return nodesData.y-(height)+10
            })
            .attr("r", "10")
            .style("fill", "#FF7D67")
            .on("click",()=>{
              ignore = true
              if(eltDom.classList.contains("exclude") || eltDom.classList.contains("selected")){
                _removeSelectedKeyword({value : d.label})
              }else{
                _addSelectedKeywords({...nodesData, value : nodesData.code, exclude : true})
              }
              eltDom.classList.remove("selected")
              eltDom.classList.remove("exclude")

              removeArea()
            })

          let cross = area.append("g")

          cross.append("line")
            .style("stroke", "white")
            .style("stroke-width", "2px")
            .attr("x1", function (){
              return nodesData.x+(width/2)-19
            })
            .attr("y1", function (){
              return nodesData.y-(height)+6
            })
            .attr("x2", function (){
              return nodesData.x+(width/2)-11
            })
            .attr("y2", function (){
              return nodesData.y-(height)+13
            })

          cross.append("line")
            .style("stroke", "white")
            .style("stroke-width", "2px")
            .attr("x1", function (){
              return nodesData.x+(width/2)-11
            })
            .attr("y1", function (){
              return nodesData.y-(height)+6
            })
            .attr("x2", function (){
              return nodesData.x+(width/2)-19
            })
            .attr("y2", function (){
              return nodesData.y-(height)+13
            })

          area.on("mouseleave", ()=>{
            removeArea()
          },false)

          area.on("click", ()=>{
            if(ignore) return
            if(eltDom.classList.contains("selected") || eltDom.classList.contains("exclude")){
              _removeSelectedKeyword({value : d.label})
            }else{
              eltDom.classList.add("selected")
              _addSelectedKeywords({...nodesData, value : nodesData.code})
            }
            eltDom.classList.remove("exclude")

            removeArea()
          },false)

          function removeArea(){
            let eltRect = d3.select("#area")
            if(!eltRect) return
            eltRect.remove()
          }


        },true)

      groupWord.append("text")
        .attr('id',(d)=>{
          return "word-"+d.code
        })
        .attr('class','word')
        .attr('font-family', 'Montserrat-SemiBold')
        .attr("text-anchor", "middle")
        // .attr('font-size', 1)
        .style("font-size", function(d) { return d.size + "px"; })
        .attr("transform", function(d) { return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")"; })
        .text(function(d,i) {
          return d.label;
         })

        //Entering and existing words
        dataWordCloud
        .attr('id',(d)=>{
          return "word-"+d.code
        })
        .transition()
            .duration(600)
            .style("font-size", function(d) { return d.size + "px"; })
            .text(function(d) { return d.label; })
            .attr("transform", function(d) {
                return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
            })
            .style("fill-opacity", 1);

      if(selectedKeywords){
        let arrSelectedKeywords = selectedKeywords.map((d)=> d.code)
        let wordcloud = d3.select('#wordscloud').select(".wordscloud").selectAll(".word")
        wordcloud.each((d)=>{
          if(_.includes(arrSelectedKeywords,d.code)){
            let found = selectedKeywords.find((keyword)=> keyword.code === d.code)
            let elt = document.getElementById("word-"+d.code);
            if(found.exclude){
              elt.classList.add("exclude")
            }else{
              elt.classList.add("selected")
            }
          }else{
            let elt = document.getElementById("word-"+d.code);
            elt.classList.remove("selected")
          }
        })
      }

      dataWordCloud.exit()
      .remove()

    };

    return {
      update : (words,selectedWords) =>{
        cloud().size([props.width, props.height])
                // .size([500, 500])
                .words(words)
                .padding(15)
                .rotate(function() { return 0 })
                // .rotate(function() { return ~~(Math.random() * 2) * 90; })
                .text(function(d) { return d.label; })
                .font("Montserrat-SemiBold")
                .fontSize(function(d) {
                   return d.size;
                 })
                .on("end", words => draw(words,selectedWords))
                .start();
      }
    }

  }

  function zoomed(projection,path) {

    let groupCentered = d3.select('#wordscloud').select("svg").select("g.wordscloud-centered")
    groupCentered.attr("transform", "translate(" + ((props.width)/2)*d3.event.transform.k+" "+(props.height/2)*d3.event.transform.k+ ")");


    let groupContainer = d3.select('#wordscloud').select("svg").select("g.wordscloud")
    groupContainer.attr("transform", "translate(" + d3.event.transform.x+" "+d3.event.transform.y+ ")scale(" + d3.event.transform.k + ")");

    // projection.translate(d3.event.translate).scale(d3.event.scale);
    // d3.select('#map').select('svg').select("g").selectAll("path").attr("d", path);
  }


  return (
    <>
    { !props.isLoading && words && props.data && props.data.length === 0 ?
      <div className="wordscloud-noData" style={{height : props.height, width : props.width}}>No data</div> : null
    }
    <div style={props.data && props.data.length === 0 ? {display:"none"} : {}} id="wordscloud">
    </div>
    </>

  )

}
