import React from "react";
import { withLocalize } from "react-localize-redux";

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage, dispatch }) => {

  function toggleLanguage(langCode){
    dispatch({
      type : "setLang",
      payload : langCode
    })
    localStorage.setItem("lang",langCode)
    setActiveLanguage(langCode)
  }

  return (
    <ul className="selector-lang" style={{"marginRight" : "10px"}}>
      {languages.map((lang, i) => (
        <React.Fragment key={lang.code}>
          <li  className={activeLanguage.code === lang.code ? "active" : ""} onClick={() => toggleLanguage(lang.code)}>
            {lang.name}
          </li>
          { i !== languages.length-1 ? <li >/</li> : null}
        </React.Fragment>
      ))}
    </ul>
  );
}

export default withLocalize(LanguageToggle);
