import React, { useEffect, useState } from 'react'
import { Router,Switch, Route } from 'react-router';
import axios from 'axios';
import queryString from 'query-string'
import history from '../../../history'
import AnalysisTools from './AnalysisTools'
import Tools from './Tools'
import OptTools from './OptTools'
import SearchByKeywords from '../../partials/SearchByKeywords'
import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'
import ExplorationNav2 from '../../partials/ExplorationNav2'

import loader from '../../../assets/images/loader.gif'

import { API_URL } from '../../../actions/index.js'
import { useGlobalContext } from '../../../GlobalContext'

import useFilterView from '../../../customHooks/useFilterView'
import useContentDimension from '../../../customHooks/useContentDimension'
import usePlateforme from '../../../customHooks/usePlateforme'


const AnalysisToolsSwitch = (props) => {
  const [context, dispatch]= useGlobalContext();
  const contentDimension = useContentDimension();
  const defaultRubrique = props.entries
  const filtersJSON = usePlateforme()

  const viewReducer = context.viewReducer;
  const filtersReducer = context.filtersReducer;

  const [ allFilters, setAllFilters ] = useState();
  const [ isFiltersLoading, setIsFiltersLoading ] = useState(false);

  const [ indexSpecificTool, setIndexSpecificTool ] = useState()
  const [ indexSpecificToolItem, setIndexSpecificToolItem ] = useState()

  useFilterView(defaultRubrique)

  // GET ALL FILTERS
  useEffect(()=>{

    if(
      !filtersJSON ||
      indexSpecificTool === undefined ||
      indexSpecificToolItem === undefined ||
      !filtersReducer.date.min.date ||
      !filtersReducer.date.max.date ||
      (!filtersReducer.indexSelectedEntries && filtersReducer.indexSelectedEntries !== 0) ||
      filtersReducer.isDateLoading
    ) return;

    let additionalFilters;


    if(typeof indexSpecificTool === "number" && typeof indexSpecificToolItem === "number"){
      let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
      let currentFilter = currentHeaderEntry["dropdown-tools"].find((d,i) => i === indexSpecificTool)
      if(
        currentFilter.tools && currentFilter.tools[0].items[indexSpecificToolItem].filters &&
        !currentFilter.tools[0].items[indexSpecificToolItem].inspiringContentsNotSpecific
      ){
        additionalFilters = currentFilter.tools[0].items[indexSpecificToolItem].filters
      }
    }

    const requestUrl = API_URL + 'dataviz/filter';

    let config = {
      headers: {
        Authorization : localStorage.getItem('token')
      }
    }

    let selectedKeywords = [];
    let selectedExcludeKeywords = [];
    filtersReducer.selectedKeywords.forEach((d)=>{
      if(d.exclude){
        selectedExcludeKeywords.push({ code : d.code, id : d.id})
      }else{
        selectedKeywords.push({ code : d.code, id : d.id})
      }
    })

    let excludesKeywords = filtersReducer.defaultExcludedFilters.map((d)=>({ code : d.code, id : d.id}))
    let filters = [
      ...filtersReducer.selectedHeaderEntries,
      ...filtersReducer.defaultFilters,
      ...selectedKeywords
    ]

    if(additionalFilters){
      filters = [ ...filters, ...additionalFilters]
    }

    let obj = {
      plateformeID : filtersReducer.plateformeID,
      filters : filters,
      excludes : [...excludesKeywords, ...selectedExcludeKeywords],
      lang : viewReducer.lang,
      start : filtersReducer.date.min.date,
      end : filtersReducer.date.max.date,
      search : filtersReducer.search
    }

    setIsFiltersLoading(true);
    axios.post(requestUrl, obj, config)
      .then((response) => {

        // let count = 0
        // response.data.filters.find(d => d.id === "FILTER_NBM2").valeurs.forEach(d => count = count + parseInt(d.frequence))
        // console.log(count);
        setAllFilters(response.data.filters)
        setIsFiltersLoading(false);

      })
      .catch((error) => {
        setIsFiltersLoading(false);
      });

  },[
    filtersJSON,
    indexSpecificTool,
    indexSpecificToolItem,
    filtersReducer.indexSelectedEntries,
    filtersReducer.date.max.date,
    filtersReducer.date.min.date,
    filtersReducer.selectedKeywords,
    filtersReducer.search,
    filtersReducer.isDateLoading
  ])

  /// HANDLE PARAMS SEARCH IN URL
  useEffect(()=>{
    let parseSearch;
    if(history.location.search){
      parseSearch = queryString.parse(history.location.search)
    }else{
      setIndexSpecificTool(null)
      setIndexSpecificToolItem(null)
      return;
    }

    let indexSpecificTool = parseInt(parseSearch['specific-tool'])
    let indexSpecificToolItem = parseInt(parseSearch['item'])

    setIndexSpecificTool(!isNaN(indexSpecificTool) ? indexSpecificTool : null)
    setIndexSpecificToolItem(!isNaN(indexSpecificToolItem) ? indexSpecificToolItem : null)


  },[history.location.search])

  function getFilterId(){
    let splitLocation = history.location.pathname.split("/");
    let filterId = splitLocation[splitLocation.length-1]
    return filterId
  }

  function getEndpoint(){
    if( getFilterId () === "FILTER_KTC") return 'dataviz/ktc'
    else if( getFilterId () === "FILTER_CMG") return 'dataviz/cmg'
  }

  return (
    <>
      { (!allFilters && isFiltersLoading) && 
        <div className={props.filterIsOpen ? "analysis-tools-overlay-loading filters-opened" : "analysis-tools-overlay-loading"}>
          <img src={loader} alt="loading" />
        </div>
      }
      <div className={props.filterIsOpen ? "analysis-tools filters-opened" : "analysis-tools"} style={{height : contentDimension.height}}>
        { allFilters &&
          <Switch>
            <Route exact path={`/search-innov/${props.match.params.id}/analysis-tools`} render={() =>
              <AnalysisTools
                history={history}
                isFiltersLoading={isFiltersLoading}
                allFilters={allFilters}
                toggleDocument={props.toggleDocument}
                match={props.match}
              />
            }/>
            <Route exact path={`/search-innov/${props.match.params.id}/analysis-tools/(FILTER_KTC|FILTER_CMG)`} render={() =>
              <>
                <div className={viewReducer.lightmode === "light" ? "container-search light" : "container-search"}>
                  <div className="container-left">
                    {!props.hideHeaderButtons && 
                      <ButtonFilters
                        toggleFilter={props.toggleFilter}
                      />
                    }
                    <SearchByKeywords
                      testId={"global"}
                      className={"header"}
                      iconPosition={"right"}
                      isMulti={true}
                      isClearable={true}
                      placeholder={"Filter par mots clés"}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                    />
                  </div>
                  <div className="container-right">
                    <ExplorationNav2 history={props.history} match={props.match} />
                    { !props.hideHeaderButtons &&
                      <ButtonDocs
                        documentsIsOpen={props.documentsIsOpen}
                        toggleDocument={props.toggleDocument}
                      />
                    }
                  </div>
                </div>
                <OptTools
                  endpoint={getEndpoint()}
                  history={history}
                  allFilters={allFilters}
                  toggleDocument={props.toggleDocument}
                  filterIsOpen={props.filterIsOpen}
                  match={props.match}
                />
              </>

            }/>
            <Route exact path={`/search-innov/${props.match.params.id}/analysis-tools/:filter_id`} render={() =>
              <>
                <div className={viewReducer.lightmode === "light" ? "container-search light" : "container-search"}>
                  <div className="container-left">
                    {(!props.filterIsOpen && !props.hideHeaderButtons) && <ButtonFilters
                      toggleFilter={props.toggleFilter}
                    />}
                    <SearchByKeywords
                      testId={"global"}
                      className={"header"}
                      iconPosition={"right"}
                      isMulti={true}
                      isClearable={true}
                      placeholder={"Filter par mots clés"}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                    />
                  </div>
                  <div className="container-right">
                    <ExplorationNav2 history={props.history} match={props.match} />
                    { !props.hideHeaderButtons &&
                      <ButtonDocs
                        documentsIsOpen={props.documentsIsOpen}
                        toggleDocument={props.toggleDocument}
                      />
                    }
                  </div>
                </div>
                <Tools
                  history={history}
                  allFilters={allFilters}
                  toggleDocument={props.toggleDocument}
                  filterIsOpen={props.filterIsOpen}
                  match={props.match}
                />
              </>
            }/>
          </Switch>
        }
      </div>
    </>
  )
}

export default AnalysisToolsSwitch;
