import React from 'react';
import style from './burger.module.scss'
import cs from 'classnames'

export default function BurgerButton({open, onClick}){

  return (
    <div className={!open ? style.burger : `${style.burger} ${style.open}`} onClick={onClick}>
      <div></div>
      <div className={style.middle}></div>
      <div></div>
      <div className={!open ? style.cross : `${style.cross} ${style.crossopen}`}></div>
      <div className={!open ? style.cross : `${style.cross} ${style.crossopen}`}></div>
    </div>
  )
}
