import React from 'react';

export default function IconExport({color}){
	return(
    <svg width="14px" height="15px" viewBox="0 0 14 15" >
        <g id="v3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-1125.000000, -137.000000)" fill={color} fillRule="nonzero">
                <path d="M1137.238,151.45 C1137.646,151.45 1138,151.3 1138.3,151 C1138.6,150.7 1138.75,150.346 1138.75,149.938 L1138.75,149.938 L1138.75,140.578 C1138.75,140.41 1138.717,140.236 1138.651,140.056 C1138.585,139.876 1138.504,139.726 1138.408,139.606 L1138.408,139.606 L1137.382,138.364 C1137.13,138.088 1136.836,137.95 1136.5,137.95 L1136.5,137.95 L1127.5,137.95 C1127.332,137.95 1127.17,137.989 1127.014,138.067 C1126.858,138.145 1126.726,138.244 1126.618,138.364 L1126.618,138.364 L1125.61,139.606 C1125.502,139.726 1125.415,139.876 1125.349,140.056 C1125.283,140.236 1125.25,140.41 1125.25,140.578 L1125.25,140.578 L1125.25,149.938 C1125.25,150.346 1125.397,150.7 1125.691,151 C1125.985,151.3 1126.342,151.45 1126.762,151.45 L1126.762,151.45 L1137.238,151.45 Z M1137.166,139.462 L1126.834,139.462 L1127.464,138.688 L1136.464,138.688 L1137.166,139.462 Z M1133.512,148.462 L1130.488,148.462 L1130.488,146.95 L1127.896,146.95 L1132,142.828 L1136.122,146.95 L1133.512,146.95 L1133.512,148.462 Z" id=""></path>
            </g>
        </g>
    </svg>
	)
}
