import React from 'react';
import { Translate, withLocalize } from "react-localize-redux";
import loaderDisabled from '../../../assets/images/loader-disabled.gif'
import { useGlobalContext } from '../../../GlobalContext'
import styles from './loader-view.module.scss'

const LoaderView = ({noText = false}) => {
  const content  = useGlobalContext()[0];
  const viewReducer = content.viewReducer

  return (
    <div className={viewReducer.lightmode === "light" ? `container-loader light ${styles["container-loader"]} ${styles["light"]}` : `container-loader ${styles["container-loader"]}`}>
      <img className="loader" src={loaderDisabled} alt="loader"/>
      {!noText && <p><Translate id="global.loading"/></p>}
    </div>
  )
}

export default withLocalize(LoaderView)
