import React, { useState, useEffect } from 'react';
import { Translate, withLocalize } from "react-localize-redux";
import axios from 'axios';
import queryString from 'query-string'
import { API_URL } from '../../../actions'
import SliderImage from "../article/sliderImage/SliderImage"
import { renderToStaticMarkup } from "react-dom/server";

import { useGlobalContext } from '../../../GlobalContext'
import usePlateforme from '../../../customHooks/usePlateforme'

import { renderDate }  from '../../../helpers/helpers'

import styles from './document-offline.module.scss'

/// TRADUCTION
import globalTranslations from "../../../assets/translations/global.json";
import documentsTranslations from "../../../assets/translations/documents.json";

const DocumentOffline = ({history, initialize, setActiveLanguage}) => {
  const [ doc, setDoc ] = useState()
  const [ contact, setContact] = useState()
  const dispatch = useGlobalContext()[1];
  const filtersJSON = usePlateforme()

  useEffect(()=>{

    initialize({
      languages: [
        { name: "EN", code: "en" },
        { name: "FR", code: "fr" }
      ],
      translation: {
        ...globalTranslations,
        ...documentsTranslations
      },
      options: { renderToStaticMarkup }
    });

    let lang = localStorage.getItem("lang")
    if(lang){
      setActiveLanguage(lang)
      dispatch({
        type : "setLang",
        payload : lang
      })
    }else{
      localStorage.setItem("lang",'en')
      setActiveLanguage('en')
    }


  },[])

  useEffect(()=>{
    let search = history.location.search
    let parseSearch = queryString.parse(history.location.search)
    let entry = parseInt(parseSearch.entry)
    let platform = parseInt(parseSearch.platform)

    if(!parseSearch ||
      isNaN(entry) ||
      !parseSearch.id ||
      !parseSearch.doc ||
      isNaN(platform)
    ) return;

    let token = localStorage.getItem("token")
    let plateforme = localStorage.getItem("plateforme")
    let plateformes = localStorage.getItem("plateformes")
    let user = localStorage.getItem("user")
    let plateformeID = localStorage.getItem("plateformeID")

    if(
      token &&
      plateforme &&
      plateformes &&
      user &&
      plateformeID
    ){
      let url = `/search-innov/${platform}/strategic-watch?entry=${parseSearch.entry}&id=${parseSearch.id}&doc=${parseSearch.doc}`
      history.push(url)
    }else{

      const requestUrl = API_URL + "/doc/offline";
      let headers = {}
      axios.post(requestUrl, {
       id : `${parseSearch.id}/${parseSearch.doc}`
      }, headers)
      .then((response) => {
        setDoc(response.data.result)
      })
      .catch((error) => {
        console.log(error);
      });

      let filtersJSON;
      try{
        filtersJSON = require(`../../../config/filters-${platform}.json`);
      }catch{
        filtersJSON = require(`../../../config/${platform}`).default;
      }

      if(filtersJSON.contact) setContact(filtersJSON.contact)

    }

  },[])

  function renderTexte (texte){
    let re = /style="(.*?)"/g
    let reHTML = /<[a-z][\s\S]*>/i

    // is HTML content, reHTML.test(texte) ?
    if(!reHTML.test(texte)){
      let texteContent = texte.replace('.','.<br/>')
      texteContent = `<p>${texteContent}</p>`
      return texteContent
    }else{
      let textWithoutStyle = texte.replace(re,"")
      return textWithoutStyle
    }

  }

  return (
    <div className={styles["document-offline"]}>
      {doc &&
        <div className={`${styles["container-document"]} container-document`}>
          <h1>
            <span dangerouslySetInnerHTML={{__html: doc.TITLE}}></span>
          </h1>
          { doc.ABSTRACT &&
            <span className={styles["abstract"]} dangerouslySetInnerHTML={{ __html: renderTexte(doc.ABSTRACT)}}></span>
          }
          <div className={styles["article-info"]}>
            <span>
              <p className={styles["date"]}>{renderDate(doc.PUB_DATE, "en")}</p>
            </span>
          </div>
          { doc.KEY_LEARNING &&
            <fieldset className={styles["take-away"]}>
              <legend>Take away</legend>
              <span dangerouslySetInnerHTML={{ __html: renderTexte(doc.KEY_LEARNING)}} />
            </fieldset>
          }
          <SliderImage doc={doc} offline/>
          { doc.TEXTE &&
            <div className={styles["detail-fact"]}>
              <span dangerouslySetInnerHTML={{ __html: renderTexte(doc.TEXTE) }} ></span>
            </div>
          }
          { contact && <div className={styles["contact"]}><p><Translate id="global.contact"/>&nbsp;: {contact}</p></div>}

        </div>
      }
    </div>
  )
}

export default withLocalize(DocumentOffline)
