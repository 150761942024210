import { postData } from './index'

import {
  GET_FILTERS,
  GET_DATES,
  TOGGLE_FILTER_BY_DATE,
  GET_NODES_DATES_FILTERED,
  ADD_SELECTED_KEYWORDS,
  REMOVE_SELECTED_KEYWORDS,
  REMOVE_SELECTED_KEYWORDS_BY_CAT,
  REMOVE_ALL_SELECTED_KEYWORDS,
  SET_PLATEFORME_ID,
  SET_FILTER_SEARCH,
  SET_FILTER_SEARCH_TYPES,
  SET_FILTER_RELATIONSHIPS,
  SET_FILTER_DATE,
  SET_INPUT_RANGE,
  SET_FILTER_VIEW,
  ERROR_FIlTERS,
  // LOADING_DATES,
  LOADING_FILTERS,
  LOADING_DATES
} from './types'

export async function getFilters(dispatch, obj, origine){
  let url = 'dataviz/filter'
  dispatch({type : LOADING_FILTERS})
  await postData(GET_FILTERS, ERROR_FIlTERS, true, url , dispatch, obj ,origine, obj.plateformeID)
}

export async function toggleFilterByDate(dispatch, bool){
  dispatch({type : TOGGLE_FILTER_BY_DATE, payload : bool})
}

export async function getDates(dispatch, plateformeID,filters, excludedFilters = null){
  // console.log(GET_DATES);
  let url = 'dataviz/date'
  dispatch({type : LOADING_DATES})
  let obj = {plateformeID : plateformeID}
  if(filters) {
    obj.filters = filters
  }

  if(excludedFilters){
    obj.excludes = excludedFilters
  }

  await postData(GET_DATES, ERROR_FIlTERS, true, url , dispatch, obj)
}

export async function getDatesNodesFiltered(dispatch, obj){
  let url = 'dataviz/date'
  await postData(GET_NODES_DATES_FILTERED, ERROR_FIlTERS, true, url , dispatch, obj)
}

export async function setPlateformeId(dispatch, id){
  dispatch({type : SET_PLATEFORME_ID, payload : id })
}

export async function setSearch(dispatch, obj){
  dispatch({type : SET_FILTER_SEARCH, payload : obj })
}

export async function setFilterSearchTypes(dispatch, type){
  dispatch({type : SET_FILTER_SEARCH_TYPES, payload : type})
}

export async function setFilterView(dispatch, obj){
  dispatch({type : SET_FILTER_VIEW, payload : obj })
}

export function addSelectedKeywords(dispatch,obj){
  dispatch({type : ADD_SELECTED_KEYWORDS, payload : obj })
}

export function removeSelectedKeyword(dispatch,obj){
  dispatch({type : REMOVE_SELECTED_KEYWORDS, payload : obj })
}

export function removeSelectedKeywordsByCat(dispatch,id){
  dispatch({type : REMOVE_SELECTED_KEYWORDS_BY_CAT, payload : id })
}

export function removeAllSelectedKeywords(dispatch){
  dispatch({type : REMOVE_ALL_SELECTED_KEYWORDS})
}

export function setInputRange(dispatch,obj){
  dispatch({type : SET_INPUT_RANGE, payload : obj })
}

export function setFilterDate(dispatch,obj){
  dispatch({type : SET_FILTER_DATE, payload : obj })
}

export function setFilterRelationships(dispatch,obj){
  dispatch({type : SET_FILTER_RELATIONSHIPS, payload : obj })
}
