import React, { useState, useEffect, useCallback } from "react"
import { withLocalize } from "react-localize-redux";
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import * as d3 from 'd3'
import { Scrollbars } from 'react-custom-scrollbars';
// import ImgItem from '../../partials/ImgItem'
import { clusteriser }  from '../../../helpers/helpers'

import SearchByKeywords from '../../partials/SearchByKeywords'
import LoaderView from '../../partials/LoaderView'
import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'
import ExplorationNav2 from '../../partials/ExplorationNav2'

import { useGlobalContext } from '../../../GlobalContext'
import useContentDimension from '../../../customHooks/useContentDimension'
import useFilterView from '../../../customHooks/useFilterView'
import useGetNodes from '../../../customHooks/useGetNodes'

import { filtersParams } from '../../../helpers/filtersParams'
import { getTimelines } from '../../../actions/dataviz'

import Graduation from './Graduation'
import MarkerTimeline from './MarkerTimeline'

const Timeline = (props) =>{
  const [ context , dispatch ] = useGlobalContext();
  const contentDimension = useContentDimension();
  const filtersReducer = context.filtersReducer
  const datavizReducer = context.datavizReducer
  const documentsReducer = context.documentsReducer
  const viewReducer = context.viewReducer

  const [ init, setInit ] = useState(true)
  const [ data, setData ] = useState(null)
  const [ widthTimeline ] = useState(20000)
  const [ timestampMin , setTimestampMin] = useState()
  const [ timestampMax , setTimestampMax] = useState()


  const [ clusterPeriod, setClusterPeriod ] = useState()

  const [ range, setRange ] = useState()

 const defaultRubrique = props.entries

  useFilterView(defaultRubrique)
  useGetNodes(defaultRubrique)

  const _getTimelines = (filter) => getTimelines(dispatch, filter)

  const measuredRef = useCallback(node => {
    if (node !== null) {
      node.scrollToRight(0)
    }
  }, []);

  useEffect(()=>{


    if(filtersReducer.date.min.date  &&
      filtersReducer.date.max.date &&
      documentsReducer.countDocs
    ){

      let result = filtersParams(filtersReducer,viewReducer.lang)
      let filters = _.cloneDeep(result.filters);
      filters.page = 1
      filters.number = null

      let isRUBSelected = filters.filters.find((d)=> d.id === "RUBRIQUE") ? true : false

      if(isRUBSelected){
        filters.filters = [ ...filters.filters]
      }else{
        let obj = {}
        defaultRubrique.forEach((d)=>{
          if(!obj[d.id]){
            obj[d.id] = { ...d }
          }else{
            obj[d.id].code += "%20OR%20"+d.code
          }
        })
        filters.filters.push(Object.values(obj)[0])
      }

      filters.number = parseInt(documentsReducer.countDocs)
      _getTimelines(filters)

    }

  },[
    defaultRubrique,
    viewReducer.lang,
    filtersReducer.selectedKeywords,
    documentsReducer.countDocs,
    filtersReducer.search,
    filtersReducer.filteredByDate,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date])


  useEffect(()=>{

    if(!datavizReducer.timelines) return


    if(datavizReducer.timelines.length === 0){
      setData([])
      return
    }

    let docs = _.cloneDeep(datavizReducer.timelines);
    let data = []
    docs.sort(function(a, b) {
      a = new Date(a.PUB_DATE);
      b = new Date(b.PUB_DATE);
      return a>b ? -1 : a<b ? 1 : 0;
    });


    ////// SET ARR OF DATA
    // let monthInSec = 2628000
    let weekInSec = 604800
    let dayInSec = 3600 * 24

    let firstPosition = 2;
    let arrPosition = [ 0, 1, 2, 3, 4, 5, 6]
    let docsPosition = []

    let timestampMax = new Date(docs[0].PUB_DATE);
    let timestampMin = new Date(docs[docs.length-1].PUB_DATE);

    // DO NOT MOVE THIS SET STATE //
    setTimestampMin(timestampMin)
    setTimestampMax(timestampMax)
    ///////////////////////////////

    timestampMin =  new Date(timestampMin.getTime() - (((timestampMin.getDay()-1) * (dayInSec*1000))))
    timestampMin = timestampMin.getTime()/1000
    timestampMax = timestampMax.getTime()/1000

    let scaleOffsetX = d3.scaleLinear()
      .domain([timestampMin,timestampMax])
      .range([0,widthTimeline-230]);

    docs.forEach((d,i)=>{
      let obj = {}
      let found = filtersReducer.matchCode.find((match)=> match.code === d.RUBRIQUE[0])
      if(found) obj.rubrique = found.label
      obj.title = d.TITLE[0]
      obj.image = d.IMAGE
      obj.date_pub = d.PUB_DATE
      obj.ID_QES = d.ID_QES
      ///// CALCUL INDEX LINE

      if(i === 0){
        docsPosition.push(firstPosition)
        d.lineIndex = 2
      }else{

        let prevPosition = docsPosition[i-1]
        let arrPositionEmpty = []

        if(prevPosition-1 >= 0){
          arrPositionEmpty.push(prevPosition-1)
        }
        arrPositionEmpty.push(prevPosition)
        if(prevPosition+1 <= 6){
          arrPositionEmpty.push(prevPosition+1)
        }

        let possibilityPosition = _.difference(arrPosition,arrPositionEmpty)
        let x = ~~(Math.random() * possibilityPosition.length);
        docsPosition.push(possibilityPosition[x])


        let date = new Date(d.PUB_DATE);
        obj.offsetX = scaleOffsetX(date.getTime()/1000)
        obj.lineIndex = possibilityPosition[x]

      }

      //////////////////

      data.push(obj)

    })


    ///////////////
    let clusterPeriod = ''
    let totalWeeks = (timestampMax - timestampMin)/weekInSec

    if(Math.ceil(totalWeeks) <= 52/4){
      clusterPeriod = 'days'
      setRange(props.translate("timeline.groupByDay"))
    }else if(Math.ceil(totalWeeks) > 52/4 && Math.ceil(totalWeeks) <= 52/2){
      clusterPeriod = 'per4Weeks'
      setRange(props.translate("timeline.groupByQuarterWeek"))
    }else if(Math.ceil(totalWeeks) > 52/2 && Math.ceil(totalWeeks) <= 52){
      clusterPeriod = 'halfWeeks'
      setRange(props.translate("timeline.groupByHalfWeek"))
    }else if(Math.ceil(totalWeeks) > 52 && Math.ceil(totalWeeks) <= 52*3){
      clusterPeriod = 'weeks'
      setRange(props.translate("timeline.groupByWeek"))
    }else if(Math.ceil(totalWeeks) > 52*3 && Math.ceil(totalWeeks) <= 52*4){
      clusterPeriod = '2weeks'
      setRange(props.translate("timeline.groupByTwoWeeks"))
    }else{
      clusterPeriod = 'month'
      setRange(props.translate("timeline.groupByMonth"))
    }

    data = clusteriser(data,scaleOffsetX,clusterPeriod)
    delete data.length

    setClusterPeriod(clusterPeriod)
    setData(Object.values(data))
    setInit(false)

  },[
    props.activeLanguage.code,
    datavizReducer.timelines
  ])


  function renderThumb({ style, ...props }) {
    const thumbStyle = {
        backgroundColor: viewReducer.lightmode === "light" ? "#151845" : "white",
        borderRadius : "3px",
        cursor : "pointer"
    };
    return (
        <div
          style={{ ...style, ...thumbStyle }}
          {...props}
        />
    );
  }

  function renderTrackHorizontal({ style, ...props }) {

    const trackStyle = {
      zIndex: 2,
      right : "2px",
      bottom : ((contentDimension.height-(contentDimension.height/1.2))/2)-30+"px",
      left : "2px",
      borderRadius: "3px",
      backgroundColor: viewReducer.lightmode === "light" ? "#D0D1DA" : "#6E708C",
    };
    return (
        <div
          style={{ ...style, ...trackStyle }}
          {...props}
        />
    );
  }

  return(
    <>
      <div className={props.filterIsOpen ? "timeline filters-opened" : "timeline"}>
       <div className={viewReducer.lightmode === "light" ? "container-search light" : "container-search"}>
          <div className="container-left">
            { (!props.hideHeaderButtons && !props.filterIsOpen) &&
              <ButtonFilters
                toggleFilter={props.toggleFilter}
              />
            }
            <SearchByKeywords
              testId={"global"}
              className={"header"}
              iconPosition={"right"}
              isMulti={true}
              isClearable={true}
              placeholder={"Filter par mots clés"}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
            />
          </div>
          <div className="container-right">
            <ExplorationNav2 history={props.history} match={props.match} />
            { (!props.hideHeaderButtons && (data && data.length > 0)) &&
              <ButtonDocs
                documentsIsOpen={props.documentsIsOpen}
                toggleDocument={props.toggleDocument}
              />
            }
          </div>
        </div>
        <div>
          { data && data.length>0 ?

            <div className={datavizReducer.isLoading ?  "container-scroll loading" : "container-scroll"}>
              <div className="gradient-left"></div>
              <div className="gradient-right"></div>
              <Scrollbars ref={measuredRef} style={{ width: contentDimension.width - 60, height: contentDimension.height/1.15 }}
                renderThumbHorizontal={renderThumb}
                renderTrackHorizontal={renderTrackHorizontal}
              >
              <div  className="container-timeline"
                style={{
                  width : "20000px" ,
                  height : contentDimension.height/1.35+"px",
               }}>

                <MarkerTimeline data={data} viewReducer={viewReducer} toggleDocument={props.toggleDocument} />
                {datavizReducer.timelines && datavizReducer.timelines.length > 0 ?
                  <Graduation
                    clusterPeriod={clusterPeriod}
                    timestampMin={timestampMin}
                    timestampMax={timestampMax}
                  /> : null
                }
              </div>
              </Scrollbars>
            </div>
            : null
          }
          { (data && datavizReducer.isLoading) &&
            <div className="loader-dataviz" style={{ width: contentDimension.width/1.5, height: contentDimension.height/1.3 }}>
              <LoaderView/>
            </div>
          }

          { (!data && init) &&
            <div className="loader-dataviz" style={{ left: 0, width: "100%", height: contentDimension.height/1.3 }}>
              <LoaderView/>
            </div>
          }

          { range ?
            <>
            <div className="help"><p data-tip data-for='helpRange'>?</p></div>
            <ReactTooltip className="react-tooltip white" delayShow={500} id='helpRange' place="left" effect='solid'>
              <span>{range}</span>
            </ReactTooltip>
            </> : null
          }

        </div>
      </div>
    </>
  )

}

export default withLocalize(Timeline)
