export const groupStyles = {
 display: 'flex',
 alignItems: 'center',
 justifyContent: 'space-between',
};
export const groupBadgeStyles = {
 backgroundColor: '#EBECF0',
 borderRadius: '2em',
 color: '#172B4D',
 display: 'inline-block',
 fontSize: 12,
 fontWeight: 'normal',
 lineHeight: '1',
 minWidth: 1,
 padding: '0.16666666666667em 0.5em',
 textAlign: 'center',
};

export const customStylesRelationships = {
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "hidden",
    whiteSpace: "nowrap",
    direction: 'ltr',
    justifyContent : 'flex-start',
    padding: '2px'
  }),
  input: (provided, state) => ({
    ...provided,
    display: "inline-block"
  }),
  multiValueLabel: (provided, state) => {
    return ({
      color : state.data.color,
      padding : '3px',
      maxWidth: '100%',
      whiteSpace : 'initial'
    })
  },
  multiValue: (provided, state) => {
    return ({
      ...provided,
      color : state.data.color,
      flexBasis: 'max-content',
      minWidth: 'auto',
      maxWidth: '100%',
      backgroundColor: 'rgba(0,0,0,0)',
    })
  },
  multiValueRemove: (provided, state) =>({
    ...provided,
    cursor:'pointer',
    '&:hover': {
      color : "#FF7D67",
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  control : (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius : "8px",
    boxShadow: 0,
    backgroundColor : 'rgba(0,0,0,0)',
    '&:hover': {
      border: 0
    }
  }),
  indicatorsContainer : (provided, state) => ({
    ...provided,
    backgroundColor : "#FF7D67",
    borderRadius : "8px",
    cursor : 'pointer',
    alignSelf: 'start',
    boxShadow : '0 3px 15px 0 rgba(255,125,103,.5)'
  }),
  dropdownIndicator : (provided, state) => ({
    ...provided,
    color:"#964335",
    '&:hover': {
      color : 'white'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    display:'none'
  }),
  option : (provided, state) => ({
    ...provided,
    color : '#151845',
    cursor: 'pointer'
  }),
}

export const customStyles = {
  placeholder: (provided, state) =>({
    ...provided,
    color : "#b8b8b8",
    fontStyle: "italic",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "auto",
    whiteSpace: "nowrap",
    flexWrap : 'nowrap'
  }),
  input: (provided, state) => ({
    ...provided,
    display: "inline-block"
  }),
  multiValueLabel: (provided, state) => ({
    color : 'white',
    padding : '3px'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color : 'white',
    flexBasis: 'max-content',
    minWidth: 'auto',
    backgroundColor: 'purple'
  }),
  multiValueRemove: (provided, state) =>({
    ...provided,
    cursor:'pointer',
    '&:hover': {
      color : 'white',
      backgroundColor: '#7073AD'
    }
  }),
  control : (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius : "8px",
    boxShadow: 0,
    '&:hover': {
      border: 0
    }
  }),
  indicatorsContainer : (provided, state) => ({
    ...provided,
    backgroundColor : "#FF7D67",
    borderRadius : "8px",
    cursor : 'pointer',
    boxShadow : '0 3px 15px 0 rgba(255,125,103,.5)'
  }),
  dropdownIndicator : (provided, state) => ({
    ...provided,
    color:"#964335",
    '&:hover': {
      color : 'white'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    display:'none'
  }),
  option : (provided, state) => ({
    ...provided,
    color : '#151845',
    cursor: 'pointer'
  }),
  menu: (provided, state) => ({
    zIndex: 1000,
    backgroundColor: 'red'
  })
}

//HEADER
export const customStylesGlobalHeader = {
  placeholder: (provided, state)=>({
    ...provided,
    color : "#b8b8b8",
    fontStyle: "italic",
    padding: "0px"
  }),
  dropdownIndicator: (provided, state)=>({
    ...provided,
    cursor: "pointer"
  }),
  menuList: (provided, state)=>({
    ...provided,
    padding: "0px"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "hidden",
    whiteSpace: "nowrap",
    flexWrap : 'nowrap',
    direction: 'rtl',
    justifyContent : 'flex-end',
    padding: '2px',
  }),
  input: (provided, state) => ({
    ...provided,
    display: "inline-block",
    direction: 'ltr'
  }),
  multiValueLabel: (provided, state) => ({
    color : 'white',
    padding : '3px 5px',
    direction: 'ltr',
    textOverflow: "ellipsis",
    whiteWpace: "nowrap",
    overflow: "hidden"
  }),
  multiValue: (provided, state) => {
    let color;
    let colorHover;

    if(state.data.fulltext || state.data.year){
      color = '#9bb754'
      colorHover = '#b7d175'
    }else if(state.data.exclude){
      color = '#FF7D67'
      colorHover = 'darken(#FF7D67,7%)'
    }else if(/%20OR%20/.test(state.data.code)){
      color = '#AA7FD5'
      colorHover = 'darken(#AA7FD5,7%)'
    }else if(state.data.id === "RUBRIQUE"){
      color = '#f6ce3c'
      colorHover = 'darken(#f6ce3c,10%)'
    }else{
      color = '#B6BDFA'
      colorHover = '#4E529D'
    }

    return({
      ...provided,
      position : 'relative',
      padding : "2px 17px 2px 5px",
      color : 'white',
      flexBasis: 'max-content',
      minWidth: 'auto',
      maxWidth: "97%",
      direction: 'ltr',
      borderRadius: "13px",
      backgroundColor: state.isSelected ? '' : color,
      '&:hover': {
        color : 'white',
        backgroundColor: colorHover
      }
    })
  },
  multiValueRemove: (provided, state) =>({
    ...provided,
    cursor:'pointer',
    position : 'absolute',
    paddingRight: "10px",
    width: "100%",
    top: "0",
    bottom : "0",
    justifyContent : "flex-end",
    '&:hover': {
      color : 'white',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  control : (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius : "8px 8px 0 0",
    boxShadow: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    '&:hover': {
      border: 0
    }
  }),
  option : (provided, state) => {
    return ({
      ...provided,
      color : state.data.__isNew__ ? '#FF7D67' : '#151845',
      cursor: 'pointer',
      textAlign: "left",
      backgroundColor:"white"
    })
  }
}

export const customStylesGlobalExpandedHeader = {
  menu : (provided, state) =>({
    ...provided,
    top: "calc(100% + 4px)"
  }),
  menuList: (provided, state)=>({
    ...provided,
    padding: "0px"
  }),
  placeholder: (provided, state)=>({
    ...provided,
    color : "#b8b8b8",
    fontStyle: "italic",
    padding: "0px"
  }),
  dropdownIndicator: (provided, state)=>({
    ...provided,
    cursor: "pointer"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "hidden",
    direction: 'ltr',
    justifyContent : 'flex-start',
    padding: '2px'
  }),
  input: (provided, state) => ({
    ...provided,
    display: "inline-block",
    direction: 'ltr',
  }),
  multiValueLabel: (provided, state) => ({
    color : 'white',
    padding : '3px 5px',
    direction: 'ltr',
    textOverflow: "ellipsis",
    overflow: "hidden",
  }),
  multiValue: (provided, state) => {
    let color;
    let colorHover;
    if(state.data.fulltext || state.data.year){
      color = '#9bb754'
      colorHover = '#b7d175'
    }else if(state.data.exclude){
      color = '#FF7D67'
      colorHover = 'darken(#FF7D67,7%)'
    }else if(/%20OR%20/.test(state.data.code)){
      color = '#AA7FD5'
      colorHover = 'darken(#AA7FD5,7%)'
    }else if(state.data.id === "RUBRIQUE"){
      color = '#f6ce3c'
      colorHover = 'darken(#f6ce3c,10%)'
    }else{
      color = '#B6BDFA'
      colorHover = '#4E529D'
    }

    return ({
      ...provided,
      position : 'relative',
      padding : "2px 17px 2px 5px",
      color : 'white',
      flexBasis: 'max-content',
      minWidth: 'auto',
      maxWidth: "100%",
      direction: 'ltr',
      borderRadius: "13px",
      backgroundColor: state.isSelected ? '' : color,
      '&:hover': {
        color : 'white',
        backgroundColor: colorHover
      }
    })
  },
  multiValueRemove: (provided, state) =>({
    ...provided,
    cursor:'pointer',
    position : 'absolute',
    paddingRight: "10px",
    width: "100%",
    top: "0",
    bottom : "0",
    justifyContent : "flex-end",
    '&:hover': {
      color : 'white',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  control : (provided, state) => ({
    ...provided,
    border: 0,
    boxShadow: 0,
    borderRadius : "8px 8px 0 0",
    backgroundColor: 'rgba(0,0,0,0)',
    '&:hover': {
      border: 0
    }
  }),
  option : (provided, state) => {
    return ({
      ...provided,
      color : state.data.__isNew__ ? '#FF7D67' : '#151845',
      cursor: 'pointer',
      textAlign: "left",
      backgroundColor:"white"
    })
  }
}

///////

export const customStylesGlobal = {
  placeholder: (provided, state) =>({
    ...provided,
    color : "#b8b8b8",
    fontStyle: "italic",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "hidden",
    whiteSpace: "nowrap",
    flexWrap : 'nowrap',
    direction: 'rtl',
    justifyContent : 'flex-end',
    padding: '2px',
  }),
  input: (provided, state) => ({
    ...provided,
    display: "inline-block",
    direction: 'ltr',
  }),
  multiValueLabel: (provided, state) => ({
    color : 'white',
    padding : '3px 5px',
    direction: 'ltr',
  }),
  multiValue: (provided, state) => {
    let color;
    let colorHover;
    if(state.data.fulltext || state.data.year){
      color = '#9bb754'
      colorHover = '#b7d175'
    }else if(state.data.exclude){
      color = '#FF7D67'
      colorHover = 'darken(#FF7D67,7%)'
    }else if(/%20OR%20/.test(state.data.code)){
      color = '#AA7FD5'
      colorHover = 'darken(#AA7FD5,7%)'
    }else{
      color = '#B6BDFA'
      colorHover = '#4E529D'
    }

    return({
      ...provided,
      position : 'relative',
      paddingRight : "14px",
      color : 'white',
      flexBasis: 'max-content',
      minWidth: 'auto',
      direction: 'ltr',
      backgroundColor: state.isSelected ? '' : color,
      '&:hover': {
        color : 'white',
        backgroundColor: colorHover
      }
    })
  },
  multiValueRemove: (provided, state) =>({
    ...provided,
    cursor:'pointer',
    position : 'absolute',
    width: "100%",
    top: "0",
    bottom : "0",
    justifyContent : "flex-end",
    '&:hover': {
      color : 'white',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  control : (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius : "4px 4px 0 0",
    boxShadow: 0,
    '&:hover': {
      border: 0
    }
  }),
  option : (provided, state) => ({
    ...provided,
    color : '#151845',
    cursor: 'pointer'
  }),
}

export const customStylesGlobalExpanded = {
  placeholder: (provided, state) =>({
    ...provided,
    color : "#b8b8b8",
    fontStyle: "italic",
  }),
  menu : (provided, state) =>({
    ...provided,
    top: "calc(100% + 4px)"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "hidden",
    direction: 'ltr',
    justifyContent : 'flex-start',
    padding: '2px'
  }),
  input: (provided, state) => ({
    ...provided,
    display: "inline-block",
    direction: 'ltr',
  }),
  multiValueLabel: (provided, state) => ({
    color : 'white',
    padding : '3px 5px',
    direction: 'ltr',
  }),
  multiValue: (provided, state) => {
    let color;
    let colorHover;
    if(state.data.fulltext || state.data.year){
      color = '#9bb754'
      colorHover = '#b7d175'
    }else if(state.data.exclude){
      color = '#FF7D67'
      colorHover = 'darken(#FF7D67,7%)'
    }else if(/%20OR%20/.test(state.data.code)){
      color = '#AA7FD5'
      colorHover = 'darken(#AA7FD5,7%)'
    }else{
      color = '#B6BDFA'
      colorHover = '#4E529D'
    }

    return ({
      ...provided,
      position : 'relative',
      paddingRight : "14px",
      color : 'white',
      flexBasis: 'max-content',
      minWidth: 'auto',
      direction: 'ltr',
      backgroundColor: state.isSelected ? '' : color,
      '&:hover': {
        color : 'white',
        backgroundColor: colorHover
      }
    })
  },
  multiValueRemove: (provided, state) =>({
    ...provided,
    cursor:'pointer',
    position : 'absolute',
    width: "100%",
    top: "0",
    bottom : "0",
    justifyContent : "flex-end",
    '&:hover': {
      color : 'white',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  control : (provided, state) => ({
    ...provided,
    border: 0,
    boxShadow: 0,
    borderRadius : "4px 4px 0 0",
    '&:hover': {
      border: 0
    }
  }),
  option : (provided, state) => ({
    ...provided,
    color : '#151845',
    cursor: 'pointer'
  }),
}

////////

export const customStylesGlobal2 = {
  menu : (provided, state) =>({
    ...provided,
    top: "calc(100% + 4px)",
    zIndex: 1000
  }),
  placeholder: (provided, state) =>({
    ...provided,
    color : "#b8b8b8",
    fontStyle: "italic",
    marginRight: "-2px"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "hidden",
    whiteSpace: "nowrap",
    flexWrap : 'nowrap',
    direction: 'rtl',
    justifyContent : 'flex-end',
    padding: '2px',
  }),
  input: (provided, state) => ({
    ...provided,
    display: "inline-block",
    direction: 'ltr',
  }),
  multiValueLabel: (provided, state) => ({
    color : 'white',
    padding : '3px 5px',
    direction: 'ltr',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    position : 'relative',
    paddingRight : "14px",
    color : 'white',
    flexBasis: 'max-content',
    minWidth: 'auto',
    direction: 'ltr',
    backgroundColor: state.isSelected ? '' : '#B6BDFA',
    '&:hover': {
      color : 'white',
      backgroundColor: '#4E529D'
    }
  }),
  multiValueRemove: (provided, state) =>({
    ...provided,
    cursor:'pointer',
    position : 'absolute',
    width: "100%",
    top: "0",
    bottom : "0",
    justifyContent : "flex-end",
    '&:hover': {
      color : 'white',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  control : (provided, state) => ({
    ...provided,
    border: 0,
    borderRadius : "4px 4px 0 0",
    boxShadow: 0,
    '&:hover': {
      border: 0
    }
  }),
  indicatorsContainer : (provided, state) => ({
    ...provided,
    backgroundColor : "#FF7D67",
    borderRadius : "0 4px 0 0",
    cursor : 'pointer',
    boxShadow : '0 3px 15px 0 rgba(255,125,103,.5)'
  }),
  dropdownIndicator : (provided, state) => ({
    ...provided,
    color:"#964335",
    '&:hover': {
      color : 'white'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    display:'none'
  }),
  option : (provided, state) => ({
    ...provided,
    color : '#151845',
    cursor: 'pointer'
  }),
}

export const customStylesGlobalExpanded2 = {
  placeholder: (provided, state) =>({
    ...provided,
    color : "#b8b8b8",
    fontStyle: "italic",
  }),
  menu : (provided, state) =>({
    ...provided,
    top: "calc(100% + 4px)",
    zIndex: 1000
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "hidden",
    direction: 'ltr',
    justifyContent : 'flex-start',
    padding: '2px 6px',
    minHeight : "38px"
  }),
  input: (provided, state) => ({
    ...provided,
    display: "inline-block",
    direction: 'ltr',
  }),
  multiValueLabel: (provided, state) => ({
    color : 'white',
    padding : '3px 5px',
    direction: 'ltr',
  }),
  multiValue: (provided, state) => {
    let color;
    let colorHover;
    if(state.data.fulltext || state.data.year){
      color = '#9bb754'
      colorHover = '#b7d175'
    }else if(state.data.id === "RUBRIQUE"){
      color = '#f6ce3c'
      colorHover = 'darken(#f6ce3c,10%)'
    }else{
      color = '#B6BDFA'
      colorHover = '#4E529D'
    }

    return ({
      ...provided,
      position : 'relative',
      paddingRight : "14px",
      color : 'white',
      flexBasis: 'max-content',
      minWidth: 'auto',
      direction: 'ltr',
      backgroundColor: state.isSelected ? '' : color,
      '&:hover': {
        color : 'white',
        backgroundColor: colorHover
      }
    })
  },
  multiValueRemove: (provided, state) =>({
    ...provided,
    cursor:'pointer',
    position : 'absolute',
    width: "100%",
    top: "0",
    bottom : "0",
    justifyContent : "flex-end",
    '&:hover': {
      color : 'white',
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }),
  control : (provided, state) => ({
    ...provided,
    alignItems : "flex-start",
    border: 0,
    boxShadow: 0,
    borderRadius : "4px",
    '&:hover': {
      border: 0
    }
  }),
  indicatorsContainer : (provided, state) => ({
    ...provided,
    alignSelf : "inherit",
    minHeight : "38px",
    borderRadius : "0 4px 4px 0",
    paddingTop: 0,
    paddingBottom: 0,
    boxSizing : "border-box",
    cursor : 'pointer',
  }),
  dropdownIndicator : (provided, state) => ({
    ...provided,
    color:"#151845",
    '&:hover': {
      color : '#1D2261'
    }
  }),
  clearIndicator : (provided, state) => ({
    ...provided,
    color:"#151845",
    '&:hover': {
      color : '#1D2261'
    }
  }),
  indicatorSeparator: (provided, state) => ({
    display:'none'
  }),
  option : (provided, state) => ({
    ...provided,
    color : '#151845',
    cursor: 'pointer',
    textAlign: "left"
  }),
}
