const darkGrey = '#6E708C'
const tonic = "#1D2261"
const nuanceTonic = "#6E708C"
const fontSize = '14px'
const font = "Montserrat-SemiBold"

const border = `solid 1px ${darkGrey}`

const style = {
  container: (provided, state) => {
    return ({
      ...provided,
      margin: '10px 0',
    })
  },
  control: (provided, state) => {
    return ({
      ...provided,
      backgroundColor : "white",
      borderRadius: "8px",
      borderSizing : "border-box",
      border: "none",
      borderTop: !state.selectProps.noBorderTop ? border : null,
      borderBottom: border,
      outline: "none",
      boxShadow: 0,
      cursor: 'pointer',
      '&:hover': {
        borderColor : darkGrey
      }
    })
  },
  placeholder: (provided, state) => ({
    ...provided,
    fontSize : fontSize,
    color : darkGrey,
    fontFamily : font
  }),
  valueContainer : (provided, state) => ({
    ...provided,
    paddingLeft:"5px"
  }),
  singleValue : (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font,
    fontSize : fontSize
  }),
  multiValue : (provided, state) => ({
    ...provided,
    backgroundColor : 'transparent'
  }),
  multiValueLabel : (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font,
    fontSize : fontSize,
    paddingLeft:0,
    padding:0,
    margin: 0
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    paddingLeft:0,
    '&:hover': {
      backgroundColor : 'transparent',
      color : tonic
    }
  }),
  input: (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color : darkGrey,
    paddingRight: 0,
    paddingLeft: 0,
    '&:hover': {
      borderColor : tonic
    }
  }),
  option: (provided, state) => {
    return ({
      ...provided,
      color : !state.isSelected ? darkGrey : 'white',
      backgroundColor : !state.isSelected ? 'white' : tonic,
      '&:hover' : {
        cursor : 'pointer',
        backgroundColor : !state.isSelected ? nuanceTonic : tonic,
        color : 'white'
      }
    })
  },
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: 0,
    color : darkGrey
  }),
  indicatorSeparator: () => ({ display : 'none' }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '0 3px'
  })
}

const style2 = {
  container: (provided, state) => {
    return ({
      ...provided,
      margin: '10px 0',
    })
  },
  control: (provided, state) => {
    return ({
      ...provided,
      backgroundColor : "#151845",
      borderRadius: "8px",
      borderSizing : "border-box",
      border: "none",
      outline: "none",
      boxShadow: 0,
      cursor: 'pointer',
      padding: '5px 0 5px 5px',
      '&:hover': {
        borderColor : darkGrey
      }
    })
  },
  placeholder: (provided, state) => ({
    ...provided,
    fontSize : fontSize,
    color : darkGrey,
    fontFamily : font
  }),
  valueContainer : (provided, state) => ({
    ...provided,
    paddingLeft:"5px"
  }),
  singleValue : (provided, state) => ({
    ...provided,
    color : "white",
    fontFamily : font,
    fontSize : fontSize
  }),
  multiValue : (provided, state) => ({
    ...provided,
    backgroundColor : 'transparent'
  }),
  multiValueLabel : (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font,
    fontSize : fontSize,
    paddingLeft:0,
    padding:0,
    margin: 0
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    paddingLeft:0,
    '&:hover': {
      backgroundColor : 'transparent',
      color : tonic
    }
  }),
  input: (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color : darkGrey,
    paddingRight: 0,
    paddingLeft: 0,
    '&:hover': {
      borderColor : tonic
    }
  }),
  option: (provided, state) => {
    return ({
      ...provided,
      color : !state.isSelected ? darkGrey : 'white',
      backgroundColor : !state.isSelected ? 'white' : tonic,
      '&:hover' : {
        cursor : 'pointer',
        backgroundColor : !state.isSelected ? nuanceTonic : tonic,
        color : 'white'
      }
    })
  },
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: 0,
    color : darkGrey
  }),
  indicatorSeparator: () => ({ display : 'none' }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '0 3px'
  })
}

const style3 = {
  container: (provided, state) => {
    return ({
      ...provided,
      margin: '10px 0',
    })
  },
  control: (provided, state) => {
    return ({
      ...provided,
      backgroundColor : "#ffffff",
      borderRadius: "8px",
      borderSizing : "border-box",
      border: "none",
      outline: "none",
      boxShadow: 0,
      cursor: 'pointer',
      padding: '5px 0 5px 5px',
      '&:hover': {
        borderColor : darkGrey
      }
    })
  },
  placeholder: (provided, state) => ({
    ...provided,
    fontSize : fontSize,
    color : darkGrey,
    fontFamily : font
  }),
  valueContainer : (provided, state) => ({
    ...provided,
    paddingLeft:"5px"
  }),
  singleValue : (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font,
    fontSize : fontSize
  }),
  multiValue : (provided, state) => ({
    ...provided,
    backgroundColor : 'transparent'
  }),
  multiValueLabel : (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font,
    fontSize : fontSize,
    paddingLeft:0,
    padding:0,
    margin: 0
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    paddingLeft:0,
    '&:hover': {
      backgroundColor : 'transparent',
      color : tonic
    }
  }),
  input: (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color : darkGrey,
    paddingRight: 0,
    paddingLeft: 0,
    '&:hover': {
      borderColor : tonic
    }
  }),
  option: (provided, state) => {
    return ({
      ...provided,
      color : !state.isSelected ? darkGrey : 'white',
      backgroundColor : !state.isSelected ? 'white' : tonic,
      '&:hover' : {
        cursor : 'pointer',
        backgroundColor : !state.isSelected ? nuanceTonic : tonic,
        color : 'white'
      }
    })
  },
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: 0,
    color : darkGrey
  }),
  indicatorSeparator: () => ({ display : 'none' }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '0 3px'
  })
}

const style3Dark = {
  container: (provided, state) => {
    return ({
      ...provided,
      margin: '10px 0',
    })
  },
  control: (provided, state) => {
    return ({
      ...provided,
      backgroundColor : "#1D2261",
      borderRadius: "8px",
      borderSizing : "border-box",
      border: "none",
      outline: "none",
      boxShadow: 0,
      cursor: 'pointer',
      padding: '5px 0 5px 5px',
      '&:hover': {
        borderColor : darkGrey
      }
    })
  },
  placeholder: (provided, state) => ({
    ...provided,
    fontSize : fontSize,
    color : darkGrey,
    fontFamily : font
  }),
  valueContainer : (provided, state) => ({
    ...provided,
    paddingLeft:"5px"
  }),
  singleValue : (provided, state) => ({
    ...provided,
    color : 'white',
    fontFamily : font,
    fontSize : fontSize
  }),
  multiValue : (provided, state) => ({
    ...provided,
    backgroundColor : 'transparent'
  }),
  multiValueLabel : (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font,
    fontSize : fontSize,
    paddingLeft:0,
    padding:0,
    margin: 0
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    paddingLeft:0,
    '&:hover': {
      backgroundColor : 'transparent',
      color : tonic
    }
  }),
  input: (provided, state) => ({
    ...provided,
    color : tonic,
    fontFamily : font
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color : 'white',
    paddingRight: 0,
    paddingLeft: 0,
    '&:hover': {
      borderColor : 'white'
    }
  }),
  option: (provided, state) => {
    return ({
      ...provided,
      color : !state.isSelected ? darkGrey : 'white',
      backgroundColor : !state.isSelected ? 'white' : tonic,
      '&:hover' : {
        cursor : 'pointer',
        backgroundColor : !state.isSelected ? nuanceTonic : tonic,
        color : 'white'
      }
    })
  },
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: 0,
    color : 'white'
  }),
  indicatorSeparator: () => ({ display : 'none' }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '0 3px'
  })
}


export default { style, style2, style3, style3Dark }
