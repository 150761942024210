import React from 'react';

export default function IconHome (){
  return(
		<svg width="19px" height="21px"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 499.1 443.8" style={{enableBackground: "new 0 0 499.1 443.8"}} xmlSpace="preserve">
			<path fill="#8A8BA2" d="M57.4,199.5c-8,6.6-15.6,12.8-23.2,19c-5.3,4.3-11.8,4.6-16.8,0.9c-1-0.7-2-1.6-2.8-2.5
				c-2.9-3.4-5.7-6.7-8.5-10.2c-5.3-6.7-4.2-14.9,2.4-20.3c27.4-22.4,54.7-44.9,82.1-67.3c35.1-28.8,70.2-57.6,105.3-86.4
				c7.2-5.9,14.4-11.8,21.6-17.8C236.5-0.8,261-1,280,14.7c37.5,30.7,75,61.5,112.4,92.3c32.3,26.5,64.6,53,96.9,79.6
				c6.9,5.6,7.7,14.2,1.9,21c-2.5,3-5,6-7.6,9c-5.6,6.6-13.6,7.4-20.3,1.9c-6.6-5.3-13.2-10.8-19.7-16.1c-0.9-0.8-1.9-1.5-3.3-2.7
				c0,1.4,0,2.4,0,3.3c0,74.3,0,148.7,0,223c0,7.4-3.2,12-9.7,14.3c-1.7,0.6-3.5,0.4-5.2,0.4c-30.6,0-61.2,0-91.7,0
				c-87.1,0-174.2,0-261.2,0c-3.5,0-6.8-0.4-9.7-2.5c-4.5-3.3-5.7-7.9-5.7-13.2c0-30.9,0-61.8,0-92.7c0-26.2-0.2-52.3,0.1-78.5
				c0.2-17,0.1-34,0.2-51C57.5,201.9,57.4,201,57.4,199.5z M98.4,249.1c-0.1,3.3-0.2,6.6-0.2,9.9c0,5.9-0.3,11.7,0.2,17.6
				c-0.5,2.7-0.2,5.4-0.2,8.2c0.1,6.4-0.3,12.9,0.2,19.3c-0.4,9.2-0.4,18.3,0,27.5c-0.6,2.5-0.3,5.1-0.2,7.7c0.1,6.5-0.3,12.9,0.2,19.4
				c-0.5,3.2-0.2,6.4-0.2,9.6c0,5.9-0.3,11.9,0.2,17.8c-0.5,4-0.1,8.1-0.2,12.1c0,1.3,0.6,1.5,1.7,1.5c4-0.1,8.1-0.1,12.1-0.1
				c0.9,0,1.8,0.1,2.7,0.1c25.4,0,50.8,0,76.2,0c3.2,0,3.2,0,3.2-3.3c0-39.2,0-78.5,0-117.7c0-14.8,7.5-25.1,20.8-28.7
				c2.2-0.3,4.3-0.6,6.5-0.8c9.2,0,18.3,0,27.5,0c3,0.6,5.9,0.3,8.9,0.2c5.9,0,11.8,0.3,17.6-0.2c1,0.1,2,0.3,3,0.3
				c4.2,0.1,8,1.4,11.6,3.5c9.2,5.7,13.7,13.9,13.6,24.7c-0.1,39.6,0,79.3-0.1,118.9c0,2.5,0.7,3,3,3c29.8-0.1,59.6,0,89.5,0
				c3.2,0,3.2,0,3.2-3.2c0-66.2-0.1-132.4,0-198.7c0-10.2,0.2-20.5,0.1-30.7c0.2-0.9-0.1-1.5-0.8-2.1c-4.2-3.3-8.3-6.7-12.5-10.1
				c-0.4-0.8-1.4-0.9-2-1.4c-6.1-5.2-12.2-10.4-18.4-15.5c-27.5-22.7-55.1-45.3-82.6-67.9c-10.8-8.9-21.6-17.8-32.4-26.6
				c-0.8-0.6-1.3-1.7-2.7-0.5c-8.8,7.4-17.7,14.7-26.5,22c-8.3,6.5-16.5,13.3-24.5,20.1c-1.2,0.5-2.1,1.4-2.9,2.4
				c-9.2,7.5-18.3,15.1-27.5,22.6c-4.4,3.6-8.8,7.1-13.1,10.7c-7.6,6.3-15.2,12.6-22.8,18.9c-6.3,5.2-12.7,10.3-19,15.5
				c-4,3.2-8,6.4-12,9.6c-0.8,0.6-1.3,1.3-1,2.3c-0.6,3.8-0.4,7.6-0.4,11.4c0.1,5.4-0.3,10.8,0.2,16.1c-0.5,5.1-0.2,10.2-0.2,15.3
				c0,4-0.3,8.1,0.2,12.1c-0.5,6-0.2,11.9-0.2,17.9C98.1,243,97.8,246.1,98.4,249.1z"/>
		</svg>
  )
}
