import React from 'react';

export default function IconDown(props){
	return(
		<svg width="15" height="15" x="0px" y="0px" viewBox="0 0 493.4 493.4">
			<g>
				<path fill={props.color} d="M413.9,69l-148.2,6.9c-3.9,0.4-6.6,2.4-8.1,6.1c-1.5,3.6-0.8,6.9,2,9.7l45.2,45.2L52.9,388.8c-1.9,1.9-2.8,4-2.8,6.5
					c0,2.4,0.9,4.6,2.8,6.5l38.8,38.8c1.9,1.9,4,2.8,6.5,2.8c2.4,0,4.6-0.9,6.5-2.8l252-252l45.2,45.2c2.7,2.7,5.9,3.4,9.7,2
					c3.6-1.5,5.6-4.2,5.9-8.3l6-149c0-2.7-0.9-5-2.8-6.9C418.8,69.9,416.5,69,413.9,69z"/>
			</g>
		</svg>
	)
}
