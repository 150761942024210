import {
  GET_NODES,
  GET_NODES_DATES_FILTERED,
  GET_RELATIONSHIPS,
  GET_PARTNERSHIPS,
  GET_TIMELINES,
  REMOVE_PARTNERSHIPS,
  REMOVE_RELATIONSHIPS,
  LOADING_DATAVIZ,
  ERROR_DATAVIZ
} from '../actions/types'

const initialState = { relationships : null, partnerships : null, timelines : null, nodes : null, nodesDates : null, isLoading : false};
let nodesDatesUpdated;

export default function datavizReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TIMELINES :
      return {...state, timelines : action.payload.result.docs, isLoading : false}
    case GET_NODES :
      nodesDatesUpdated = action.payload.result.dates.map((d)=>new Date(d.label))
      return {...state, nodes : action.payload.nodes, nodesDates : nodesDatesUpdated, isLoading : false}
    case GET_RELATIONSHIPS:
      return {...state, relationships : action.payload, isLoading : false};
    case GET_PARTNERSHIPS :
      let matchCode = {}
      action.payload.result.filters.map((d)=> d.valeurs).forEach((valeurs) => {
        valeurs.forEach((d)=>{
          if(d.frequence > 0) matchCode[d.code] = d
        })
      })

      let links = action.payload.links.map((link)=>{
        if(matchCode[link.type]){
          link.libelle = matchCode[link.type].libelle
        }
        return link
      })

      return {...state, partnerships : {nodes : action.payload.nodes, links} ,isLoading : false}
    case GET_NODES_DATES_FILTERED :
      // nodesDates is Used for Graphline, GrapRange
      nodesDatesUpdated = action.payload.dates.map((d)=>new Date(d.label))
      return {...state, nodesDates : nodesDatesUpdated , isLoading : false}
    case REMOVE_PARTNERSHIPS:
      return {...state, partnerships : null};
    case REMOVE_RELATIONSHIPS:
    return {...state, relationships : null};
    case LOADING_DATAVIZ:
      // console.log(LOADING_DATAVIZ);
      return {...state, nodes : null, isLoading : true};
    case ERROR_DATAVIZ :
      console.log(ERROR_DATAVIZ);
      return {...state}
    default:
      return {...state};
  }
}
