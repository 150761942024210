import React from 'react';

export default function IconDashboard(){
  return (
    <svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="words_cloud" transform="translate(-25.000000, -326.000000)" fill="#8A8BA2">
                <g id="Group" transform="translate(23.000000, 233.000000)">
                    <path d="M12.984,106.456 C13.8640044,106.199999 14.4879982,105.576005 14.856,104.584 L21.984,104.584 C21.8399993,106.152008 21.368004,107.595993 20.568,108.916 C19.767996,110.236007 18.7280064,111.295996 17.448,112.096 C16.1039933,112.944004 14.6160082,113.439999 12.984,113.584 L12.984,106.456 Z M14.856,102.616 C14.4879982,101.623995 13.8640044,101.000001 12.984,100.744 L12.984,93.616 C14.6160082,93.7600007 16.1039933,94.2559958 17.448,95.104 C18.7280064,95.904004 19.767996,96.9639934 20.568,98.284 C21.368004,99.6040066 21.8399993,101.047992 21.984,102.616 L14.856,102.616 Z M11.016,100.744 C10.4879974,100.968001 10.0320019,101.327998 9.648,101.824 C9.21599784,102.384003 9,102.975997 9,103.6 C9,104.224003 9.21599784,104.815997 9.648,105.376 C10.0320019,105.872002 10.4879974,106.231999 11.016,106.456 L11.016,113.584 C9.36799176,113.423999 7.85600688,112.880005 6.48,111.952 C5.10399312,111.023995 4.02400392,109.840007 3.24,108.4 C2.42399592,106.911993 2.016,105.312009 2.016,103.6 C2.016,101.887991 2.42399592,100.288007 3.24,98.8 C4.02400392,97.3599928 5.10399312,96.1760046 6.48,95.248 C7.85600688,94.3199954 9.36799176,93.7760008 11.016,93.616 L11.016,100.744 Z" id="icon-dashboard"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}
