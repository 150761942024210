import React from 'react';

export default function IconNewspaper(){
  return(
    <svg width="19px" height="15px" viewBox="0 0 19 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="words_cloud" transform="translate(-24.000000, -561.000000)" fill="#8A8BA2">
                <g id="Group" transform="translate(23.000000, 233.000000)">
                    <path d="M11.984,328.616 C12.2720014,328.616 12.515999,328.711999 12.716,328.904 C12.916001,329.096001 13.016,329.327999 13.016,329.6 L13.016,341.6 C13.016,341.872001 12.916001,342.103999 12.716,342.296 C12.515999,342.488001 12.2720014,342.584 11.984,342.584 L8.984,342.584 C8.71199864,342.584 8.48000096,342.488001 8.288,342.296 C8.09599904,342.103999 8,341.872001 8,341.6 L8,329.6 C8,329.327999 8.09599904,329.096001 8.288,328.904 C8.48000096,328.711999 8.71199864,328.616 8.984,328.616 L11.984,328.616 Z M19.016,328.616 C19.2880014,328.616 19.519999,328.711999 19.712,328.904 C19.904001,329.096001 20,329.327999 20,329.6 L20,341.6 C20,341.872001 19.904001,342.103999 19.712,342.296 C19.519999,342.488001 19.2880014,342.584 19.016,342.584 L16.016,342.584 C15.7439986,342.584 15.504001,342.488001 15.296,342.296 C15.087999,342.103999 14.984,341.872001 14.984,341.6 L14.984,329.6 C14.984,329.327999 15.087999,329.096001 15.296,328.904 C15.504001,328.711999 15.7439986,328.616 16.016,328.616 L19.016,328.616 Z M5,328.616 C5.27200136,328.616 5.50399904,328.711999 5.696,328.904 C5.88800096,329.096001 5.984,329.327999 5.984,329.6 L5.984,341.6 C5.984,341.872001 5.88800096,342.103999 5.696,342.296 C5.50399904,342.488001 5.27200136,342.584 5,342.584 L2,342.584 C1.72799864,342.584 1.49600096,342.488001 1.304,342.296 C1.11199904,342.103999 1.016,341.872001 1.016,341.6 L1.016,329.6 C1.016,329.327999 1.11199904,329.096001 1.304,328.904 C1.49600096,328.711999 1.72799864,328.616 2,328.616 L5,328.616 Z" id="icon-newspaper"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}
