import React from 'react'
import { withLocalize, Translate } from 'react-localize-redux'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'
import { useGlobalContext } from '../../GlobalContext';

const Export = (props) => {
  const context = useGlobalContext()[0];
  const viewReducer = context.viewReducer;

  return(
    <>
    {!props.isLoading ?
      <div className={props.className ? "container-export "+props.className+" "+viewReducer.lightmode : "container-export "+viewReducer.lightmode}>
        <div className={"export-viz "+viewReducer.lightmode} data-tip data-for='help-export' onClick={props.exportImage}>
          <FontAwesomeIcon icon={faFileExport}/>
        </div>
        <ReactTooltip data-place={ "left"} className={"react-tooltip white"} delayShow={350} id={'help-export'} place="left" effect='solid'>
          <span><Translate id="global.export-info"/></span>
        </ReactTooltip>
      </div> : null
    }
    </>
  )
}

export default withLocalize(Export)
