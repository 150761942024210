import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { NavLink } from "react-router-dom"
import MaterialIcon from 'material-icons-react'
import { withLocalize, Translate  } from 'react-localize-redux'
import axios from 'axios';
import { API_URL } from '../../../actions/index.js'
import usePlateforme from '../../../customHooks/usePlateforme'

import { useGlobalContext } from '../../../GlobalContext'
import queryString from 'query-string'

const Tools = (props) => {
  const [context, dispatch] = useGlobalContext()
  const filtersJSON = usePlateforme()
  const filtersReducer = context.filtersReducer
  const viewReducer = context.viewReducer

  const [ nav, setNav ] = useState();
  const [ tools, setTools ] = useState();

  const [ indexLang, setIndexLang ] = useState()
  const [ filters, setFilters ] = useState()
  const [ referencesMap, setReferencesMap ] = useState()
  const [ specificTool, setSpecificTool ] = useState(false)

  const [ additionalFilters, setAdditionalFilters ] = useState()

  // SET TOOLS
  useEffect(()=>{
    if(!filtersJSON || filtersReducer.indexSelectedEntries === null) return;
    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]

    let parseSearch;
    if(props.history.location.search){
      parseSearch = queryString.parse(props.history.location.search)
    }

    //SPECIFIC TOOLS
    if(parseSearch && parseSearch['specific-tool']){
      if(!isNaN(parseInt(parseSearch['specific-tool']))){
        setSpecificTool(parseInt(parseSearch['specific-tool']))
        let currentFilter = currentHeaderEntry["dropdown-tools"].find((d,i) => i === parseInt(parseSearch['specific-tool']))
        if(currentFilter) setTools(currentFilter.tools)

        if(parseSearch['item'] && !isNaN(parseInt(parseSearch['item']))){
          let indexItem = parseInt(parseSearch['item']);
          if(currentFilter.tools[0].items[indexItem].filters){
            setAdditionalFilters(currentFilter.tools[0].items[indexItem].filters)
          }else{
            setAdditionalFilters(null)
          }
        }else{
          setAdditionalFilters(null)
        }

      }

    }else{
      setSpecificTool(null)
      setAdditionalFilters(null)

      if(currentHeaderEntry.tools){
        setTools(currentHeaderEntry.tools)
      }else if(!currentHeaderEntry.tools && filtersJSON["tools"]){
        setTools(filtersJSON["tools"])
      }

    }


  },[
    filtersJSON,
    filtersReducer.indexSelectedEntries,
    props.history.location
  ])

  // INIT NAV
  useEffect(()=>{
    if(!tools || !props.allFilters) return;
    let nav = []

    let allTools = []
    tools.forEach(tool => allTools.push(...tool.items))
    allTools.forEach((tool)=>{
      let found = props.allFilters.find((d)=> d.id === tool.code)
      if(found) tool.label = found.libelle
    })
    allTools = allTools.filter(d=>d.code)
    setNav(allTools)
  },[
    tools,
    props.allFilters
  ])

  // RESET ANALYSIS TOOLS REF ON UNMOUNT
  useEffect(()=>{
    return () => {
      dispatch({
        type : "SET_REFERENCES",
        payload : null
      })
    }
  },[])

  // VERIFY IF IMAGE EXIST INSIDE DIRECTORY
  function imageExist(imageSrc) {
    return new Promise((resolve, reject)=>{
      let img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        resolve(true)
      };
      img.onerror = () => {
        resolve(false)
      };
    })
  }

  async function asyncForEach(array, callback,i) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index)
    }
  }


  // SET FILTER
  useEffect(()=>{
    if(!props.allFilters || additionalFilters === undefined ) return;

    let code = props.match.params.tools;
    let filter = props.allFilters.find((d)=> d.id === code)
    let parentFilter = props.allFilters.find((d)=> d.id === code.replace('2',''))


    if(filter && filter.valeurs){

      getFiltersWithImageAttr().then(async (res)=>{
        let references = await getReferences(res)
        let map = new Map()
        references.forEach((d)=>{
          map.set(d.code2,d)
        })

        setReferencesMap(map)
        setFilters(res)
      })

      async function getFiltersWithImageAttr(){
        return new Promise(resolve => {
          let obj = []
          asyncForEach(filter.valeurs,async (d,i)=>{
            let key = d.code.slice(0,6)

            if(obj[key] && obj[key].values){
              if(d.code !== d.libelle){
                obj[key].values.push(d)
              }
              obj[key].code = key
            }else{
              obj[key] = {}
              let url = `${process.env.PUBLIC_URL}/images/tools/${key}.png`
              let isImageExist = await imageExist(url)
              let found = parentFilter.valeurs.find((j)=> j.code === key)
              if(found) obj[key].label = found.libelle
              if(d.code !== d.libelle){
                obj[key].values = [d]
              }else{
                obj[key].values = []
              }

              obj[key].image = isImageExist
            }

            obj[key].values = obj[key].values.sort((a, b) => (parseInt(a.frequence) > parseInt(b.frequence)) ? -1 : 1)

            if(filter.valeurs.length === i+1 ){

              resolve(obj)
            }
          })
        })
      }
    }else{
      setFilters(null)
    }


  },[
    props.history.location.search,
    additionalFilters,
    props.allFilters,
    props.match.params
  ])

  // SET INDEX LANG
  useEffect(()=>{
    let indexLang = props.languages.map((d)=>d.active).indexOf(true);
    setIndexLang(indexLang)
  },[props.activeLanguage])

  // INIT MANSORY
  useEffect(()=>{
    resizeAllMasonryItems()

    setTimeout(()=>{
      resizeAllMasonryItems()
    },2000)

  },[filters])

  useEffect(()=>{
    setTimeout(()=>{
      resizeAllMasonryItems()
    },500)
  },[props.filterIsOpen])

  // ADD EVENT FOR HANDLE RESIZE SCREEN
  useEffect(()=>{
    /* Resize all the grid items on the load and resize events */
    var masonryEvents = ['load', 'resize'];

    const resizeMansory = () => resizeAllMasonryItems()

    masonryEvents.forEach( function(event) {
      window.addEventListener(event, resizeMansory );
    } )

    return () => {
      masonryEvents.forEach( function(event) {
        window.removeEventListener(event, resizeMansory);
      } )
    }
  },[props.history.location])

  function resizeAllMasonryItems(){
    var allItems = document.querySelectorAll('.table-tools .tools-list');
    for(var i=0;i<allItems.length;i++){
      resizeMasonryItem(allItems[i]);
    }
  }

  function resizeMasonryItem(item){
    /* Get the grid object, its row-gap, and the size of its implicit rows */
    var grid = document.querySelector('.table-tools'),
        rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
        rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));

    var rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = 'span '+rowSpan;
  }

  function getReferences(paramsFilters){
    const requestUrl = API_URL + 'doc/reference';

    let config = {
      headers: {
        Authorization : localStorage.getItem('token')
      }
    }

    const defaultRubrique = filtersReducer.selectedHeaderEntries
    let parentFilter = props.match.params.tools.replace('2','')

    let arrCode = Object.keys(paramsFilters).map((key)=> key)


    let obj = {
      plateformeID : filtersReducer.plateformeID,
      filters : [
        ...defaultRubrique,
        {code: arrCode.join("%20OR%20"), id: parentFilter}
      ],
      lang : viewReducer.lang,
      page : 1,
      number : 10000
    }

    if(additionalFilters){
      obj.filters = [ ...obj.filters, ...additionalFilters]
    }

    let targetedCode = props.match.params.tools

    return new Promise((resolve,reject)=>{
      axios.post(requestUrl, obj, config)
      .then((response) => {

        // let filtersValues = filters[filter.code].values
        let referenceWithFrequence = []

        response.data.result.docs.forEach((d)=>{
          if(d[targetedCode]){
            let targetCode = d[parentFilter][0].split(";")
            targetCode = targetCode[targetCode.length-1];
            let filtersValues = paramsFilters[targetCode].values
            let find = filtersValues.find((u)=> u.code === d[targetedCode][0]);
            if (find) {
              referenceWithFrequence.push({ ...d, frequence : find ? parseInt(find.frequence) : 0, title : d.TITLE[0], code : targetCode, code2 : d[targetedCode][0]})
            }
          }
        })
        referenceWithFrequence = _.orderBy(referenceWithFrequence,["code","frequence", "title"], ["asc","desc","desc"])
        dispatch({
          type : "SET_REFERENCES",
          payload : referenceWithFrequence
        })

        resolve(referenceWithFrequence)


      })
      .catch((error) => {
        reject(error)
      });
    })


  }

  function openDocument(value,filter){

    const requestUrl = API_URL + 'doc/reference';

    let config = {
      headers: {
        Authorization : localStorage.getItem('token')
      }
    }

    const defaultRubrique = filtersReducer.selectedHeaderEntries
    let parentFilter = props.match.params.tools.replace('2','')

    /////

    if(referencesMap.get(value.code)){
      props.toggleDocument(referencesMap.get(value.code).ID_QES,true,'analysis-tools')
    }

  }

  function getImage(filter){
    if(filter.image){
      let url = `${process.env.PUBLIC_URL}/images/tools/${filter.code}.png`
      return <img src={url} alt={filter.code}/>
    }else{
      return null
    }
  }

  function getPrev(){
    if(typeof specificTool === "number"){
      return `/search-innov/${props.match.params.id}/analysis-tools?specific-tool=${specificTool}&entry=${filtersReducer.indexSelectedEntries}`

    }else{
      return `/search-innov/${props.match.params.id}/analysis-tools?entry=${filtersReducer.indexSelectedEntries}`
    }
  }

  function getActiveDot(tools,code,to){
    let currentUrl = props.history.location.pathname
    if(props.history.location.search) currentUrl += props.history.location.search
    return (
      <div
        className={to === currentUrl ? "dot active" : "dot"}
      >
      </div>
    );
  }

  return (
    <>
      <div className="page-tools">
        <h3 className="only-on-tablet">
          <NavLink to={getPrev()}>
            <MaterialIcon key="icon-backward" icon="arrow_back" size={24} />
            <Translate id="global.view.analysis-tools" />
          </NavLink>
        </h3>
        { nav &&
          <ul className="navigation">
            {nav.map((d,i)=>(
              <li key={`item-nav-${i}`}>
                <ActiveNavLink
                  handleClick={()=> setAdditionalFilters()}
                  strSearch={typeof specificTool === "number" ?
                   `?specific-tool=${specificTool}&item=${i}&entry=${filtersReducer.indexSelectedEntries}` :
                   `?entry=${filtersReducer.indexSelectedEntries}`
                  }
                  to={`/search-innov/${props.match.params.id}/analysis-tools/${d.code}`}
                >
                  {(d.title && d.title[indexLang]) ? d.title[indexLang] : d.label}
                </ActiveNavLink>
              </li>
            ))}
          </ul>
        }
        { (filters && referencesMap) &&
          <div className="table-tools mansory">
            {_.orderBy(Object.values(filters),"code").map((filter,k)=>(
              <div key={`tools-list-${k}`} className="tools-list">
                <div className="content">
                  <label>{filter.label}</label>
                  {getImage(filter)}
                  <ul>
                    {filter.values.map((d,i)=>(
                      <li key={`value-${i}`} className={referencesMap.get(d.code) ? "" : "disabled"}>
                        <div onClick={referencesMap.get(d.code) ? () => openDocument(d,filter) : () => {}}>
                          <p>{d.libelle}</p>
                          <span>{parseInt(d.frequence)}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        }

        { nav && (
          <div className="navigation-dots only-on-tablet">
            {nav.map((d, i) => {
              let to = typeof specificTool === "number" ?
              `/search-innov/${props.match.params.id}/analysis-tools/${d.code}?specific-tool=${specificTool}&item=${i}&entry=${filtersReducer.indexSelectedEntries}` :
              `/search-innov/${props.match.params.id}/analysis-tools/${d.code}?entry=${filtersReducer.indexSelectedEntries}`
              
              return (
                <div key={"dot-"+i}>
              <NavLink to={to}>
                  {getActiveDot(props.match.params.tools,d.code,to)}
                </NavLink>
              </div>
              )
            })}
          </div>
        )}

      </div>
    </>

  )
}

const ActiveNavLink = ({to, strSearch = "", children, handleClick}) => {
  return (
    <NavLink
      onClick={() => handleClick()}
      isActive={(_, {pathname, search}) => {
        return pathname+search == to+strSearch
      }}
      to={{
        pathname : to,
        search : strSearch
      }}
    >
      {children}
    </NavLink>
  )
}

export default withLocalize(Tools);
