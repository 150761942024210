import React from 'react'
import { NavLink } from "react-router-dom"
import styles from './michelin.module.scss'
import bibendum from '../../../../assets/images/bibendum.png'

const Michelin = () => {
  return (
    <div className={`${styles["sso"]}`}>
      <div>

        <div className={`${styles["container"]}`}>
          <h2>
            Connectez-vous<br/>à votre espace
            Michelin !
          </h2>
          <NavLink to="/">
            <button>Login standard</button>
          </NavLink>
          <a href="https://api.explore.intelligencemaker.com/api/auth/michelin/login-saml">
            <button>Login SSO</button>
          </a>
        </div>
      </div>
      <div className={`${styles["image-container"]}`}>
        <img src={bibendum} alt="bibendum michelin" />
      </div>
    </div>
  )
}

export default Michelin;
