import React, { useEffect, useState, useMemo } from "react"
import { withLocalize } from "react-localize-redux";
import _ from "lodash"

import useFilterView from '../../../customHooks/useFilterView'
import useContentDimension from '../../../customHooks/useContentDimension'
import { useGlobalContext } from '../../../GlobalContext'
import { datavizParams } from '../../../helpers/filtersParams'
import { getNodes } from '../../../actions/dataviz'
import LoaderView from '../../partials/LoaderView'
import SearchByKeywords from '../../partials/SearchByKeywords'
import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'
import ExplorationNav2 from '../../partials/ExplorationNav2'

import Export from '../../partials/Export'
import InfoExport from "../../partials/InfoExport";
import GeoViz from './GeoViz'
// import GeoVizMarkers from './GeoVizMarkers'

import countries from './countries.js'
import { fnExportImage } from '../../../helpers/helpers'

const Geolocalisation = (props) => {
  const contentDimension = useContentDimension();
  const [ context , dispatch ] = useGlobalContext();
  const datavizReducer = context.datavizReducer
  const filtersReducer = context.filtersReducer
  const viewReducer = context.viewReducer

  const [ data , setData ] = useState(null)
  const [ init, setInit ] = useState(true)

  const defaultEntries = _.compact(["FILTER_GAR","FILTER_GAR2"])
  useFilterView(props.entries)

  // ACTIONS
  const _getNodes = (filter) => getNodes(dispatch, filter)

  useEffect(()=>{

    if(filtersReducer.date.min.date &&
      filtersReducer.date.max.date){
      let result = datavizParams(filtersReducer,viewReducer.lang)
      result.entries = defaultEntries
      result.filters.push(...props.entries)
      _getNodes(result)
    }

  },[
    props.entries,
    viewReducer.lang,
    filtersReducer.selectedKeywords,
    filtersReducer.search,
    filtersReducer.filteredByDate,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date])

  useEffect(()=>{
    if(filtersReducer.matchCode && context.datavizReducer.nodes){

      var geoNodes = datavizReducer.nodes.filter((d)=>{
        return d.category === "FILTER_GAR2"
      })

      let associatedLocations = []
      let allCountries = countries();

      geoNodes.forEach((node)=>{
        let country = filtersReducer.matchCode.find((d)=> d.code === node.value)
        if(country){
          node.country = country.label
          node.abbreviation = allCountries[country.label]
        }
      })


      // REMOVE geoNodes WHICH CORRESPOND TO SELECTED FILTERS
      let selectedFilterCodes = filtersReducer.selectedKeywords.map(d=> d.code)
      geoNodes = geoNodes.filter(d => !selectedFilterCodes.includes(d.value) )

      setInit(false)
      setData(geoNodes)

    }
  },[datavizReducer.nodes,filtersReducer.matchCode])

  function exportImage(){
    let exportGroup = document.querySelector(".geoViz")
    let pathCountries = exportGroup.querySelectorAll("#map path.empty");
    pathCountries.forEach((pathCountry)=>{
      let style = getComputedStyle(pathCountry);
      pathCountry.setAttribute("fill",style.fill)
    })


    let infoExport = exportGroup.querySelector(".info-export");
    if(infoExport) infoExport.style.display = "block"

    let options = {
      width : exportGroup.clientWidth,
    }
    fnExportImage(exportGroup,"geolocalisation.png",options)

    if(infoExport) infoExport.style.display = "none"

  }

  // <GeoVizMarkers
  //   projectionScale={110}
  //   width={contentDimension.width}
  //   height={contentDimension.height}
  //   data={require('./places0.json')}
  // />

  return useMemo(()=>{
    // if(filteredDocuments === null) return false
    return (
      <>
        <div className={props.filterIsOpen ? "geolocalisation filters-opened" : "geolocalisation"}>
          { (datavizReducer.isLoading || (!data && init)) &&
            <LoaderView/>
          }
          <Export exportImage={exportImage} isLoading={!data || datavizReducer.isLoading}/>
          <div  className="geoViz" >
            <InfoExport />
            { data ?
              <>
               <div className={viewReducer.lightmode === "light" ? "container-search light" : "container-search"}>
                <div className="container-left">
                { (!props.hideHeaderButtons && !props.filterIsOpen) &&
                    <ButtonFilters
                      toggleFilter={props.toggleFilter}
                    />
                  }
                  <SearchByKeywords
                    testId={"global"}
                    className={"header"}
                    iconPosition={"right"}
                    isMulti={true}
                    isClearable={true}
                    placeholder={"Filter par mots clés"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }}
                  />
                </div>
                <div className="container-right">
                  <ExplorationNav2 history={props.history} match={props.match} />
                  { !props.hideHeaderButtons &&
                    <ButtonDocs
                      documentsIsOpen={props.documentsIsOpen}
                      toggleDocument={props.toggleDocument}
                    />
                  }
                </div>
              </div>
              <div className={datavizReducer.isLoading ? "container-geo loading" : "container-geo"}>
              <GeoViz
                projectionScale={110}
                unknownColor={"#3435ff"}
                width={contentDimension.width}
                height={contentDimension.height}
                data={data}
              />
              </div>
              </> : null
            }
          </div>
        </div>
      </>
    )
  },[
    props.activeLanguage.code,
    data,
    datavizReducer.isLoading,
    contentDimension.height,
    props.filterIsOpen
  ])

}

export default withLocalize(Geolocalisation)
