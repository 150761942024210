import React from 'react';

export default function IconHelp (){
  return(
    <svg width="19px" height="21px" viewBox="0 0 19 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="words_cloud" transform="translate(-24.000000, -609.000000)" fill="#8A8BA2">
                <g id="Group" transform="translate(23.000000, 233.000000)">
                    <path d="M13.421,384.294 C13.6970014,384.002665 13.9154992,383.673002 14.0765,383.305 C14.2375008,382.936998 14.318,382.546002 14.318,382.132 C14.318,381.441997 14.1455017,380.798003 13.8005,380.2 C13.4554983,379.601997 12.9916696,379.130502 12.409,378.7855 C11.8263304,378.440498 11.1900034,378.268 10.5,378.268 C9.80999655,378.268 9.17366958,378.440498 8.591,378.7855 C8.00833042,379.130502 7.54450172,379.601997 7.1995,380.2 C6.85449827,380.798003 6.682,381.441997 6.682,382.132 L8.568,382.132 C8.568,381.610664 8.75966475,381.158335 9.143,380.775 C9.52633525,380.391665 9.97866406,380.2 10.5,380.2 C11.0213359,380.2 11.4736648,380.391665 11.857,380.775 C12.2403353,381.158335 12.432,381.602997 12.432,382.109 C12.432,382.615003 12.2403353,383.074998 11.857,383.489 L10.684,384.685 C10.3313316,385.068335 10.0630009,385.482331 9.879,385.927 C9.66433226,386.402336 9.557,386.885331 9.557,387.376 L9.557,387.882 L11.443,387.882 C11.443,387.222663 11.5503323,386.670669 11.765,386.226 C11.9030007,385.934665 12.1713313,385.574335 12.57,385.145 L13.421,384.294 Z M11.443,391.7 L11.443,389.768 L9.557,389.768 L9.557,391.7 L11.443,391.7 Z M17.193,376.382 C17.7143359,376.382 18.1666648,376.565998 18.55,376.934 C18.9333352,377.302002 19.125,377.746664 19.125,378.268 L19.125,391.7 C19.125,392.221336 18.9333352,392.673665 18.55,393.057 C18.1666648,393.440335 17.7143359,393.632 17.193,393.632 L13.375,393.632 L10.5,396.507 L7.625,393.632 L3.807,393.632 C3.27033065,393.632 2.81416855,393.440335 2.4385,393.057 C2.06283145,392.673665 1.875,392.221336 1.875,391.7 L1.875,378.268 C1.875,377.746664 2.06283145,377.302002 2.4385,376.934 C2.81416855,376.565998 3.27033065,376.382 3.807,376.382 L17.193,376.382 Z" id="icon-help"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}
