import React from 'react';

export default function DashboardNavigation ({sections, indexLang, selectSection, selectedSection, isMobile}){

  function getClassName(d,indexLang){
    if(d.pies.length === 0) return 'inactive'
    return selectedSection === d.title[indexLang].toLowerCase() ? "active" : ""
  }

  return (
    <ul >
      {sections.map((d,i)=>{
        return (
          <li
            key={"nav-dashboard-"+i}
            className={getClassName(d,indexLang)}
            onClick={() => selectSection(d.title[indexLang].toLowerCase(),i)}
          >
            {d.title[indexLang]}
          </li>
        )
      })}
    </ul>
  )

}
