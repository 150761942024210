import React, { useState, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip'
import _ from "lodash";
import { IoChevronForwardSharp } from 'react-icons/io5'
import queryString from 'query-string'
import { NavLink } from "react-router-dom";
import { withLocalize, Translate } from 'react-localize-redux'
import useContentDimension from '../../../customHooks/useContentDimension'
import { useGlobalContext } from '../../../GlobalContext';
import usePlateforme from '../../../customHooks/usePlateforme'
import history from '../../../history'

import styles from './exploration-nav.module.scss';

import IconHome from '../../../assets/images/exploration/icon-home.js'
import IconRelationships from '../../../assets/images/exploration/icon-relationships.js'
import IconDashboard from '../../../assets/images/exploration/icon-dashboard.js'
import IconGeolocalisation from '../../../assets/images/exploration/icon-geolocalisation.js'
import IconWordsCloud from '../../../assets/images/exploration/icon-words-cloud.js'
import IconTimeline from '../../../assets/images/exploration/icon-timeline.js'
import IconKF from '../../../assets/images/exploration/icon-partner.js'
import IconNewspaper from '../../../assets/images/exploration/icon-newspaper.js'
import IconAnalysisTools from '../../../assets/images/exploration/icon-analysis-tools.js'
import IconHelp from '../../../assets/images/exploration/icon-help.js'

const ExplorationNav = (props) => {
  const contentDimension = useContentDimension()
  const context = useGlobalContext()[0];
  const [ indexLang, setIndexLang ] = useState();
  const hideNav = useRef();

  const [ explorationTools, setExplorationTools ] = useState();
  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;

  const filtersJSON = usePlateforme()

  const iconCases = {
   "/home" : <IconHome/>,
   "/strategic-watch" : <IconNewspaper/>,
   "/dashboard" : <IconDashboard/>,
   "/wordscloud" : <IconWordsCloud/>,
   "/links-exploration" : <IconRelationships/>,
   "/partnerships" : <IconKF/>,
   "/geolocalisation" : <IconGeolocalisation/>,
   "/timeline" : <IconTimeline/>,
   "/analysis-tools" : <IconAnalysisTools/>,
   "/help" : <IconHelp/>
  }

  useEffect(()=>{
    if(!filtersJSON || filtersReducer.indexSelectedEntries === null) return;
    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
    hideNav.current = shouldIhideNavbar(history.location,currentHeaderEntry)
    history.listen((location)=>{
      hideNav.current = shouldIhideNavbar(location)
    })
  },[history.location.pathname,filtersJSON,filtersReducer.indexSelectedEntries])

  useEffect(()=>{
    let index;
    props.languages.forEach((d,i) => {
      if(d.active) index = i
    })
    setIndexLang(index)
  },[props.activeLanguage])

  useEffect(()=>{
    if(!filtersJSON) return;
    let findEntries = filtersJSON["header"][filtersReducer.indexSelectedEntries];
    if(findEntries && findEntries.navbar) setExplorationTools(findEntries.navbar)
  },[
    filtersJSON,
    filtersReducer.indexSelectedEntries
  ])

  function shouldIhideNavbar(location,currentHeaderEntry){
    if(currentHeaderEntry && currentHeaderEntry.hideNavbar) return true
    let re = new RegExp(`\/search-innov\/${props.match.params.id}\/(about|analysis-tools)$`)

    if(re.test(location.pathname)){
      return true
    }else{
      return false
    }
  }

  function renderHelp(mobile){
    let help = explorationTools.find((tool)=>tool.url === "/help");
    if(help){
      return (
        <li>
          <NavLink to={`/search-innov/${props.match.params.id}${help.url}`}>{iconCases[help.url]}</NavLink>
        </li>
      )
    }else{
      return null
    }
  }

  function getObjectTo(url){
    let search = history.location.search
    let entry = queryString.parse(search)?.entry
    return ({
      pathname : url,
      search : entry ? `?entry=${entry}` : ''
    })
  }

  function getId(tool){
    return `exploration-nav-${tool.url.replace("/","")}`
  }

  if(contentDimension.width > 867){
    return (
      <>
        { (explorationTools && !hideNav.current) && 
          <div className={viewReducer.lightmode === "light" ? `${styles["exploration-nav"]} ${styles["light"]}` : `${styles["exploration-nav"]}`}>
            <label><Translate id="global.visualize" /> <IoChevronForwardSharp size="14px" /></label>
            <ul>
              {explorationTools.map((tool,i)=>
                <React.Fragment key={`tool-${i}`}>
                  { tool.url !== "/help" ?
                    <li>
                      { /\/analysis-tools/.test(tool.url) ?
                        <NavLink activeClassName={styles.active} to={getObjectTo(`/search-innov/${props.match.params.id}${tool.url}`)}><span data-tip data-for={getId(tool)}>{iconCases["/analysis-tools"]}</span></NavLink> :
                        <NavLink activeClassName={styles.active} exact to={getObjectTo(`/search-innov/${props.match.params.id}${tool.url}`)}><span data-tip data-for={getId(tool)}>{iconCases[tool.url]}</span></NavLink>
                      }
                      <ReactTooltip className={`react-tooltip white ${styles["react-tooltip"]}`} delayShow={500} id={getId(tool)} place="bottom" effect='solid'>
                        <span>{tool.title[indexLang]}</span>
                      </ReactTooltip>
                    </li> : null
                  }
                </React.Fragment>
              )}
              {renderHelp()}
            </ul>
          </div>
        }
      </>
    )
  }else{
    return null
  }

}

export default withLocalize(ExplorationNav)
