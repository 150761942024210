import React from 'react'
import PropTypes from 'prop-types';
import styles from './button-docs.module.scss'
import format from 'format-number';
import { useGlobalContext } from '../../../GlobalContext'
import { withLocalize , Translate } from 'react-localize-redux'
import loaderDisabled from '../../../assets/images/loader-white.gif'

const fnFormat = (number) => format({integerSeparator: " "})(number)


const ButtonDocs = ({background = true, text, toggleDocument,documentsIsOpen,countDocs})=> {
  const context = useGlobalContext()[0];
  const documentsReducer = context.documentsReducer;

  function getCount(){
    if(documentsReducer.countDocs === 0) return 0
    else return documentsReducer.countDocs < 10 ? "0"+documentsReducer.countDocs : fnFormat(documentsReducer.countDocs)
  }

  return (
    <div
      className={background ? styles["btn-documents"] : `${styles["btn-documents"]} ${styles["no-background"]}`}
      onClick={()=> toggleDocument(null,undefined,'documents')}
    >
      { text ?
        <>
          {(documentsReducer.countDocs >= 0 && documentsReducer.countDocs !== null)  ?
            <>
              <span dangerouslySetInnerHTML={{ __html : text.replace("%count%", `<span style="font-weight:bold">${getCount()}</span>`)}}/>
            </> :
            <img src={loaderDisabled} alt="loader"/>
          }
        </> :
        <>
          {(documentsReducer.countDocs >= 0 && documentsReducer.countDocs !== null) ?
            <>
              {getCount()}
              <span className={`${styles["sub"]} hide-on-tablet`}>
                <Translate id="global.docs" />
                {/*
                  <span>
                  {documentsIsOpen ?
                      <>(<Translate id="global.hide-docs"/>)</> :
                      <>(<Translate id="global.show-docs"/>)</>
                  }
                </span>
                */}
              </span>
            </> :
            <img src={loaderDisabled} alt="loader"/>
          }
        </>
      }
    </div>
  )
}

ButtonDocs.propTypes = {
  documentsIsOpen : PropTypes.bool,
  toggleDocument : PropTypes.func
}


export default withLocalize(ButtonDocs)
