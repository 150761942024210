import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import queryString from 'query-string'
import usePlateforme from '../../../customHooks/usePlateforme'
import { Translate, withLocalize } from "react-localize-redux";
import { useGlobalContext } from '../../../GlobalContext'
import loader from '../../../assets/images/loader.gif'
import { AiOutlineDownload, AiFillEye } from "react-icons/ai"


const AnalysisTools = (props) => {
  const [ context , dispatch ] = useGlobalContext()
  const filtersReducer = context.filtersReducer

  const filtersJSON = usePlateforme()
  const [ configTools, setConfigTools ] = useState()
  const [ tools, setTools ] = useState()
  const [ toolsTitle, setToolsTitle ] = useState()
  const [ specificToolTitle, setSpecificToolTitle ] = useState()
  const [ specificTool, setSpecificTool ] = useState(false)

  const [ countTools, setCountTools ] = useState()
  const [ indexLang, setIndexLang ] = useState()
  const [ documentsTiles, setDocumentsTiles ] = useState([])

  // RESET ANALYSIS TOOLS REF ON UNMOUNT
  // USEFULL WHEN ITEMS POINT TO A DOCUMENT
  useEffect(()=>{
    return () => {
      dispatch({
        type : "SET_REFERENCES",
        payload : null
      })
    }
  },[])

  // SET TOOLS
  useEffect(()=>{
    if(!filtersJSON || filtersReducer.indexSelectedEntries === null) return;
    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]

    let tools;
    let parseSearch;
    if(props.history.location.search){
      parseSearch = queryString.parse(props.history.location.search)
    }

    //SPECIFIC TOOLS
    if(parseSearch && parseSearch['specific-tool']){
      if(!isNaN(parseInt(parseSearch['specific-tool']))){
        let currentFilter = currentHeaderEntry["dropdown-tools"].find((d,i) => i === parseInt(parseSearch['specific-tool']))
        if(currentFilter){
          tools = currentFilter.tools
          setConfigTools(tools)
          setSpecificToolTitle(currentFilter.title)
          setToolsTitle(null)
          setSpecificTool(parseInt(parseSearch['specific-tool']))
        }
      }

    }else{
      //ALL TOOLS
      setSpecificToolTitle(null)
      setSpecificTool(null)

      if(currentHeaderEntry.tools){
        setConfigTools(currentHeaderEntry.tools)

        //LOOK FOR ITEMS THAT POINT TO A DOCUMENT
        let arrID = currentHeaderEntry.tools[0].items.filter(d => d.id).map(d => ({ ID_QES : d.id}))
        if(arrID.length > 0){
          dispatch({
            type : "SET_REFERENCES",
            payload : arrID
          })
        }
        setDocumentsTiles(arrID)

        setToolsTitle(currentHeaderEntry["tools-title"])
      }else if(!currentHeaderEntry.tools && filtersJSON["tools"]){
        setConfigTools(filtersJSON["tools"])
      }

    }


  },[
    filtersJSON,
    filtersReducer.indexSelectedEntries,
    props.history.location.search
  ])

  useEffect(()=>{
    if(!configTools || !props.allFilters) return;
    let count = 0

    let tools = configTools.map((tool)=>{
      tool.items.forEach((item) =>{
        let found = props.allFilters.find((d)=> d.id === item.code)
        if(found){
          item.label = found.libelle
          count++
        }
      })
      return tool
    })

    count = count + documentsTiles.length

    setCountTools(count)
    setTools(tools)
  },[
    configTools,
    props.allFilters
  ])

  useEffect(()=>{
    let indexLang = props.languages.map((d)=>d.active).indexOf(true);
    setIndexLang(indexLang)
  },[props.languages])

  function goTo(specificTool,item,j){
    if(item.code){
      let parseSearch;
      if(props.history.location.search){
        parseSearch = queryString.parse(props.history.location.search)
      }
  
      let params = typeof specificTool === "number" ? `?specific-tool=${specificTool}&item=${j}` : null
      let url = `/search-innov/${props.match.params.id}/analysis-tools/${item.code}`
      let operator = params ? '&' : '?'
      props.history.push({
        pathname : url,
        search : params ? `${params}${operator}entry=${parseSearch.entry}` : `${operator}entry=${parseSearch.entry}`
      })
    }else if(item.id){
      props.toggleDocument(item.id,true,'analysis-tools')
    }

  }

  function isTouchScreendevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints;      
  };

  return (
    <>
      {props.isFiltersLoading ?
        <div className="overlay-loading"><img src={loader} alt="loading" /></div> :
        <>
          { specificToolTitle &&
            <h3>{specificToolTitle[indexLang]}</h3>
          }
          { toolsTitle &&
            <h3>{toolsTitle["part-1"][indexLang]} <span>{countTools} </span>{toolsTitle["part-2"][indexLang]}</h3>
          }
          <div className="container-tools">
            { tools && tools.map((d,i)=>(
              <div key={`column-${i}`} className={tools.length === 1 ? "grid" : "column"}>
                { d.title &&
                  <p className="title">{d.title[indexLang]}</p>
                }
                { d.items.map((item,j)=>(
                  <div className="container-item" key={`column-${i}-${j}`} >
                    <div className={d.items.length === 1 ? "item square" : "item"} >
                      <p className="filter">
                        {(item.title && item.title[indexLang]) ? item.title[indexLang] : item.label}
                      </p>
                      <p className="filter-hover">
                        {(item.title && item.title[indexLang]) ? item.title[indexLang] : item.label}
                      </p>
                      <div className="image" onClick={()=> goTo(specificTool,item,j)}>
                        {<img src={require(`../../../config/images/tools/${item.img}`)} />}
                      </div>
                      <a className={isTouchScreendevice() ? "see-ressource touch-screen" : "see-ressource"} onClick={()=> goTo(specificTool,item,j)}>
                        <AiFillEye />
                      </a>
                      { item.document &&
                        <a className={isTouchScreendevice() ? "download-file touch-screen" : "download-file"} target="_blank" href={require(`../../../config/${filtersReducer.plateformeID}/documents/${item.document}`)}>
                          <AiOutlineDownload />
                        </a>
                      }
                      { item.text &&
                        <div className="description" onClick={()=> goTo(specificTool,item,j)}>
                          <p>
                            {item.text[indexLang]}
                          </p>
                        </div>
                      }

                    </div>
                  </div>
                ))}
              </div>
            ))}

          </div>
        </>
      }
    </>
  )
}

export default withLocalize(AnalysisTools);
