import { postData } from './index'
import {
   GET_FILTERED_NEWSPAPER,
   REMOVE_NEWSPAPER,
   SET_RUBRIQUE,
   SET_TYPOLOGY,
   SET_ORDER_BY_NEWSPAPER,
   LOADING_NEWSPAPER,
   ERROR_NEWSPAPER
} from './types'

export async function getFilteredNewspaper(dispatch, filters, change = "default"){
  let url = 'doc/filtered/newspaper'
  dispatch({type : LOADING_NEWSPAPER, payload : change})
  await postData(GET_FILTERED_NEWSPAPER, ERROR_NEWSPAPER, true, url , dispatch, filters)
}

export async function removeNewspaper(dispatch){
  dispatch({type : REMOVE_NEWSPAPER})
}

export function setSelectedRubrique(dispatch,obj){
  dispatch({type : SET_RUBRIQUE, payload : obj })
}

export async function orderBy(dispatch, order){
  dispatch({type : SET_ORDER_BY_NEWSPAPER, payload : order})
}

export async function setTypo(dispatch, typo){
  dispatch({type : SET_TYPOLOGY, payload : typo})
}
