import React, { useState, useEffect } from 'react'
import { Translate, withLocalize } from "react-localize-redux";

import * as d3 from 'd3'
import _ from 'lodash'

const Graduation = (props) => {
  const [ widthTimeline ] = useState(20000)
  const [ arrDays, setArrDays ] = useState()
  const [ arrWeeks, setArrWeeks ] = useState()
  const [ arrMonth, setArrMonth ] = useState()

  useEffect(()=>{

    if(!props.timestampMax || !props.timestampMin) return

    ////// SET ARR OF DATA
    // let monthInSec = 2628000
    // let weekInSec = 604800
    let dayInSec = 3600 * 24

    let timestampMax = props.timestampMax;
    let timestampMin = props.timestampMin;

    timestampMin =  new Date(timestampMin.getTime() - (((timestampMin.getDay()-1) * (dayInSec*1000))))


    timestampMin = timestampMin.getTime()/1000
    timestampMax = timestampMax.getTime()/1000

    let scaleOffsetX = d3.scaleLinear()
      .domain([timestampMin,timestampMax])
      .range([0,widthTimeline-230]);

    let total = (timestampMax - timestampMin)/dayInSec
    let arrDays = []

    let monthNamesEn = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let monthNamesFr = ["Janvier", "Février", "Mars", "Avril",
     "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    let monthNames;
    if(props.activeLanguage.code === "fr"){
      monthNames = monthNamesFr
    }else if(props.activeLanguage.code === "en"){
      monthNames = monthNamesEn
    }

    _.times(Math.ceil(total+2),(i)=>{
      let x = timestampMin + (dayInSec*i)
      x = Math.floor(x)
      let date = new Date(x*1000)
      arrDays.push({
        date : date,
        yearString : date.getFullYear(),
        monthString : monthNames[date.getMonth()],
        dayString : date.getDate(),
        dateString : date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear(),
        offsetX : scaleOffsetX(x)})
    })

    let arrMonth = arrDays.filter((d)=>{
      return /^1\//.test(d.dateString)
    })

    let arrWeeks = arrDays.filter((d)=>{
      return d.date.getDay() === 1
    })

    setArrDays(arrDays)
    setArrMonth(arrMonth)
    setArrWeeks(arrWeeks)


  },[
    props.activeLanguage.code,
    props.timestampMax,
    props.timestampMin])

  function renderPositionMonth(d){
    return {left : d+"px"}
  }

  // <div className={ d.monthString === "January" ? 'month-line january' : 'month-line'} key={"timeline-month-line-"+i} style={renderPositionMonth(d.offsetX)}>
  //   <p className="date-line">{d.monthString+" "+d.yearString}</p>
  // </div>

  return(
    <>
      { arrDays && arrWeeks && arrMonth ?
        <>
          <span className="container-month-lines">
            {arrMonth.map((d,i)=>(
              <React.Fragment key={"timeline-month-line-"+i} >
              <div className={ d.monthString === "January" ? 'month-line january' : 'month-line'} key={"timeline-month-line-"+i} style={renderPositionMonth(d.offsetX)}>
                <p className="date-line">{d.monthString+" "+d.yearString}</p>
              </div>
              </React.Fragment>
            ))}
            { props.clusterPeriod === "days" ||
              props.clusterPeriod === "per4Weeks" ||
              props.clusterPeriod === "halfWeeks" ?
              <span className="container-days-lines">
                {arrDays.map((d,i)=>(
                  <div className={"day-line"} key={"timeline-days-line-"+i} style={renderPositionMonth(d.offsetX)}>
                  </div>
                ))}
              </span> : null
            }
            { props.clusterPeriod !== "month" &&
              props.clusterPeriod !== "2weeks" ?
              <span className="container-weeks-lines">
                {arrWeeks.map((d,i)=>(
                  <div className={i === 0 ? "week-line first" : "week-line"} key={"timeline-weeks-line-"+i} style={renderPositionMonth(d.offsetX)}>
                    {i !== 0 && (props.clusterPeriod === "days" || props.clusterPeriod === "per4Weeks" || props.clusterPeriod === "halfWeeks") ?
                      <p className="date-line"><b><Translate id={"timeline.abbrMonday"} /> </b>{d.dateString}</p> : null
                    }
                  </div>
                ))}
              </span> : null
            }
          </span>
        </> : null
      }
    </>
  )
}

export default withLocalize(Graduation)
