export function getPicto(filters){
  if(!filters || !filters.valeurs) return [];
  class Filter{
    constructor(code) {
      this.code = code;
    }
    getCode(){
      return {
        code : this.code,
        equals : (matchingCode) => {
          return this.code === matchingCode
        }
      }
    }
  }

  class Icons{
    constructor(){
      this.pictos = {}
    }
    put(filter,value){
      this.pictos[filter.code] = { picto : value}
    }

  }

  class Colors{
    constructor(){
      this.colors = {}
    }
    put(filter,value){
      this.colors[filter.code] = { color : value}
    }
  }

  let hashIcoNBM = new Icons()
  let hashColNBM = new Colors()
  let hashIcoCEX = new Icons()
  let hashColCEX = new Colors()

  filters.valeurs.forEach((d)=>{
    const lvv = new Filter(d.code)

    if (lvv.getCode().equals("CEX_01_01")) {
      hashColCEX.put(lvv.getCode(), "28bdbe");
      hashIcoCEX.put(lvv.getCode(), "fas star");
    } else if (lvv.getCode().equals("CEX_01_02")) {
      hashColCEX.put(lvv.getCode(), "28bdbe");
      hashIcoCEX.put(lvv.getCode(), "fas space-shuttle");
    } else if (lvv.getCode().equals("CEX_01_04")) {
      hashColCEX.put(lvv.getCode(), "28bdbe");
      hashIcoCEX.put(lvv.getCode(), "fas check");
    } else if (lvv.getCode().equals("CEX_01_08")) {
      hashColCEX.put(lvv.getCode(), "28bdbe");
      hashIcoCEX.put(lvv.getCode(), "fas toolbox");
    } else if (lvv.getCode().equals("CEX_01_09")) {
      hashColCEX.put(lvv.getCode(), "28bdbe");
      hashIcoCEX.put(lvv.getCode(), "fas briefcase-medical");
    } else if (lvv.getCode().equals("CEX_01_11")) {
      hashColCEX.put(lvv.getCode(), "28bdbe");
      hashIcoCEX.put(lvv.getCode(), "fas plus-square");
    } else if (lvv.getCode().equals("CEX_01_12")) {
      hashColCEX.put(lvv.getCode(), "28bdbe");
      hashIcoCEX.put(lvv.getCode(), "fas search");
    } else if (lvv.getCode().equals("CEX_01_13")) {
      hashColCEX.put(lvv.getCode(), "28bdbe");
      hashIcoCEX.put(lvv.getCode(), "fab pagelines");
    } else if (lvv.getCode().equals("CEX_02_01")) {
      hashColCEX.put(lvv.getCode(), "f5834e");
      hashIcoCEX.put(lvv.getCode(), "fab twitter");
    } else if (lvv.getCode().equals("CEX_02_02")) {
      hashColCEX.put(lvv.getCode(), "f5834e");
      hashIcoCEX.put(lvv.getCode(), "fas coffee");
    } else if (lvv.getCode().equals("CEX_02_03")) {
      hashColCEX.put(lvv.getCode(), "f5834e");
      hashIcoCEX.put(lvv.getCode(), "fas grin-stars");
    } else if (lvv.getCode().equals("CEX_02_04")) {
      hashColCEX.put(lvv.getCode(), "f5834e");
      hashIcoCEX.put(lvv.getCode(), "fas award");
    } else if (lvv.getCode().equals("CEX_02_05")) {
      hashColCEX.put(lvv.getCode(), "f5834e");
      hashIcoCEX.put(lvv.getCode(), "fas calendar-alt");
    } else if (lvv.getCode().equals("CEX_02_07")) {
      hashColCEX.put(lvv.getCode(), "f5834e");
      hashIcoCEX.put(lvv.getCode(), "fas tablet-alt");
    } else if (lvv.getCode().equals("CEX_02_08")) {
      hashColCEX.put(lvv.getCode(), "f5834e");
      hashIcoCEX.put(lvv.getCode(), "fas globe");
    } else if (lvv.getCode().equals("CEX_02_09")) {
      hashColCEX.put(lvv.getCode(), "f5834e");
      hashIcoCEX.put(lvv.getCode(), "fas male");
    } else if (lvv.getCode().equals("CEX_03_01")) {
      hashColCEX.put(lvv.getCode(), "39bb9d");
      hashIcoCEX.put(lvv.getCode(), "fas search-minus");
    } else if (lvv.getCode().equals("CEX_03_03")) {
      hashColCEX.put(lvv.getCode(), "39bb9d");
      hashIcoCEX.put(lvv.getCode(), "fas users");
    } else if (lvv.getCode().equals("CEX_03_04")) {
      hashColCEX.put(lvv.getCode(), "39bb9d");
      hashIcoCEX.put(lvv.getCode(), "fas heartbeat");
    } else if (lvv.getCode().equals("CEX_03_06")) {
      hashColCEX.put(lvv.getCode(), "39bb9d");
      hashIcoCEX.put(lvv.getCode(), "fas recycle");
    } else if (lvv.getCode().equals("CEX_03_07")) {
      hashColCEX.put(lvv.getCode(), "39bb9d");
      hashIcoCEX.put(lvv.getCode(), "fas user-check");
    } else if (lvv.getCode().equals("CEX_04_01")) {
      hashColCEX.put(lvv.getCode(), "f1b810");
      hashIcoCEX.put(lvv.getCode(), "fas file-invoice-dollar");
    } else if (lvv.getCode().equals("CEX_04_02")) {
      hashColCEX.put(lvv.getCode(), "f1b810");
      hashIcoCEX.put(lvv.getCode(), "fas coins");
    } else if (lvv.getCode().equals("CEX_04_03")) {
      hashColCEX.put(lvv.getCode(), "f1b810");
      hashIcoCEX.put(lvv.getCode(), "fas hand-holding-usd");
    } else if (lvv.getCode().equals("CEX_04_04")) {
      hashColCEX.put(lvv.getCode(), "f1b810");
      hashIcoCEX.put(lvv.getCode(), "fas chart-line");
    } else if (lvv.getCode().equals("CEX_04_05")) {
      hashColCEX.put(lvv.getCode(), "f1b810");
      hashIcoCEX.put(lvv.getCode(), "fas money-bill-alt");
    }

    if (lvv.getCode().equals("NBM_01_01")) {
      hashColNBM.put(lvv.getCode(), "e31d48");
      hashIcoNBM.put(lvv.getCode(), "fas shopping-cart");
    } else if (lvv.getCode().equals("NBM_01_02")) {
      hashColNBM.put(lvv.getCode(), "e31d48");
      hashIcoNBM.put(lvv.getCode(), "fas share-alt");
    } else if (lvv.getCode().equals("NBM_01_03")) {
      hashColNBM.put(lvv.getCode(), "e31d48");
      hashIcoNBM.put(lvv.getCode(), "fas signature");
    } else if (lvv.getCode().equals("NBM_01_04")) {
      hashColNBM.put(lvv.getCode(), "e31d48");
      hashIcoNBM.put(lvv.getCode(), "fas hands-helping");
    } else if (lvv.getCode().equals("NBM_02_01")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fas key");
    } else if (lvv.getCode().equals("NBM_02_02")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fab angellist");
    } else if (lvv.getCode().equals("NBM_02_03")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fab creative-commons-nc");
    } else if (lvv.getCode().equals("NBM_02_04")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fas building");
    } else if (lvv.getCode().equals("NBM_02_05")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fas dollar-sign");
    } else if (lvv.getCode().equals("NBM_02_06")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fas sitemap");
    } else if (lvv.getCode().equals("NBM_02_07")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fab slideshare");
    } else if (lvv.getCode().equals("NBM_02_08")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fas file-code");
    } else if (lvv.getCode().equals("NBM_02_10")) {
      hashColNBM.put(lvv.getCode(), "019edf");
      hashIcoNBM.put(lvv.getCode(), "fas exchange-alt");
    } else if (lvv.getCode().equals("NBM_03_01")) {
      hashColNBM.put(lvv.getCode(), "b44398");
      hashIcoNBM.put(lvv.getCode(), "fas money-check-alt");
    } else if (lvv.getCode().equals("NBM_03_02")) {
      hashColNBM.put(lvv.getCode(), "b44398");
      hashIcoNBM.put(lvv.getCode(), "fas credit-card");
    } else if (lvv.getCode().equals("NBM_03_03")) {
      hashColNBM.put(lvv.getCode(), "b44398");
      hashIcoNBM.put(lvv.getCode(), "fas crosshairs");
    } else if (lvv.getCode().equals("NBM_03_04")) {
      hashColNBM.put(lvv.getCode(), "b44398");
      hashIcoNBM.put(lvv.getCode(), "fas object-ungroup");
    } else if (lvv.getCode().equals("NBM_03_05")) {
      hashColNBM.put(lvv.getCode(), "b44398");
      hashIcoNBM.put(lvv.getCode(), "fas mouse-pointer");
    } else if (lvv.getCode().equals("NBM_03_06")) {
      hashColNBM.put(lvv.getCode(), "b44398");
      hashIcoNBM.put(lvv.getCode(), "fas calendar-check");
    } else if (lvv.getCode().equals("NBM_04_01")) {
      hashColNBM.put(lvv.getCode(), "921140");
      hashIcoNBM.put(lvv.getCode(), "fas handshake");
    } else if (lvv.getCode().equals("NBM_04_02")) {
      hashColNBM.put(lvv.getCode(), "921140");
      hashIcoNBM.put(lvv.getCode(), "fas gem");
    } else if (lvv.getCode().equals("NBM_04_03")) {
      hashColNBM.put(lvv.getCode(), "921140");
      hashIcoNBM.put(lvv.getCode(), "fas file-signature");
    } else if (lvv.getCode().equals("NBM_04_04")) {
      hashColNBM.put(lvv.getCode(), "921140");
      hashIcoNBM.put(lvv.getCode(), "fas receipt");
    } else if (lvv.getCode().equals("NBM_04_05")) {
      hashColNBM.put(lvv.getCode(), "921140");
      hashIcoNBM.put(lvv.getCode(), "fas level-up-alt");
    } else if (lvv.getCode().equals("NBM_04_06")) {
      hashColNBM.put(lvv.getCode(), "921140");
      hashIcoNBM.put(lvv.getCode(), "fas comments");
    } else if (lvv.getCode().equals("NBM_04_07")) {
      hashColNBM.put(lvv.getCode(), "921140");
      hashIcoNBM.put(lvv.getCode(), "fas infinity");
    }

  })

  let map
  if(Object.values(hashIcoNBM.pictos).length > 0 && Object.values(hashColNBM.colors).length > 0){
    map = getMap(hashIcoNBM.pictos,hashColNBM.colors)
  }

  if(Object.values(hashIcoCEX.pictos).length > 0 && Object.values(hashColCEX.colors).length > 0){
    map = getMap(hashIcoCEX.pictos,hashColCEX.colors)
  }

  return map


  function getMap(pictos,colors){
    let map = new Map()
    let merge = (obj1, obj2) => ({...obj1, ...obj2});

    let arrKeys = Object.keys(pictos);
    arrKeys.forEach((key)=>{
      let obj = merge(colors[key],pictos[key])
      map.set(key,obj)
    })

    return map
  }

}
