import { useState, useEffect } from 'react'
import _ from "lodash"
import { filtersParams } from '../helpers/filtersParams'
import { useGlobalContext } from '../GlobalContext'

import { getReferences } from '../actions/references'

export default function useListDocuments(context,reducer,docs,getDocs,removeDocs,defaultRubrique = null, viewFilters){
  const dispatch = useGlobalContext()[1]
  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;
  const documents = docs;

  // ACTIONS
  const _getReferences = (filter,page,nameReducer) => getReferences(dispatch, filter, page, nameReducer)

  const [ stateDocuments , setStateDocuments ] = useState(null)
  const [ disabledHitBottom, setDisabledHitBottom ] = useState(false)
  const [ init, setInit ] = useState(true)

  useEffect(()=>{
    return () => {
      if(removeDocs){
        removeDocs()
      }
    }
  },[filtersReducer.selectedHeaderEntries])

  useEffect(()=>{
    if(documents === null &&
      filtersReducer.date.min.date &&
      filtersReducer.date.max.date){
        getInitDocuments()
    }

    if(documents !== null && documents.length>0){
      let timer = setTimeout(()=>{
        setDisabledHitBottom(false)
        clearTimeout(timer)
      },700)

      let result = filtersParams(filtersReducer,viewReducer.lang,reducer.number)

      setStateDocuments(documents)
      setInit(false)
    }

    if(documents === null || (documents !== null && documents.length === 0)){
      setStateDocuments(null)
    }

  },[
    defaultRubrique,
    viewReducer.lang,
    documents,
    reducer.number,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date])

  useEffect(()=>{
    if(filtersReducer.date.min.date && filtersReducer.date.max.date && !init){
      let result = filtersParams(filtersReducer,viewReducer.lang,reducer.number,viewFilters)
      // console.log('getDocuments update');
      let filters = _.cloneDeep(result.filters);
      filters.orderBy = reducer.orderBy

      if(!viewFilters && reducer.selectedRubrique){
        let rubrique = reducer.selectedRubrique
        filters.filters = [ ...filters.filters, {code : rubrique.code , id : rubrique.id}]
      }

      if(!viewFilters && defaultRubrique){
        let foundRubrique = filters.filters.find((filter) => filter.id === "RUBRIQUE")
        if(!foundRubrique){
          let obj = {}
          defaultRubrique.forEach((d)=>{
            if(!obj[d.id]){
              obj[d.id] = { ...d }
            }else{
              obj[d.id].code += "%20OR%20"+d.code
            }
          })
          if(obj.RUBRIQUE) filters.filters = [ ...filters.filters, obj.RUBRIQUE]
        }
      }

      getDocuments(filters,"change")
    }
  },[
    defaultRubrique,
    viewReducer.lang,
    filtersReducer.selectedKeywords,
    filtersReducer.search,
    filtersReducer.filteredByDate,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date])

  function scroll(e){

    if(!disabledHitBottom && !reducer.isLoading &&
      stateDocuments !== null && stateDocuments.length < reducer.countDocs  &&
      reducer.countDocs > reducer.number &&
      e.target.scrollHeight-e.target.offsetHeight < Math.ceil(e.target.scrollTop)+1){
      // PREPARE OBJECT TO FILTER FOR NEXT DOCS (increment page)
      // let result = filtersParams(filtersReducer)

      let result = filtersParams(filtersReducer,viewReducer.lang,reducer.number,viewFilters)

      let filters = _.cloneDeep(result.filters);
      let targetPage = reducer.page
      targetPage = targetPage+1
      filters.page = targetPage
      filters.orderBy = reducer.orderBy

      if(reducer.selectedRubrique){
        let rubrique = reducer.selectedRubrique
        filters.filters = [ ...filters.filters, {code : rubrique.code , id : rubrique.id}]
      }

      if(!viewFilters && defaultRubrique){
        let foundRubrique = filters.filters.find((filter) => filter.id === "RUBRIQUE")
        if(!foundRubrique){
          let obj = {}
          defaultRubrique.forEach((d)=>{
            if(!obj[d.id]){
              obj[d.id] = { ...d }
            }else{
              obj[d.id].code += "%20OR%20"+d.code
            }
          })
          if(obj.RUBRIQUE) filters.filters = [ ...filters.filters, obj.RUBRIQUE]
        }
      }


      setDisabledHitBottom(true)
      getDocuments(filters,"page")
    }

  }

  function getInitDocuments(number,change = 'init'){
    // NUMBER = number of docs per load
    // default number is 30 (filtersParams.js)

    let result = filtersParams(filtersReducer,viewReducer.lang,reducer.number,viewFilters)
    result.filters.orderBy = reducer.orderBy

    if(number){
      result.filters.number = number
    }

    if(!viewFilters && defaultRubrique){
      let obj = {}
      defaultRubrique.forEach((d)=>{
        if(!obj[d.id]){
          obj[d.id] = { ...d }
        }else{
          obj[d.id].code += "%20OR%20"+d.code
        }
      })

      if(obj.RUBRIQUE) result.filters.filters = [ ...result.filters.filters, obj.RUBRIQUE]
    }

    getDocuments(result.filters,change)

  }

  async function getDocuments(filters,change){
    // change == init|page|orderBy|count| change

    // reducer.nameReducer
    // deternime which reducer should be use to save references
    // console.log('getDocuments', change);

    if(filters.filters[0] === undefined && filters.filters.length > 0) return;

    if( change !== "orderBy" && reducer.pageRef !== filters.page){
      _getReferences({...filters},filters.page,reducer.nameReducer)
    }else if(change === "orderBy" || change === "count" || change === "change"){
      _getReferences({...filters},filters.page,reducer.nameReducer)
    }

    await getDocs(filters,{change})
  }

  return {
    documents : stateDocuments,
    isLoading : reducer.isLoading,
    countDocs : reducer.countDocs,
    disabledHitBottom,
    setDisabledHitBottom,
    scroll,
    getInitDocuments,
    getDocuments
  }



}
