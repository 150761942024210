import React, { useState, useEffect, useMemo, useRef } from "react"
import { Route } from "react-router-dom";
import queryString from 'query-string'

import useContentDimension from '../../customHooks/useContentDimension'
import { useGlobalContext } from '../../GlobalContext';
import { fnSelectEntry } from '../header/helpers'
import usePlateforme from '../../customHooks/usePlateforme'
import history from '../../history'
import ExplorationNav from './ExplorationNav'

// ROUTE
import Home from './home'
import LinksExploration from './links_exploration/LinksExploration'
import Dashboard from './dashboard/Dashboard'
import Geolocalisation from './geolocalisation/Geolocalisation'
import WordsCloud from './words_cloud/WordsCloud'
import TimelineCluster from './timelineCluster/TimelineCluster'
import Partenerships  from './partenerships/Partenerships'
import StrategicWatch from './strategic_watch/StrategicWatch'
import AnalysisToolsSwitch from './analysis-tools/AnalysisToolsSwitch'
import Help from './help/Help'
import About from './about/About'


const Exploration = (props) => {
  const contentDimension = useContentDimension()
  const [context, dispatch] = useGlobalContext();
  const viewReducer = context.viewReducer;
  const filtersReducer = context.filtersReducer;
  const filtersJson = usePlateforme()
  const locationKeys = useRef([])
  const [ showSearchbar, setShowSearchbar ] = useState(false)
  const [ hideHeaderButtons, setHideHeaderButtons ] = useState(false)
  const [ view, setView ] = useState()

  // const [ expand, setExpand ] = useState(true)

  // function toggleView(){
  //   if(!expand ){
  //     setExpand(true)
  //     dispatch({type : "setView", payload : {explorationView: "expand", intelligenceView: "reduce"}})
  //   }else if (expand) {
  //     setExpand(null)
  //     dispatch({type : "setView", payload : {explorationView: "", intelligenceView: ""}})
  //   }
  // }


    const _fnSelectEntry = (entry,indexSelectedEntries,index,url) => {
      return (
        fnSelectEntry(
          history,
          filtersJson,
          dispatch,
          entry,
          indexSelectedEntries,
          index,
          url
        )
      )
    }

  useEffect(()=>{
    if(!filtersJson) return;
    const unlisten = history.listen((location)=>{
      if (history.action === 'PUSH') {
        locationKeys.current = [ location.key ]
      }

      let entry = queryString.parse(location.search)?.entry

      if (history.action === 'POP') {
        if(locationKeys.current[1] === location.key){

          locationKeys.current = locationKeys.current.filter( (key,i) => i !== 0 )

          if(entry && parseInt(entry) !== filtersReducer.indexSelectedEntries){
            // updateEntries(parseInt(entry))
            document.location.reload();
          }

        }else{
          locationKeys.current = [ location.key, ...locationKeys.current ]

          if(entry && parseInt(entry) !== filtersReducer.indexSelectedEntries){
            // updateEntries(parseInt(entry))
            document.location.reload();
          }

        }
      }

    })

    return () => {
      unlisten();
    }

  },[
    filtersJson,
    filtersReducer.indexSelectedEntries
  ])

  // function updateEntries(index){
  //   let entry = filtersJson["header"].find((d,i) => {
  //     return i === index
  //   })
  //
  //   let entries = entry.rubrique.map((d)=>(
  //     {code : d, id : "RUBRIQUE"}
  //   ))
  //
  //   let defaultFilters;
  //   if(entry.filters){
  //     defaultFilters = entry.filters.map((d)=>{
  //       return d
  //     })
  //   }
  //
  //   let defaultExcludedFilters;
  //   if(entry["filters-exclude"]){
  //     defaultExcludedFilters = entry["filters-exclude"].map((d)=>{
  //       return d
  //     })
  //   }
  //
  //   dispatch({
  //     type : "SET_HEADER_ENTRIES",
  //     payload : {
  //       entries,
  //       defaultFilters,
  //       defaultExcludedFilters,
  //       index
  //     }
  //   })
  // }

  useEffect(()=>{
    if(!filtersJson) return;

    let view;
    if(filtersJson["header"][filtersReducer.indexSelectedEntries].navbar){
      view = filtersJson["header"][filtersReducer.indexSelectedEntries].navbar.find(d=>{
        if(d.url === "/") return false
        else{
          let rePage = new RegExp(`${props.match.params.page}`)
          let re = new RegExp(`${d.url}`)
          return re.test(props.pathname)
        }
      })

      let bool = !!filtersJson["header"][filtersReducer.indexSelectedEntries].hideHeaderButtons
      setHideHeaderButtons(bool)

      

      if(view) {
        setShowSearchbar(!!view.showSearchbar)
        setView(view)
      }
    }

  },[filtersJson,filtersReducer.indexSelectedEntries,props.pathname])

  function renderClass(){
    if(props.pathname === `/search-innov/${props.match.params.id}/timeline`){
      return "content-timeline"
    }else if(props.pathname === `/search-innov/${props.match.params.id}/dashboard` ||
      props.pathname === `/search-innov/${props.match.params.id}/strategic-watch` ||
      props.pathname === `/search-innov/${props.match.params.id}/help`
    ){
      return "content-centered"
    }else if(
      props.pathname === `/search-innov/${props.match.params.id}/partnerships` ||
      props.pathname === `/search-innov/${props.match.params.id}/links-exploration`
    ){
      return "content-centered large "
    }else if(
      props.pathname === `/search-innov/${props.match.params.id}/about`
    ){
      return "content-centered about"
    }else{
      return "content-centered "
    }
  }

  return useMemo(()=>{
    let viewFilters;
    if(view?.url === "/strategic-watch"){
      if(view.filters || view.rubrique){
        viewFilters = [];
        if(view.filters) viewFilters.push(...view.filters);
        if(view.rubrique) view.rubrique.forEach((d) => viewFilters.push({id: "RUBRIQUE", code: d}));
      }else{
        viewFilters = null;
      }
    }
    return (
      <div className={"exploration "+viewReducer.explorationView+" "+viewReducer.lightmode}>
          <ExplorationNav match={props.match}/>
          {/*<p className="title">Exploration</p>*/}
          {/*<button className="toggleView" onClick={toggleView}><img src={IconFullscreen} alt="toggle fullscreen"/></button>*/}
          <div className={renderClass()} id="container-exploration" style={{height : contentDimension.height}}>
            <Route exact path={`/search-innov/${props.match.params.id}/home`} render={() => <Home match={props.match} toggleDocument={props.toggleDocument} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen}/>} />
    
            <Route exact path={`/search-innov/${props.match.params.id}/analysis-tools/:tools?/:code?`} render={() => <AnalysisToolsSwitch showSearchbar={showSearchbar} hideHeaderButtons={hideHeaderButtons} entries={props.entries} match={props.match} toggleDocument={props.toggleDocument} documentsIsOpen={props.documentsIsOpen} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen} />} />
            {(viewFilters === null || Array.isArray(viewFilters)) && <Route exact path={`/search-innov/${props.match.params.id}/strategic-watch/:doc_id?`} render={() => <StrategicWatch showSearchbar={showSearchbar} hideHeaderButtons={hideHeaderButtons} entries={props.entries} match={props.match} toggleDocument={props.toggleDocument} documentsIsOpen={props.documentsIsOpen} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen} viewFilters={viewFilters}/>} />}
            <Route exact path={`/search-innov/${props.match.params.id}/dashboard`} render={() => <Dashboard match={props.match} showSearchbar={showSearchbar} hideHeaderButtons={hideHeaderButtons} entries={props.entries} toggleDocument={props.toggleDocument} documentsIsOpen={props.documentsIsOpen} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen}/>} />
            <Route exact path={`/search-innov/${props.match.params.id}/links-exploration`} render={(p) => <LinksExploration match={props.match} showSearchbar={showSearchbar} hideHeaderButtons={hideHeaderButtons} entries={props.entries} location={p.location} toggleDocument={props.toggleDocument} documentsIsOpen={props.documentsIsOpen} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen}/>}/>
            <Route exact path={`/search-innov/${props.match.params.id}/geolocalisation`} render={() => <Geolocalisation match={props.match} showSearchbar={showSearchbar} hideHeaderButtons={hideHeaderButtons} entries={props.entries} toggleDocument={props.toggleDocument} documentsIsOpen={props.documentsIsOpen} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen}/>} />
            <Route exact path={`/search-innov/${props.match.params.id}/wordscloud`} render={() => <WordsCloud match={props.match} showSearchbar={showSearchbar} hideHeaderButtons={hideHeaderButtons} entries={props.entries} toggleDocument={props.toggleDocument} documentsIsOpen={props.documentsIsOpen} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen} />} />
            <Route exact path={`/search-innov/${props.match.params.id}/timeline`} render={() => <TimelineCluster match={props.match} showSearchbar={showSearchbar} hideHeaderButtons={hideHeaderButtons} entries={props.entries} toggleDocument={props.toggleDocument} documentsIsOpen={props.documentsIsOpen} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen} />} />
            <Route exact path={`/search-innov/${props.match.params.id}/partnerships`} render={() => <Partenerships match={props.match} showSearchbar={showSearchbar} hideHeaderButtons={hideHeaderButtons} entries={props.entries} toggleDocument={props.toggleDocument} documentsIsOpen={props.documentsIsOpen} toggleFilter={props.toggleFilter} filterIsOpen={props.filterIsOpen} />} />
            <Route exact path={`/search-innov/${props.match.params.id}/help`} render={() => <Help match={props.match}/>} />
            <Route exact path={`/search-innov/${props.match.params.id}/about`} render={() => <About match={props.match}/>} />
          </div>
      </div>
    )
  },[
    view,
    hideHeaderButtons,
    showSearchbar,
    props.entries,
    props.pathname,
    viewReducer.explorationView,
    viewReducer.lightmode,
    contentDimension.height,
    props.filterIsOpen
  ])

}

export default Exploration
