import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { debounce } from 'lodash';
import { withLocalize } from 'react-localize-redux'
import styles from './home.module.scss'
import LastContent from './LastContent'
import BlockLink from './BlockLink'
import BlockTags from './BlockTags'
import BlockFilter from './BlockFilter'
import BlockShortcut from './BlockShortcut'
import SearchByKeywords from '../../partials/SearchByKeywords'
import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'
import ExplorationNav2 from '../../partials/ExplorationNav2'
import usePlateforme from '../../../customHooks/usePlateforme'
import { useGlobalContext } from '../../../GlobalContext'
import { getIndexLang } from '../../../helpers/helpers'

import homeTranslations from "../../../assets/translations/home.json";

const Home = ({
  documentsIsOpen,
  toggleDocument,
  toggleFilter,
  match,
  history,
  activeLanguage
}) => {
  const context  = useGlobalContext()[0];
  const viewReducer = context.viewReducer;
  const filtersReducer = context.filtersReducer;
  const filtersJSON = usePlateforme()
  const [ config, setConfig ] = useState()
  const [ isStrategicView, setIsStrategicView ] = useState(null)
  const [ heightBlockRight, setHeightBlockRight ] = useState()

  //HANDLE HIDE HEADER BUTTON
  useEffect(()=>{
    if(!filtersJSON || filtersReducer.indexSelectedEntries === null) return;

    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]
    let configHome = currentHeaderEntry?.home
    setConfig(configHome)

    let findStrategic = !!currentHeaderEntry?.navbar.find(d => d.url === '/strategic-watch')
    setIsStrategicView(findStrategic)

  },[filtersJSON,filtersReducer.indexSelectedEntries])

  useLayoutEffect(() => {
    if(!window.innerWidth || !window.innerHeight) return;
    let header = document.querySelector('.App #header');
    let topbar = document.querySelector('.home .container-search');
    let headerHeight = topbar.offsetHeight + header.offsetHeight;
    setHeightBlockRight(window.innerHeight - headerHeight);
  }, [window.innerWidth, window.innerHeight])

  function getBlock(block,i){
    let type = block.type
    let elt
    switch (type) {
      case "block-filter":
        elt = getBlocksFilters(block,i)
      break;
      case "block-shortcut":
        elt = getBlocksShortcut(block,i)
      break;
      case "block-link":
          elt = (
            <div className={`${styles.row} ${i === 0 ? `${styles["first-block-link"]}` : ''}`} key={`block-${i}`}>
              <BlockLink 
                layout={block.layout}
                title={block.title}
                punchline={block.punchline}
                text={block.text}
                video={block.video ? require(`../../../config/${filtersReducer.plateformeID}/espaces/home-page/${block.video}`) : null}
                img={block.img ? require(`../../../config/${filtersReducer.plateformeID}/espaces/home-page/${block.img}`) : null}
                url={block.url}
                link={block["link-to"]}
                filtersJSON={filtersJSON}
              />
            </div>
          )
        break;
      case "block-tags":
        elt = (
          <div className={`${styles.row} ${i === 0 ? `${styles["first-block-tags"]}` : ''}`} key={`block-${i}`}>
          <BlockTags      
              filter={block.filter}
              linkTo={block["link-to"]}
              filtersJSON={filtersJSON}
            />
          </div>
        )
        break;
      default:
        elt = null
        break;
    }
    return elt
  }


  function getBlocksShortcut(block,i){
    let arrElts = []

    arrElts = block.blocks.map((d,j)=>(
      <li key={`block-${i}-${j}`} className={styles.block}>
        <BlockShortcut
          key={`block-shortcut-${i}-${j}`}
          title={d.title}
          text={d.text}
          img={d.img ? require(`../../../config/${filtersReducer.plateformeID}/espaces/home-page/${d.img}`) : null}
          picto={d.picto ? require(`../../../config/${filtersReducer.plateformeID}/espaces/home-page/${d.picto}`) : null}
          link={d["link-to"]}
        />
      </li>
      ))

    return (
      <div 
        className={`${styles["container-block-shortcut"]} ${i === 0 ? `${styles["first-block-shortcut"]}` : ''}`}
        key={`block-shortcut-container-${i}`}
      >
        <div className={styles["block-title"]}>
          <h3>{block.title[getIndexLang(activeLanguage)]}</h3>
        </div>
        <ul>
          {arrElts}
        </ul>
      </div>
    )
  }

  function getBlocksFilters(block,i){
    let arrElts = []

    arrElts = block.blocks.map((d,j)=>(
      <li key={`block-${i}-${j}`} className={styles.block}>
        <BlockFilter
          id={`block-${i}-${j}`}
          toggleDocument={toggleDocument}
          tooltip={d.tooltip}
          noResult={d["no-result"]}
          defaultFilters={d.filters}
          requestReference={d.getReference}
        />
      </li>
      ))

    return (
      <div 
        className={`${styles["container-block-filter"]} ${i === 0 ? `${styles["first-block-filter"]}` : ''}`} 
        key={`block-filter-container-${i}`}
      >
        <div className={styles["block-title"]}>
          <h3>{block.title[getIndexLang(activeLanguage)]}</h3>
        </div>
        <ul>
          {arrElts}
        </ul>
      </div>
    )
  }

  function getClassNameLightMode(){
    return viewReducer.lightmode === "light" ? ` ${styles.light}` : ''
  }

  // function handleScroll(e) {
  //   let { target } = e
  //   let firstChild = target.childNodes[0]
  //   let offsetScrollY = firstChild.getBoundingClientRect().y-target.getBoundingClientRect().y
  //   // console.log(offsetScrollY)
  // }

  return (
    <div className={`${styles["home"]} home ${getClassNameLightMode()}`}>
      <div className={viewReducer.lightmode === "light" ? `container-search ${styles["container-search"]} light` : `container-search ${styles["container-search"]}`}>
        <div className="container-left">
          <ButtonFilters
            toggleFilter={toggleFilter}
          />
          <SearchByKeywords
            testId={"global"}
            className={"header"}
            iconPosition={"right"}
            isMulti={true}
            isClearable={true}
            placeholder={"Filter par mots clés"}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
          />
        </div>
        <div className="container-right">
          <ExplorationNav2 history={history} match={match} />
          <ButtonDocs
              documentsIsOpen={documentsIsOpen}
              toggleDocument={toggleDocument}
            />
        </div>
      </div>
      {(config && (typeof heightBlockRight === "number" || window.innerWidth < 1024)) &&
        <div className={styles["container"]}>
          <div
           className={!config["block-latest-content"] ? `${styles["container-block"]} ${styles.fullwidth}` : styles["container-block"]}
           style={window.innerWidth < 1024 ? {} : {height : `${heightBlockRight}px`}}
          >
            {config.blocks.map((block,i) => getBlock(block,i))}
          </div>
          { (typeof isStrategicView === "boolean" && config["block-latest-content"]) &&
              <LastContent
                blockTitle={homeTranslations.home["latest-content"]}
                noResult={homeTranslations.home["latest-content-no-result"]}
                rubriques={config["block-latest-content"].rubriques}
                espaces={config["block-latest-content"].espaces}
              />
            }
        </div>
      }

    </div>
  )
}


export default withLocalize(Home)
