import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import { NavLink } from "react-router-dom"
import { Translate, withLocalize  } from 'react-localize-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useGlobalContext } from '../../GlobalContext'
import usePlateforme from '../../customHooks/usePlateforme'
import LanguageToggle from '../partials/LanguageToggle'
import ToggleDarkMode from '../partials/ToggleDarkMode'
import SelectPlatforms from './SelectPlatforms'

import { fnSelectEntry, fnLogout } from './helpers'

import iconLogout from '../../assets/images/icon-logout.svg'
import iconAbout from '../../assets/images/icon-about.svg'
import logoBackOffice from '../../assets/images/backoffice.svg'
import imgGoogle from '../../assets/images/icon-google2.svg'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

import googleCustomLink from "../googleCustomLink.json"
import plateformesID from "../../config/list-plateforms-id.json"

const ListEntries = ({languages,activeLanguage, history, match,entriesOpened, toggleEntries}) =>{
  const filtersJSON = usePlateforme()
  const [context, dispatch] = useGlobalContext();
  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;

  const [ user , setUser ] = useState(null)
  const [ indexLang, setIndexLang ] = useState()
  const [ entries, setEntries ] = useState()
  const [ paramEntry, setParamsEntry] = useState()
  const [ dropdownOpened, setDropdownOpened] = useState([])
  const [ googleCustom , setGoogleCustom ] = useState(null)
  const [ plateforme , setPlateforme ] = useState(null)

  const _fnSelectEntry = (entry,indexSelectedEntries,index,url) => {
    return (
      fnSelectEntry(
        history,
        filtersJSON,
        dispatch,
        entry,
        indexSelectedEntries,
        index,
        url
      )
    )
  }

  useEffect(()=>{
    let plateformeID = JSON.parse(localStorage.getItem("plateformeID"))

    let found = JSON.parse(localStorage.getItem("plateformes")).filter((plateforme)=>{
      return plateformesID.includes(plateforme.id)
    })

    let plateforme = found.find((plateforme)=>{
      return plateforme.id === plateformeID
    })
    setPlateforme(plateforme)
    setGoogleCustom(googleCustomLink.links[plateformeID])

  },[])

  // USE PARAM ENTRY IF EXIST AND CORRESPONDING
  // TO AN ENTRIES WITH RUBRIQUE
  useEffect(()=>{
    if(!filtersJSON) return;
    if(history.location.search){
      let parseSearch = queryString.parse(history.location.search)
      if(parseSearch.entry && !isNaN(parseSearch.entry)){
        let index = parseInt(parseSearch.entry)
        let find = filtersJSON["header"].find((d,i) => {
          return i === index && d.rubrique
        })
        if(find){
           setParamsEntry(parseInt(parseSearch.entry))
        }else{
          setParamsEntry(getDefaultIndex())
        }
      }
    }else{
      setParamsEntry(getDefaultIndex())
    }

    function getDefaultIndex (){
      let defaultIndex;
      let defaultEntries = filtersJSON["header"].find((d,i) => {
        if(d.rubrique) defaultIndex = i
        return d.rubrique
      })
      return defaultIndex
    }

  },[filtersJSON])


  useEffect(()=>{
    if(!filtersJSON || paramEntry === undefined || filtersReducer.indexSelectedEntries !== null) return;
    let index = paramEntry;

    let defaultEntries = filtersJSON["header"].find((d,i) => {
      return i === index
    })

    let entries = defaultEntries.rubrique.map((d)=>(
      {code : d, id : "RUBRIQUE"}
    ))

    let defaultFilters;
    if(defaultEntries.filters){
      defaultFilters = defaultEntries.filters.map((d)=>{
        return d
      })
    }

    let defaultExcludedFilters;
    if(defaultEntries["filters-exclude"]){
      defaultExcludedFilters = defaultEntries["filters-exclude"].map((d)=>{
        return d
      })
    }

    dispatch({
      type : "SET_HEADER_ENTRIES",
      payload : {
        entries,
        defaultFilters,
        defaultExcludedFilters,
        index
      }
    })

  },[
    filtersJSON,
    paramEntry,
    filtersReducer.indexSelectedEntries
  ])

  useEffect(()=>{
    if(!filtersJSON) return;
    let indexLang = languages.map((d)=>d.active).indexOf(true);
    setIndexLang(indexLang)
    let found = filtersJSON["header"].filter(d => d.visibility || d.visibility === undefined)
    setEntries(found)

  },[filtersJSON,activeLanguage])

  useEffect(()=>{
    let user = JSON.parse(localStorage.getItem("user"))
    setUser(user)
  },[])

  function selectEntry(d,index,url){

    
    if(!d.url && !url && d["dropdown-tools"]){
      if(d["dropdown-tools"][0]){
        _fnSelectEntry(d,filtersReducer.indexSelectedEntries,index,
        `/search-innov/${filtersReducer.plateformeID}/analysis-tools?specific-tool=0`)
      }
    }if(d.url && !url){
      _fnSelectEntry(d,filtersReducer.indexSelectedEntries,index,
      `/search-innov/${filtersReducer.plateformeID}${d.url}`)
    }else{
      _fnSelectEntry(d,filtersReducer.indexSelectedEntries,index,url)
    }
    toggleEntries()
  }

  function gotoPlatform(link,index){
    //GO TO AN OTHER PLATFORM
    let operator = new RegExp('\\?').test(link.url) ? '&' : '?'
    history.push(`/search-innov/${link["platform-id"]}${link.url}${operator}entry=${index}`)
    document.location.reload();
  }

  function renderDropdown(d,i){
    if(d["dropdown-tools"]){
      // <li key={`dropdown-tool-${i}-${u}`} onClick={() => setActiveEntry(i,url)}>
      return (
        <ul>
          { d["dropdown-tools"].map((item,u)=>{
            let url;
            let index = i

            if(typeof(item["link-to"]?.["espace-id"]) === "number"){
              index = filtersJSON["header"].findIndex((d) => d.id === item["link-to"]["espace-id"])
            }

            if(item["link-to"]?.url){
              url = `/search-innov/${filtersReducer.plateformeID}${item["link-to"]?.url}`
            }else if(item.tools && item.tools[0].items.length === 1){
              url = `/search-innov/${filtersReducer.plateformeID}/analysis-tools/${item.code}?specific-tool=${u}&item=0`
            }else  if(item.tools){
              url = `/search-innov/${filtersReducer.plateformeID}/analysis-tools?specific-tool=${u}`
            }else{
              url = `/search-innov/${filtersReducer.plateformeID}/analysis-tools${item.code ? '/'+item.code : ''}`
            }

            const shouldChangedPlatform = item["link-to"]?.["platform-id"] && typeof(item["link-to"]?.["espace-id"]) && item["link-to"]?.url
            const handleClick = (
              shouldChangedPlatform ?
              () => gotoPlatform(item["link-to"],index) :
              () => selectEntry(filtersJSON["header"][index],index,url)
            )

            return (
              <li key={`dropdown-tool-${i}-${u}`} onClick={handleClick}>
                <a><label>{item.title[indexLang]}</label></a>
              </li>
            )
          })}
        </ul>
      )
    }else{
      return null
    }
  }

  function toggleDropdown(e,i){
    e.preventDefault()
    e.stopPropagation()

    if(dropdownOpened.includes(i)){
      setDropdownOpened(dropdownOpened.filter(d=> d !== i))
    }else{
      setDropdownOpened([...dropdownOpened, i])
    }

  }

  function getEntryClassName(i){
    let opened = dropdownOpened.includes(i) ? " opened" : ""
    return i === filtersReducer.indexSelectedEntries ? `selected${opened}`  : `${opened}`
  }

  return(
    <>
      {entriesOpened && (
        <div
          className={viewReducer.lightmode === "light" ? "list-entries-overlay light" : "list-entries-overlay"}
          onClick={()=>toggleEntries()}
          >
        </div>
      )}
      { (entries && entries.length>0) &&
        <div className={entriesOpened ? "list-entries open" : "list-entries"}>
          <div className="container-overflow">
            <div>
              <SelectPlatforms match={match} />
              <label className="title-menu"><Translate id="header.menuItems"/></label>
              <ul>
              {entries.map((d,i)=>{
                return (
                  <React.Fragment key={`entrie-${i}`}>
                    <li  className={getEntryClassName(i)}
                      id={`entry-${i}`}
                      onClick={(e)=>{
                        if(d["dropdown-tools"] && d["dropdown-tools"].length>1){
                          toggleDropdown(e,i)
                        }else{
                          selectEntry(d,i)
                        }
                      }}
                    >
                      {d.title[indexLang]}
                      {(d["dropdown-tools"] && d["dropdown-tools"].length>1)  &&
                        <div className="dropdown-indicator" onClick={(e)=>toggleDropdown(e,i)}>
                          {dropdownOpened.includes(i) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        </div>
                      }
                    </li>
                    {(d["dropdown-tools"] && d["dropdown-tools"].length>1 && dropdownOpened.includes(i) )&& <li className="container-dropdown">{renderDropdown(d,i)}</li>}
                  </React.Fragment>
                )
              })}
              </ul>
            </div>
            <div className="container-bottom">
              <ul className="only-on-mobile">
                { googleCustom ?
                  <li className={"google-search"}>
                    <a data-tip data-for='google-custom-search' className="google-custom" target="_blank" href={googleCustom}>
                      <img src={imgGoogle} alt="google custom search" />
                    </a>
                    <ReactTooltip className="react-tooltip" delayShow={500} id='google-custom-search' place="left" effect='solid'>
                      <span><Translate id="header.googleSearchTooltip" /></span>
                    </ReactTooltip>
                  </li>: null
                }
                <li className={"link-about"}>
                  <NavLink to={`/search-innov/${match.params.id}/about`}>
                    <img src={iconAbout} alt="about" />
                    <Translate id="header.about" />
                  </NavLink>
                </li>
                { (plateforme && (filtersJSON && !filtersJSON["hideBoLink"])) &&
                  <li className="admin">
                    <a href={plateforme.url} target="_blank" rel="noopener noreferrer"  >
                      <img src={logoBackOffice} alt="backoffice" />
                      <Translate id="header.admin" />
                    </a>
                  </li>
                }
              </ul>
              <div>
                <LanguageToggle dispatch={dispatch}/>
                <ToggleDarkMode blueSun/>
              </div>

              { user &&
                <div className="container-user only-on-mobile">
                  <FontAwesomeIcon icon={faUser} /><p>{user.prenom} {user.nom}</p>
                  <img onClick={() => fnLogout()} src={iconLogout} alt="logout" />
                </div>
              }
            </div>
          </div>

        </div>
      }
    </>

  )

}

export default withLocalize(ListEntries)
