import React, { useState , useEffect } from 'react'
import sanitize from "sanitize-filename"
import accents from "remove-accents"
import _ from 'lodash'
import * as d3 from 'd3';

import Export from './Export'
import SelectedFilters from './SelectedFilters'
import InfoExport from './InfoExport'

import IconInc from  '../../assets/images/hausse.js'
import Icondesc from  '../../assets/images/baisse.js'
import { fnExportImage } from '../../helpers/helpers'

import imgLoading from '../../assets/images/inline-loader-disabled.gif'

import {
  addSelectedKeywords,
  removeSelectedKeyword
} from '../../actions/filters'

import { useGlobalContext } from '../../GlobalContext'

const Pie = (props) => {
  const [ context, dispatch ]  = useGlobalContext();
  const [ allData, setAllData ] = useState()
  const [ isPieInit , setIsPieInit] = useState(false)
  const filtersReducer = context.filtersReducer
  var width = props.width,
    height = props.height,
    radius = Math.min(width, height) / 2 - 10,
    innerRadius = radius *.6;

  //DATA RENDERED IN DONUT
  const [ data, setData ] = useState()
  const [ pieState , setPie ] = useState(null)
  const [ arc , setArc ] = useState()

  //ACTIONS
  const _addSelectedKeywords = (keyword) => addSelectedKeywords(dispatch,keyword)
  const _removeSelectedKeyword = (keyword) => removeSelectedKeyword(dispatch,keyword)

  useEffect(()=>{
    if(props.data && props.data.length > 0){
      let dataArr = []
      // let maxCount = _.maxBy(props.data,(d)=> d.count).count
      // let minCount = _.minBy(props.data,(d)=> d.count).count
      dataArr = _.sortBy(dataArr,(d)=> parseInt(d.frequence))

      // let colors = [
      //    "#998e77",
      //    "#ffec6a",
      //    "#fc842b",
      //    "#f8505c",
      //    "#fa85a7",
      //    "#7f4798",
      //    "#9affea",
      //    "#26c784",
      //    "#266f84",
      //  ];

       // let colors = [
       //    "#ffec6a",
       //    "#f8505c",
       //    "#fa85a7",
       //    "#26c784",
       //    "#266f84",
       //  ];


        let colors = [
          "#ffdf2b",
          "#b2e20d",
          "#0de285",
          "#0ddfe2",
          "#800de2",
          "#f416fc",
          "#f4358a",
          "#da4545",
          "#e68200",
          "#c69f6d"
        ];


       let domain = []


       _.each(colors, function(c, i) {
         if (i === 0) {
           domain.push(0)
         }else if (i === colors.length - 1) {
           domain.push(props.data.length)
         }else {
           domain.push((props.data.length) / (colors.length - i))
         }
       })

      let scaleColor = d3.scaleLinear()
        .domain(domain)
        .range(colors);

      props.data.forEach((d,i)=>{
        let obj = {}
        obj.id = d.id
        obj.value = d.count
        obj.name = d.name
        obj.frequence = parseInt(d.frequence)
        obj.tendance = d.tendance
        let color = scaleColor(i)
        obj.color = color
        if(d.code) obj.code = d.code

        if((/FILTER/.test(d.id) && d.code !== d.name) ||
          !/FILTER/.test(d.id)
        ){
          dataArr.push(obj)
        }
      })

      if(props.sortList){
        dataArr = _.sortBy(dataArr,(d) => d.frequence).reverse()
      }

      setData(dataArr);
      setAllData(dataArr);

    }

  },[props.data])

  // INIT PIE
  useEffect(()=>{
    if(!data || isPieInit) return

    let svg = d3.select("body").select('.'+props.refPie).append("svg")
        .attr("width", width)
        .attr("height", height)
      .append("g")
        .attr("transform", "translate(" + ((width / 2)) + "," + ((height / 2)) + ")");

    let pie = d3.pie()
      .sort(null)
      .value(function(d) { return d.value; });

    let arc = d3.arc()
        .outerRadius(radius)
        .innerRadius(innerRadius);

    setPie(() => pie)
    setArc(() => arc)
    drawPie(data,pie,arc,svg)
    setIsPieInit(true)

  },[data])

  // UPDATE PIE
  useEffect(()=>{
    if(!data ) return
    if(isPieInit){
      // console.log(data);
      let svg = d3.select("body").select('.'+props.refPie).select('svg').select('g')
      drawPie(data,pieState,arc,svg)
    }


  },[data])

  function drawPie(data,pie,arc,svg){

     // join
      var arcs = svg.selectAll("g.arc")
         .data(pie(data), function(d){;return d.index; });

      // tooltips
      d3.select("body").select('.'+props.refPie).append("div")
                     .attr('class', 'container-tooltip')
                     .selectAll('.tooltip')
                     .data(data)
                     .enter()
                     .append('div')
                     .attr('id', (d)=>{
                       return d.code
                     })
                     .html((d)=>{
                       return "<p>"+d.name+"</p>"
                     })
                     .attr('class', 'tooltip');

     // enter
     arcs.enter().append("path")
        .attr("class", "arc")
        .attr("fill", function(d, i) { return d.data.color; })
        .attr('id', (d)=>{
           return d.code
        })
        .attr("d", arc)
        .transition()
          .ease(d3.easeCubic)
          .duration(2000)
          .attrTween("d",  function(d, i) { return tweenPie(d,arc); })
        .each(function(d) {
          this._current = d;
        })

      d3.select('.'+props.refPie).select('svg')
      .selectAll(".arc")
      .on("click", d => {

        let isSelected;
        if(filtersReducer.selectedKeywords && filtersReducer.selectedKeywords.length > 0){
          isSelected = filtersReducer.selectedKeywords.find((k)=> k.code === d.data.code)
        }

        if(!isSelected){
          let keyword = {
            code : d.data.code,
            id : d.data.id,
            label : d.data.name,
            value : d.data.name
          }
          _addSelectedKeywords(keyword)
        }else if(isSelected){
          _removeSelectedKeyword({value : d.data.name})
        }

      })
      .on("mouseover", function(d) {

        let tooltip = d3.select("[id='"+d.data.code+"']")
        tooltip.style("display", "block");
        tooltip.style("left", (arc.centroid(d)[0]+ (width/2))+"px");
        tooltip.style("top", (arc.centroid(d)[1]+ (height/2))+"px");

      })
      .on("mouseout", function(d) {
        let tooltip = d3.select("[id='"+d.data.code+"']")

        tooltip.style("display", "none");
      });



      // arcs.exit().remove();

      // console.log(arcs);

   }

  function tweenPie(b,arc) {
    var i = d3.interpolate({startAngle: 0, endAngle: 0}, b);
    return function(t) {
      return arc(i(t));
     };
  }

  function selectEntries (entry){
    if(!filtersReducer.selectedKeywords ||
      !filtersReducer.selectedKeywords.find((d)=>{ return d.code === entry.code})
      ){
      _addSelectedKeywords({id : entry.id, code : entry.code, label : entry.name, value : entry.name})
    }else{
      // console.log(entry);
      _removeSelectedKeyword({value : entry.name})
    }
  }

  function exportImage(){
    let exportGroup = document.querySelector("#container-"+props.refPie)
    let eltExport = exportGroup.querySelector(".container-export")
    let legends = exportGroup.querySelector(".legends ul")
    let options = { width : 270, backgroundColor : props.light ? "white" : "#151845"}

    exportGroup.style.maxWidth="270px";

    let title = exportGroup.querySelector(".container-pie h3");
    let styleTitle = getComputedStyle(title);
    title.style.marginTop = "40px";


    let selectedFilters = exportGroup.querySelector(".selected-keywords");
    if(selectedFilters) selectedFilters.style.display = "block"

    let infoExport = exportGroup.querySelector(".info-export");
    if(infoExport) infoExport.style.display = "block"

    let maxHeight = legends.style.maxHeight

    eltExport.style.display = "none"
    legends.style.maxHeight = "inherit"


    legends.classList.add("show")
    let filename = sanitize(props.title)
    filename = accents.remove(filename)
    filename = filename.replace(/ /g,"_")

    let t = setTimeout(()=>{
      fnExportImage(exportGroup,filename+".png",options)
      clearTimeout(t)
    },100)


    let t2 = setTimeout(()=>{
      exportGroup.style.maxWidth="inherit";

      eltExport.style.display = "block"
      if(selectedFilters) selectedFilters.style.display = "none"
      if(infoExport) infoExport.style.display = "none"

      title.style.marginTop = styleTitle["margin-top"];
      legends.style.maxHeight = maxHeight
      legends.classList.add("remove")
      clearTimeout(t2)
    },200)


  }

  function renderTendance(tendance,color,d){
    let elt;

    switch (tendance) {
      case "down":
        elt = <Icondesc color={color} />
        break;
      case "equal":
        elt = <div style={{backgroundColor : color}} className="dot"></div>
        break;
      case "up":
        elt = <IconInc color={color} />
        break;
      default:

    }

    return <div className={"tendance "+tendance}>{elt}</div>
  }

  return (
    <div className="container-pie" id={"container-"+props.refPie}>
      { props.export ?
        <span className="hide-on-mobile"><Export exportImage={exportImage} isLoading={props.isLoading}/></span>
         : null
      }

      {props.title ? <h3>{props.title}</h3> : <h3>Undefined</h3>}
      { props.export ?
        <>
          <InfoExport hideKeywords={true}/>
          <SelectedFilters/>
        </>
         : null
      }
      <div style={{maxWidth : props.width}} className={props.isLoading ? props.refPie+" pie-loading " : props.refPie} >
        {props.isLoading ? <img src={imgLoading} className="loader" alt="loading" /> : null}
      </div>
      { props.showList && data ?
        <div className={!props.isLoading ? "legends" : "legends loading"} >
          <ul >
            {allData.map((d, i)=> {
              if(!d.name){
                return (
                  <li key={"legend-"+i}>
                    No data
                  </li>
                )
              }
              return (
                <li key={"legend-"+i} onClick={() => selectEntries(d)}>
                  {renderTendance(d.tendance,d.color,d)}
                  <span>({d.frequence}) {d.name}</span>
                </li>
              )

            }
            )}
          </ul>
        </div> : null
      }
    </div>
  )
}

export default React.memo(Pie)
