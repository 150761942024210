import React, { useState, useEffect } from "react"
import _ from 'lodash'
import { getPartnerships, removePartnerships } from '../../../actions/dataviz'
import { useGlobalContext } from '../../../GlobalContext'
import useContentDimension from '../../../customHooks/useContentDimension'
import useFilterView from '../../../customHooks/useFilterView'
import useGetNodes from '../../../customHooks/useGetNodes'

import LoaderView from '../../partials/LoaderView'
import NetworkVizSvg from '../links_exploration/NetworkVizSvg'

import SearchByKeywords from '../../partials/SearchByKeywords'
import InfoExport from "../../partials/InfoExport";
import Export from '../../partials/Export'
import ButtonDocs from '../../partials/ButtonDocs'
import ButtonFilters from '../../partials/ButtonFilters'
import ExplorationNav2 from '../../partials/ExplorationNav2'

import { datavizParams } from '../../../helpers/filtersParams'
import { fnExportImage } from '../../../helpers/helpers'

const Partenerships = (props) => {
  const [ context , dispatch ] = useGlobalContext();
  const contentDimension = useContentDimension();

  const filtersReducer = context.filtersReducer
  const datavizReducer = context.datavizReducer
  const viewReducer = context.viewReducer

  const [ data, setData ] = useState(null)
  const [ dataFiltered, setDataFiltered ] = useState(null)
  const [ legends, setLegends ] = useState(null)
  const [ hasLegendSelected, setHasLegendSelected ] = useState(false)

  const [colorLinks,setColorLinks] = useState(null)

  const defaultRubrique = props.entries
  useFilterView(defaultRubrique)
  useGetNodes(defaultRubrique)

  //Actions
  const _getPartnerships = (filter) => getPartnerships(dispatch, filter)
  const _removePartnerships = () => removePartnerships(dispatch)

  useEffect(()=>{

    return () => {
      _removePartnerships()
    }
  },[])

  useEffect(()=>{
    if(!legends) return;
    setHasLegendSelected(!!legends.find(d=>d.selected))
  },[legends])

  useEffect(()=>{
    if(filtersReducer.date.min.date &&
      filtersReducer.date.max.date
    ){
      setData(null)
      setDataFiltered(null)
      _removePartnerships()

      let result = datavizParams(filtersReducer,viewReducer.lang)

      let isRUBSelected = result.filters.find((d)=> d.id === "RUBRIQUE") ? true : false

      if(isRUBSelected){
        result.filters = [ ...result.filters]
      }else{
        let obj = {}
        defaultRubrique.forEach((d)=>{
          if(!obj[d.id]){
            obj[d.id] = { ...d }
          }else{
            obj[d.id].code += "%20OR%20"+d.code
          }
        })
        if(Object.values(obj)[0]) result.filters.push(Object.values(obj)[0])
      }

      _getPartnerships(result)
    }

  },[
    defaultRubrique,
    viewReducer.lang,
    filtersReducer.selectedKeywords,
    filtersReducer.search,
    filtersReducer.filteredByDate,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date])

  useEffect(()=>{
    if(data !== null || !datavizReducer.partnerships || !filtersReducer.matchCode) return
    // console.log(datavizReducer.partnerships.nodes);
    // console.log(datavizReducer.partnerships.links);
    // console.log(datavizReducer.partnerships.links);

    let colors = [
      "#998e77",
      "#f50057",
      "#ffcb1f",
      "#eea0e4",
      "#363df0",
      "#ff8336",
      "#a20041"
    ]

    // let types = {}

    let links = _.cloneDeep(datavizReducer.partnerships.links)
    let nodes = _.cloneDeep(datavizReducer.partnerships.nodes)

    // REMOVE NODES AND LINKS WHICH CORRESPOND TO SELECTED FILTERS
    let selectedFilterCodes = filtersReducer.selectedKeywords.map(d=> d.code)
    nodes = nodes.filter(d => !selectedFilterCodes.includes(d.value) )
    links = links.filter(d => !selectedFilterCodes.includes(d.source) && !selectedFilterCodes.includes(d.target))

    let types = {}

    links.forEach((d,i)=>{
      if(!types[d.type]){
        let libelle = d.libelle ? d.libelle : d.type
        types[d.type] = {libelle : libelle}
        types[d.type].color = d.color
        types[d.type].type = d.type
        colors.shift()
      }

    })

    setLegends(Object.values(types))
    setColorLinks(types)
    setDataFiltered({nodes , links})
    setData({nodes , links})

  },[datavizReducer.partnerships,
    viewReducer.lang,
    filtersReducer.matchCode])

  function filterData(filter){

    setDataFiltered(null)

    let updatedLegends = [...legends]

    let legendSelected = updatedLegends.filter(d=>d.selected).map(d=>d.type)
    if(legendSelected.includes(filter.type)){
      legendSelected = legendSelected.filter((d)=> d !== filter.type)
    }else{
      legendSelected.push(filter.type)
    }

    let updatedData;
    if(legendSelected.length === 0){
      updatedData = { nodes : data.nodes, links : data.links }
    }else{
      let links = data.links.filter((link)=> legendSelected.includes(link.type))
      let arrLinkedNodes = []

      links.map((link)=>{
        arrLinkedNodes.push(link.source)
        arrLinkedNodes.push(link.target)
      })
      arrLinkedNodes = _.uniq(arrLinkedNodes)

      let nodes = data.nodes.filter((node)=> _.includes(arrLinkedNodes,node.value))
      updatedData = { nodes, links}
    }

    updatedLegends.forEach((d)=>{
      if(legendSelected.includes(d.type)){
        d.selected = true
      }else{
        d.selected = false
      }
    })

    let timer = setTimeout(()=>{
      setDataFiltered(updatedData)
      setLegends(updatedLegends)
      clearTimeout(timer)
    },350)

  }

  function exportImage(){
    let exportGroup = document.getElementById("export-group");
    let target = exportGroup.querySelector(".networkViz");
    let labels = target.querySelectorAll("#group-circles text");
    let circles = target.querySelectorAll("#group-circles circle");
    let lines = target.querySelectorAll("#group-lines line");

    let legends = exportGroup.querySelector(".partnerships-legends");
    let attrLeft = getComputedStyle(legends).left
    let attrTop = getComputedStyle(legends).top

    legends.style.top = "23px";
    legends.style.left = "20px";


    let infoExport = exportGroup.querySelector(".info-export");
    if(infoExport) infoExport.style.display = "block"


    //// LEGENDS ////



    labels.forEach((label)=>{
      let style = getComputedStyle(label);
      label.setAttribute("fill",style.fill)
    })

    circles.forEach((circle)=>{
      let style = getComputedStyle(circle);
      circle.setAttribute("fill",style.fill)
      circle.setAttribute("stroke-width",style["stroke-width"])
      circle.setAttribute("stroke",style["stroke"])
    })

    lines.forEach((line)=>{
      if(parseInt(line.style["stroke-width"].replace("px","")) < 1){
        line.style["stroke-width"] = "1px"
      }
    })

    let options = {
      width : target.clientWidth,
    }

    fnExportImage(exportGroup,"partnerships.png",options)

    legends.style.left = attrLeft;
    legends.style.top = attrTop;
    if(infoExport) infoExport.style.display = "none"

  }

  // PARTENERSHIP
  return(
    <>
      <div className={props.filterIsOpen ? "partnerships filters-opened" : "partnerships"}>
        <span id="export-group">
          <InfoExport />
          {(legends && legends.length > 0) &&
          <ul className={!hasLegendSelected ? "partnerships-legends active" : "partnerships-legends" }>
            {legends.map((d,i)=>(
              <li key={"legend-"+i}
               className={d.selected ? 'selected' : ''}
               onClick={()=>filterData(d)}
               style={!hasLegendSelected || d.selected ? {color : d.color} : {}}
              >
                <div className="dash" style={{backgroundColor : d.color}}></div>{d.libelle}
              </li>
            ))}
            </ul>
          }

          <div className={viewReducer.lightmode === "light" ? "container-search light" : "container-search"}>
            <div className="container-left">
              { (!props.hideHeaderButtons && !props.filterIsOpen) &&
                <ButtonFilters
                  toggleFilter={props.toggleFilter}
                />
              }
              <SearchByKeywords
                testId={"global"}
                className={"header"}
                iconPosition={"right"}
                isMulti={true}
                isClearable={true}
                placeholder={"Filter par mots clés"}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
              />
            </div>
            
            <div className="container-right">
              <ExplorationNav2 history={props.history} match={props.match} />
              { !props.hideHeaderButtons &&
                <ButtonDocs
                  documentsIsOpen={props.documentsIsOpen}
                  toggleDocument={props.toggleDocument}
                />
              }
            </div>
          </div>

          { dataFiltered ?
            <NetworkVizSvg
              width={contentDimension.width}
              height={contentDimension.height}
              gravityStrength={0.3}
              data={dataFiltered}
              colorLinks={colorLinks}
              strokeWidth={2}
              labelCentered={false}
              hideLinks={false}
            /> : null
          }
        </span>
        { (!dataFiltered || datavizReducer.isLoading) &&
          <LoaderView/>
        }
        <Export exportImage={exportImage} isLoading={!data || datavizReducer.isLoading}/>

      </div>
    </>
  )

}

export default Partenerships
