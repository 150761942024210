import globaldisruptions from './global-disruptions.json'
import tutorials from './tutorials.json'



const config = {
 "header" : [
   globaldisruptions,
   tutorials
 ]
}

export default config
