import {
   /// WHEN ENTRIES CHANGE WE HAVE TO RESET THE
   /// SELECTED RUBRIQUE
  SET_HEADER_ENTRIES,
  ///
  SET_RUBRIQUE,
  SET_TYPOLOGY,
  GET_NEWSPAPER_BY_ID,
  GET_REFERENCES_NEWSPAPER,
  GET_FILTERED_NEWSPAPER,
  REMOVE_NEWSPAPER,
  SET_ORDER_BY_NEWSPAPER,
  LOADING_NEWSPAPER,
  ERROR_NEWSPAPER,
  GET_DATES,
  SET_FILTER_DATE
} from '../actions/types'

const initialState = {
  nameReducer : 'newspaper',
  newspaper : null,
  references : null,
  countDocs : null,
  page : 1,
  pageRef : null,
  number : 30, // NUMBER DE DOCUMENTS A CHARGER PAR APPELLE
  orderBy : "date_ressource_desc",
  isLoading : false,
  change : null,
  selectedRubrique : null,
  typo : null
};

let updatedDocs = null
let updatedReferences = null

export default function settingsReducer(state = initialState, action) {
  // if(!/LOADING/.test(action.type)) console.log(action);
  switch (action.type) {
    case SET_HEADER_ENTRIES :
      return { ...state, selectedRubrique : null}
    case SET_TYPOLOGY :
      return { ...state, typo : action.payload}
    case SET_RUBRIQUE :
      return { ...state, selectedRubrique : action.payload}
    case SET_FILTER_DATE :
    return { ...state, newspaper : null}
    // INIT NUMBER DOCUMENTS
    case GET_DATES :
      return { ...state, countDocs : action.payload.dates.length}
    case GET_NEWSPAPER_BY_ID :
      return { ...state, newspaper : action.payload, isLoading : false}
    case GET_FILTERED_NEWSPAPER :
       if(action.payload.result){
        if(action.payload.result.currentPage > 1){
          updatedDocs = [ ...state.newspaper, ...action.payload.result.docs ]
        }else{
          updatedDocs = action.payload.result.docs
        }
        return { ...state, newspaper : updatedDocs,
          countDocs : parseInt(action.payload.result.numFound),
          page : action.payload.result.currentPage,
          isLoading : false,
          change : null
        }
      }else{
        return { ...state, countDocs:'Ops !' , isLoading : false, change : null}
      }
    case GET_REFERENCES_NEWSPAPER :
      if(action.payload.currentPage > 1){
        updatedReferences = [ ...state.references, ...action.payload.docs ]
      }else{
        updatedReferences = action.payload.docs
      }

      if(updatedReferences[parseInt(action.payload.numFound)-1]){
        updatedReferences[parseInt(action.payload.numFound)-1].lastOne = true
      }

      return {...state, references : updatedReferences, pageRef : action.payload.currentPage}
    case SET_ORDER_BY_NEWSPAPER :
      return { ...state, orderBy : action.payload}
    case REMOVE_NEWSPAPER :
      return {
        ...state,
        pageRef : null,
        newspaper : null,
        references : null,
        countDocs : null,
        page : null,
      }
    case LOADING_NEWSPAPER :
      return { ...state, isLoading : true, change : action.payload.change}
    case ERROR_NEWSPAPER :
      console.log(ERROR_NEWSPAPER);
    return {...state}
    default:
      return {...state};
  }
}
