import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../GlobalContext'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)


export default function Picto(props){
  const [ context ]  = useGlobalContext();
  const filtersReducer = context.filtersReducer
  const [ label, setLabel ] = useState()

  useEffect(()=>{
    let find = filtersReducer.matchCode.find((d)=> d.code === props.code)
    if(find) setLabel(find.label)
  },[])

  // function getLabel(){
  //   let find = filtersReducer.matchCode.find((d)=> d.code === props.code)
  //   if(!find){
  //     return "Non renseigné"
  //   }
  //   return find.label
  // }

  return (
    <>
      { label &&
        <>
          <ReactTooltip getContent={() => label} className={"react-tooltip "+props.tooltipColor} delayShow={200} id={`picto-${props.code}`} place="top" effect='solid'/>
          <span data-tip data-for={`picto-${props.code}`}><FontAwesomeIcon color={props.color} icon={props.icon} /></span>
        </>
      }
    </>
  )
}
