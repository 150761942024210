import React from 'react';

export default function IconTimeline(){
  return (
    <svg width="20px" height="13px" viewBox="0 0 20 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="words_cloud" transform="translate(-24.000000, -472.000000)" fill="#8A8BA2">
                <g id="Group" transform="translate(23.000000, 233.000000)">
                    <polygon id="icon-timeline" points="2.528 252.056 1.016 250.568 8.528 243.056 12.512 247.088 19.568 239.12 20.984 240.536 12.512 250.088 8.528 246.056"></polygon>
                </g>
            </g>
        </g>
    </svg>
  )
}
