import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import { withLocalize , Translate } from 'react-localize-redux'
import ReactTooltip from 'react-tooltip'
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import history from '../../history'
import BurgerButton from '../partials/BurgerButton'

import logoInnovText from '../../assets/images/logo_innov_text.png'

import iconLogout from '../../assets/images/icon-logout.svg'
import iconAbout from '../../assets/images/icon-about.svg'
import logoBackOffice from '../../assets/images/backoffice.svg'
import imgGoogle from '../../assets/images/icon-google2.svg'

import { useGlobalContext } from '../../GlobalContext'
import usePlateforme from '../../customHooks/usePlateforme'
import usePrevious from '../../customHooks/usePrevious'
import { fnLogout } from './helpers'

import googleCustomLink from "../googleCustomLink.json"
import plateformesID from "../../config/list-plateforms-id.json"

import { getFilters, getDates } from '../../actions/filters'
import { setDocumentCount } from "../../actions/documents"

import styles from './header.module.scss'

const Header = (props) => {
  const [context, dispatch]= useGlobalContext();
  const viewReducer = context.viewReducer;
  const filtersReducer = context.filtersReducer;
  const filtersJSON = usePlateforme()

  const [ user , setUser ] = useState(null)
  const [ googleCustom , setGoogleCustom ] = useState(null)

  const [ availablePlateformes , setAvailablePlateformes ] = useState(null)
  const [ plateforme , setPlateforme ] = useState(null)
  const [ entries, setEntries ] = useState()
  const [ indexLang, setIndexLang ] = useState()
  const [ firstViewUrl, setFirstViewUrl ] = useState('')

  const prevEntries = usePrevious(filtersReducer.selectedHeaderEntries)

  //ACTIONS
  const _getFilters = (obj,origin) => getFilters(dispatch,obj,origin)
  const _getDates = (plateformeID,filters,excludedFilters) => getDates(dispatch,plateformeID,filters,excludedFilters)
  const _setDocumentCount = (count) => setDocumentCount(dispatch, count)

  useEffect(()=>{
    let plateformeID = JSON.parse(localStorage.getItem("plateformeID"))
    let user = JSON.parse(localStorage.getItem("user"))
    setUser(user)
    // console.log(JSON.parse(localStorage.getItem("plateformes")).map(d => d.id));
    let found = JSON.parse(localStorage.getItem("plateformes")).filter((plateforme)=>{
      return plateformesID.includes(plateforme.id)
    })

    let plateforme = found.find((plateforme)=>{
      return plateforme.id === plateformeID
    })
    setPlateforme(plateforme)
    setGoogleCustom(googleCustomLink.links[plateformeID])

    // PLATEFORME CONFIGURER MAIS NON ACCESSIBLE
    // let notAccesible = JSON.parse(localStorage.getItem("plateformes")).filter((plateforme)=>{
    //   return !plateformesID.includes(plateforme.id)
    // }).map((t)=> t.id)
    let sortPlateformes = _.sortBy(found,(plateforme)=> plateforme.libelle);
    setAvailablePlateformes(sortPlateformes)


  },[])

  useEffect(()=>{
    if(!filtersJSON) return;
    let indexLang = props.languages.map((d)=>d.active).indexOf(true);
    setIndexLang(indexLang)
    setEntries(filtersJSON["header"])
  },[filtersJSON])


  //HANDLE HIDE HEADER BUTTON
  useEffect(()=>{
    if(!filtersJSON || filtersReducer.indexSelectedEntries === null ||
      props.location.pathname === `/search-innov/${props.match.params.id}/about`) return;

    let currentHeaderEntry = filtersJSON.header[filtersReducer.indexSelectedEntries]


    let url = currentHeaderEntry.url || currentHeaderEntry?.navbar[0]?.url ||
      `/strategic-watch`

    setFirstViewUrl(`${url}?entry=${filtersReducer.indexSelectedEntries}`)


  },[filtersJSON,filtersReducer.indexSelectedEntries,props.location.pathname])


  // GET SELECTED & SET ARRCODE
  useEffect(()=>{

    if( filtersReducer.selectedKeywords){
      _setDocumentCount(null)
    }

  },[
    viewReducer.lang,
    filtersReducer.selectedKeywords
  ])

  /// INIT DATE
  useEffect(()=>{

    if(
      (filtersReducer.selectedHeaderEntries &&
      filtersReducer.defaultFilters && filtersReducer.defaultExcludedFilters &&
      !filtersReducer.dateRange && !filtersReducer.date.min.date && !filtersReducer.date.max.date) ||
      (prevEntries && ( prevEntries !== filtersReducer.selectedHeaderEntries))
    ){
      let filters = [...filtersReducer.selectedHeaderEntries, ...filtersReducer.defaultFilters]
      _getDates(filtersReducer.plateformeID,filters,filtersReducer.defaultExcludedFilters);
    }

 },[
    filtersReducer.defaultExcludedFilters,
    filtersReducer.defaultFilters,
    filtersReducer.selectedHeaderEntries,
    filtersReducer.filters,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date,
    props.location.pathname
  ])

  useEffect(()=>{
    if(!filtersReducer.dateRange || (prevEntries && ( prevEntries !== filtersReducer.selectedHeaderEntries))) return;

    let selectedKeywords = filtersReducer.selectedKeywords.map(d => ({ code : d.code, id : d.id}))
    let filters = [
      ...filtersReducer.selectedHeaderEntries,
      ...filtersReducer.defaultFilters,
      ...selectedKeywords
    ]

    _getDates(filtersReducer.plateformeID,filters,filtersReducer.defaultExcludedFilters);

  },[
    filtersReducer.selectedKeywords
  ])


  /// UPDATE REQUEST
  useEffect(()=>{
    let reg = new RegExp(`/search-innov/${props.match.params.id}/analysis-tools`)

    if(
        filtersReducer.defaultFilters && filtersReducer.defaultExcludedFilters &&
        filtersReducer.selectedHeaderEntries &&
        filtersReducer.date.min.date && filtersReducer.date.max.date &&
        (
          props.location.pathname === `/search-innov/${props.match.params.id}/home` ||
          props.location.pathname === `/search-innov/${props.match.params.id}/help` ||
          props.location.pathname === `/search-innov/${props.match.params.id}/strategic-watch` ||
          (reg.test(props.location.pathname))
        )
      ){
        let result = paramsRouteFilter(filtersReducer)
        _getFilters(result,props.location.pathname);
    }
  },[
    viewReducer.lang,
    filtersReducer.defaultExcludedFilters,
    filtersReducer.defaultFilters,
    filtersReducer.selectedHeaderEntries,
    props.location.pathname,
    filtersReducer.search,
    filtersReducer.date.min.date,
    filtersReducer.date.max.date,
    filtersReducer.selectedKeywords,
    filtersReducer.filteredByDate
  ])

  function paramsRouteFilter(){

    let arrCode = []
    let excludes = []
    let viewFilters;

    if(filtersReducer.selectedKeywords){
      filtersReducer.selectedKeywords.forEach((d)=>{
        if(d.exclude){
          excludes.push({ code : d.code, id : d.id})
        }else{
          arrCode.push({ code : d.code, id : d.id})
        }
      })
    }

    if(props.location.pathname === `/search-innov/${props.match.params.id}/strategic-watch`){

      const defaultRubrique = filtersReducer.selectedHeaderEntries
      let find;
      if(filtersJSON.header?.[filtersReducer.indexSelectedEntries]?.navbar){
        find = filtersJSON.header?.[filtersReducer.indexSelectedEntries]?.navbar.find((d) => d.url === "/strategic-watch" );
        if(find){
          viewFilters = []
          if(find.filters) viewFilters.push(...find.filters);
          if(find.rubrique) find.rubrique.forEach((d) => viewFilters.push({id: "RUBRIQUE", code: d}));
        }
      }
      let foundRubrique = arrCode.find((d) => d.id === "RUBRIQUE")
      if(!foundRubrique){
        let obj = {}
        defaultRubrique.forEach((d)=>{
          if(!obj[d.id]){
            obj[d.id] = { ...d }
          }else{
            obj[d.id].code += "%20OR%20"+d.code
          }
        })
        if(obj.RUBRIQUE) arrCode = [...arrCode, obj.RUBRIQUE]
      }

    }else{
      arrCode.push(...filtersReducer.selectedHeaderEntries)
    }

    // DEFAULT FILTERS PROVIDE IN CONFIG
    if(!viewFilters && filtersReducer.defaultFilters){
      filtersReducer.defaultFilters.forEach((d)=>{
        arrCode.push({ code : d.code, id : d.id})
      })
    }

    // DEFAULT EXCLUDED FILTERS PROVIDE IN CONFIG
    if(!viewFilters && filtersReducer.defaultExcludedFilters){
      filtersReducer.defaultExcludedFilters.forEach((d)=>{
        excludes.push({ code : d.code, id : d.id})
      })
    }

    if(viewFilters && viewFilters.length>0){
      viewFilters.forEach((d)=>{
        arrCode.push({ code : d.code, id : d.id})
      })
    }

    let filters = {
      search :filtersReducer.search,
      filters : arrCode, // array
      excludes,
      start : filtersReducer.filteredByDate ? filtersReducer.date.min.date : null,
      end : filtersReducer.filteredByDate ? filtersReducer.date.max.date : null,
      plateformeID : filtersReducer.plateformeID,
      lang : viewReducer.lang
    }

    return filters

  }

  function getActiveEntry(){
    if(indexLang === undefined) return;
    let found = (entries && entries.find((d,i) => i === filtersReducer.indexSelectedEntries))
    if(found) return (<label>{found.title[indexLang]}</label>)
    else return null
  }

  function getLogo(){
    const baseUrl = plateforme?.url.split('/').splice(0,4).join('/');
    return `${baseUrl}/servletpub/QESServlet_fwdImg?Base=22801201&Fichier=${plateforme.logo}`
  }

  return(
    <div id="header" className={`${styles["header"]}${viewReducer.lightmode ? " "+styles[viewReducer.lightmode] : ""}`}>
      <div className={`${styles.first} ${styles.line}`}>
        <div className={styles["container-left"]}>
          { availablePlateformes &&
           <div className={styles["toggle-plateforme"]}>
              <BurgerButton open={props.entriesOpened} onClick={props.toggleEntries}/>
              <label onClick={props.toggleEntries}><Translate id="header.menu" /></label>
            </div>
          }
          <div className={styles["container-space"]} onClick={props.toggleEntries}>
            {getActiveEntry()}
          </div>
        </div>
        <span className={styles.platform}>
          <NavLink to={`/search-innov/${props.match.params.id}${firstViewUrl}`}>
            {plateforme && plateforme.logo ?
              <img className={styles.logo} src={getLogo()} alt={"logo plateforme "+plateforme.libelle} /> :
              <img className={styles.logo} src={logoInnovText} alt="logo search innov" />
            }
            { (plateforme && filtersJSON && filtersJSON["platformName"]) &&
              <label className={styles["platform-name"]} >{plateforme.libelle}</label>
            }
          </NavLink>
        </span>
        <div className={`${styles["container-right"]} hide-on-mobile`} >
          <ul>
            { googleCustom ?
              <li className={"google-search"}>
                <a data-tip data-for='google-custom-search' className="google-custom" target="_blank" href={googleCustom}>
                  <img src={imgGoogle} alt="google custom search" />
                </a>
                <ReactTooltip className="react-tooltip" delayShow={500} id='google-custom-search' place="left" effect='solid'>
                  <span><Translate id="header.googleSearchTooltip" /></span>
                </ReactTooltip>
              </li>: null
            }
            <li className={styles["link-about"]}>
              <NavLink to={`/search-innov/${props.match.params.id}/about`}>
                <img src={iconAbout} alt="about" />
                <span><Translate id="header.about" /></span>
              </NavLink>
            </li>
            { (plateforme && (filtersJSON && !filtersJSON["hideBoLink"])) &&
              <li className={styles["admin"]}>
                <a href={plateforme.url} target="_blank" rel="noopener noreferrer"  >
                  <img src={logoBackOffice} alt="backoffice" />
                  <span><Translate id="header.admin" /></span>
                </a>
              </li>
            }
          </ul>
          { user &&
            <div className={styles["container-user"]}>
              <FontAwesomeIcon icon={faUser} /><p>{user.prenom} {user.nom}</p>
              <img onClick={() => fnLogout()} src={iconLogout} alt="logout" />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default withLocalize(Header)
