import React, { useState, useEffect } from 'react'
import { Translate, withLocalize } from "react-localize-redux";

import { renderDate }  from '../../../helpers/helpers'
import useContentDimension from '../../../customHooks/useContentDimension'

import ImgLink from '../../../assets/images/link-solid.svg'
import ImgLinkWhite from '../../../assets/images/link-solid-white.svg'

const MarkerTimeline = (props) => {
  const contentDimension = useContentDimension();
  const [ data, setData ] = useState(props.data)

  useEffect(()=>{
    setData(props.data)
  },[props.data])

  function onMouseEnterHandler(id){
    let elts = document.querySelectorAll(".timeline-object")
    let month = document.querySelector(".container-month-lines")
    month.classList.add('fadeOut');

    // console.log(elts);
    for (let i = 0; i < elts.length; i++) {
      console.log();
      if(elts[i].id !== id){
        elts[i].classList.add('fadeOut');
      }else{
        elts[i].classList.add('active');
      }
    }
  }

  function onMouseLeaveHandler(e){
    let elts = document.querySelectorAll(".timeline-object")

    let month = document.querySelector(".container-month-lines")
    month.classList.remove('fadeOut');


    for (let i = 0; i < elts.length; i++) {
      elts[i].classList.remove('fadeOut');
      elts[i].classList.remove('active');
    }
  }

  function renderPositionDocs(d,i){
    let offsetX = d.offsetX

    let incY = (contentDimension.height/1.35)/7
    let offsetY = incY*d.lineIndex+"px";

    return {left : offsetX+"px", top : offsetY}
  }

  return(
    <>
      {data.map((d,i)=>(
        <div id={"timeline-object-"+i}
          className={d.nodes.length > 1 ? "timeline-object cluster" : "timeline-object"}
          key={"timeline-object-"+i}
          style={renderPositionDocs(d,i)}
          onMouseEnter={() => onMouseEnterHandler("timeline-object-"+i)}
          onMouseLeave={(e) => onMouseLeaveHandler(e)}
        >
          {d.nodes.length !== 1 ?
            <>
              <div className="number-docs">
                <p>{d.nodes.length}</p>
              </div>
              <div>
                <h3>{d.rubrique}</h3>
                <p> <Translate id="global.fromDate"/> {renderDate(d.nodes[d.nodes.length-1].date_pub,props.activeLanguage.code)}<br/> <Translate id="global.toDate"/> {renderDate(d.date,props.activeLanguage.code)}</p>
              </div>
              <div className={d.lineIndex < 4 ? "cluster-nodes" : "cluster-nodes above"}>
                <ul>
                  {d.nodes.map((node, j)=>(
                    <li key={"nodes-"+props.index+"-"+j}>
                      <div onClick={() => props.toggleDocument(node.ID_QES)}>
                        <p><img src={ImgLink} alt="link"/> {renderDate(node.date_pub,props.activeLanguage.code)}</p>
                        <p>{node.title}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
            : <div className="container-node" onClick={() => props.toggleDocument(d.nodes[0].ID_QES)}>
              <div className="icon">
                {props.viewReducer.lightmode === "light" ?
                  <img src={ImgLinkWhite} alt="link"/> : <img src={ImgLink} alt="link"/>
                }

              </div><div>
              <h3>{d.nodes[0].rubrique}</h3>
              <p>{renderDate(d.nodes[0].date_pub)}</p>
              <p>{d.nodes[0].title}</p>
            </div></div>
          }
        </div>
      ))}

    </>
  )
}

export default withLocalize(MarkerTimeline)
