import React, { useState, useEffect } from 'react';
import { Translate, withLocalize } from "react-localize-redux";
import { useGlobalContext } from '../../../GlobalContext'
import useContentDimension from '../../../customHooks/useContentDimension'

const Tags = ({tagsFilters,tagsQES, toggleKeywords, isMobile}) => {
  const context  = useGlobalContext()[0]
  const contentDimension = useContentDimension();
  const filtersReducer = context.filtersReducer
  const [ showTags, setShowTags] = useState(false)

  function renderManualTags(){

    let elts = tagsFilters.map((d,j)=>{
      if(d.visible || showTags || isMobile){
        return(
          <div key={"filter_group_"+j} >
            {d.title && <p className="title-section">{d.title}</p>}
            {tags(d,j)}
          </div>
        )
      }else { return null }
    }).filter(Boolean);


    if(showTags && !isMobile){
      elts.push(
        <div className="toggle-tags" key={"toggle-tags"}>
          <div className="content-centered">
            <button onClick={()=>setShowTags(false)}><Translate id="documents.document-view.less-tags"/></button>
          </div>
        </div>
      )
    }else if(!isMobile){
      let found = tagsFilters.find(d=>d.visible === false)
      if(found){
        elts.push(
          <div className="toggle-tags hide" key={"toggle-tags"}>
            <div className="gradient"></div>
            <div key={"filter_group_preview"} >
              {found.title && <p className="title-section">{found.title}</p>}
              {tags(found)}
            </div>
            <div className="content-centered">
              <button onClick={()=>setShowTags(true)}><Translate id="documents.document-view.more-tags"/></button>
            </div>
          </div>
        )
      }

    }

    return elts

    function tags(d){
      let arrID = Object.keys(d.filters)
      return arrID.map((id,j)=>{
        let filters = d.filters[id]
        let find = filtersReducer.filters.find(match=> match.id === id)
        if(find){
          return (
            <React.Fragment key={j+"-id"}>
              <p className="sub-title">{find.libelle}</p>
              <ul className="tags-filters">
                {filters.map((d,i)=>{
                  return <li key={"filter_"+j+"_"+i} className={d.active ? "active" : ""} onClick={(e)=>toggleKeywords(e,d)} >
                    <span>{d.label}</span>
                    {d.filters &&
                      <ul className="tags-filters">
                        {d.filters.map((d,i)=>{
                          return <li key={"filter_"+d.label+"_"+i} className={d.active ? "active" : ""} onClick={(e)=>toggleKeywords(e,d)} >
                            <span>{d.label}</span>
                          </li>
                        })}
                      </ul>
                    }
                  </li>
                })}
              </ul>
            </React.Fragment>
          )
        }else{
          return null
        }
      })
    }

  }

  return (
    <>
      { tagsFilters.length>0 &&
        <>
          {renderManualTags()}
        </>
      }
      { tagsQES.length>0 &&
        <>
        {tagsQES.map((d,j)=>{
          return(
            <div key={"qes_group_"+j} >
              <p className="title-section">{d.title}</p>
              <ul className="tags-qes">
                {d.tags.map((d,i)=>{
                  return <li key={"filter_"+j+"_"+i} className={d.active ? "active" : ""} onClick={(e)=>toggleKeywords(e,d,'QES')} >{d.label}</li>
                })}
              </ul>
            </div>
          )
        })}
        </>
      }
    </>
  )
}

export default withLocalize(Tags)
