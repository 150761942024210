import { postData } from './index'
import {
   GET_DOCUMENTS_BY_ID,
   GET_SIMILIRAIRE_DOCUMENT_BY_ID,
   GET_FILTERED_DOCUMENTS,
   GET_DOCUMENTS_COUNT,
   SET_DOCUMENTS_COUNT,
   SET_DOCUMENT_NUMBER,
   SET_ORDER_BY_DOCUMENT,
   REMOVE_DOCS,
   REMOVE_SIMILIRAIRE_DOCUMENT,
   LOADING_DOCUMENTS,
   ERROR_DOCUMENTS
} from './types'

export async function getDocumentById(dispatch, obj){
  let url = 'doc'
  await postData(GET_DOCUMENTS_BY_ID, ERROR_DOCUMENTS, true, url , dispatch, obj)
}

export async function getSimilaireDocumentById(dispatch, obj){
  let url = 'doc'
  await postData(GET_SIMILIRAIRE_DOCUMENT_BY_ID, ERROR_DOCUMENTS, true, url , dispatch, obj)
}

export async function removeDocs(dispatch){
  dispatch({type : REMOVE_DOCS})
}

export async function orderBy(dispatch, order){
  // console.log(SET_ORDER_BY_DOCUMENT);
  dispatch({type : SET_ORDER_BY_DOCUMENT, payload : order})
}

export async function setDocumentNumber(dispatch, number){
  dispatch({type : SET_DOCUMENT_NUMBER, payload : number})
}

export async function setDocumentCount(dispatch, count){
  dispatch({type : SET_DOCUMENTS_COUNT, payload : count})
}

export async function removeSimilaireDocument(dispatch, id){
  // console.log(REMOVE_SIMILIRAIRE_DOCUMENT);
  dispatch({type : REMOVE_SIMILIRAIRE_DOCUMENT})
}

export async function getFilteredDocuments(dispatch, filters, change = "default"){
  let url = 'doc/filtered'
  dispatch({type : LOADING_DOCUMENTS, payload : change})
  await postData(GET_FILTERED_DOCUMENTS, ERROR_DOCUMENTS, true, url , dispatch, filters)
}

export async function getDocumentsCount(dispatch, filters){
  let url = 'doc/count'
  dispatch({type : LOADING_DOCUMENTS})
  await postData(GET_DOCUMENTS_COUNT, ERROR_DOCUMENTS, true, url , dispatch, filters)
}
