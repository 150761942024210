import axios from 'axios';
import { ERROR_AUTH } from "./types"
export const API_URL = 'https://api.explore.intelligencemaker.com/api/';
//export const API_URL = 'https://api.searchinnov.bakasable.io/api/';

export function getData(action, errorType, url, dispatch, isAuthReq) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    let config = {};

    if (isAuthReq) {

      config = {
        headers: {
          Authorization : localStorage.getItem('token')
        }
      }
    }

    axios.get(requestUrl, config)
    .then((response) => {
      resolve(action)
      dispatch({
        type: action,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(error);
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  });

}

export function postData(action, errorType, isAuthReq, url, dispatch, data, origine, plateformeID) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    // console.log(requestUrl);

    let headers = {
      timeout: 1000 * 5
    };

    if (isAuthReq) {
      headers = { headers: { Authorization: localStorage.getItem('token') } };
    }

    axios.post(requestUrl, data, headers)
    .then((response) => {
      resolve(action)
      dispatch({
        type: action,
        origine,
        plateformeID,
        payload: response.data,
      });
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  })

}

// DOESN'T DISPATCH RESPONSE
// BUT DISPATCH ERROR
export function postDataWithoutDispatching(action, errorType, isAuthReq, url, dispatch, data) {
  return new Promise(function(resolve, reject) {

    const requestUrl = API_URL + url;
    // console.log(requestUrl);

    let headers = {
      timeout: 1000 * 5
    };

    if (isAuthReq) {
      headers = { headers: { Authorization: localStorage.getItem('token') } };
    }

    axios.post(requestUrl, data, headers)
    .then((response) => {
      resolve(response.data)
    })
    .catch((error) => {
      reject(action)
      errorHandler(dispatch, error, errorType);
    });

  })

}

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error);

  let errorMessage = ''

  if(error){
    errorMessage = error.response ? error.response.data : error;
  }

  if(error && error.wrongToken){
    // window.location.href = 'https://www.google.com'
    return null;
  }

  if(type === ERROR_AUTH && error.response !== undefined && error.response.status === 401){
    errorMessage = 'Les identifiants inscrits ne sont pas corrects ';
    dispatch({
      type,
      payload: errorMessage,
    });
  }


   // NOT AUTHENTICATED ERROR
  if (error.response !== undefined && error.response.status === 401 && type !== ERROR_AUTH) {
    errorMessage = "Erreur d'authentification";
    window.location = window.location.origin
  }else if(error.response !== undefined ){
    errorMessage = 'Bad gateaway error'
  }else{
    errorMessage = 'local error'
  }

  dispatch({
    type,
    payload: errorMessage,
  });

  // window.location = window.location.origin


}
