import React from 'react'
import styles from './tags-count.module.scss'

const TagsCount = ({count}) => {
    return (
        <>
            {count.rub>0 && <span className={`${styles.count} ${styles.rub}`}>{count.rub}</span>}
            {count.select>0 && <span className={`${styles.count} ${styles.select}`}>{count.select}</span>}
            {count.exclude>0 && <span className={`${styles.count} ${styles.exclude}`}>{count.exclude}</span>}
            {count.cumul>0 && <span className={`${styles.count} ${styles.cumul}`}>{count.cumul}</span>}
        </>
    )
}

export default TagsCount;