import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from "react-localize-redux";

import _ from 'lodash'
import inlineLoader from '../../../assets/images/inline-loader.gif'
import loader from '../../../assets/images/loader.gif'
import MaterialIcon from 'material-icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

import useContentDimension from '../../../customHooks/useContentDimension'
import useListDocuments from '../../../customHooks/useListDocuments'
import { useGlobalContext } from '../../../GlobalContext'
import { filtersParams } from '../../../helpers/filtersParams'
import { getFilteredDocuments, orderBy, setDocumentNumber, removeDocs } from '../../../actions/documents'

import DocItem from './DocItem'
import PopupExport from './PopupExport'
import IconInc from  '../../../assets/images/hausse.js'
import Icondesc from  '../../../assets/images/baisse.js'
import IconExport from  '../../../assets/images/icon-export.js'

function initSelectedFilter(order,translate){
  let res;
  switch (order) {
    case "date_ressource_desc":
      res = {label: translate("global.action-date"), id: "0"}
      break;
    case "date_ressource_inc":
      res = {label: translate("global.action-date"), id: "1"}
      break;
    case "date_pub_desc":
      res = {label: translate("global.date-pub"), id: "2"}
      break;
    case "date_pub_inc":
      res = {label: translate("global.date-pub"), id: "3"}
      break;
    default:
      break;
  }

  return res

}

const ListeDocuments = (props) => {
  const contentDimension = useContentDimension()

  const [ context, dispatch ]  = useGlobalContext();

  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;

  const [ selectedDoc, setSelectedDoc ] = useState(null)
  const [ listSelectedDocs, setListSelectedDocs ] =  useState([]) // useState(fakeIds)
  const [ isFilterOpen , setIsFilterOpen ] = useState(false)
  const [ filterSelected , setFilterSelected ] = useState()

  const [ selectAll , setSelectAll ] = useState(false)
  const [ isExportOpen , setIsExportOpen ] = useState(false) // useState(true)


  const _getFilteredDocuments = (codes,change) =>  getFilteredDocuments(dispatch,codes,change);
  const _orderBy = (order) =>  orderBy(dispatch,order);
  const _removeDocs = () => removeDocs(dispatch);
  const _setDocumentNumber = (number) => setDocumentNumber(dispatch,number);

  let listDocuments = useListDocuments(
    context,
    context.documentsReducer,
    context.documentsReducer.documents,
    _getFilteredDocuments,
    _removeDocs,
    filtersReducer.filterView
  )


  useEffect(()=>{
    setFilterSelected(initSelectedFilter(context.documentsReducer.orderBy,props.translate))
  },[props.activeLanguage.code])

  useEffect(()=>{
    setSelectedDoc(null)
  },[filtersReducer.selectedKeywords,filtersReducer.date])

  function changeCountByRequest(number){
    _setDocumentNumber(number)
    listDocuments.getInitDocuments(number, "count")
  }

  function selectDoc(e,doc){
    e.preventDefault();
    e.stopPropagation();
    // if(doc){
    //   listDocuments.setDisabledHitBottom(true)
    // }else{
    //   listDocuments.setDisabledHitBottom(false)
    // }
    props.selectDoc(doc)
  }

  function toggleCheckbox(e,doc, action){
    e.preventDefault();
    e.stopPropagation();
    if( action === 'add' ){
      setListSelectedDocs([...listSelectedDocs, doc.ID_QES])
    }else if( action === 'remove' ) {
      setListSelectedDocs(listSelectedDocs.filter(d => d !== doc.ID_QES))
    }
  }

  function selectAllForExport(bool){
    setListSelectedDocs([])
    setSelectAll(bool)
  }

  function selectFilter(e){
    let filter;
    let idFilter = parseInt(e.target.id);
    if (idFilter === 0) {
      filter = "date_ressource_desc"
    } else if (idFilter === 1) {
      filter = "date_ressource_inc"
    } else if (idFilter === 2) {
      filter = "date_pub_desc"
    } else if (idFilter === 3) {
      filter = "date_pub_inc"
    }

    if(filter !== 'reset'){
      _orderBy(filter)
      let result = filtersParams(filtersReducer,viewReducer.lang,context.documentsReducer.number)
      result = _.cloneDeep(result);
      result.filters.orderBy = filter

      if(filtersReducer.filterView){
        let obj = {}
        filtersReducer.filterView.forEach((d)=>{
          if(!obj[d.id]){
            obj[d.id] = { ...d }
          }else{
            obj[d.id].code += "%20OR%20"+d.code
          }
        })

        result.filters.filters = [ ...result.filters.filters, obj.RUBRIQUE]
      }

      listDocuments.getDocuments(result.filters,"orderBy")
      setFilterSelected({label : e.target.textContent, id : e.target.id })
    }
  }

  function scrollTop(){
    let elt = document.querySelector("#container-documents>div")
    elt.scrollTop = 0
  }

  function listScroll(e){
    if(!isExportOpen) listDocuments.scroll(e)
  }

  function getWidthContainerDocs(){

    if(contentDimension.width < 867){
      return { left : "0px" }
    }else if(contentDimension.width < 1165){
      return { left : "30px" }
    }else{
      return {width : (contentDimension.width-320)/2}
    }
  }

  return(
    <div id="container-documents" className={selectedDoc ? "documents noscroll" : "documents" }
      onScroll={(e) => listScroll(e)}
      style={getWidthContainerDocs()}
    >
      {isExportOpen && <PopupExport
        translate={props.translate}
        ids={listSelectedDocs}
        documents={listDocuments.documents}
        selectAll={selectAll}
        closePopup={() => setIsExportOpen(false)}/>
      }
      <div className="content">
      {
        <div className="scrollTop" onClick={scrollTop} ><FontAwesomeIcon icon={faChevronUp} /></div>
      }
      { !listDocuments.isLoading && listDocuments.documents === null ?
        <p className="no-docs"> 0 <Translate id={"documents.document"} /></p> : null
      }
      { listDocuments.isLoading && listDocuments.documents === null ?
        <img className="loader-circle" src={loader} alt="loader" /> : null
      }
      {listDocuments.documents !== null ?
        <>
          <span className="close" onClick={props.closeDocuments}></span>
          <div className="title">
            <h1>{listDocuments.countDocs} <Translate id={"documents.document"} />{listDocuments.countDocs > 1 ? "s" : ""}</h1>
            <div className="container-export hide-on-mobile">
              { !selectAll ?
                <p onClick={()=>selectAllForExport(true)}><Translate id={"documents.export-select-all"} /></p> :
                <p onClick={()=>selectAllForExport(false)}><Translate id={"documents.export-deselect-all"} /></p>
              }
              <div data-tip data-for='export' className="btn-export" onClick={() => setIsExportOpen(!isExportOpen)}>
                <Translate id={"documents.export-selection"} />
                <IconExport color={"#151845"}/>
                <IconExport color={"#FF7D67"}/>
              </div>

              <ReactTooltip className="react-tooltip" delayShow={500} id='export' place="top" effect='solid'>
                <span><Translate id={"documents.tooltip-export"} /></span>
              </ReactTooltip>
            </div>
          </div>
          <div className="container-documents-filters">
            <p className="hide-on-mobile"><Translate id={"documents.document-per-load"} /> :&nbsp;
              <span>
                <span className={context.documentsReducer.number === 30 ? "selected" : ""} onClick={()=>changeCountByRequest(30)}>30</span>
                <span className={context.documentsReducer.number === 50 ? "selected" : ""} onClick={()=>changeCountByRequest(50)}>50</span>
                <span className={context.documentsReducer.number === 100 ? "selected" : ""} onClick={()=>changeCountByRequest(100)}>100</span>
                <span className={context.documentsReducer.number === 1000 ? "selected" : ""} onClick={()=>changeCountByRequest(1000)}>1000</span>
              </span>
            </p>
            <div className="filters-list">
              <p className={isFilterOpen ? "open" : ""} onClick={() => setIsFilterOpen(!isFilterOpen)} >
                { filterSelected ?
                  <>
                  <Translate id="global.filter-by"/> <b>{filterSelected.label}</b>
                  {filterSelected.id === "0" || filterSelected.id === "2" ?
                    <Icondesc color={"#6E708C"} /> : <IconInc color={"#6E708C"} />
                  }
                  </> : <><Translate id="global.filter-by"/> ...</>
                }
                { isFilterOpen ?
                  <MaterialIcon icon="keyboard_arrow_up" key="arrow-up" size={18} /> :
                  <MaterialIcon icon="keyboard_arrow_down" key="arrow-down" size={18} />
                }

              </p>

              { isFilterOpen ?
                <div className="select" onMouseLeave={() => setIsFilterOpen(false)}>
                  <ul>
                    { !filterSelected || filterSelected.id !== "0" ? <li id="0" onClick={selectFilter}>
                      <Translate id="global.action-date"/><Icondesc color={"#6E708C"} />
                      </li> : null}
                    { !filterSelected || filterSelected.id !== "1" ? <li id="1" onClick={selectFilter}>
                      <Translate id="global.action-date"/><IconInc color={"#6E708C"} />
                      </li> : null}
                    { !filterSelected || filterSelected.id !== "2" ? <li id="2" onClick={selectFilter}>
                      <Translate id="global.date-pub"/><Icondesc color={"#6E708C"} />
                      </li> : null}
                    { !filterSelected || filterSelected.id !== "3" ? <li id="3" onClick={selectFilter}>
                      <Translate id="global.date-pub"/><IconInc color={"#6E708C"} />
                      </li> : null}
                  </ul>
                </div> : null
              }

            </div>
          </div>

          <ul className={listDocuments.isLoading ? "list-documents loading" : "list-documents"} >
          {listDocuments.documents.map((d,i)=>{
            return (
              <DocItem
                key={"documents-"+i}
                filtersReducer={filtersReducer}
                indexFilter={filterSelected.id}
                content={d}
                selected={selectAll ? selectAll : listSelectedDocs.includes(d.ID_QES)}
                selectDoc={selectDoc} toggleCheckbox={toggleCheckbox}
              />
            )
          })}
          </ul>

          {listDocuments.isLoading || listDocuments.disabledHitBottom ?
            <div className="container-loader"><img className="loader" src={inlineLoader} alt="loader" /></div> : null
          }
          {!listDocuments.isLoading && listDocuments.documents.length === listDocuments.countDocs ?
            <p className="loaded"><Translate id="global.all-docs"/></p> : null
          }

        </> : null
      }

      </div>
    </div>

  )
}

export default withLocalize(ListeDocuments)
