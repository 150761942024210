import innovations from './innovations.json'
import startups from './startups.json'
import sustainability from './sustainability.json'
import playersstrategies from './players-strategies.json'
import publicframework from './public-framework.json'
import intelligencetools from './intelligence-tools.json'
import academy from './academy.json'
import megatrends from './megatrends.json'
import hottopics from './hot-topics.json'
import businessmodels from './business-models.json'
import keytechnologies from './key-technologies.json'
import cities from './cities.json'
import corporates from './corporates.json'
import circulargoals from './circular-goals.json'
import sustainablegoals from './sustainable-goals.json'
import clientexpectations from './client-expectations.json'


const config = {
  "header": [
    innovations,
    startups,
    sustainability,
    playersstrategies,
    publicframework,
    intelligencetools,
    academy,
    megatrends,
    hottopics,
    businessmodels,
    keytechnologies,
    cities,
    corporates,
    circulargoals,
    sustainablegoals,
    clientexpectations


  ]
}

export default config
