import startups from './startups.json'
import highpotentials from './high-potentials.json'


const config = {
  "header": [
    startups,
    highpotentials,
  ]
}

export default config
