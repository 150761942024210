import React, { useState, useEffect, useRef } from 'react'
import { Translate, withLocalize } from "react-localize-redux";
import { FaChevronRight, FaChevronLeft} from 'react-icons/fa';

import InspiringContents from './InspiringContents'
import loader from '../../../assets/images/loader-disabled.gif'
import IconDownload from '../../../assets/images/icon-download.svg'
import useContentDimension from '../../../customHooks/useContentDimension'
import { getDownloadBaseUrl, getExt } from '../../../helpers/helpers'

const Referentiel =(props) => {
  const [ doc , setDoc] = useState(null)
  const [ readMore , setReadMore] = useState(null)
  const [ inspiringContentsOpened, setInspiringContentsOpened ] = useState(false)
  const dimension = useContentDimension();
  const [ maxHeightImg, setMaxHeightImg] = useState();
  const [baseUrl, setBaseUrl] = useState();

  const titleAbstractRef = useRef()
  const containerRef = useRef()

  useEffect(() => {
    const plateforme = JSON.parse(localStorage.getItem('plateforme'));
    if (plateforme?.url) {
      const url = plateforme?.url.split('/').splice(0, 4).join('/');
      setBaseUrl(`${url}/servletpub/QESServlet_fwdImg?Base=22801201&Fichier=`);
    }
  }, [])

  useEffect(()=>{
    setDoc(props.document)
    return () => {
      setReadMore(false)
    }
  },[props.document])

  useEffect(()=>{
    if(!containerRef.current || !titleAbstractRef.current || !doc) return;
    let heightContainer = (containerRef.current.offsetHeight)-30
    let heightTitleAbstract = titleAbstractRef.current.offsetHeight
    setMaxHeightImg(heightContainer-heightTitleAbstract)
  },[
    dimension.width,
    containerRef.current,
    titleAbstractRef.current,
    doc?.ID_QES
  ])

  function renderTexte (texte){
    var re = /style="(.*?)"/g

    let textWithoutStyle = texte.replace(re,"")
    return textWithoutStyle
  }

  function download(){
    // ENVIRONMENTAL FOOTPRINT & Nuisance Free
    let file;
    if(Array.isArray(doc.FILES)){
      file = doc.FILES[0]
    }else{
      file = doc.FILES
    }

    let token = localStorage.getItem("token")
    let ext = getExt(file)
    let urlFile = getDownloadBaseUrl(ext)+file+"&token="+token
    window.open(urlFile)

  }

  function toggleInspiringContent(){
    setInspiringContentsOpened(!inspiringContentsOpened)
  }

  function toggleReadMore(bool){
    if(!bool){
      let elt = document.querySelector('.document-view.referentiel>div')
      elt.scrollTo(0,0);
    }
    setReadMore(bool)
  }


  return (
    <>
      <div className="document-view referentiel" ref={containerRef}>
        { doc ?
          <div style={!readMore ? { overflow : "hidden" } : {}}>
            <h1 ref={titleAbstractRef} >
              <div className="ref-container-actions">
                <div className="back" onClick={() => props.selectDoc(null)}>
                  <span className="close"></span>
                  <Translate id="global.close"/>
                </div>
                <span>
                  <button className="btn-inspiring-contents only-on-mobile" onClick={() => toggleInspiringContent()}>
                    <Translate id={"documents.ref.title-inspiring-content"} />
                  </button>
                  { doc.FILES &&
                    <button onClick={download} ><img src={IconDownload} alt="download"/><Translate id="global.download"/></button>
                  }
                </span>

              </div>

              <span dangerouslySetInnerHTML={{__html: doc.TITLE}}></span>
              { doc.ABSTRACT &&
                <div className={"container-abstract"}>
                  <span className="abstract" dangerouslySetInnerHTML={{ __html: renderTexte(doc.ABSTRACT)}}></span>
                </div>
              }
            </h1>
            <div className="content">
              {doc.IMAGE &&
                <>
                  { (doc.IMAGE && Array.isArray(doc.IMAGE)) ?
                    <div className="container-image">
                      <img
                        id="image"
                        style={!readMore ? {maxHeight : maxHeightImg+"px"} : {}}
                        src={`${baseUrl}${doc.IMAGE[0]}`} alt="document"
                      />
                    </div> :
                    <div className="container-image" style={!readMore ? {maxHeight : maxHeightImg+"px"} : {}}>
                      <img
                        id="image"
                        style={!readMore ? {maxHeight : maxHeightImg+"px"} : {}}
                        src={`${baseUrl}${doc.IMAGE}`} alt="document"
                      />
                    </div>
                  }
                </>
              }

              {!readMore && (
                <div className="container-read-more">
                  <div className="gradient"></div>
                  <button onClick={() => toggleReadMore(true)}><Translate id="documents.document-view.read-more"/></button>
                </div>
              )}

              { doc.TEXTE && readMore && (
                <div className={"detail-fact"}>
                  <p dangerouslySetInnerHTML={{ __html: renderTexte(doc.TEXTE) }} ></p>
                </div>
              )}

              { readMore && (
                <div className="centered"><button onClick={() => toggleReadMore(false)}><Translate id="documents.document-view.back-to-resume"/></button></div>
              )}



            </div>
          </div> :
          <img className="loader-doc" alt="loader" src={loader} />
        }
      </div>
      { (doc && props.firstRef) &&
        <div className="navigation-referentiel">
          { doc && (props.firstRef && props.firstRef.ID_QES === doc.ID_QES) ?
            <div></div> :
            <div onClick={() => props.prevNext("prev")}>
              <FaChevronLeft size={14} color={"#FF7D67"}/>
              <p>Previous</p>
            </div>
          }
          { !doc.lastOne &&
            <div onClick={() => props.prevNext("next")}>
              <p>Next</p>
              <FaChevronRight size={14} color={"#FF7D67"}/>
            </div>
          }

        </div>
      }
      <InspiringContents
        match={props.match} 
        isOpenedOnMobile={inspiringContentsOpened}
        toggleInspiringContent={toggleInspiringContent} doc={doc} selectDoc={props.selectDoc}
      />
    </>
  )
}

export default withLocalize(Referentiel)
