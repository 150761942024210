import {
  GET_FILTERED_REFERENCES,
  LOADING_REFERENCES,
  ERROR_REFERENCES
} from '../actions/types'

const initialState = { references : false, isLoading: false, messageError : null};

export default function referencesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FILTERED_REFERENCES :
      return {...state, references : action.payload.result.docs}
    case LOADING_REFERENCES :
      return {...state}
    case ERROR_REFERENCES :
      return {...state}
    default:
      return {...state};
  }
}
