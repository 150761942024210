import React, { useState, useEffect } from 'react';
import {useGlobalContext} from "../../GlobalContext";
import ButtonDocs from './ButtonDocs'

export default function InfoExport(props){
  const context  = useGlobalContext()[0];
  const filtersReducer = context.filtersReducer;
  const [ search, setSearch ] = useState();
  const [ plateforme, setPlateforme ] = useState();
  const [ date, setDate ] = useState()

  useEffect(()=>{
    let type;
    if(filtersReducer.search && filtersReducer.search.type){
      type = filtersReducer.search.type.map((type)=>type.toLowerCase()).join(" / ")
      type =  " ("+type+")"
      let search = filtersReducer.search.string+type
      setSearch(search)
    }
  },[filtersReducer.search])

  useEffect(()=>{
    setPlateforme(JSON.parse(localStorage.getItem("plateforme")))

    let d = new Date();
    let date = d.toLocaleDateString();
    date = date.toString()
    setDate(date)

  },[])

  return (
      <div className="info-export">
         <div className="container-btn-docs"><ButtonDocs background={false} /></div>
        
        <div className="export-logo">
          {plateforme ?
            <p>{plateforme.libelle}</p> : null
          }
        </div>
        <div className="export-date">
          <p>{date}</p>
        </div>
        { (!props.hideKeywords &&
          (filtersReducer.selectedKeywords && filtersReducer.selectedKeywords.length > 0)) ||
          search ?
          <div className="selected-keywords">
          { search ?
            <p className="search">{search}</p> : null
          }
          { filtersReducer.selectedKeywords ?
            <>
              {filtersReducer.selectedKeywords.map((d,i)=>{
                return <p key={d.id+"-"+i} className={d.exclude ? "exclude" : ""}>{d.label}</p>
              })}
            </> : null
          }
          </div>
          : null
        }

      </div>
  )
}
