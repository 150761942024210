import queryString from 'query-string'

export function fnLogout(){
  if(localStorage.getItem("token")){
    localStorage.removeItem("token")
  }
  if(localStorage.getItem("plateforme")){
    localStorage.removeItem("plateforme")
  }
  if(localStorage.getItem("plateformes")){
    localStorage.removeItem("plateformes")
  }
  if(localStorage.getItem("user")){
    localStorage.removeItem("user")
  }
  if(localStorage.getItem("filters")){
    localStorage.removeItem("filters")
  }
  if(localStorage.getItem("search")){
    localStorage.removeItem("search")
  }
  if(localStorage.getItem("popup")){
    localStorage.removeItem("popup")
  }
  if(localStorage.getItem("plateformeID")){
    localStorage.removeItem("plateformeID")
  }
  window.location = "/"

}

export function fnSelectEntry(
  history,
  filtersJSON,
  dispatch,
  entry,
  indexSelectedEntries,
  index,
  url
){

  let fromLocationUrl = history.location.pathname
  

  if(filtersJSON["header"][index].link){
    history.replace(filtersJSON["header"][index].link)
    document.location.reload();
    return;
  }

  if(url){
    let params = url.match(/\?(.*)$/, "");
    if(params){
      history.push({
        pathname : url.replace(params[0],''),
        search : `${params[0]}&entry=${index}`
      })
    }else if (typeof index === "number") {
      history.push({
        pathname : url,
        search : `?entry=${index}`
      })
    }else{
      history.push(url)
    }
  }else{
    let pathname = history.location.pathname
    let plateformeID = localStorage.getItem("plateformeID")
    let currentPathname = pathname.replace(`/search-innov/${plateformeID}`,"")

    if(filtersJSON["header"][index].navbar){
      let allowViews = filtersJSON["header"][index].navbar.map((d) => entry.url)
      if(!allowViews.includes(currentPathname)){

        history.push({
          pathname : `/search-innov/${plateformeID}${filtersJSON["header"][index].navbar[0].url}`,
          search : `?entry=${index}`
        })
      }
    }
  }

  let defaultFilters;
  if(filtersJSON["header"][index].filters){
    defaultFilters = filtersJSON["header"][index].filters.map((d)=>{
      return d
    })
  }

  let defaultExcludedFilters;
  if(filtersJSON["header"][index]["filters-exclude"]){
    defaultExcludedFilters = filtersJSON["header"][index]["filters-exclude"].map((d)=>{
      return d
    })
  }

  if(indexSelectedEntries !== index){

    let entries = entry.rubrique.map((d)=>(
      {code : d, id : "RUBRIQUE"}
    ))


    dispatch({
      type : "SET_HEADER_ENTRIES",
      payload : { entries, defaultFilters, defaultExcludedFilters, index},
      origin : fromLocationUrl
    })

  }
}
