import React from 'react';
import { LocalizeProvider } from "react-localize-redux";
import { combineReducers } from 'redux';
import { Router } from 'react-router';
import history from './history';

import { GlobalProvider } from './GlobalContext'
import './scss/App.scss';

import Routes from './Routes'

import authReducer from './reducers/authReducer'
import viewReducer from './reducers/viewReducer'
import filtersReducer from './reducers/filtersReducer'
import documentsReducer from './reducers/documentsReducer'
import newspaperReducer from './reducers/newspaperReducer'
import datavizReducer from './reducers/datavizReducer'
import referencesReducer from './reducers/referencesReducer'
import analysisToolsReducer from './reducers/analysisToolsReducer'

const reducers = combineReducers({
  authReducer,
  viewReducer,
  filtersReducer,
  documentsReducer,
  newspaperReducer,
  datavizReducer,
  referencesReducer,
  analysisToolsReducer
})

const initialState = reducers( {} , { type: '__INIT__' });

function App() {

  return (
    <div className="App">
      <LocalizeProvider>
        <Router history={history}>
          <GlobalProvider reducers={reducers} initialState={initialState}>
            <Routes/>
          </GlobalProvider>
        </Router>

      </LocalizeProvider>
    </div>
  );
}


export default App;
