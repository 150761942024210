import React from 'react';
import { Switch, Route } from 'react-router';
import plateformesID from "./config/list-plateforms-id.json"
import Login from './components/auth/Login'
import Michelin from './components/auth/sso/Michelin'
import Grantthornton from './components/auth/sso/Grantthornton'
import Redirect from './components/redirect'
import ContainerApp from './components/ContainerApp'
import NoMatch from './components/partials/NoMatch'
import DocumentOffline from './components/documents/DocumentOffline'

/// TRADUCTION
// import globalTranslations from "./assets/translations/global.json";
// import headerTranslations from "./assets/translations/header.json";
// import documentsTranslations from "./assets/translations/documents.json";
// import strategicWatchTranslations from "./assets/translations/explorations/strategic-watch.json";
// import linksExplorationTranslations from "./assets/translations/explorations/links-exploration.json";
// import timelineTranslations from "./assets/translations/explorations/timeline.json";
// import filtersTranslations from "./assets/translations/filters.json";
// import helpTranslations from "./assets/translations/help.json";
// import assitModeTranslations from "./assets/translations/assist-mode.json";
// import startups from "./assets/translations/start-ups.json";
// import aboutTranslations from "./assets/translations/about.json";

const Routes = (props) => {

  function ofOneParamId(){
    let availablePlateformes = plateformesID
    return `:id(${availablePlateformes.join('|')})`
  }

  function ofOneParamPage(){
    let availablePages = ["home","strategic-watch", "dashboard", "links-exploration", "geolocalisation",
    "wordscloud", "timeline", "partnerships", "analysis-tools", "help", "about"]
    return `:page/:tools?/:code?`
    // return `:page(${availablePages.join('|')})/:tools?/:code?`
  }

  return (
    <Switch>
      <Route exact path="/" component={Login}/>
      <Route exact path="/michelin" component={Michelin}/>
      <Route exact path="/grantthornton" component={Grantthornton}/>
      <Route exact path="/redirect/:token" component={Redirect}/>
      
      <Route exact path={`/search-innov/${ofOneParamId()}/${ofOneParamPage()}`} component={ (props) =>
        <ContainerApp
          {...props}
        />
      }/>
      <Route path={`/document`} component={(props) => {
        return <DocumentOffline history={props.history} />
      } } />
      <Route path="*">
        <NoMatch/>
      </Route>
    </Switch>
  )
}

export default Routes
