export default () => (
  {
  "Afghanistan": "AFG",
  "Albanie": "ALB",
  "Algérie": "DZA",
  "Andorre": "AND",
  "Angola": "AGO",
  "Antigua-et-Barbuda": "ATG",
  "Argentine": "ARG",
  "Arménie": "ARM",
  "Aruba": "ABW",
  "Samoa américaines": "ASM",
  "Australie": "AUS",
  "Autriche": "AUT",
  "Azerbaïdjan": "AZE",
  "Bahamas": "BHS",
  "Bangladesh": "BGD",
  "Barbade": "BRB",
  "Burundi": "BDI",
  "Belgique": "BEL",
  "Bénin Bénin": "BEN",
  "Bermudes": "BMU",
  "Bhoutan": "BTN",
  "Bosnie-Herzégovine": "BIH",
  "Belize": "BLZ",
  "Biélorussie": "BLR",
  "Bolivie": "BOL",
  "Botswana": "BWA",
  "Brésil": "BRA",
  "Bahreïn": "BHR",
  "Brunei": "BRN",
  "Bulgarie": "BGR",
  "Burkina Faso": "BFA",
  "République centrafricaine": "CAF",
  "Cambodge": "KHM",
  "Canada": "CAN",
  "Îles Caïmans": "CYM",
  "République du Congo": "COG",
  "Tchad": "TCD",
  "Chili": "CHL",
  "Chine": "CHN",
  "Côte d'Ivoire": "CIV",
  "Cameroun": "CMR",
  "République démocratique du Congo": "COD",
  "Îles Cook": "COK",
  "Colombie": "COL",
  "Comores": "COM",
  "Corée unifiée": "COR",
  "Cap-Vert": "CPV",
  "Costa Rica": "CRI",
  "Croatie": "HRV",
  "Cuba": "CUB",
  "Chypre": "CYP",
  "République tchèque": "CZE",
  "Danemark": "DNK",
  "Djibouti": "DJI",
  "Dominique": "DMA",
  "République dominicaine": "DOM",
  "Equateur": "ECU",
  "Égypte": "EGY",
  "Erythrée": "ERI",
  "Salvador": "SLV",
  "Espagne": "ESP",
  "Estonie": "EST",
  "Éthiopie": "ETH",
  "Fidji": "FJI",
  "Finlande": "FIN",
  "France": "FRA",
  "Etats fédérés de Micronésie": "FSM",
  "Gabon": "GAB",
  "Gambie": "GMB",
  "Royaume-Uni": "GBR",
  "Guinée-Bissau": "GNB",
  "Géorgie": "GEO",
  "Guinée équatoriale": "GNQ",
  "Allemagne": "DEU",
  "Ghana": "GHA",
  "Grèce": "GRC",
  "Grenade": "GRD",
  "Guatemala": "GTM",
  "Guinée": "GIN",
  "Guam": "GUM",
  "Guyana": "GUY",
  "Haïti": "HTI",
  "Hong Kong": "HKG",
  "Honduras": "HND",
  "Hongrie": "HUN",
  "Indonésie": "IDN",
  "Inde": "IND",
  "Iran": "IRN",
  "Irlande": "IRL",
  "Irak": "IRQ",
  "Islande": "ISL",
  "Israël": "ISR",
  "Îles Vierges des États-Unis": "VIR",
  "Italie": "ITA",
  "Îles Vierges britanniques": "VGB",
  "Jamaïque": "JAM",
  "Jordanie": "JOR",
  "Japon": "JPN",
  "Kazakhstan": "KAZ",
  "Kenya": "KEN",
  "Kirghizistan": "KGZ",
  "Kiribati": "KIR",
  "Kosovo": "N.D.",
  "Corée du Sud": "KOR",
  "Arabie Saoudite": "SAU",
  "Koweït": "KWT",
  "Laos": "LAO",
  "Lettonie": "LVA",
  "Libye": "LBY",
  "Liban": "LBN",
  "Liberia": "LBR",
  "Sainte-Lucie": "LCA",
  "Lesotho": "LSO",
  "Liechtenstein": "LIE",
  "Lituanie": "LTU",
  "Luxembourg": "LUX",
  "Madagascar": "MDG",
  "Maroc": "MAR",
  "Malaisie": "MYS",
  "Malawi": "MWI",
  "Moldavie": "MDA",
  "Maldives": "MDV",
  "Mexique": "MEX",
  "Mongolie": "MNG",
  "Îles Marshall": "MHL",
  "Macédoine du Nord": "MKD",
  "Mali": "MLI",
  "Malte": "MLT",
  "Monténégro": "MNE",
  "Monaco": "MCO",
  "Mozambique": "MOZ",
  "Maurice": "MUS",
  "Mauritanie": "MRT",
  "Birmanie": "MMR",
  "Namibie": "NAM",
  "Nicaragua": "NIC",
  "Pays-Bas": "NLD",
  "Népal": "NPL",
  "Nigeria": "NGA",
  "Niger": "NER",
  "Norvège": "NOR",
  "Nauru": "NRU",
  "Nouvelle-Zélande": "NZL",
  "Oman": "OMN",
  "Pakistan": "PAK",
  "Panama": "PAN",
  "Paraguay": "PRY",
  "Pérou": "PER",
  "Philippines": "PHL",
  "Palestine": "PSE",
  "Palaos": "PLW",
  "Papouasie-Nouvelle-Guinée": "PNG",
  "Pologne": "POL",
  "Portugal": "PRT",
  "Corée du Nord": "PRK",
  "Porto Rico": "PRI",
  "Qatar": "QAT",
  "Roumanie": "ROU",
  "Afrique du Sud": "ZAF",
  "Russie": "RUS",
  "Rwanda": "RWA",
  "Samoa": "WSM",
  "Sénégal": "SEN",
  "Seychelles": "SYC",
  "Singapour": "SGP",
  "Saint-Christophe-et-Niévès": "KNA",
  "Sierra Leone": "SLE",
  "Slovénie": "SVN",
  "Saint-Marin": "SMR",
  "Salomon": "SLB",
  "Somalie": "SOM",
  "Serbie": "SRB",
  "Sri Lanka": "LKA",
  "Soudan du Sud": "SSD",
  "Sao Tomé-et-Principe": "STP",
  "Soudan": "SDN",
  "Suisse": "CHE",
  "Suriname": "SUR",
  "Slovaquie": "SVK",
  "Suède": "SWE",
  "Swaziland": "SWZ",
  "Syrie": "SYR",
  "Tanzanie": "TZA",
  "Tonga": "TON",
  "Thaïlande": "THA",
  "Tadjikistan": "TJK",
  "Turkménistan": "TKM",
  "Timor oriental": "TLS",
  "Togo": "TGO",
  " Taipei chinois": "TWN",
  "Trinité-et-Tobago": "TTO",
  "Tunisie": "TUN",
  "Turquie": "TUR",
  "Tuvalu": "TUV",
  "Emirats arabes unis": "ARE",
  "Ouganda": "UGA",
  "Ukraine": "UKR",
  "Uruguay": "URY",
  "Etats-Unis": "USA",
  "Ouzbékistan": "UZB",
  "Vanuatu": "VUT",
  "Venezuela": "VEN",
  "Viêt Nam": "VNM",
  "Saint-Vincent-et-les-Grenadines": "VCT",
  "Yémen": "YEM",
  "Zambie": "ZMB",
  "Zimbabwe": "ZWE",
  "Albania": "ALB",
  "Algeria": "DZA",
  "Andorra": "AND",
  "Antigua-and-Barbuda": "ATG",
  "Argentina": "ARG",
  "Armenia": "ARM",
  "American Samoa": "ASM",
  "Australia": "AUS",
  "Austria": "AUT",
  "Azerbaijan": "AZE",
  "Barbados": "BRB",
  "Belgium": "BEL",
  "Benin": "BEN",
  "Bermuda": "BMU",
  "Bhutan": "BTN",
  "Bosnia-Herzegovina": "BIH",
  "Belarus": "BLR",
  "Bolivia": "BOL",
  "Brazil": "BRA",
  "Bahrain": "BHR",
  "Bulgaria": "BGR",
  "Central African Republic": "CAF",
  "Cambodia": "KHM",
  "Cayman Islands": "CYM",
  "Congo Republic": "COG",
  "Chad": "TCD",
  "Chile": "CHL",
  "China": "CHN",
  "Ivory Coast": "CIV",
  "Cameroon": "CMR",
  "Democratic Republic of Congo": "COD",
  "Cook Islands": "COK",
  "Colombia": "COL",
  "Comoros": "COM",
  "united Korea": "COR",
  "Cape Verde": "CPV",
  "Croatia": "HRV",
  "Cyprus": "CYP",
  "Czech Republic": "CZE",
  "Denmark": "DNK",
  "Dominica": "DMA",
  "Dominican Republic": "DOM",
  "Ecuador": "ECU",
  "Egypt": "EGY",
  "Eritrea": "ERI",
  "El Salvador": "SLV",
  "Spain": "ESP",
  "Estonia": "EST",
  "Ethiopia": "ETH",
  "Fiji": "FJI",
  "Finland": "FIN",
  "Federated States of Micronesia": "FSM",
  "The Gambia": "GMB",
  "United Kingdom": "GBR",
  "Guinea-Bissau": "GNB",
  "Georgia": "GEO",
  "Equatorial Guinea": "GNQ",
  "Germany": "DEU",
  "Greece": "GRC",
  "Granada": "GRD",
  "Guinea": "GIN",
  "Haiti": "HTI",
  "Hungary": "HUN",
  "Indonesia": "IDN",
  "India": "IND",
  "Ireland": "IRL",
  "Iraq": "IRQ",
  "Iceland": "ISL",
  "Israel": "ISR",
  "United States Virgin Islands": "VIR",
  "Italy": "ITA",
  "British Virgin Islands": "VGB",
  "Jamaica": "JAM",
  "Jordan": "JOR",
  "Japan": "JPN",
  "Kyrgyzstan": "KGZ",
  "South Korea": "KOR",
  "Saudi Arabia": "SAU",
  "Kuwait": "KWT",
  "Latvia": "LVA",
  "Libya": "LBY",
  "Lebanon": "LBN",
  "Saint Lucia": "LCA",
  "Lithuania": "LTU",
  "Morocco": "MAR",
  "Malaysia": "MYS",
  "Moldova": "MDA",
  "Mexico": "MEX",
  "Mongolia": "MNG",
  "Marshall Islands": "MHL",
  "Northern Macedonia": "MKD",
  "Malta": "MLT",
  "Montenegro": "MNE",
  "Mauritius": "MUS",
  "Mauritania": "MRT",
  "Burma": "MMR",
  "Namibia": "NAM",
  "The Netherlands": "NLD",
  "Nepal": "NPL",
  "Norway": "NOR",
  "New Zealand": "NZL",
  "athlètes olympiques de Russie": "N.D.",
  "Peru": "PER",
  "Palau": "PLW",
  "Papua New Guinea": "PNG",
  "Poland": "POL",
  "North Korea": "PRK",
  "Puerto Rico": "PRI",
  "Equipe olympique des réfugiés": "– n. d. –note 1",
  "Romania": "ROU",
  "South Africa": "ZAF",
  "Russia": "RUS",
  "Senegal": "SEN",
  "Singapore": "SGP",
  "Saint Kitts and Nevis": "KNA",
  "Slovenia": "SVN",
  "San Marino": "SMR",
  "Somalia": "SOM",
  "Serbia": "SRB",
  "South Sudan": "SSD",
  "Sao Tome and Principe": "STP",
  "Sudan": "SDN",
  "Switzerland": "CHE",
  "Slovakia": "SVK",
  "Sweden": "SWE",
  "Syria": "SYR",
  "Tanzania": "TZA",
  "Thailand": "THA",
  "Tajikistan": "TJK",
  "Turkmenistan": "TKM",
  "East Timor": "TLS",
  "Chinese Taipei": "TWN",
  "Trinidad and Tobago": "TTO",
  "Tunisi&": "TUN",
  "Turkey": "TUR",
  "United Arab Emirates": "ARE",
  "Uganda": "UGA",
  "United States": "USA",
  "Uzbekistan": "UZB",
  "Vietnam": "VNM",
  "Saint Vincent and the Grenadines": "VCT",
  "Yemen": "YEM",
  "Zambia": "ZMB"
}
)
