import React from 'react';
import { Skeleton } from '@material-ui/lab';
import styles from './loading-document.module.scss';

export default function LoadingDocument(){
  return (
    <div className={styles["loading-document"]}>
      <div>
        <Skeleton variant="rect" width={90} height={90} style={{}} />
      </div>
      <div className={styles["text"]}>
        <Skeleton variant="text" animation={"wave"} height={30} style={{}} width="100%"/>
        <Skeleton variant="text" animation={"wave"} height={30} style={{}} width="70%"/>
        <Skeleton variant="text" animation={"wave"} height={30} style={{}} width="30%"/>

      </div>
    </div>
  )
}
