import { useState, useEffect } from 'react'
import { useGlobalContext } from '../GlobalContext'

export default function usePlateforme(){
  const [ filtersJson, setfiltersJson ] = useState();
  const [context, dispatch] =  useGlobalContext();
  const filtersReducer = context.filtersReducer

  useEffect(()=>{
    if(!localStorage.getItem("plateformeID")) return;
    setfiltersJson({...getPlateforme(localStorage.getItem("plateformeID")), timestamp : Date.now()})
  },[filtersReducer.plateformeID])

  return filtersJson

}

export function getPlateforme(id){
  try{
    const filtersFiles = require(`../config/filters-${id}.json`);
    return filtersFiles
  }catch{
    const config = require(`../config/${id}/index.js`).default;
    return config
  }
}
