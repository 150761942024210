import React, { useState, useEffect } from 'react';
import format from 'format-number';
import moment from 'moment';
import { Translate, withLocalize } from "react-localize-redux";
import startUpConfig from './start-ups-config.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { escapeRegExp } from '../../../../helpers/helpers'

const fnFormat = (number) => format({integerSeparator: "&nbsp;"})(number)

const Indicators = ({title, websiteLink, filters, indicators, toggleKeywords, activeLanguage, ...props}) => {
  const [ organizedIndicators, setOrganizedIndicators ] = useState()
  const [ isOpen, setIsOpen ] = useState(true)

  useEffect(()=>{
    let arr = indicators.map((d)=> d)
    arr = indicators.filter((d)=> {
      return d.values.every(k => k.value !== "0.00")
    })
    let organizedIndicators = startUpConfig.entries.map((entry)=>{
      let obj = Object.assign({},entry)
      obj.entries = []
      let reg = new RegExp(escapeRegExp(obj.indicators.join("|")));
      arr.forEach((d,i)=>{
        if(reg.test(d.id)){

          obj.entries.push(d)
        }
      })

      let sortedEntries = obj.indicators.map((indicator)=>{
        let find = obj.entries.find(entry => entry.id === indicator)
        if(find) return find
      })
      // REMOVE FALSY VALUES
      obj.entries = sortedEntries.filter(entry => !!entry)

      return obj
    })

    setOrganizedIndicators(organizedIndicators)

  },[indicators])

  function hasTraduction(id){
    let trad = props.translate("su."+id)
    let reg = new RegExp(escapeRegExp(id));

    if(reg.test(trad) && /translationId:/.test(trad)){
      return id
    }else{
      return trad
    }

  }

  function renderIndicators(entries,sourceLink){
    let elts;
    elts = entries.map((entrie)=>{
      return (
        <div key={entrie.id}>
          <label>{entrie.title ? entrie.title : hasTraduction(entrie.id)} : </label>
          {renderValues(entrie.values)}
        </div>
      )
    })

    if(sourceLink && websiteLink){
      elts.push(
        <div key={"source-link"}>
          <label><Translate id="documents.document-view.start-up-website" /> : </label>
          <a href={websiteLink} target="_blank">{websiteLink}</a>
        </div>
      )
    }

    return elts

    function renderValues(values){

      let elt = values.map((d,i)=>{

        if(d.tag){
          return <span className={d.isSelected ? "tag active" : "tag"}  key={"key-"+i} onClick={(e)=> toggleKeywords(e,d,null)}>{d.label}</span>
        }else{
          return (
            <span key={"key-"+i} >
              {(isALink(d.value) && !isADate(d.value)) &&
                <a href={d.value} target="_blank">{d.value}</a>
              }
              {(!isALink(d.value) && isADate(d.value)) &&
                <p>
                  {moment(d.value).format("L", 'fr')}
                </p>
              }
              {(!isALink(d.value) && !isADate(d.value)) &&
                <p>
                  {!isNaN(d.value) ?
                    <span dangerouslySetInnerHTML={{__html: fnFormat(d.value)}}/> :
                    d.value
                   }
                  { (values.length > 1 && values.length-1 !== i) && <>,&nbsp;</>}
                </p>
              }
            </span>
          )
        }
      })

      return elt
    }


  }

  function isALink(value){
    return /(http:\/\/|https:\/\/)/.test(value)
  }

  function isADate(value) {
    return  (moment(new Date(value), moment.ISO_8601, true).isValid() && isNaN(value));
  }

  function getTranslatedTitle(title){
    let indexLang;
    if(activeLanguage === "fr"){
      indexLang = 1
    }else if(activeLanguage === "en"){
      indexLang = 0
    }
    return title[indexLang]
  }



  return(
    <div className="doc-indicators">
      <h3 onClick={() => setIsOpen(!isOpen)}>
        { isOpen ?
          <FontAwesomeIcon icon={faChevronDown} size={"xs"} /> : <FontAwesomeIcon icon={faChevronRight} size={"xs"}/>
        }
        {title}
      </h3>
      {(isOpen && organizedIndicators) &&
        <div className="indicators">
          {organizedIndicators.map((indicators,i)=>(
            <React.Fragment key={"indicator-"+i}>
              { indicators.entries.length > 0 ?
                <div>
                  <p className="title-indicator">{getTranslatedTitle(indicators.title)}</p>
                  <div className="indicators-containers">
                    {renderIndicators(indicators.entries,indicators.sourceLink)}
                  </div>
                </div> : null
              }
            </React.Fragment>

          ))}
        </div>
      }
    </div>
  )
}

export default withLocalize(Indicators)
