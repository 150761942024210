import React, { useState, useEffect } from 'react';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver'
import sanitize from "sanitize-filename"
import accents from "remove-accents"
import moment from "moment"
import _ from 'lodash'
import format from 'format-number';


import { Translate, withLocalize } from "react-localize-redux";
import { useGlobalContext } from '../../../GlobalContext'
import usePlateforme from '../../../customHooks/usePlateforme'

import checkboxChecked from '../../../assets/images/checkboxes/checkbox-checked.svg'
import checkbox from '../../../assets/images/checkboxes/checkbox.svg'

const fnFormat = (number) => format({integerSeparator: " "})(number)

const PopupExport = ({ids, documents, selectAll,closePopup, translate}) => {
	const context  = useGlobalContext()[0];
	const filtersReducer = context.filtersReducer
	const filtersJSON = usePlateforme()

	const [ selectedDocuments, setSelectedDocuments ] = useState()
	const [ fields, setFields ] = useState()
	const [ selectedFields, setSelectedFields ] = useState([])

	useEffect(() => {
		if(!documents || !filtersJSON) return;

		let selectedDocs;
		if(selectAll){
			selectedDocs = documents
		}else{
			selectedDocs = documents.filter((d)=> ids.includes(d.ID_QES))
		}
		let fields = new Map()

 		let currentHeaderEntry = filtersJSON
		 console.log(currentHeaderEntry)

		fields.set("TITLE",{ id : "TITLE", libelle : translate("documents.fields.title"), type : "characteristic"})
		fields.set("TOP_RESSOURCE",{ id : "TOP_RESSOURCE", libelle : translate("documents.fields.top-ressource"), type : "characteristic"})
		fields.set("VIDEO",{ id : "VIDEO", libelle : translate("documents.fields.video"), type : "characteristic"})
		fields.set("ABSTRACT",{ id : "ABSTRACT", libelle : translate("documents.fields.essential-fact"), type : "characteristic"})
		fields.set("SOURCE_LINK",{ id : "SOURCE_LINK", libelle : translate("documents.fields.source-link"), type : "characteristic"})
		fields.set("PERMA_LINKS",{ id : "PERMA_LINKS", libelle : translate("documents.fields.perma-link"), type : "characteristic"})
		fields.set("DATE_COLLECT",{ id : "DATE_COLLECT", libelle : translate("documents.fields.action-ressource"), type : "characteristic"})
		fields.set("PUB_DATE",{ id : "PUB_DATE", libelle : translate("documents.fields.creation-date"), type : "characteristic"})

		let availabledProperties = []
		let availabledFilters = []

		let ignoreDocProps = filtersJSON["export-ignoredFields"] || [];

		selectedDocs.forEach((doc)=>{

			Object.keys(doc).forEach((key)=>{
				if(!ignoreDocProps.includes(key)){
					if(/^(FILTER_|QES_|SU_)/.test(key) && !availabledFilters.includes(key)){
						availabledFilters.push(key)
					}else if(!availabledProperties.includes(key)){
						availabledProperties.push(key)
					}
				}
			})
		})
		
		availabledFilters.sort().forEach((key)=>{
			if(!fields.get(key)){
				let parentFilterId = key.replace("2","")
				let found = filtersReducer.filters.find((d)=> d.id === key)

				// LOOKING FOR A COUPLE PARENT / CHILD FILTER ( level 1 and level 2)
				let re = new RegExp(parentFilterId)
				let isAffliated;
				let parentLibelle;
				if(availabledFilters.filter((d)=> re.test(d)).length === 2){
					isAffliated = parentFilterId;
					parentLibelle = filtersReducer.filters.find((d)=> d.id === parentFilterId)?.libelle
				}
				

				let trad;
				if(/SU_/.test(key)){
					trad = translate("su."+key)
					if(/translationId:/.test(trad)){
						trad = null
					}
				}

				if(trad && /SU_/.test(key)){
					fields.set(key,{ id: key, libelle: trad, type : /^(QES_)/.test(key) ? "qes" : "filter"});
				}else if(!trad && found){
					fields.set(key,{ id: key, libelle: found.libelle, type : /^(QES_)/.test(key) ? "qes" : "filter", affiliated : isAffliated, parentLibelle})
				}

			}
		})

		let arr = ["TITLE","TOP_RESSOURCE","VIDEO","ABSTRACT","SOURCE_LINK","DATE_COLLECT","PUB_DATE"]

		arr.forEach((prop)=>{
			if(!availabledProperties.includes(prop)){
				fields.delete(prop)
			}
		})

		setFields(fields)
		setSelectedDocuments(selectedDocs)

	},[documents,filtersJSON])

	function selectField(field, action){
		if( action === 'add' ){
			setSelectedFields([...selectedFields, field])
		}else if( action === 'remove' ) {
			setSelectedFields(selectedFields.filter(d => d.id !== field.id))
		}
	}

	function toggleAllField(){
		if(selectedFields.length === Array.from(fields.values()).length){
			setSelectedFields([])
		}else{
			setSelectedFields(Array.from(fields.values()))
		}
	}

	function exportXLSX(){
		if(!selectedDocuments || selectedFields.length === 0) return;
		let wb = XLSX.utils.book_new();

		let user = JSON.parse(localStorage.getItem('user'))

		let plateforme = localStorage.getItem("plateforme")
		let token = localStorage.getItem("token")
		plateforme = JSON.parse(plateforme);

		wb.Props = {
			Title : "Export",
			Subject : "Export",
			Author: `${user.nom} ${user.prenom}`,
			CreatedDate : new Date()
		}

		wb.SheetNames.push("Export");

		let ws_data = [[]]

		let sortSelectedField = []
		Array.from(fields.values()).forEach((field) => {
			if(selectedFields.find(d => d.id === field.id )) sortSelectedField.push(field)
		});

		sortSelectedField.forEach((field)=>{
			ws_data[0].push(field.libelle)
		})

		// PATENT CLASS SU_POP_PATENT_CLASS
		// EXIT DATE SU_EXIT_DATE
		// SU_LAST_FUNDING_DATE
		console.log(selectedDocuments);
		selectedDocuments.forEach((d)=>{
			let line = []
			// line.push(d.TITLE[0])
			sortSelectedField.forEach((field)=>{
				let valuesLibelle = []
				// IF THIS FIELD EXIST IN THE CURRENT INSTANCE OF DOCUMENT
				if(d[field.id]){
					// IF THIS FIELD IS A FILTER AN NOT A QES_ find corresponding label
					if(/^SU_/.test(field.id) && field.id !== 'SU_LAST_FUNDING_DATE'){
						if(Array.isArray(d[field.id]) && d[field.id][0]){
							let values = d[field.id][0].split(";")
							values.forEach((value)=>{
								let find = filtersReducer.matchCode.find(match => match.code === value)
								if(find){
									valuesLibelle.push(find.label)
								}else{
									valuesLibelle.push(d[field.id][0])
								}
							})
						} else if (!isNaN(d[field.id])) {
							valuesLibelle.push(fnFormat(d[field.id]));
						} else if (moment(new Date(d[field.id]), moment.ISO_8601, true).isValid() && isNaN(d[field.id])) {
							valuesLibelle.push(moment(new Date(d[field.id])).format("DD/MM/YYYY"));
					 	} else {
							valuesLibelle.push(d[field.id])
						}
					}else if(/^FILTER_/.test(field.id)){
						let values = d[field.id][0].split(";")
						values.forEach((value)=>{
							let find = filtersReducer.matchCode.find(match => match.code === value)
							if(find) valuesLibelle.push(find.label)
						})
					}else if(/^QES_/.test(field.id)){
						// ADD SPACE BEFORE SEMICOL
						valuesLibelle.push(d[field.id][0].split(";").join("; "))
					}else if(field.id === 'DATE_COLLECT'){
						valuesLibelle.push(moment(new Date(d.DATE_COLLECT)).format("DD/MM/YYYY"))
					}else if(field.id === 'PUB_DATE'){
						valuesLibelle.push(moment(new Date(d.PUB_DATE)).format("DD/MM/YYYY"))
					}else{
						valuesLibelle.push(d[field.id])
					}
				}else if(field.id === 'PERMA_LINKS'){
					let splitID = d.ID_QES.split('/')
			    let search = { id : splitID[0], doc : splitID[1] }
			    let url = window.location.origin+`/document?platform=${filtersReducer.plateformeID}&entry=${filtersReducer.indexSelectedEntries}&id=${search.id}&doc=${search.doc}`
					valuesLibelle.push(url)
					// valuesLibelle.push(plateforme.urlpermlink+d.ID_QES+"&token="+token)
				}
				line.push(valuesLibelle.join("; "))
			})
			ws_data.push(line)
		})

		// aoa = array of array
		let worksheet = XLSX.utils.aoa_to_sheet(ws_data,{ header: 1, blankrows: false, defval: '' });

		Object.keys(worksheet).map(key => {
			let cellObject = worksheet[key]
			if(cellObject.v){
				let regex = new RegExp(/(http:\/\/|https:\/\/)/)
				let htmlReg = new RegExp(/<.+?>/)
				if(regex.test(cellObject.v)){
					let clone = Object.assign({},cellObject)
						clone.l = {
		        'Target': cellObject.v,
		        'Tooltip': cellObject.v
		      }
					worksheet[key] = clone
				}else if(htmlReg.test(cellObject.v)){
					let clone = Object.assign({},cellObject)
					clone.h = cellObject.v
					worksheet[key] = clone
				}
			}
		})

		worksheet['!cols'] = fitToColumn(ws_data);

		wb.Sheets["Export"] = worksheet

		let wb_out = XLSX.write(wb, { bookType : 'xlsx', type : 'binary'});

		// binary to octed
		function s2ab(s){
			let buf = new ArrayBuffer(s.length);
			let view = new Uint8Array(buf);
			for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
			return buf;
		}

		function fitToColumn(arrayOfArray) {
    	// get maximum character of each column
    	return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i].toString().length)) }));
		}

		let date = moment(new Date()).format("DD/MM/YYYY")
		let plateformeName = JSON.parse(localStorage.getItem('plateforme')).libelle
		let filename = sanitize(plateformeName)
		filename = accents.remove(filename)
		filename = filename.replace(/ /g,"_").replace("'","").replace('"',"")
		filename = `${filename}_${date}`

		saveAs(new Blob([s2ab(wb_out)], {type : "application/octect-stream"}), `${filename}.xlsx`)

	}

	function renderCheckboxFields(){
		let prev;

		let arrCharacteristic = Array.from(fields.values()).filter((d) => d.type ==="characteristic")
		let arrFilters = Array.from(fields.values()).filter((d) => d.type ==="filter")

		arrFilters = _.orderBy(arrFilters,[(d) => {
			if(d.parentLibelle) return d.parentLibelle
			else return d.libelle
		},"id"])

		let arrQES = Array.from(fields.values()).filter((d) => d.type ==="qes")
		arrQES = _.orderBy(arrQES,['libelle'],["asc"])
		let arr = [ ...arrCharacteristic, ...arrFilters, ...arrQES ]

		return (
			<>
			<ul className="list-fields">
				{arr.map((d,i)=>{
					let selected = selectedFields.find(field => field.id === d.id)
					let separator;
					if(d.type !== prev && i !== 0){
						separator = <li key="separator" className="separator"></li>
					}
					prev = d.type
					return (
						<React.Fragment key={d.id}>
							{separator}
							<li 
								onClick={() => selectField(d, selected ? "remove" : "add")}
								style={d.affiliated || d.type === "characteristic" ? {width : "50%"} : {width : "100%"}}
							>
								{selected ?
									<img src={checkboxChecked} alt="checkbox checked"/> :
									<img src={checkbox} alt="checkbox"/>
								}
								<p>{d.libelle}</p>
							</li>
						</React.Fragment>
					)
				})}
			</ul>
			<button onClick={exportXLSX} className={selectedFields.length > 0 ? "" : "disabled"}><Translate id="documents.button-export"/></button>
			</>
		)
	}



	return(
    <>
      <div className="popup-export-overlay" onClick={closePopup}>
      </div>
      <div className="popup-export">
				{ ids.length === 0 && !selectAll ?
					<>
						<h3 className="no-docs-selected"><Translate id="documents.no-documents-selected"/></h3>
					</> :
					<>
						{ selectAll ?
							<h3>{documents.length} document{ids.length > 0 && "s"} <Translate id="documents.ready-to-export"/></h3> :
							<h3>{ids.length} document{ids.length > 0 && "s"} <Translate id="documents.ready-to-export"/></h3>
						}
						<div className="title-list">
							<p><Translate id="documents.export-fields"/></p>
							<p onClick={toggleAllField}>
								{ (
										(selectedFields && fields) &&
										(selectedFields.length === Array.from(fields.values()).length)
									) ?
									<Translate id="documents.export-deselect-all"/> :
									<Translate id="documents.export-select-all"/>
								}
							</p>
						</div>
						{ fields && renderCheckboxFields() }
					</>
				}
      </div>
    </>
	)
}

export default withLocalize(PopupExport)
