import React from 'react';

export default function IconWordsCloud (){
  return(
    <svg width="22px" height="16px" viewBox="0 0 22 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="keyfigures" transform="translate(-23.000000, -427.000000)" fill="#8A8BA2">
                <g id="Group" transform="translate(22.000000, 229.000000)">
                    <path d="M18.754,204.496 C19.5313372,204.554667 20.2426634,204.792998 20.888,205.211 C21.5333366,205.629002 22.0466648,206.17533 22.428,206.85 C22.8093352,207.52467 23,208.257996 23,209.05 C23,209.886004 22.7946687,210.655996 22.384,211.36 C21.9733313,212.064004 21.4160035,212.621331 20.712,213.032 C20.0079965,213.442669 19.2380042,213.648 18.402,213.648 L6.5,213.648 C5.50266168,213.648 4.58233755,213.402336 3.739,212.911 C2.89566245,212.419664 2.22833579,211.752338 1.737,210.909 C1.24566421,210.065662 1,209.145338 1,208.148 C1,207.223995 1.21633117,206.362337 1.649,205.563 C2.08166883,204.763663 2.67199626,204.111003 3.42,203.605 C4.16800374,203.098997 4.99666212,202.794667 5.906,202.692 C6.4926696,201.562661 7.32132798,200.66067 8.392,199.986 C9.4920055,199.296663 10.6946601,198.952 12,198.952 C13.1000055,198.952 14.1266619,199.193998 15.08,199.678 C16.0186714,200.147336 16.8106634,200.803662 17.456,201.647 C18.1013366,202.490338 18.5339989,203.439995 18.754,204.496 Z" id="icon-words-cloud"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}
