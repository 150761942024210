import React from 'react'
import queryString from 'query-string'
import { withLocalize, Translate } from 'react-localize-redux'
import { useGlobalContext } from '../../../../GlobalContext'
import { fnSelectEntry } from '../../../header/helpers'
import { getIndexLang } from '../../../../helpers/helpers'
import history from '../../../../history'
import { getPlateforme } from '../../../../customHooks/usePlateforme'

import styles from './block-link.module.scss'

const BlockLink = ({
    title,
    layout = "left",
    punchline,
    text,
    url,
    link,
    img,
    video,
    activeLanguage,
    filtersJSON
}) => {
    const [context, dispatch]  = useGlobalContext();
    const filtersReducer = context.filtersReducer
    const viewReducer = context.viewReducer

    const _fnSelectEntry = (entry,indexSelectedEntries,index,url) => {
        return (
          fnSelectEntry(
            history,
            filtersJSON,
            dispatch,
            entry,
            indexSelectedEntries,
            index,
            url
          )
        )
    }

    // function goTo(){
    //     if(url){
    //         window.open(url, '_blank').focus();
    //     }else if(filtersJSON){
    //         let findIndex = filtersJSON.header.findIndex(d=>d.id === linkTo)
    //         let findEntry = filtersJSON.header.find(d=>d.id === linkTo)
    //         if(findIndex > 0){
    //             _fnSelectEntry(
    //                 findEntry,
    //                 filtersReducer.indexSelectedEntries,
    //                 findIndex
    //             )
    //         }
    //     }
    // }

    function goToView(){
        if(url){
            window.open(url, '_blank').focus();
        }else{
            let search = history.location.search
            let entry = queryString.parse(search)?.entry
            let url = `/search-innov/${filtersReducer.plateformeID}${link.url}`
            
  
            // STAY ON THAT PLATFORM AND THAT ESPACE BUT GO TO AN OTHER TOOL
            if(!link["platform-id"] && !link["espace-id"]){
                history.push({
                    pathname : url,
                    search : entry ? `?entry=${entry}` : ''
                })
            }else{
                let entries;
                if(link["platform-id"]){
                    entries = getPlateforme(link["platform-id"])?.header;
                }else if (link["espace-id"] && !link["platform-id"]){
                    entries = filtersJSON.header;
                }
        
                if(entries){
                    let findIndex = entries.findIndex((d) => d.id === link["espace-id"])
                    let findEntry = entries.find(d=>d.id === link["espace-id"])
                    if(link.url) url = `/search-innov/${filtersReducer.plateformeID}${link.url}`;
                    else url = `/search-innov/${filtersReducer.plateformeID}${findEntry.url}`;
                    if(link["espace-id"] && link["platform-id"] && findIndex >= 0){
                        //GO TO AN OTHER PLATFORM
                        let operator = new RegExp('\\?').test(link.url) ? '&' : '?'
                        history.push(`/search-innov/${link["platform-id"]}${link.url ? link.url : findEntry.url}${operator}entry=${findIndex}`)
                        document.location.reload();
                    }else if(link["espace-id"] && !link["platform-id"] && findIndex >= 0){
                        //STAY ON THAT PLATFORM BUT GO TO AN OTHER ESPACE
                        _fnSelectEntry(
                            findEntry,
                            filtersReducer.indexSelectedEntries,
                            findIndex,
                            url
                        );
                    }
                }
            }

        }
    }

    function getClassNameLightMode(){
        return viewReducer.lightmode === "light" ? ` ${styles.light}` : ''
    }

    return (
        <div className={`${styles["block-link"]} ${styles[layout]}${getClassNameLightMode()}`}>
            <div className={styles.content}>
                {title && <h4>{title[getIndexLang(activeLanguage)]}</h4>}
                {punchline && <h3>{punchline[getIndexLang(activeLanguage)]}</h3>}
                {text && <p>{text[getIndexLang(activeLanguage)]}</p>}
                {(link || url) && <a onClick={() => goToView()}><Translate id="home.block-link.access"/></a>}
            </div>
            {(img && !video) &&
                <div className={`${styles["container-media"]} ${styles.image}`}>
                   {<img src={img} alt={title[getIndexLang()]} />}
                </div>
            }
            {video &&
                <div className={`${styles["container-media"]} ${styles.video}`}>
                   <video controls>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            }
        </div>
    )
}


export default withLocalize(BlockLink)
