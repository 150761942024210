import React, { useState, useLayoutEffect, useRef } from 'react'
import _ from 'lodash'
import queryString from 'query-string'
import { withLocalize } from 'react-localize-redux'
import { useGlobalContext } from '../../../../GlobalContext'
import styles from './block-shortcut.module.scss'
import { getIndexLang } from '../../../../helpers/helpers'
import { fnSelectEntry } from '../../../header/helpers'
import usePlateforme, { getPlateforme } from '../../../../customHooks/usePlateforme'

import history from '../../../../history'


const BlockShortcut = ({
    title,
    text,
    img,
    picto,
    link,
    activeLanguage
}) => {
    const [ context, dispatch ]  = useGlobalContext();
    const filtersReducer = context.filtersReducer;
    const viewReducer = context.viewReducer;
    const filtersJSON = usePlateforme()
    const descriptionRef = useRef()
    const [ translateY, setTranslateY ] = useState()

    const _fnSelectEntry = (entry,indexSelectedEntries,index,url) => {
        return (
          fnSelectEntry(
            history,
            filtersJSON,
            dispatch,
            entry,
            indexSelectedEntries,
            index,
            url
          )
        )
    }

    useLayoutEffect(() => {
        hideDescription()
    },[
        descriptionRef.current,
        window.innerHeight,
        window.innerWidth
    ])

    function hideDescription(){
        let { current } = descriptionRef;
        if(!current) return;
        setTranslateY(descriptionRef.current.offsetHeight+15)
    }

    function goToView(){
        let search = history.location.search
        let entry = queryString.parse(search)?.entry
        let url = `/search-innov/${filtersReducer.plateformeID}${link.url}`

        let findIndex;
        let findEntry;

        //STAY ON THAT PLATFORM AND THAT ESPACE BUT GO TO AN OTHER TOOL
        if(!link["platform-id"] && !link["espace-id"]){
            history.push({
                pathname : url,
                search : entry ? `?entry=${entry}` : ''
            })
        }else{
            let entries;
            if(link["espace-id"] && link["platform-id"]){
                entries = getPlateforme(link["platform-id"])?.header;
            }else if (link["espace-id"] && !link["platform-id"]){
                entries = filtersJSON.header;
            }

            if(entries){
                findIndex = entries.findIndex((d) => d.id === link["espace-id"])
                findEntry = filtersJSON.header.find(d=>d.id === link["espace-id"])
                if(link.url) url = `/search-innov/${filtersReducer.plateformeID}${link.url}`;
                else url = `/search-innov/${filtersReducer.plateformeID}${findEntry.url}`;
                
                if(link["espace-id"] && link["platform-id"] && findIndex >= 0){
                    //GO TO AN OTHER PLATFORM
                    let operator = new RegExp('\\?').test(link.url) ? '&' : '?'
                    history.push(`/search-innov/${link["platform-id"]}${link.url ? link.url : findEntry.url}${operator}entry=${findIndex}`)
                    document.location.reload();
                }else if(link["espace-id"] && !link["platform-id"] && findIndex >= 0){
                    //STAY ON THAT PLATFORM BUT GO TO AN OTHER ESPACE
                    _fnSelectEntry(
                        findEntry,
                        filtersReducer.indexSelectedEntries,
                        findIndex,
                        url
                    );
                }
            }
        }
    }
    
    function getClassNameLightMode(){
        return viewReducer.lightmode ? ` ${styles.light}` : ''
    }

    function isTouchScreendevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;      
    };

    return (
        <div
            className={`${styles["block-shortcut"]}${getClassNameLightMode()}`}
            style={img ? {backgroundImage : `url(${img})`} : {}}
            onClick={() => goToView()}
            onMouseEnter={() => setTranslateY(0)}
            onMouseLeave={() => hideDescription()}
        >
            {picto && <div className={styles["picto"]}><img src={picto} alt={title[getIndexLang(activeLanguage)]}/></div>}
            <div className={styles.mask}>
                <div className={styles.content} style={!isTouchScreendevice() ? { transform : `translateY(${translateY}px)`} : {}}>
                    {title && <h5>{title[getIndexLang(activeLanguage)]}</h5>}
                    {text && <p ref={descriptionRef}>{text[getIndexLang(activeLanguage)]}</p>}
                </div>     
            </div>
            
            <div className={styles.gradient}>
                
            </div>
        </div>
    )
}

export default withLocalize(BlockShortcut);