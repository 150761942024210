import { postData } from './index'
import {
   AUTH_LOGIN,
   ERROR_AUTH
} from './types'

export async function login(dispatch, obj, change = "default"){
  let url = 'auth/login-imaker'
  await postData(AUTH_LOGIN, ERROR_AUTH, true, url , dispatch, obj)
}

export async function loginSSO(dispatch, obj){
  dispatch({
    type : AUTH_LOGIN,
    payload : obj
  })
}
