import { useEffect } from 'react'
import { useGlobalContext } from '../GlobalContext'
import { setFilterView } from '../actions/filters'

export default function useFilterView(filterview){
  const dispatch = useGlobalContext()[1];
  const _setFilterView = (f) => setFilterView(dispatch,f)

  useEffect(()=>{
    // console.log(filterview);
    _setFilterView(filterview)
  },[filterview])

}
