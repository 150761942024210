import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import axios from 'axios';
import { withLocalize, Translate } from 'react-localize-redux'
import { API_URL } from '../../../../actions/index.js'
import history from "../../../../history"
import usePlateforme from '../../../../customHooks/usePlateforme'
import { useGlobalContext } from '../../../../GlobalContext'
import { filtersParams } from '../../../../helpers/filtersParams'
import { getIndexLang, getLinkDocument, renderDate }  from '../../../../helpers/helpers'

import StyledSelect from '../../../partials/StyledSelect'
import ImgItem from '../../../partials/ImgItem'
import LoadingDocument from '../LoadingDocument'
import styles from './last-content.module.scss'

const LastContent = ({
  rubriques = null,
  espaces = null,
  blockTitle = null,
  noResult = [],
  documentsCount = 15,
  activeLanguage,
}) => {
  const [ context ]  = useGlobalContext();
  const filtersReducer = context.filtersReducer;
  const viewReducer = context.viewReducer;
  const headerLastContent = useRef();
  const filtersJSON = usePlateforme();

  //THIS STATE IS THE LIST OF FILTERS AVAILABLE
  //ACCORDING TO THE FILTER BLOCK CONFIGURATION
  //IT'S INIT AT THE FIRST CALL OF getDocuments()
  const [ allFilters, setAllFilters ] = useState()

  const [ optionsRubriques, setOptionsRubriques ] = useState()
  const [ selectedRubrique, setSelectedRubrique ] = useState()

  const [ optionsEspaces, setOptionsEspaces ] = useState()
  const [ selectedEspace, setSelectedEspace ] = useState()

  const [ title, setTitle ] = useState()
  const [ isLoading, setIsLoading ] = useState()
  const [ textNoResult, setTextNoResult ] = useState()
  const [ documents, setDocuments ] = useState()
  const [ maxItems, setMaxItems ] = useState()
  const [ listHeight, setListHeight ] = useState('inherit')

  useEffect(() => {
    if(activeLanguage.code === "en"){
      if(blockTitle) setTitle(blockTitle[0])
      if(noResult) setTextNoResult(noResult[0])
    }else if (activeLanguage.code === "fr") {
      if(blockTitle) setTitle(blockTitle[1])
      if(noResult) setTextNoResult(noResult[1])
    }
  }, [activeLanguage.code])

  //SET NUMBER OF ITEMS IN LIST
  useLayoutEffect(() => {
    if(!optionsRubriques || !window.innerHeight) return;
    let header = document.querySelector('.App #header');
    let topbar = document.querySelector('.home .container-search');

    let headerHeight = topbar.offsetHeight + header.offsetHeight;
    let listHeight = window.innerHeight - (headerHeight + headerLastContent.current.offsetHeight);
    setMaxItems(Math.floor(listHeight/90));
    setListHeight(`${listHeight}px`)
  }, [headerLastContent.current, optionsRubriques,window.innerHeight])

  // SET OPTIONS RUBRIQUES
  useEffect(() => {
    if(!allFilters || (optionsRubriques && optionsRubriques.length>0)) return;
    if(rubriques && rubriques.length === 0){
      setOptionsRubriques([])
    }else{
      let found = allFilters.find(d => d.id === "RUBRIQUE")
      if(found){
        let values = found.valeurs
        if(values && values.length>0){
          let opts = values
          if(rubriques && rubriques.length>0) opts = opts.filter(d => rubriques.includes(d.code));
          opts = opts.map(d=>({label : `${d.libelle} (${d.frequence})`, value : d.code, id : "RUBRIQUE"}))
          setOptionsRubriques(opts)
        }
      }else{
        setOptionsRubriques([])
      }
    }
  }, [allFilters])

  // SET OPTIONS LIST ESPACES
  useEffect(() => {
    if (!filtersJSON) return;
    if(espaces && espaces.length === 0){
      setOptionsEspaces([])
    }else{
      let options = []
      filtersJSON.header.forEach((entry) => {
        if(typeof entry.id === "number" && (!espaces || espaces.includes(entry.id))){
          options.push({
            label: entry.title[getIndexLang(activeLanguage)],
            value: entry.id,
            entry
          });
        }
      })
      setOptionsEspaces(options)
    }
  }, [filtersJSON])

  useEffect(() => {
    if(!filtersReducer.filters || isLoading) return;
    getDocuments()
  }, [
    filtersReducer.filters,
    selectedRubrique,
    selectedEspace
  ])

  function getDocuments(){
    const requestUrl = API_URL + 'doc/filtered';
    let { filters : params } = filtersParams(filtersReducer,viewReducer.lang)
    setIsLoading(true)
    let config = {
      headers: {
        Authorization : localStorage.getItem('token')
      }
    }

    params.number = maxItems || documentsCount
    params.orderBy = "date_ressource_desc"

    let defaultBlockFilters = []

    if(rubriques && rubriques.length>0){
      let rub = rubriques.join("%20OR%20")
      defaultBlockFilters.push({id : "RUBRIQUE", code : rub})
    }

    params.filters = [...params.filters, ...defaultBlockFilters]

    if(selectedRubrique){
      params.filters.push({id: selectedRubrique.id, code : selectedRubrique.value})
    }

    if(selectedEspace){
      let selectedEntry = selectedEspace.entry
      if(selectedEntry.rubrique && selectedEntry.rubrique.length>0) {
        selectedEntry.rubrique.forEach((item) => {
          params.filters.push({id: "RUBRIQUE", code : item})
        });
      }

      if(selectedEntry.filters && selectedEntry.filters.length>0) {
        let filters = []
        filters = selectedEntry.filters.map((item) => (
          {id: item.id, code : item.code}
        ));
        params.filters = [...params.filters, ...filters]
      }

      if(selectedEntry["filters-exclude"] && selectedEntry["filters-exclude"].length>0) {
        let excludes = []
        excludes = selectedEntry["filters-exclude"].map((item) => (
          {id: item.id, code : item.code}
        ));
        params.excludes = params.excludes ? [...params.excludes, ...excludes] : [ ...excludes];
      }
      
    }

    axios.post(requestUrl, params, config)
      .then((response) => {
        setDocuments(response.data.result.docs)
        setIsLoading(false)
        if(!allFilters) setAllFilters(response.data.result.filters)
      })
      .catch((error) => {
        setDocuments([])
        setIsLoading(false)
      });

  }

  function handleChangeRubrique(option,change){
    setSelectedRubrique(option)
  }

  function handleChangeEspace(option,change){
    setSelectedEspace(option)
  }

  function openDocument(doc){
    let url =  getLinkDocument(doc, filtersReducer); 
    window.location.href = url
  }

  function getImage(image){
    if(Array.isArray(image)){
      return image[0]
    }else if (image){
      return image
    }else{
      return null
    }
  }

  function getClassNameLightMode(){
    return viewReducer.lightmode === "light" ? ` ${styles.light}` : ''
  }

  return (
    <div className={isLoading ? `${styles["last-content"]} ${styles["loading"]}${getClassNameLightMode()}` : `${styles["last-content"]}${getClassNameLightMode()}`}>
      <div ref={headerLastContent}>
        <div className={styles["block-title"]}>
          <h3>{title}</h3>
          {(documents) &&
            <div className={styles["link-entry"]}>
              <p onClick={()=> history.push({
                pathname: `/search-innov/${filtersReducer.plateformeID}/strategic-watch`,
                search: history.location.search
              })}><Translate id="home.see-all"/></p>
            </div>
          }
        </div>
        { (optionsEspaces && optionsEspaces.length>0) &&
          <div className={styles["select-filter"]}>
            <StyledSelect
              value={selectedEspace}
              options={optionsEspaces}
              onChange={handleChangeEspace}
              placeholder={"Espaces"}
              style={viewReducer.lightmode === 'light' ? "style3" : 'style3Dark'}
            />
          </div>
        }
        { (optionsRubriques && optionsRubriques.length>0) &&
          <div className={styles["select-filter"]}>
            <StyledSelect
              value={selectedRubrique}
              options={optionsRubriques}
              onChange={handleChangeRubrique}
              placeholder={"Rubrique"}
              style={viewReducer.lightmode === 'light' ? "style3" : 'style3Dark'}
            />
          </div>
        }
      </div>
      {(documents && typeof maxItems === "number") ?
        <>
          { documents.length > 0 &&
            <ul className={styles["list-documents"]} style={{ height : `${listHeight}`}}>
              {documents.slice(0,maxItems).map(doc =>
                <li key={`document-${doc.ID_QES}`} onClick={() => openDocument(doc)}>
                  <ImgItem
                    className={styles["image-doc"]}
                    image={getImage(doc.IMAGE)}
                  />
                  <div>
                    <p className={styles["date"]}>{renderDate(doc.PUB_DATE,activeLanguage.code)}</p>
                    <label>{doc.TITLE}</label>
                  </div>
                </li>
              )}
            </ul>
          }
        </> :
        <ul className={styles["list-documents"]}>
          {Array(5).fill(null).map((d,i) =>
            <li key={`document-${i}`}>
               <LoadingDocument/>
            </li>
          )}
        </ul>
      }
      { (documents && documents.length === 0 ) &&
        <p className={styles["no-documents"]}>
          {textNoResult}
        </p>

      }

    </div>
  )

}

export default withLocalize (LastContent)
