export const filtersParams = (filtersReducer, lang = "en",number, viewFilters) => {

  let arrCode = []
  let arrCodeFilters = []
  let arrCodeExcludes = []

  if(filtersReducer.selectedKeywords){
    filtersReducer.selectedKeywords.forEach((d)=>{
      arrCode.push({ code : d.code, id : d.id})
      if(d.exclude){
        arrCodeExcludes.push({ code : d.code, id : d.id})
      }else{
        arrCodeFilters.push({ code : d.code, id : d.id})
      }
    })
  }

  // DEFAULT FILTERS PROVIDE IN CONFIG
  if(!viewFilters && filtersReducer.defaultFilters){
    filtersReducer.defaultFilters.forEach((d)=>{
      arrCodeFilters.push({ code : d.code, id : d.id})
    })
  }

  // DEFAULT EXCLUDED FILTERS PROVIDE IN CONFIG
  if(!viewFilters && filtersReducer.defaultExcludedFilters){
    filtersReducer.defaultExcludedFilters.forEach((d)=>{
      arrCodeExcludes.push({ code : d.code, id : d.id})
    })
  }

  if(viewFilters && viewFilters.length>0){
    viewFilters.forEach((d)=>{
      arrCodeFilters.push({ code : d.code, id : d.id})
    })
  }


  let dateStart = null
  let dateEnd = null

  if(filtersReducer.filteredByDate){
    if(filtersReducer.date !== null && filtersReducer.date.min.date !== null){
      dateStart = new Date(filtersReducer.date.min.date)
    }

    if(filtersReducer.date !== null && filtersReducer.date.max.date !== null){
      dateEnd = new Date(filtersReducer.date.max.date)
    }
  }

  let filters = {
    search : filtersReducer.search,
    filters : arrCodeFilters, // array
    excludes : arrCodeExcludes.length === 0 ? null : arrCodeExcludes,
    start : dateStart,
    end : dateEnd,
    page : 1,
    number : number ? number : 30,
    favorite : false,
    orderBy : "",
    plateformeID : filtersReducer.plateformeID, // 1005,
    lang
  }

  //platformes libelle: "iTrend innovation", id: 58

  return {filters , arrCode}

}

export const datavizParams = (filtersReducer, lang = "en") => {

  let arrCodeFilters = []
  let arrCodeExcludes = []
  if(filtersReducer.selectedKeywords){
    filtersReducer.selectedKeywords.forEach((d)=>{
      if(d.exclude){
        arrCodeExcludes.push({ code : d.code, id : d.id})
      }else{
        arrCodeFilters.push({ code : d.code, id : d.id})
      }
    })
  }

  // DEFAULT FILTERS PROVIDE IN CONFIG
  if(filtersReducer.defaultFilters){
    filtersReducer.defaultFilters.forEach((d)=>{
      arrCodeFilters.push({ code : d.code, id : d.id})
    })
  }

  // DEFAULT EXCLUDED FILTERS PROVIDE IN CONFIG
  if(filtersReducer.defaultExcludedFilters){
    filtersReducer.defaultExcludedFilters.forEach((d)=>{
      arrCodeExcludes.push({ code : d.code, id : d.id})
    })
  }

  let dateStart = null
  let dateEnd = null

  if(filtersReducer.filteredByDate){
    if(filtersReducer.date !== null && filtersReducer.date.min.date !== null){
      dateStart = new Date(filtersReducer.date.min.date)
    }
    if(filtersReducer.date !== null && filtersReducer.date.max.date !== null){
      dateEnd = new Date(filtersReducer.date.max.date)
    }
  }

  let filters = {
    search : filtersReducer.search,
    filters : arrCodeFilters, // array
    excludes : arrCodeExcludes.length === 0 ? null : arrCodeExcludes,
    start : dateStart,
    end : dateEnd,
    plateformeID : filtersReducer.plateformeID,
    lang
  }

  return filters

}
