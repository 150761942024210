import React, { useEffect } from 'react'
import _ from 'lodash'
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import { useGlobalContext } from '../../../GlobalContext'
import { useFormInput } from '../../../customHooks/useFormInput'
import { getPlateforme } from '../../../customHooks/usePlateforme'
import { login } from '../../../actions/auth'
import { setPlateformeId } from '../../../actions/filters'

import loginTranslations from "../../../assets/translations/login.json";
import InputField from '../../partials/InputField'

import { Translate } from "react-localize-redux";

import imgLogin from '../../../assets/images/login-img.svg'
import imgAnna from '../../../assets/images/anna2.svg'
import imgLogoImaker from '../../../assets/images/logo-imaker.png'
import imgLogoInspiringTrend from '../../../assets/images/logo-inspiring-trend.png'

import imgLogo from '../../../assets/images/LOGO-ONESTEPAHEAD_HORIZONTAL.png'

import styles from './login.module.scss';

const Login = (props) => {
  const context = useGlobalContext()[0];
  const dispatch = useGlobalContext()[1];
  const authReducer = context.authReducer;

  const email = useFormInput('', props.translate("login.form.email"))
  const password = useFormInput('', props.translate("login.form.pwd"), 'password')

  /// ACTIONS
  const _login = (obj) => login(dispatch, obj)
  const _setPlateformeId = (id) => setPlateformeId(dispatch, id)

  function signIn() {
    if (email.DOMAttribute.value !== '' && password.DOMAttribute.value !== '') {
      _login({ email: email.DOMAttribute.value, password: password.DOMAttribute.value })
    }
  }

  function handlePressEnter(e) {
    if (e.key !== "Enter") return;
    signIn()
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token")
    }
    if (localStorage.getItem("plateforme")) {
      localStorage.removeItem("plateforme")
    }
    if (localStorage.getItem("plateformes")) {
      localStorage.removeItem("plateformes")
    }
    if (localStorage.getItem("user")) {
      localStorage.removeItem("user")
    }
    if (localStorage.getItem("filters")) {
      localStorage.removeItem("filters")
    }
    if (localStorage.getItem("search")) {
      localStorage.removeItem("search")
    }
    if (localStorage.getItem("popup")) {
      localStorage.removeItem("popup")
    }
    if (localStorage.getItem("plateformeID")) {
      localStorage.removeItem("plateformeID")
    }
    if (localStorage.getItem('previousDoc')) {
      localStorage.removeItem("previousRef")
    }

    // if(localStorage.getItem('lang')){
    //   localStorage.removeItem("lang")
    // }

    props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "French", code: "fr" }
      ],
      translation: {
        ...loginTranslations
      },
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true
      }
    });

  }, [])

  useEffect(() => {
    if (authReducer.isAuthenticated) {


      let plateformes = _.unionBy(authReducer.plateformes, 'id');

      let idPlateformeSelectedOnLog = 1015;
      let plateformeSelected = plateformes.find((plateforme) => plateforme.id === idPlateformeSelectedOnLog);

      if (plateformeSelected) {
        plateformes = plateformes.filter((plateforme) => plateforme.id !== idPlateformeSelectedOnLog);
        plateformes = [plateformeSelected, ...plateformes];
      }


      //////////////////

      localStorage.setItem("plateformeID", plateformes[0].id);
      _setPlateformeId(plateformes[0].id);

      let plateforme = authReducer.plateformes.find((d) => {
        return d.id === plateformes[0].id;
      });

      localStorage.setItem("plateformes", JSON.stringify(plateformes));
      localStorage.setItem("plateforme", JSON.stringify(plateforme));
      localStorage.setItem("popup", JSON.stringify({ popup: true }));

      let json = getPlateforme(plateformes[0].id);
      let findEntry = json.header.find(d => d.url || d.navbar);
      let findEntryIndex = json.header.findIndex(d => d.url || d.navbar);

      // {
      //   pathname : "search-innov/"+plateformes[0].id+firstView.url,
      //   search: "?" + new URLSearchParams({entry: findEntryIndex}).toString()
      // }

      if (findEntry.url) {
        props.history.push({
          pathname: "search-innov/" + plateformes[0].id + findEntry.url,
          search: "?" + new URLSearchParams({ entry: findEntryIndex }).toString(),
        });
      } else if (findEntry.navbar) {
        let firstView = findEntry.navbar.find(d => d.url);
        props.history.push({
          pathname: "search-innov/" + plateformes[0].id + firstView.url,
          search: "?" + new URLSearchParams({ entry: findEntryIndex }).toString(),
        });
      }

    }
  }, [authReducer.isAuthenticated])


  return (
    <div className={styles.login}>
      <div className={`${styles.left} hide-on-mobile`}>
       
      </div>
      <div className={styles.right}>
        <div className={styles["col-2"]}>
          <div>
            <div className={`${styles['container-logo-imaker']} only-on-mobile`} ><img src={imgLogoImaker} alt="intelligence maker" /></div>
            <h1><Translate id="login.form.title.big" /></h1>
            <h3><Translate id="login.form.title.sub" /></h3>
            <span onKeyPress={handlePressEnter}>
              <InputField initialState={email} />
              <InputField initialState={password} />
            </span>
            {authReducer.messageError ? <p className="error">{authReducer.messageError}</p> : null}
            <button onClick={signIn}><Translate id="login.form.button" /></button>
          </div>
          <div className="hide-on-mobile"><img src={imgAnna} alt="anna" /></div>
        </div>
      </div>
      <div className={`${styles['container-logo-inspiring']} only-on-mobile`} >
        <div><img src={imgLogoInspiringTrend} alt="intelligence maker" /></div>
      </div>
    </div>
  )
}

export default withLocalize(Login)
